/*eslint-disable*/
import { getFirestoreData, updateFirestoreWithOneObject } from 'store/actions/firestoreActions.jsx'
import { startSetUser, updateUser } from 'store/actions/profileActions.jsx'
import moment from "moment"
import { db, firebase } from "firebase/fbConfig.js"

export const setFirstAction = (user, displayName) => {
  console.log("checking action", user, displayName)
  // create a first action
  let actionData = {
    status: "assigned",
    volUID: user.uid,
    title: "Take the Assessment",
    description: "Click Assess Tab and take the assessment",
    dueDate: new Date(),
    days: 1,
    points: 100,
    updatedAt: new Date()
  }

  let userData = { }

  if (!displayName) {
    return db.collection('TEST-users').doc(user.uid).get()
    .then((snapshot) => {
      snapshot = snapshot.data()
      console.log("🚀 ~ .then ~ snapshot:", snapshot)
      let userData = {
        displayName: snapshot.displayName,
        email: snapshot.email,
        score: 0,
        hideData: false,
        groups: [],
        termGroups: [],
      }

      updateUser(userData, user.uid)
    })

  } else {
   userData = {
      displayName: displayName,
      email: user.email,
      score: 0,
      hideData: false,
      groups: [],
      termGroups: [],
    }

    updateUser(userData, user.uid)
  }
}

export const startSetActionsList = (userID) => {
  console.log("🚀 ~ startSetActionsList ~ userID:", userID)
  return (dispatch) => {
    dispatch(setActionsList([]))
  }
}

export const setActionsList = (dataRows) => ({
  type: 'SET_ACTIONS_LIST',
  dataRows
})

export const startSetPendingActionsList = () => {
  // Get firestore Data and convert it to tableData format
  return (dispatch) => {
    dispatch(setPendingActionsList([]))
  }
}

export const setPendingActionsList = (dataRows) => ({
  type: 'SET_PENDING_ACTIONS_LIST',
  dataRows
})

// Get Users for Leaderboard and Profile
export const startSetUsersList = (user) => {
  const UID = user.uid
  let userIndex
  return (dispatch) => {
  db.collection("TEST-users").where("email", "==", user.email).get()
    .then((snapshot) => {
      const data = [];
      // Parse data into array like in firebase.js
      snapshot.forEach((doc) => {
        var docObj = {
          ...doc.data()
        }
        console.log('startSetUsersList', docObj, user)
        // put index of user to userIndex and pass user info to startSetUser
        if(doc.id == UID){
          userIndex = data.length
          dispatch(startSetUser(docObj, user))
        }

        data.push([docObj.displayName, docObj.score]);
      })
      dispatch(setUsersList(data, userIndex))
    })
  }
}

export const setUsersList = (tableData, userIndex) => ({
  type: 'SET_USERS_LIST',
  tableData,
  userIndex
})

export const setUserScore = (score, uid) => {
  const userObject = {
    score: score
  }
  db.collection("TEST-users").doc(uid).set({
    ...userObject,
    updatedAt: new Date()
  }, {merge: true}).then(() => {
    window.location.reload()
  }).catch((err) => {
    return (`Update TEST-users with id: ${uid} with error: ${err}`)
  })
}

export const resetActionsData = () => ({
  type: 'RESET_ACTIONS_DATA'
})
