// brokers Data
  const pages = {
    header: "Park Center Lounge"
  };

  const initState = {
    pages: pages
  }

  export default (state = initState, action) => {
    switch (action.type) {
      default:
        return state
    }
  }
