import React from "react";
import Actions from "views/Actions/Actions.jsx";
import JobApplication from "views/JobApplication/JobApplication.jsx";
import Brokers from "views/Brokering/Brokers.jsx";
import Orders from "views/Brokering/Orders.jsx";
import Profile from "views/Profile/Profile.jsx";
import PendingActions from "views/Admin/PendingActions.jsx";
import Users from "views/Admin/Users.jsx";
import pagesRoutes from "./pages.jsx";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import Apps from "@material-ui/icons/Apps";
import PersonIcon from "@material-ui/icons/Person";
import ContactsIcon from "@material-ui/icons/Contacts";
import GetAppIcon from "@material-ui/icons/GetApp";
import PeopleIcon from "@material-ui/icons/People";
import QueueMusicIcon from "@material-ui/icons/QueueMusic";
import LibraryMusicIcon from "@material-ui/icons/LibraryMusic";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import SettingsIcon from "@material-ui/icons/Settings";
import AnnouncementIcon from "@material-ui/icons/Announcement";
// import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import DanceIcon from "assets/img/danceIcon.png";
import { FaBook } from "react-icons/fa";

/* Karaoke Routes */
import PrivateQueue from "../views/Request/PrivateQueue.jsx";
import SongPreview from "../views/Preview/SongPreview.jsx";
import UserSongs from "../views/Preview/UserSongs.jsx";
import QueueManager from "../views/Admin/QueueManager.jsx";
import SongDownloadRequest from "../views/Admin/SongDownloadRequest.jsx";
import DownloadRequests from "../views/Admin/DownloadRequests.jsx";
import AdminDanceRequests from "../views/Admin/AdminDanceRequests.jsx";
import UserDanceRequests from "../views/Preview/UserDanceRequests.jsx";
import CollectionSearchResults from "../views/Pages/CollectionSearchResults.jsx";
import SpotifySearchResults from "../views/Pages/SpotifySearchResults.jsx";
import DanceRequestSearch from "../views/Pages/DanceRequestSearch.jsx";
import Config from "../views/Config/Config.jsx";
import WhatsNew from "../views/Admin/WhatsNew.jsx";
import HowToUse from "../views/Pages/HowToUse.jsx";
import Favorites from "../views/Preview/Favorites.jsx";
import DanceRequestPreviewLoggedIn from "../views/Preview/DanceRequestPreviewLoggedIn.jsx";

export default [
  {
    collapse: true,
    admin: true,
    path: "/admin",
    name: "Admin",
    icon: DashboardIcon,
    views: [
      // { path: "/admin/users", name: "Users", component: Users, icon: PeopleIcon, },
      {
        path: "/admin/kj-dashboard",
        name: "KJ Dashboard",
        component: QueueManager,
        icon: ContactsIcon,
      },
      // { path: "/admin/download-requests", name: "DL Requests", component: DownloadRequests, icon: GetAppIcon, },
      // { path: "/admin/dance-requests", name: "Dance Requests", component: AdminDanceRequests, icon: MusicNoteIcon, },
      {
        path: "/config",
        name: "Config",
        component: Config,
        icon: SettingsIcon,
      },
      {
        path: "/admin/whats-new",
        name: "What's New",
        component: WhatsNew,
        icon: AnnouncementIcon,
      },
    ],
  },
  { path: "/profile", name: "Profile", component: Profile, icon: PersonIcon },
  {
    path: "/queue",
    name: "Singer's List",
    component: PrivateQueue,
    icon: QueueMusicIcon,
  },
  {
    path: "/browse-songs",
    name: "Browse Songs",
    component: CollectionSearchResults,
    icon: FaBook,
  },
  // { path: "/browse-songs", name: "Browse Songs", component: UserSongs, icon: LibraryMusicIcon, },
  {
    path: "/dance-requests/choose-song",
    name: "Dance Request Search",
    component: DanceRequestPreviewLoggedIn,
  },
  {
    path: "/dance-requests",
    name: "Dance Requests",
    component: UserDanceRequests,
    icon: DanceIcon,
  },
  // { path: "/pcl-search-results", name: "PCL Search Results", component: CollectionSearchResults, icon: MusicNoteIcon, },
  {
    path: "/spotify-search-results",
    name: "Spotify Search Results",
    component: SpotifySearchResults,
    icon: MusicNoteIcon,
  },
  {
    path: "/dance-request-search-results",
    name: "Dance Request Search Results",
    component: DanceRequestSearch,
    icon: MusicNoteIcon,
  },
  {
    path: "/how-it-works",
    name: "How It Works",
    component: HowToUse,
    icon: ContactSupportIcon,
  },
  {
    path: "/favorites",
    name: "Favorites",
    component: Favorites,
    icon: ContactSupportIcon,
  },
  { path: "/actions", name: "", component: Actions, hide: true },
  // { path: "/dashboard", name: "Dashboard", component: Dashboard },
];
