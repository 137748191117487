import * as firebase from "firebase";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
console.log("firebase", firebase);
// Initialize Firebase from .env files
const projectId = process.env.REACT_APP_PROJECT_ID;

var prodConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY_PROD,
  authDomain: `${projectId}.firebaseapp.com`,
  databaseURL: `https://${projectId}.firebaseio.com`,
  projectId: projectId,
  storageBucket: `${projectId}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_FB_MSG_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

// const devConfig = {
//   apiKey: process.env.REACT_APP_FB_API_KEY_DEV,
//   authDomain: "park-center-test.firebaseapp.com",
//   projectId: "park-center-test",
//   storageBucket: "park-center-test.appspot.com",
//   messagingSenderId: "970022088894",
//   appId: "1:970022088894:web:a7d3302776bdcd60292eef",
//   measurementId: "G-Z0FPSTJ86W",
// };

firebase.initializeApp(prodConfig);
var storage = firebase.storage();
firebase.firestore().settings({});
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
var db = firebase.firestore();
var functions = firebase.functions();
var sendEmail = functions.httpsCallable("sendEmail");

export {
  db,
  firebase,
  sendEmail,
  googleAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
  storage,
  firebase as default,
};
