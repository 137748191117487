/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";

// Preloader
import Loader from "assets/img/loader.gif";
import Logo from "assets/img/pclBigLogo.png";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

import Style from "assets/jss/material-dashboard-pro-react/views/profileStyle";

// Forms
import ProfileForm from "components/Forms/profileForm.jsx";

import {
  setUpdateProfileModal,
  setUserNeedsToAddNameModal,
} from "store/actions/profileActions.jsx";

import AddToQueueBtns from "../Pages/AddToQueueBtns";

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

const Orders = (props) => {
  const { AuthBool, classes, user, displayName } = props;
  const [dataLoaded, setDataLoaded] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [songList, setSongList] = useState([]);
  const [currentSongChoice, setCurrentSongChoice] = useState({});
  const [userInQueueAlready, setUserInQueueAlready] = useState(false);

  useEffect(() => {
    if (props.queue.data && props.authUser) {
      setTableData(props.queue.data);
      setDataLoaded(true);
      for (var x = 0; x < props.queue.data.length; x++) {
        if (
          props.queue.data[x].uid &&
          props.queue.data[x].uid === props.authUser.uid
        ) {
          if (props.queue.data[x].songChoice) {
            setCurrentSongChoice(props.queue.data[x].songChoice);
          } else {
            setCurrentSongChoice({ song: "N/A", artist: "N/A" });
          }
          setUserInQueueAlready(true);
        }
      }
    }
    if (props.queue.songList) {
      setSongList(props.queue.songList);
    }
  }, [props]);

  return (
    <GridContainer className={classes.rootGridContainer} justify="center">
      <GridItem xs={12} sm={10} md={6}>
        {dataLoaded && props.authUser && props.userInRange !== undefined ? (
          <GridContainer>
            <GridItem xs={12} sm={6} lg={4}>
              <Card className={classes.cardStyle}>
                <h4 className={classes.subTitle}>DISPLAY NAME:</h4>
                <p className={classes.body}>
                  {user.displayName
                    ? user.displayName
                    : displayName
                    ? displayName
                    : "Click UPDATE to add a display name"}
                </p>
                <h4 className={classes.subTitle}>NAME:</h4>
                {props.user.firstName ? (
                  <p className={classes.body}>
                    {props.user.firstName}{" "}
                    {props.user.lastName ? props.user.lastName : null}
                  </p>
                ) : (
                  <p className={classes.body}>Click UPDATE to add your name</p>
                )}
                <h4 className={classes.subTitle}>EMAIL:</h4>
                <p className={classes.body}>
                  {props.email
                    ? props.email
                    : props.user.email
                    ? props.user.email
                    : "Click UPDATE to add an email"}
                </p>
                <h4 className={classes.subTitle}>PHONE:</h4>
                {props.user.phoneNumber ? (
                  <p className={classes.body}>{props.user.phoneNumber}</p>
                ) : (
                  <p className={classes.body}>
                    Click UPDATE to add phone number
                  </p>
                )}
                <Button
                  style={{ fontSize: "20px", fontWeight: 500 }}
                  color="primary"
                  onClick={() => {
                    props.setUpdateProfileModal();
                  }}
                >
                  UPDATE
                </Button>
              </Card>
            </GridItem>

            <AddToQueueBtns
              currentSongChoice={currentSongChoice}
              userInQueueAlready={userInQueueAlready}
              tableData={tableData}
              songList={songList}
            />

            <Dialog
              style={{ maxWidth: "none" }}
              classes={{
                root: classes.modalRoot,
                paper: classes.modal + " " + classes.modalSmall,
              }}
              open={props.updateProfileModal}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => props.setUpdateProfileModal()}
              aria-labelledby="shipment-modal-slide-title"
              aria-describedby="shipment-modal-slide-description"
            >
              <DialogTitle
                id="shipment-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <Button
                  simple
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  onClick={() => props.setUpdateProfileModal()}
                >
                  {" "}
                  <Close
                    style={{ color: "black" }}
                    className={classes.modalClose}
                  />
                </Button>
                <h2 className={classes.modalTitle}>EDIT PROFILE</h2>
              </DialogTitle>
              <DialogContent
                id="shipment-modal-slide-description"
                className={classes.modalBody}
              >
                {/* FORM */}
                {AuthBool ? (
                  <ProfileForm
                    stateDisplayName={displayName}
                    user={props.user}
                    uid={props.authUser.uid}
                    modalOpen={props.updateProfileModal}
                  />
                ) : null}
              </DialogContent>
            </Dialog>

            <Dialog
              style={{ maxWidth: "none" }}
              classes={{
                root: classes.modalRoot,
                paper: classes.modal + " " + classes.modalSmall,
              }}
              open={props.userNeedsToAddNameModal}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => props.setUserNeedsToAddNameModal(false)}
              aria-labelledby="shipment-modal-slide-title"
              aria-describedby="shipment-modal-slide-description"
            >
              <DialogTitle
                id="shipment-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
                style={{ height: "130px" }}
              >
                <Button
                  simple
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  onClick={() => props.setUserNeedsToAddNameModal(false)}
                >
                  {" "}
                  <Close
                    style={{ color: "black" }}
                    className={classes.modalClose}
                  />
                </Button>
                <h2
                  style={{
                    textAlign: "center",
                    paddingTop: "20px",
                    color: "#00C1F3",
                  }}
                  className={classes.modalTitle}
                >
                  Missing Information
                </h2>
              </DialogTitle>
              <DialogContent
                id="shipment-modal-slide-description"
                className={classes.modalBody}
              >
                <h6
                  style={{
                    color: "black",
                    textAlign: "center",
                    fontSize: "18px",
                    textTransform: "capitalize",
                  }}
                >
                  Please choose a display name before navigating the app.
                </h6>
              </DialogContent>
            </Dialog>
          </GridContainer>
        ) : (
          <div className={classes.easeInOut}>
            <div
              style={{
                height: "180%",
                zIndex: "99999",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "10%",
              }}
            >
              <img
                className={classes.loaderImg}
                style={{
                  width: "50%",
                  marginBottom: "50px",
                  marginTop: "50px",
                }}
                src={Logo}
                alt="..."
              />
              <img
                style={{
                  position: "relative",
                  width: "25%",
                }}
                src={Loader}
                alt="..."
              />
            </div>
          </div>
        )}
      </GridItem>
    </GridContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
    assessments: state.assessment,
    updateProfileModal: state.profile.updateProfileModal,
    userNeedsToAddNameModal: state.profile.userNeedsToAddNameModal,
    userInRange: state.auth.isUserInRange,
    displayName: state.auth.displayName,
    queue: state.queue,
    loggedInUserAddToQueueModal: state.queue.loggedInUserAddToQueueModal,
    userNameAlreadyExistsInQueue: state.queue.userNameAlreadyExistsInQueue,
    email: state.auth.email,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setUpdateProfileModal: () => dispatch(setUpdateProfileModal()),
    setUserNeedsToAddNameModal: (boolean) =>
      dispatch(setUserNeedsToAddNameModal(boolean)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(Orders);
