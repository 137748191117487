import { db } from "firebase/fbConfig.js";

export const getUserSongs = (uid) => {
  return (dispatch) => {
    return db
      .collection("TEST-users")
      .doc(uid)
      .collection("user-songs")
      .onSnapshot((snapshot) => {
        if (snapshot.empty) {
          dispatch(setUserSongs([]));
        } else {
          snapshot = snapshot.docs;
          var toReturn = [];
          snapshot.forEach((doc) => {
            var docId = doc.id;
            doc = doc.data();
            doc.userSongId = docId;
            toReturn.push(doc);
          });
          dispatch(setUserSongs(toReturn));
        }
      });
  };
};

export const deleteUserSong = (song, allSongs, indexOfSong, uid) => {
  const songsToReturn = allSongs.filter((song, index) => index !== indexOfSong);
  return async (dispatch) => {
    await db
      .collection("TEST-users")
      .doc(uid)
      .collection("user-songs")
      .doc(song.userSongId)
      .delete();
    await db.collection("requests").doc(song.requestId).delete();
    dispatch(setUserSongs(songsToReturn));
  };
};

export const setDeleteUserSong = (data) => {
  return {
    type: "SET_DELTEDUSERSONG",
    data,
  };
};

export const setUserSongs = (data) => {
  return {
    type: "SET_USERSONGS",
    data,
  };
};

export const setUserSpotifyToken = (token) => {
  return {
    type: "SET_SPOTIFYTOKEN",
    spotifyToken: token,
  };
};
