import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/jobApplicationStyle";

// Forms
import JobApplicationForm from "components/Forms/JobApplicationForm.jsx";

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
    };
  }
  render() {
    return (
      <GridContainer justify="center">
        <GridItem xs={12} lg={11} xl={10}>
          <JobApplicationForm />
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
    actionUsers: state.actions.users,
  };
};

export default compose(connect(mapStateToProps), withStyles(Style))(Orders);
