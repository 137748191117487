import {
  cardTitle,
  dangerColor,
  whiteColor,
  linkText,
  primaryColor,
} from "assets/jss/material-dashboard-pro-react.jsx";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

const queueManagerStyle = (theme) => ({
  ...modalStyle(theme),
  container: {
    // ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
    },
  },
  tableGridItem: {
    padding: "0px 15px !important",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 15px !important",
    },
  },
  formContainer: {
    margin: "20px auto",
    [theme.breakpoints.down("sm")]: {
      padding: "0px !important",
    },
  },
  cardBody: {
    padding: "20px",
    margin: "0px auto",
    background: "#2A2A2A",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  singerInputFab: {
    color: "white",
    height: "35px",
    position: "fixed",
    bottom: "45px",
    right: "0px",
    left: "260px",
    [theme.breakpoints.down("sm")]: {
      left: "30px",
      bottom: "80px",
    },
    // "&:hover": {
    //   background: "#a04d4b",
    // },
  },
  addSingerButton: {
    maxHeight: "50px",
    background: "#fff",
    "&:hover": {
      background: "#d9d6d6",
    },
  },
  cardBodyForSingerInput: {
    padding: "10px 20px 20px 20px",
    margin: "0px auto",
    background: "#00C1F3",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  previewForm: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    color: "#fff",
    paddingBottom: "140px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
    },
  },
  cardTitle: {
    ...cardTitle,
    color: "#fff",
    fontSize: "24px",
  },
  textCenter: {
    textAlign: "center",
  },
  linkText,
  textDanger: {
    fontSize: "12px",
    color: dangerColor,
    textAlign: "center",
  },
  table: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
    },
  },
  tableHeader: {
    fontSize: "24px",
    color: "white",
    // whiteSpace: "nowrap",
    // textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "19px",
    },
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: "#FFFFFF",
    },
    marginLeft: "5px",
    marginRight: "5px",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: whiteColor,
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  cardHeader: {
    margin: "20px",
    padding: "10px 100px",
  },
  socialLine: {
    padding: "0.9375rem 0",
  },
  headers: {
    borderBottom: "1px solid #D7D7D7",
    fontSize: "40px",
  },
  threeDots: {
    fontSize: "xx-large",
    float: "right",
    "&:hover": {
      cursor: "pointer",
    },
  },
  popperDiv: {
    display: "flex",
    // height: "85px",
    // flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 12px",
    height: "50px",
    // margin: "0px auto",
    "&:hover": {
      cursor: "pointer",
      background: "#F6F6F7",
    },
  },
  popperSelections: {
    width: "100%",
    margin: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  actionBtn: {
    width: "45%",
    fontSize: "13px",
    backgroundColor: "#00c1f3",
    textAlign: "center",
    padding: "10px 15px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#00c1f3",
    },
  },
  actionBtn2: {
    fontSize: "12px",
    backgroundColor: "#00c1f3",
    textAlign: "center",
    padding: "8px 12px",
    marginLeft: "10px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#00c1f3",
    },
  },
  textField: {
    marginTop: "30px",
  },
  micIcon: {
    marginRight: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  deleteAllBtn: {
    display: "flex",
    justifyContent: "flex-end",
    width: "95%",
    marginTop: "30px",
  },
  searchThroughSpotifyHeader: {
    textAlign: "center",
    fontWeight: 500,
    color: primaryColor,
  },
  underline: {
    color: "white !important",
    "&:before": {
      borderBottom: "2px solid white",
    },
    "&:after": {
      borderBottom: "2px solid white",
    },
    "&:hover": {
      borderBottom: "1px solid white !important",
    },
  },
  label: {
    color: "white",
    fontSize: "20px",
  },
  root: {
    color: "white",
  },
  requestsForm: {
    height: "400px",
  },
  requestsFormHeader: {
    textAlign: "center",
    fontWeight: 500,
    borderRadius: "5px",
    fontSize: "30px",
  },
  cardHeaderBorderRadius: {
    borderRadius: "5px",
  },
  previewFormDiv: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingTop: "35px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "35px",
    },
  },
  songArtistToggle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "30px",
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  previewFormCollection: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "95%",
    margin: "0px auto",
  },
  formTextField: {
    width: "95%",
    marginRight: "15px",
    marginLeft: "15px",
    fontWeight: 500,
    "&:hover .MuiInput-underline:before": {
      borderBottom: "none", // Set the border to none on hover
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none", // Remove the active (click) border
    },
    "&:focus .MuiInput-underline:before": {
      borderBottom: "none", // Remove the focus border
    },
    // color: "black !important"
  },
  searchBtn: {
    height: "33px",
    width: "30px",
  },
  deleteQueueBtn: {
    background: "#a94442",
    color: "white",
    width: "45px",
    height: "45px",
    position: "fixed",
    right: "30px",
    bottom: "100px",
    [theme.breakpoints.down("sm")]: {
      left: "30px",
      bottom: "80px",
    },
    "&:hover": {
      background: "#a04d4b",
    },
  },
  addSingerCard: {},
  addToQueueBtn: {
    background: "#00C1F3",
    float: "right",
    color: "white",
    width: "60px",
    height: "60px",
    position: "fixed",
    right: "20px",
    bottom: "20px",
    [theme.breakpoints.down("sm")]: {
      right: "30px",
      bottom: "80px",
    },
  },
  addNewSingerModal: {
    [theme.breakpoints.down("sm")]: {
      height: "500px",
    },
  },
  addNewSingerModalDiv: {
    [theme.breakpoints.down("sm")]: {
      height: "400px",
    },
  },
  pleaseWait: {
    fontSize: "26px",
    color: "#00C1F3",
    width: "100%",
    marginBottom: "40px",
    textAlign: "center",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  addBackToQueueBtn: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  loaderImg: {
    width: "30%",
    marginBottom: "50px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  cardHeader2: {
    textTransform: "uppercase",
    color: "white",
    fontWeight: 500,
    fontSize: "0.875rem",
    background: "#00C1F3",
    borderRadius: "6px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "72px",
    marginTop: "-41px",
    boxShadow:
      "0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.28)",
  },
  iconBtn: {
    width: "50px",
    marginTop: "-20px",
  },
  tooltip: {
    backgroundColor: "black",
  },
  editTextInput: {
    background: "inherit",
    padding: "7px",
    borderRadius: "7px",
    color: "white",
    marginRight: "20px !important",
  },
  saveBtn: {
    background: "#00C1F3",
    padding: "7px",
    borderRadius: "7px",
    color: "white",
  },
  editCancelBtn: {
    background: "rgb(249, 181, 24)",
    padding: "7px",
    borderRadius: "7px",
    color: "black",
  },
  inputGridItem: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },
  iconCursor: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});

export default queueManagerStyle;
