/*eslint-disable*/
const singerUniqueID = null
const nameSubmitted = false
const loggedInUserAddToQueueModal = false
const userNameAlreadyExistsInQueue = false
const loggedOutUserAddToQueueModal = false
const userHasSelectedSong = false
const singerMoving = false
const userNextSong = false
// Orders Data
const queueColumns = {
  columns: [
    {
      // Header: () => <div style={{ textAlign: "center", fontSize: "30px", marginBottom: "30px" }}>Name</div>,
      Header: "Name",
      accessor: "name",
      filterable: true,
      sortable: true
    },
    {
      // Header: () => <div style={{ textAlign: "center", fontSize: "30px", marginBottom: "30px" }}>Name</div>,
      Header: "Song",
      accessor: "song",
      filterable: true,
      sortable: true
    },
    {
      // Header: () => <div style={{ textAlign: "center", fontSize: "30px", marginBottom: "30px" }}>Name</div>,
      Header: "Artist",
      accessor: "artist",
      filterable: true,
      sortable: true
    },
    {
      // Header: () => <div style={{ textAlign: "center", fontSize: "30px", marginBottom: "30px" }}>Name</div>,
      Header: "Actions",
      accessor: "actions",
      filterable: false,
      sortable: false
    }
  ],
};

const queueDetails = {
  dataLoaded: false,
  deleteModal: false,
  userCreated: false,
}

export const initState = {
  queueColumns,
  queueDetails,
  singerUniqueID,
  loggedInUserAddToQueueModal,
  loggedOutUserAddToQueueModal,
  userHasSelectedSong,
  singerMoving,
  userNextSong
}


export default (state = initState, action) => {
  switch (action.type) {
    case 'SET_NEWQUEUE':
    var toReturn = {...state}
    toReturn.data = action.data
    toReturn.dataLoaded = true
    return toReturn


    case "SET_NAMEALREADYEXISTS":
    var toReturn = {...state}
    toReturn.userNameAlreadyExistsInQueue = action.boolean
    return toReturn

    case "RESET_SUBMIT_USER_FORM":
      var toReturn = {...state}
      toReturn.nameSubmitted = false
      toReturn.userHasSelectedSong = false
      toReturn.loggedOutUserAddToQueueModal = false
      toReturn.loggedInUserAddToQueueModal = false
      toReturn.addedSongToBeDownloadRequested = false
      return toReturn

      case  "SETTING_SINGER_MOVING":
        var toReturn = {...state}
        toReturn.singerMoving = action.boolean
      return toReturn

    case 'SET_QUEUE':
    var toReturn = {...state}
    toReturn.data = action.data
    const userInQueueWithSong = action.data.filter(
      (d) =>
        d.uid &&
        d.uid === action.uid &&
        d.songChoice &&
        d.songChoice.artist !== "N/A"
    );
    if (userInQueueWithSong.length) {
      toReturn.userNextSong = userInQueueWithSong[0]
    }
    toReturn.dataLoaded = true
    return toReturn

    case "HIDE_UP_NEXT":
    var toReturn = {...state}
    toReturn.userNextSong = false;
    return toReturn


    case 'setLoggedInUserAddToQueueModal':
    var toReturn = {...state}
    toReturn.loggedInUserAddToQueueModal = action.boolean
    return toReturn

    case "SET_LOGGED_OUT_USER_ADD_TO_QUEUE_MODAL":
      var toReturn = {...state}
      toReturn.loggedOutUserAddToQueueModal = action.boolean
      return toReturn


      case "SET_EDITING":
        var toReturn = {...state}
        toReturn.data = action.toReturnData
        return toReturn

        case "SET_EDITED_SINGER_IN_QUEUE":
          var toReturn = {...state}
          return toReturn


    case 'SET_LOGGEDINQUEUE':
    var toReturn = {...state}
    toReturn.data = action.data
    toReturn.dataLoaded = true
    return toReturn

    case 'USER_SELECTED_SONG':
      var toReturn = {...state}
      toReturn.userHasSelectedSong = true
      return toReturn

    case 'SET_ARCHIVED_QUEUE':
    var toReturn = {...state}
    toReturn.archivedQueueData = action.data
    toReturn.archivedDataLoaded = true
    return toReturn

    case 'SET_LOGGEDINQUEUEANDREQUEST':
    var toReturn = {...state}
    toReturn.data = action.data
    toReturn.dataLoaded = true
    toReturn.addedSongToBeDownloadRequested = true
    return toReturn

    case 'SET_ADDEDTOQUEUE':
    var toReturn = {...state}
    toReturn.data = action.data
    toReturn.dataLoaded = true
    toReturn.queueDetails.userCreated = true
    toReturn.singerUniqueID = action.uniqueId
    toReturn.nameSubmitted = true
    return toReturn

    case "SET_DATABASESONGLIST":
    var toReturn = {...state}
    toReturn.songList = action.data
    return toReturn

    case "SET_ADDEDSONGTOBEDOWNLOADREQUESTED":
      var toReturn = {...state}
      toReturn.addedSongToBeDownloadRequested = true
      return toReturn


    case 'SET_SONGLISTANDTOKEN':
    var toReturn = {...state}
    toReturn.token = action.token
    toReturn.songList = action.songList
    return toReturn


    case "SET_ADDEDSONGTOEXISTINGUSERINQUEUE":
    var toReturn = {...state}
    toReturn.userHasSelectedSong = true
    return toReturn

    case 'SET_USERCREATEDFALSE':
      var toReturn = {...state}
      toReturn.queueDetails.userCreated = false
      return toReturn
    default:
      return state
  }
}
