import React from "react";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import { Card } from "@material-ui/core";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import configPageStyle from "../../assets/jss/material-dashboard-pro-react/views/configPageStyle";
import Timeline from "../../components/Timeline/Timeline";
import { stories, widgetStories, events } from "../../variables/general";
import ReleaseNotes from "../../releaseNotes.json";

function WhatsNew(props) {

  const { classes } = props;

  return (
    <div style={{ width: "100%" }} className={classes.container}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} className={classes.formContainer}>
          <Card className={classes.card}>
            <CardBody style={{ display: "flex" }}>
              <GridContainer>
              {
                ReleaseNotes.map((releaseNote, index) => {
                  return (
                    <GridItem key={`release-${index}`} style={{marginBottom: 10, width: '100%'}}>
                      <Card >
                        <CardHeader>
                          <h3>
                          {releaseNote.releaseDate}
                          </h3>
                        </CardHeader>
                        <CardBody>
                          <ul>
                          {
                            releaseNote.changes.map((change, changeIndex) => {
                              return (<li key={`release-change-${index}-${changeIndex}`}>
                                <p>
                                  <b>{change.title}</b>
                                </p>
                                <p>
                                  {change.description}
                                </p>
                              </li>)
                            })
                          }
                          </ul>
                        </CardBody>
                      </Card>
                    </GridItem>
                  );
                })
              }
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

WhatsNew.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(
  compose(
    withStyles(configPageStyle)
  )(WhatsNew)
);