/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { db, firebase } from "firebase/fbConfig.js";
import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { withRouter } from "react-router-dom";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Timeline from "@material-ui/icons/Timeline";
import Code from "@material-ui/icons/Code";
import Group from "@material-ui/icons/Group";
import Fingerprint from "@material-ui/icons/Fingerprint";
import Lock from "@material-ui/icons/Lock";
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import {
  startGoogleLogin,
  emailSignup,
  startFacebookLogin,
  startTwitterLogin,
} from "../../store/actions/authActions";

import Style from "assets/jss/material-dashboard-pro-react/views/signupPageStyle";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

class SignupPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      passError: false,
      tocModal: false,
      signupError: false,
      displayNameError: false,
      authInfo: {
        email: "",
        password: "",
        confirmPassword: "",
        displayName: "",
        // firstName: '',
        // lastName: '',
      },
    };
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleClickOpen = (modal, actionID, actionTitle) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      editActionID: actionID,
      editActionTitle: actionTitle,
    });
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      authInfo: {
        ...this.state.authInfo,
        [e.target.id]: e.target.value,
      },
    });
  };

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // if (this.state.authInfo.code === '2020') {
    var displayNameAlreadyUsed = false;
    db.collection("TEST-users")
      .get()
      .then((snapshot) => {
        snapshot = snapshot.docs;
        snapshot.forEach((doc) => {
          doc = doc.data();

          if (
            doc.displayName &&
            doc.displayName === this.state.authInfo.displayName
          ) {
            displayNameAlreadyUsed = true;
          }
        });

        if (displayNameAlreadyUsed) {
          // prompt must change display name
          this.setState({
            displayNameError: true,
          });
        } else {
          if (
            this.state.authInfo.password !== this.state.authInfo.confirmPassword
          ) {
            this.setState({
              passError: true,
            });
            return;
          }

          if (
            !this.state.firstName ||
            !this.state.lastName ||
            !this.state.email ||
            this.state.displayName
          ) {
            this.setState({
              signupError: true,
            });
          }

          if (this.state.checked[0] !== "accepted terms") {
            this.setState({
              termsError: true,
            });
            return;
          }

          this.setState({
            signupError: false,
          });
          firebase
            .auth()
            .createUserWithEmailAndPassword(
              this.state.authInfo.email,
              this.state.authInfo.password
            )
            .then((user) => {
              console.log('useruser', user)
              db.collection("TEST-users")
                .doc(user.user.uid)
                .set({
                  displayName: this.state.authInfo.displayName,
                  email: this.state.authInfo.email,
                  groups: [],
                  hideData: false,
                  score: 0,
                  termGroups: [],
                })
                .then(() => {
                  this.props.emailSignup(this.state.authInfo, user.user);
                });
            });
        }
      });
  };

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  }

  handleError = (error) => {
    var errorCode = error.code;

    this.setState({
      signupError: true,
      errorCode,
    });
  };

  render() {
    const {
      classes,
      startGoogleLogin,
      authError,
      startFacebookLogin,
      startTwitterLogin,
    } = this.props;

    if (!!authError && !this.state.signupError) {
      this.handleError(authError);
    }

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={10} md={8} lg={6}>
            <Card
              className={classes.cardSignup}
              style={{ backgroundColor: "white" }}
            >
              <h2 style={{ padding: "0px 30px" }} className={classes.cardTitle}>
                Welcome! Choose How You Would Like To Signup
              </h2>
              <p
                style={{ color: "#333", marginTop: "30px", fontSize: "18px" }}
                className={classes.textCenter}
              >
                <span
                  style={{
                    color: "#333",
                    fontSize: "18px",
                    marginRight: "40px",
                  }}
                >
                  Already have an account?
                </span>
                <Button
                  style={{
                    fontSize: "15px",
                    fontWeight: 500,
                    color: "white",
                    width: "50px",
                  }}
                  type="submit"
                  color="primary"
                  onClick={() => {
                    this.props.history.push("/pages/login-page");
                  }}
                  className={classes.linkText}
                >
                  Login
                </Button>
              </p>
              {/* <h2 className={classes.cardTitle}> or use your email :)</h2> */}
              <CardBody>
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                  className={classes.center}
                >
                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      justIcon
                      round
                      color="google"
                      onClick={startGoogleLogin}
                    >
                      <i className="fab fa-google" />
                    </Button>
                    <p>Sign Up With Google</p>
                  </div> */}
                  {/* <Button
                    justIcon
                    round
                    color="twitter"
                    onClick={startTwitterLogin}>
                    <i className="fab fa-twitter" />
                  </Button> */}
                  {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <Button
                      justIcon
                      round
                      color="facebook"
                      onClick={startFacebookLogin}>
                      <i className="fab fa-facebook" />
                    </Button>
                    <p>
                      Sign Up With Facebook
                    </p>
                  </div> */}
                </div>
                <form className={classes.form} onSubmit={this.handleSubmit}>
                  {/* <CustomInput
                    labelText="First Name"
                    id="firstName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "firstName",
                      required: true,
                      onChange: this.handleChange,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email />
                        </InputAdornment>
                      )
                    }}
                  />

                  <CustomInput
                    labelText="Last Name"
                    id="lastName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "lastName",
                      required: true,
                      onChange: this.handleChange,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email />
                        </InputAdornment>
                      )
                    }}
                  /> */}

                  <CustomInput
                    labelText="Display Name"
                    id="displayName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "displayName",
                      required: true,
                      onChange: this.handleChange,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Face />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "email",
                      required: true,
                      onChange: this.handleChange,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* <CustomInput
                    labelText="Code"
                    id="code"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "code",
                      required: true,
                      onChange: this.handleChange,
                      endAdornment: (
                        <InputAdornment position="end">
                        </InputAdornment>
                      )
                    }}
                  /> */}

                  <CustomInput
                    labelText="Password"
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange,
                      type: "password",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon>lock_outline</Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomInput
                    labelText="Confirm Password"
                    id="confirmPassword"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      required: true,
                      onChange: this.handleChange,
                      type: "password",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon>lock_outline</Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {this.state.displayNameError ? (
                    <p className={classes.textDanger}>
                      That display name is taken, please change it and try
                      again.
                    </p>
                  ) : null}
                  {this.state.passError ? (
                    <p className={classes.textDanger}>
                      Passwords do not match, please try again.
                    </p>
                  ) : null}
                  {this.state.termsError ? (
                    <p className={classes.textDanger}>
                      Please accept the terms to continue.
                    </p>
                  ) : null}
                  {this.state.errorCode == "auth/weak-password" ? (
                    <p className={classes.textDanger}>
                      Please enter a password with six characters or more.
                    </p>
                  ) : null}
                  {this.state.errorCode == "auth/email-already-in-use" ? (
                    <div className={classes.justifyContentCenter}>
                      <p className={classes.textDanger}>
                        Error: Email already in use. <br />
                        Forgot your password?
                      </p>
                      <Button
                        type="button"
                        onClick={this.handlePasswordReset}
                        color="danger"
                        simple
                        size="lg"
                        block
                      >
                        Click Here
                      </Button>
                      <p className={classes.textDanger}>
                        to send a password reset email.
                      </p>
                    </div>
                  ) : null}

                  <FormControlLabel
                    classes={{
                      root: classes.checkboxLabelControl,
                      label: classes.checkboxLabel,
                    }}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => this.handleToggle("accepted terms")}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    label={
                      <span className={classes.bodyText}>
                        I agree to the{" "}
                        <span
                          onClick={() =>
                            window.open(
                              "https://firebasestorage.googleapis.com/v0/b/ffpo-216413.appspot.com/o/FFPO%20--%20Membership%20T%26C.MARKUP.%204.8.19.pdf?alt=media&token=277eaf75-d380-4ee6-95ba-8c00b6f2dff7",
                              "_blank"
                            )
                          }
                          className={classes.linkText}
                        >
                          terms and conditions
                        </span>
                        .
                      </span>
                    }
                  />
                  {/* TOC MODAL */}
                  <Dialog
                    classes={{
                      root: classes.modalRoot,
                      paper: classes.modal + " " + classes.modalLarge,
                    }}
                    open={this.state.tocModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.handleClose("tocModal")}
                    aria-labelledby="shipment-modal-slide-title"
                    aria-describedby="shipment-modal-slide-description"
                  >
                    <DialogTitle
                      id="shipment-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.handleClose("tocModal")}
                      >
                        {" "}
                        <Close className={classes.modalClose} />
                      </Button>
                      <h2 className={classes.bodyText}>
                        <strong>Terms and Conditions</strong>
                      </h2>
                    </DialogTitle>
                    <DialogContent
                      id="shipment-modal-slide-description"
                      className={classes.modalBody}
                    >
                      <p className={classes.bodyText}>
                        Welcome to Khalil For Congress!
                      </p>

                      <p className={classes.bodyText}>
                        These terms and conditions outline the rules and
                        regulations for the use of Khalil For Congress's
                        Website, located at https://www.khalilforcongress.com/.
                      </p>

                      <p className={classes.bodyText}>
                        By accessing this website we assume you accept these
                        terms and conditions. Do not continue to use Khalil For
                        Congress if you do not agree to take all of the terms
                        and conditions stated on this page.
                      </p>

                      <p className={classes.bodyText}>
                        The following terminology applies to these Terms and
                        Conditions, Privacy Statement and Disclaimer Notice and
                        all Agreements: "Client", "You" and "Your" refers to
                        you, the person log on this website and compliant to the
                        Company’s terms and conditions. "The Company",
                        "Ourselves", "We", "Our" and "Us", refers to our
                        Company. "Party", "Parties", or "Us", refers to both the
                        Client and ourselves. All terms refer to the offer,
                        acceptance and consideration of payment necessary to
                        undertake the process of our assistance to the Client in
                        the most appropriate manner for the express purpose of
                        meeting the Client’s needs in respect of provision of
                        the Company’s stated services, in accordance with and
                        subject to, prevailing law of Netherlands. Any use of
                        the above terminology or other words in the singular,
                        plural, capitalization and/or he/she or they, are taken
                        as interchangeable and therefore as referring to same.
                      </p>

                      <h3 className={classes.bodyText}>
                        <strong>Cookies</strong>
                      </h3>

                      <p className={classes.bodyText}>
                        We employ the use of cookies. By accessing Khalil For
                        Congress, you agreed to use cookies in agreement with
                        the Khalil For Congress's Privacy Policy.
                      </p>

                      <p className={classes.bodyText}>
                        Most interactive websites use cookies to let us retrieve
                        the user’s details for each visit. Cookies are used by
                        our website to enable the functionality of certain areas
                        to make it easier for people visiting our website. Some
                        of our affiliate/advertising partners may also use
                        cookies.
                      </p>

                      <h3 className={classes.bodyText}>
                        <strong>License</strong>
                      </h3>

                      <p className={classes.bodyText}>
                        Unless otherwise stated, Khalil For Congress and/or its
                        licensors own the intellectual property rights for all
                        material on Khalil For Congress. All intellectual
                        property rights are reserved. You may access this from
                        Khalil For Congress for your own personal use subjected
                        to restrictions set in these terms and conditions.
                      </p>

                      <p className={classes.bodyText}>You must not:</p>
                      <ul>
                        <li className={classes.bodyText}>
                          Republish material from Khalil For Congress
                        </li>
                        <li className={classes.bodyText}>
                          Sell, rent or sub-license material from Khalil For
                          Congress
                        </li>
                        <li className={classes.bodyText}>
                          Reproduce, duplicate or copy material from Khalil For
                          Congress
                        </li>
                        <li className={classes.bodyText}>
                          Redistribute content from Khalil For Congress
                        </li>
                      </ul>

                      <p className={classes.bodyText}>
                        This Agreement shall begin on the date hereof.
                      </p>

                      <p className={classes.bodyText}>
                        Parts of this website offer an opportunity for users to
                        post and exchange opinions and information in certain
                        areas of the website. Khalil For Congress does not
                        filter, edit, publish or review Comments prior to their
                        presence on the website. Comments do not reflect the
                        views and opinions of Khalil For Congress,its agents
                        and/or affiliates. Comments reflect the views and
                        opinions of the person who post their views and
                        opinions. To the extent permitted by applicable laws,
                        Khalil For Congress shall not be liable for the Comments
                        or for any liability, damages or expenses caused and/or
                        suffered as a result of any use of and/or posting of
                        and/or appearance of the Comments on this website.
                      </p>

                      <p className={classes.bodyText}>
                        Khalil For Congress reserves the right to monitor all
                        Comments and to remove any Comments which can be
                        considered inappropriate, offensive or causes breach of
                        these Terms and Conditions.
                      </p>

                      <p className={classes.bodyText}>
                        You warrant and represent that:
                      </p>

                      <ul>
                        <li className={classes.bodyText}>
                          You are entitled to post the Comments on our website
                          and have all necessary licenses and consents to do so;
                        </li>
                        <li className={classes.bodyText}>
                          The Comments do not invade any intellectual property
                          right, including without limitation copyright, patent
                          or trademark of any third party;
                        </li>
                        <li className={classes.bodyText}>
                          The Comments do not contain any defamatory, libelous,
                          offensive, indecent or otherwise unlawful material
                          which is an invasion of privacy
                        </li>
                        <li className={classes.bodyText}>
                          The Comments will not be used to solicit or promote
                          business or custom or present commercial activities or
                          unlawful activity.
                        </li>
                      </ul>

                      <p className={classes.bodyText}>
                        You hereby grant Khalil For Congress a non-exclusive
                        license to use, reproduce, edit and authorize others to
                        use, reproduce and edit any of your Comments in any and
                        all forms, formats or media.
                      </p>

                      <h3 className={classes.bodyText}>
                        <strong>Hyperlinking to our Content</strong>
                      </h3>

                      <p className={classes.bodyText}>
                        The following organizations may link to our Website
                        without prior written approval:
                      </p>

                      <ul>
                        <li className={classes.bodyText}>
                          Government agencies;
                        </li>
                        <li className={classes.bodyText}>Search engines;</li>
                        <li className={classes.bodyText}>
                          News organizations;
                        </li>
                        <li className={classes.bodyText}>
                          Online directory distributors may link to our Website
                          in the same manner as they hyperlink to the Websites
                          of other listed businesses; and
                        </li>
                        <li className={classes.bodyText}>
                          System wide Accredited Businesses except soliciting
                          non-profit organizations, charity shopping malls, and
                          charity fundraising groups which may not hyperlink to
                          our Web site.
                        </li>
                      </ul>

                      <p className={classes.bodyText}>
                        These organizations may link to our home page, to
                        publications or to other Website information so long as
                        the link: (a) is not in any way deceptive; (b) does not
                        falsely imply sponsorship, endorsement or approval of
                        the linking party and its products and/or services; and
                        (c) fits within the context of the linking party’s site.
                      </p>

                      <p className={classes.bodyText}>
                        We may consider and approve other link requests from the
                        following types of organizations:
                      </p>

                      <ul>
                        <li className={classes.bodyText}>
                          commonly-known consumer and/or business information
                          sources;
                        </li>
                        <li className={classes.bodyText}>
                          dot.com community sites;
                        </li>
                        <li className={classes.bodyText}>
                          associations or other groups representing charities;
                        </li>
                        <li className={classes.bodyText}>
                          online directory distributors;
                        </li>
                        <li className={classes.bodyText}>internet portals;</li>
                        <li className={classes.bodyText}>
                          accounting, law and consulting firms; and
                        </li>
                        <li className={classes.bodyText}>
                          educational institutions and trade associations.
                        </li>
                      </ul>

                      <p className={classes.bodyText}>
                        We will approve link requests from these organizations
                        if we decide that: (a) the link would not make us look
                        unfavorably to ourselves or to our accredited
                        businesses; (b) the organization does not have any
                        negative records with us; (c) the benefit to us from the
                        visibility of the hyperlink compensates the absence of
                        Khalil For Congress; and (d) the link is in the context
                        of general resource information.
                      </p>

                      <p className={classes.bodyText}>
                        These organizations may link to our home page so long as
                        the link: (a) is not in any way deceptive; (b) does not
                        falsely imply sponsorship, endorsement or approval of
                        the linking party and its products or services; and (c)
                        fits within the context of the linking party’s site.
                      </p>

                      <p className={classes.bodyText}>
                        If you are one of the organizations listed in paragraph
                        2 above and are interested in linking to our website,
                        you must inform us by sending an e-mail to Khalil For
                        Congress. Please include your name, your organization
                        name, contact information as well as the URL of your
                        site, a list of any URLs from which you intend to link
                        to our Website, and a list of the URLs on our site to
                        which you would like to link. Wait 2-3 weeks for a
                        response.
                      </p>

                      <p className={classes.bodyText}>
                        Approved organizations may hyperlink to our Website as
                        follows:
                      </p>

                      <ul>
                        <li className={classes.bodyText}>
                          By use of our corporate name; or
                        </li>
                        <li className={classes.bodyText}>
                          By use of the uniform resource locator being linked
                          to; or
                        </li>
                        <li className={classes.bodyText}>
                          By use of any other description of our Website being
                          linked to that makes sense within the context and
                          format of content on the linking party’s site.
                        </li>
                      </ul>

                      <p className={classes.bodyText}>
                        No use of Khalil For Congress's logo or other artwork
                        will be allowed for linking absent a trademark license
                        agreement.
                      </p>

                      <h3 className={classes.bodyText}>
                        <strong>iFrames</strong>
                      </h3>

                      <p className={classes.bodyText}>
                        Without prior approval and written permission, you may
                        not create frames around our Webpages that alter in any
                        way the visual presentation or appearance of our
                        Website.
                      </p>

                      <h3 className={classes.bodyText}>
                        <strong>Content Liability</strong>
                      </h3>

                      <p className={classes.bodyText}>
                        We shall not be hold responsible for any content that
                        appears on your Website. You agree to protect and defend
                        us against all claims that is rising on your Website. No
                        link(s) should appear on any Website that may be
                        interpreted as libelous, obscene or criminal, or which
                        infringes, otherwise violates, or advocates the
                        infringement or other violation of, any third party
                        rights.
                      </p>

                      <h3 className={classes.bodyText}>
                        <strong>Your Privacy</strong>
                      </h3>

                      <p className={classes.bodyText}>
                        Please read Privacy Policy
                      </p>

                      <h3 className={classes.bodyText}>
                        <strong>Reservation of Rights</strong>
                      </h3>

                      <p className={classes.bodyText}>
                        We reserve the right to request that you remove all
                        links or any particular link to our Website. You approve
                        to immediately remove all links to our Website upon
                        request. We also reserve the right to amen these terms
                        and conditions and it’s linking policy at any time. By
                        continuously linking to our Website, you agree to be
                        bound to and follow these linking terms and conditions.
                      </p>

                      <h3 className={classes.bodyText}>
                        <strong>Removal of links from our website</strong>
                      </h3>

                      <p className={classes.bodyText}>
                        If you find any link on our Website that is offensive
                        for any reason, you are free to contact and inform us
                        any moment. We will consider requests to remove links
                        but we are not obligated to or so or to respond to you
                        directly.
                      </p>

                      <p className={classes.bodyText}>
                        We do not ensure that the information on this website is
                        correct, we do not warrant its completeness or accuracy;
                        nor do we promise to ensure that the website remains
                        available or that the material on the website is kept up
                        to date.
                      </p>

                      <h3 className={classes.bodyText}>
                        <strong>Disclaimer</strong>
                      </h3>

                      <p className={classes.bodyText}>
                        To the maximum extent permitted by applicable law, we
                        exclude all representations, warranties and conditions
                        relating to our website and the use of this website.
                        Nothing in this disclaimer will:
                      </p>

                      <ul>
                        <li className={classes.bodyText}>
                          limit or exclude our or your liability for death or
                          personal injury;
                        </li>
                        <li className={classes.bodyText}>
                          limit or exclude our or your liability for fraud or
                          fraudulent misrepresentation;
                        </li>
                        <li className={classes.bodyText}>
                          limit any of our or your liabilities in any way that
                          is not permitted under applicable law; or
                        </li>
                        <li className={classes.bodyText}>
                          exclude any of our or your liabilities that may not be
                          excluded under applicable law.
                        </li>
                      </ul>

                      <p className={classes.bodyText}>
                        The limitations and prohibitions of liability set in
                        this Section and elsewhere in this disclaimer: (a) are
                        subject to the preceding paragraph; and (b) govern all
                        liabilities arising under the disclaimer, including
                        liabilities arising in contract, in tort and for breach
                        of statutory duty.
                      </p>

                      <p className={classes.bodyText}>
                        As long as the website and the information and services
                        on the website are provided free of charge, we will not
                        be liable for any loss or damage of any nature.
                      </p>
                      <Button
                        className={classes.ltrButtonWhite}
                        style={{
                          marginLeft: "10px",
                          marginTop: "10px",
                        }}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.handleClose("tocModal")}
                      >
                        CLOSE
                      </Button>
                    </DialogContent>
                  </Dialog>

                  <div className={classes.center}>
                    <Button
                      style={{
                        fontSize: "17px",
                        fontWeight: 500,
                        marginTop: "30px",
                        marginBottom: "50px",
                      }}
                      type="submit"
                      color="primary"
                    >
                      Let's Go
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </GridItem>

          {/* <Snackbar open={this.state.displayNameError} onClose={() => this.setState({
            displayNameError: false
          })}>
            <Alert onClose={() => this.setState({
            displayNameError: false
          })} severity='error'>
            Unfortunately that display name has been taken, please try a different one.
            </Alert>
          </Snackbar> */}
        </GridContainer>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  emailSignup: (authObject, user) => dispatch(emailSignup(authObject, user)),
  startGoogleLogin: () => dispatch(startGoogleLogin()),
  startFacebookLogin: () => dispatch(startFacebookLogin()),
  startTwitterLogin: () => dispatch(startTwitterLogin()),
});

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
  };
};

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
  )(SignupPage)
);
