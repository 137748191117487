/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { db } from "firebase/fbConfig.js";
import { withRouter } from "react-router-dom";

// Preloader
import Loader from "assets/img/loader.gif";
import Logo from "assets/img/pclBigLogo.png";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import SearchIcon from "@material-ui/icons/Search";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import MicIcon from "@material-ui/icons/Mic";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import FavoriteIcon from "@material-ui/icons/Favorite";
import DeleteIcon from "@material-ui/icons/Delete";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import TextField from "@material-ui/core/TextField";
import Style from "assets/jss/material-dashboard-pro-react/views/searchResultsStyle";
import { getQueryResults } from "store/actions/searchActions.jsx";
import {
  getJustDatabaseSongList,
  loggedInUserAddToQueue,
  addSongToExistingUserInQueue,
  addNewSingerAndSong,
  deleteCollectionSong,
} from "store/actions/queueActions";
import {
  newDanceRequest,
  setRequestCreated,
} from "store/actions/danceRequestActions.jsx";
import {
  addNewFavorite,
  removeFavorite,
} from "store/actions/favoritesActions.jsx";

import { getFavorites, showSnackbar } from "store/actions/favoritesActions";

const tableHeaders = ["Song", "Artist", "Actions"];

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

const CollectionSearchResults = (props) => {
  const {
    classes,
    songList,
    displayName,
    user,
    requestCreated,
    history,
    favoritesData,
    favoriteDataLoaded,
    addedFavoriteSnackbar,
  } = props;
  const [tableData, setTableData] = useState([]);
  const [searchErr, setSearchErr] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [selectSongModal, setSelectSongModal] = useState(false);
  const [chosenSong, setChosenSong] = useState(false);
  const [deleteSongModal, setDeleteSongModal] = useState(false);
  const [currentSongChoice, setCurrentSongChoice] = useState(false);
  const [queueData, setQueueData] = useState([]);
  const [UID, setUID] = useState(null);
  const [queueId, setQueueId] = useState(false);
  const [successSnackBar, setSuccessSnackBar] = useState(false);
  const [
    selectSongForDanceRequestModal,
    setSelectSongForDanceRequestModal,
  ] = useState(false);
  const [favIds, setFavIds] = useState([]);
  const [deletedSong, setDeletedSong] = useState(false);
  const isAdmin = user.admin;

  useEffect(() => {
    if (!favoriteDataLoaded && props.authUser) {
      props.getFavorites(props.authUser.uid);
    }
    if (songList) {
      setLoaded(true);
    }
    if (props.queue.data && props.authUser) {
      setQueueData(props.queue.data);
      for (var x = 0; x < props.queue.data.length; x++) {
        if (
          props.queue.data[x].uid &&
          props.queue.data[x].uid === props.authUser.uid
        ) {
          setQueueId(props.queue.data[x].id);
          if (props.queue.data[x].songChoice) {
            setCurrentSongChoice(props.queue.data[x].songChoice);
          }
        }
      }
    }
    if (props.authUser) {
      setUID(props.authUser.uid);
    }
  }, [props]);

  useEffect(() => {
    var favoritesIds = [];
    favoritesData.forEach((f) => {
      favoritesIds.push(f.collectionSongId);
    });
    setFavIds(favoritesIds);
  }, [favoritesData]);

  const confirmedSongChoice = async (e) => {
    e.preventDefault();
    const data = [...props.queue.data];
    if (queueId) {
      await props.addSongToExistingUserInQueue(
        queueId,
        chosenSong,
        "updatingUserSong"
      );
      setSelectSongModal(false);
    } else {
      var doc = db.collection("queue").doc();
      var toReturn = {
        displayName: displayName,
        hasSung: false,
        positionInQueue: queueData.length,
        uid: UID,
        id: doc.id,
        songChoice: {
          song: chosenSong.song ? chosenSong.song : "N/A",
          artist: chosenSong.artist ? chosenSong.artist : "N/A",
        },
      };
      await props.addNewSingerAndSong(toReturn, data);

      setSelectSongModal(false);
    }
    setSuccessSnackBar(true);
  };

  const filterAgain = () => {
    if (!searchInput) {
      setSearchErr(true);
    } else {
      const filtered = songList.filter((d) => {
        if (
          (d.artist &&
            d.artist.toLowerCase().includes(searchInput.toLowerCase())) ||
          (d.song && d.song.toLowerCase().includes(searchInput.toLowerCase()))
        ) {
          return d;
        }
      });
      setTableData(filtered);
    }
  };

  const addNewDanceRequest = () => {
    var adminData = {};
    var userData = {};
    adminData.song = chosenSong.song;
    adminData.artist = chosenSong.artist;
    adminData.requestedBy = user.displayName;
    adminData.preview_url = false;
    adminData.downloadRequest = false;

    userData.song = chosenSong.song;
    userData.artist = chosenSong.artist;
    userData.downloadStatus = "Pending";
    userData.requestId = "";
    props.newDanceRequest(userData, adminData, UID);
    setSelectSongForDanceRequestModal(false);
  };

  const renderTableData = () => {
    if (tableData.length === 0) {
      return (
        <tr
          id="tableRow"
          style={{
            fontWeight: 500,
            color: "#f9b518",
            height: "55px",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          <td style={{ textAlign: "center", width: "33%" }}></td>
          <td style={{ textAlign: "center", width: "33%", fontSize: "22px" }}>
            No Results
          </td>
          <td style={{ textAlign: "center", width: "33%" }}></td>
        </tr>
      );
    }
    return tableData.map((result, index) => {
      return (
        <tr
          id="tableRow"
          style={{
            fontWeight: 500,
            color: "#f9b518",
            height: "55px",
            fontSize: "20px",
          }}
          key={index}
        >
          <td style={{ textAlign: "center", fontSize: "20px" }}>
            {result.song}
          </td>
          <td style={{ textAlign: "center", fontSize: "20px" }}>
            {result.artist}
          </td>
          <td
            style={{ textAlign: "center", fontSize: "20px" }}
            className={classes.actionBtns}
          >
            <MicIcon
              className={classes.actionIcons}
              onClick={() => {
                setChosenSong({
                  song: result.song,
                  artist: result.artist,
                  id: result.id,
                });
                setSelectSongModal(true);
              }}
            />
            {favIds.includes(result.id) ? (
              <FavoriteIcon
                className={classes.actionIcons}
                style={{ color: "red" }}
                onClick={async () => {
                  await props.removeFavorite(UID, result.id);
                }}
              />
            ) : (
              <FavoriteIcon
                className={classes.actionIcons}
                onClick={async () => {
                  await props.addNewFavorite(UID, result, "fromSearch");
                }}
              />
            )}
            {isAdmin && (
              <DeleteIcon
                onClick={() => {
                  setChosenSong({
                    song: result.song,
                    artist: result.artist,
                    id: result.id,
                  });
                  setDeleteSongModal(true);
                }}
                className={classes.actionIcons}
              />
            )}
            {/* )} */}
          </td>
        </tr>
      );
    });
  };

  const deleteSong = async () => {
    console.log("chosen song", chosenSong);
    await props.deleteCollectionSong(chosenSong.id, songList);
    setDeleteSongModal(false);
    setDeletedSong(true);
    setTableData((prevState) =>
      prevState.filter((d) => d.id !== chosenSong.id)
    );
  };

  const renderTableHeader = () => {
    return tableHeaders.map((key, index) => {
      return (
        <th className={classes.tableHeader} key={index}>
          {key}
        </th>
      );
    });
  };

  return (
    <div className={classes.container} style={{ width: "100%" }}>
      {loaded ? (
        <GridContainer>
          <GridItem
            style={{ display: "flex", padding: "0px 10px !important" }}
            xs={12}
            sm={12}
            md={12}
          >
            <Card
              style={{ display: "flex", flexDirection: "row" }}
              className={classes.searchCard}
            >
              <TextField
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    filterAgain();
                  }
                }}
                style={{ color: "white" }}
                InputLabelProps={{
                  style: { color: "white" }, // Apply the custom styles to the label
                }}
                InputProps={{
                  classes: {
                    underline: classes.underline,
                  },
                  style: { color: "white" }, // Apply the custom styles to the text input
                }}
                onChange={(e) => setSearchInput(e.target.value)}
                className={classes.formTextField}
                // defaultValue={query}
                label="Song or Artist"
                variant="standard"
              />
              <Button
                type="submit"
                color="primary"
                style={{ maxHeight: "50px", marginLeft: "20px" }}
                onClick={() => {
                  filterAgain();
                }}
              >
                <SearchIcon />
              </Button>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} className={classes.formContainer}>
            <Card
              style={{ width: "95%", overflow: "scroll" }}
              className={classes.cardBody}
            >
              <table className={classes.table}>
                <tr style={{ height: "50px" }}>{renderTableHeader()} </tr>
                {renderTableData()}
              </table>
            </Card>
          </GridItem>

          {/* {searchResults.length === 0 ? */}
          <GridItem xs={12} sm={12} md={12} className={classes.formContainer}>
            <Card
              className={classes.cantFindCard}
              style={{ color: "white", width: "95%", padding: "10px" }}
            >
              {`Can't find what you're looking for? Click the button below to
              download request your song.`}
              <Button
                className={classes.downloadBtn}
                onClick={() => history.push("/spotify-search-results")}
              >
                Download Request
              </Button>
            </Card>
          </GridItem>
          {/* : null} */}

          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalSmall,
            }}
            open={deleteSongModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setDeleteSongModal(false);
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >
            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setDeleteSongModal(false);
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              <h3 style={{ textAlign: "center", marginTop: "50px" }}>
                Are you sure you want to delete {chosenSong.song} by{" "}
                {chosenSong.artist}?
              </h3>
            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Button onClick={deleteSong} color="primary">
                  Yes
                </Button>

                <Button
                  onClick={() => {
                    setDeleteSongModal(false);
                  }}
                  color="danger"
                >
                  No
                </Button>
              </div>
            </DialogContent>
          </Dialog>

          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalSmall,
            }}
            open={selectSongForDanceRequestModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setSelectSongForDanceRequestModal(false);
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >
            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setSelectSongForDanceRequestModal(false);
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              <h3 style={{ textAlign: "center", marginTop: "50px" }}>
                Are you sure you want to request {chosenSong.song} by{" "}
                {chosenSong.artist} as a dance request?
              </h3>
            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Button onClick={addNewDanceRequest} color="primary">
                  Yes, I Love This Song.
                </Button>

                <Button
                  onClick={() => {
                    setSelectSongForDanceRequestModal(false);
                  }}
                  color="danger"
                >
                  No, Nevermind.
                </Button>
              </div>
            </DialogContent>
          </Dialog>

          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalSmall,
            }}
            open={selectSongModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setSelectSongModal(false);
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >
            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setSelectSongModal(false);
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              {currentSongChoice ? (
                <h3 style={{ textAlign: "center", marginTop: "50px" }}>
                  You are already on track to perform {currentSongChoice.song}{" "}
                  by {currentSongChoice.artist}. Are you sure you want to choose{" "}
                  {chosenSong.song} by {chosenSong.artist} instead?
                </h3>
              ) : (
                <h3 style={{ textAlign: "center", marginTop: "50px" }}>
                  Are you sure you want to choose {chosenSong.song} by{" "}
                  {chosenSong.artist} as your next karaoke song?
                </h3>
              )}
            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                {currentSongChoice ? (
                  <Button
                    onClick={(e) => confirmedSongChoice(e, "changing")}
                    color="primary"
                  >
                    Yes, I Want To Change This Song.
                  </Button>
                ) : (
                  <Button
                    onClick={(e) => confirmedSongChoice(e, "not")}
                    color="primary"
                  >
                    Yes, I Love This Song.
                  </Button>
                )}

                <Button
                  onClick={() => {
                    setSelectSongModal(false);
                  }}
                  color="danger"
                >
                  No, Nevermind.
                </Button>
              </div>
            </DialogContent>
          </Dialog>

          <Snackbar
            style={{ marginBottom: "50px" }}
            open={deletedSong}
            autoHideDuration={4000}
            onClose={() => setDeletedSong(false)}
          >
            <Alert onClose={() => setDeletedSong(false)} severity="success">
              Successfully deleted the song!
            </Alert>
          </Snackbar>

          <Snackbar
            style={{ marginBottom: "50px" }}
            open={requestCreated}
            autoHideDuration={4000}
            onClose={() => props.setRequestCreated(false)}
          >
            <Alert
              onClose={() => props.setRequestCreated(false)}
              severity="success"
            >
              Dance request created succesfully!
            </Alert>
          </Snackbar>

          <Snackbar
            style={{ marginBottom: "50px" }}
            open={addedFavoriteSnackbar}
            autoHideDuration={4000}
            onClose={() => props.showSnackbar(false)}
          >
            <Alert onClose={() => props.showSnackbar(false)} severity="success">
              Added song to your favorites!
            </Alert>
          </Snackbar>
        </GridContainer>
      ) : (
        <div className={classes.easeInOut}>
          <div
            style={{
              height: "180%",
              zIndex: "99999",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              marginTop: "10%",
            }}
          >
            <img className={classes.loaderImg} src={Logo} alt="..." />
            <img
              style={{
                position: "relative",
                width: "15%",
              }}
              src={Loader}
              alt="..."
            />
          </div>
        </div>
      )}

      <Snackbar
        style={{ marginBottom: "30px" }}
        open={successSnackBar}
        onClose={() => {
          setSuccessSnackBar(false);
          history.push("/queue");
        }}
        autoHideDuration={2000}
      >
        <Alert
          onClose={() => {
            setSuccessSnackBar(false);
            history.push("/queue");
          }}
          severity="success"
        >
          Your song choice has been created succesfully.
        </Alert>
      </Snackbar>

      <Snackbar
        style={{ marginBottom: "50px" }}
        open={searchErr}
        autoHideDuration={4000}
        onClose={() => setSearchErr(false)}
      >
        <Alert onClose={() => setSearchErr(false)} severity="error">
          {`Error! Input can't be empty.`}
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
    assessments: state.assessment,
    updateProfileModal: state.profile.updateProfileModal,
    singerUniqueID: state.queue.singerUniqueID,
    query: state.search.searchQuery,
    searchResults: state.search.searchResults,
    searchDataLoaded: state.search.searchDataLoaded,
    songList: state.queue.songList,
    queue: state.queue,
    displayName: state.profile.user.displayName,
    requestCreated: state.dance.userDanceRequestDetails.requestCreated,
    favoritesData: state.favorites.favoritesData,
    favoriteDataLoaded: state.favorites.dataLoaded,
    addedFavoriteSnackbar: state.favorites.addedFavoriteSnackbar,
    // uid: state.auth.user.uid
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getQueryResults: (query, songList) =>
      dispatch(getQueryResults(query, songList)),
    getJustDatabaseSongList: () => dispatch(getJustDatabaseSongList()),
    loggedInUserAddToQueue: (song, userName, UID, data) =>
      dispatch(loggedInUserAddToQueue(song, userName, UID, data)),
    addSongToExistingUserInQueue: (queueId, currentSongChoice, str) =>
      dispatch(addSongToExistingUserInQueue(queueId, currentSongChoice, str)),
    addNewSingerAndSong: (newSinger, allData) =>
      dispatch(addNewSingerAndSong(newSinger, allData)),
    newDanceRequest: (userData, adminData, UID) =>
      dispatch(newDanceRequest(userData, adminData, UID)),
    setRequestCreated: (boolean) => dispatch(setRequestCreated(boolean)),
    addNewFavorite: (uid, songSelection, str) =>
      dispatch(addNewFavorite(uid, songSelection, str)),
    getFavorites: (uid) => dispatch(getFavorites(uid)),
    removeFavorite: (uid, songId) => dispatch(removeFavorite(uid, songId)),
    showSnackbar: (boolean) => dispatch(showSnackbar(boolean)),
    deleteCollectionSong: (id, allSongs) =>
      dispatch(deleteCollectionSong(id, allSongs)),
  };
};

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
  )(CollectionSearchResults)
);
