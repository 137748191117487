// Orders Data
const actions = {
    columns: [    
      {
        Header: "ACTION ID",
        accessor: "actionID",
        show: false
      },
      {
        Header: "TITLE",
        accessor: "title"
      },
      {
        Header: "BODY",
        accessor: "description",
        show: false
      },
      {
        Header: "DUE",
        accessor: "dueDate"
      },
      {
        Header: "DAYS DUE",
        accessor: "days",
        show: false
      },
      {
        Header: "POINTS",
        accessor: "points"
      },    
      {
        Header: "STATUS",
        accessor: "status"
      },    
      {
        Header: "",
        accessor: "actions",
        sortable: false,
        filterable: false
      }
    ],  
    dataLoaded: false
  }
  ;
  // Orders Data
  const pendingActions = {
    columns: [  
      {
        Header: "VOL EMAIL",
        accessor: "email"
      },  
      {
        Header: "VOL UID",
        accessor: "volUID"      
      },
      {
        Header: "ACTION ID",
        accessor: "actionId"      
      },
      {
        Header: "TITLE",
        accessor: "title"
      },
      {
        Header: "COMMENT",
        accessor: "comment"     
      },
      {
        Header: "DAYS DUE",
        accessor: "days",
        show: false
      },
      {
        Header: "POINTS",
        accessor: "points"
      },  
      {
        Header: "",
        accessor: "actions",
        sortable: false,
        filterable: false
      }
    ],  
    dataLoaded: false
  };
  
  // Orders Data
  const sharedGlossary = {
    columns: [        
      {
        Header: "TERM",
        accessor: "term"      
      },
      {
        Header: "PRACTICE",
        accessor: "practice"      
      },
      {
        Header: "DEFINITION",
        accessor: "definition"      
      },
      {
        Header: "CATEGORY",
        accessor: "category"      
      },
      {
        Header: "TOPIC",
        accessor: "topic"      
      },
      {
        Header: "LINK",
        accessor: "link"      
      }
    ],  
    dataLoaded: false
  };
  
  export const initState = {
    actions,
    pendingActions,
    sharedGlossary
  }
  
  export default (state = initState, action) => {
    switch (action.type) { 
      case 'SET_SHARED_GLOSSARY_LIST':
        console.log('SET_SHARED_GLOSSARY_LIST')     
        return {
          ...state,
          sharedGlossary: {
            ...state.sharedGlossary,
            dataRows: action.dataRows,
            dataLoaded: true
          }
        };    
      default:
        return state
    }
  }
  