/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from "firebase/fbConfig.js";
import { withRouter } from 'react-router-dom';
import axios from 'axios'
import qs from 'qs';
// Preloader
import Loader from "assets/img/preloader.gif";
import SongListLoader from "assets/img/loader.gif";
import Hidden from "@material-ui/core/Hidden";
import Logo from "assets/img/pclBigLogo.png";

// react component for creating dynamic tables
import ReactTable from "react-table";

// audio player
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Autocomplete from '@material-ui/lab/Autocomplete';

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Edit from '@material-ui/icons/Edit';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ProfilePicUpload from "components/CustomUpload/profilePicUpload"
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";
import TextField from '@material-ui/core/TextField';
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/songPreviewStyle";
import { Grid } from "@material-ui/core";
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';

import SongRequestCompleted from './SongRequestCompleted.jsx'
import SongDownloadRequestCompleted from './SongDownloadRequestCompleted.jsx'

// Firebase Redux Integration
import { tableToFirestoreObjects, updateFirestoreWithOneObject, updateFirestoreWithTableData, addFieldToDocument } from "store/actions/firestoreActions"
import { startSetActionsList, startToggleCompleted, setUserScore } from "store/actions/actionsActions"
import ReferralForm from "components/Forms/ReferralForm"
import { updateUser, setUpdateProfileModal } from "store/actions/profileActions.jsx"
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { searchDatabaseForSong, addSongToExistingUserInQueue, skipAddSongToQueue, addSpotifySongToDownloadRequest } from "store/actions/queueActions.jsx"
import { getQueryResults } from "store/actions/searchActions.jsx"



const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

const UpTransition = (props) => {
  return <Slide direction="up" {...props} />
}

const SongPreviewModal = (props) => {

  const [dataLoaded, setDataLoaded] = useState(false)
  const { classes, singerUniqueID, userHasSelectedSong, addedSongToBeDownloadRequested, songList, history } = props;
  const [spotifySongRef, setSpotifySongRef] = useState('')
  const [token, setToken] = useState('')
  const [songChoices, setSongChoices] = useState([])
  const [chosenSong, setChosenSong] = useState(null)
  const [previewUrl, setPreviewUrl] = useState('')
  const [cantPreviewAlert, setCantPreviewAlert] = useState(false)
  const [songLibrary, setSongLibrary] = useState([])
  const [openSongSelectionModal, setOpenSongSelectionModal] = useState(false)
  const [filteredSongLibrary, setFilteredSongLibrary] = useState([])
  const [selectedSongFromDatabase, setSelectedSongFromDatabase] = useState(null)
  const [needToSelectSongAlert, setNeedToSelectSongAlert] = useState(false)
  const [checkToSeeIfUserWantsToRequestSong, setCheckToSeeIfUserWantsToRequestSong] = useState(false)
  const [songTitle, setSongTitle] = useState(null)
  const [artistName, setArtistName] = useState(null)
  const [selectedSongFromSpotify, setSelectedSongFromSpotify] = useState(null)
  const [songRef, setSongRef] = useState('')
  const [songOrArtist, setSongOrArtist] = useState('Song')
  const searchSpotify = (e) => {

    if (songOrArtist === 'Song') {


      axios.get(`https://api.spotify.com/v1/search?q=track:${e.target.value}&type=track`, { headers: { 'Authorization': 'Bearer ' + token } })
        .then((response) => {
          setSongChoices(response.data.tracks.items)
        })
        .catch((err) => {
          console.log('errr', err)
        })

    } else {
      axios.get(`https://api.spotify.com/v1/search?query=${e.target.value}&type=artist&market=us&limit=50&offset=0`, { headers: { 'Authorization': 'Bearer ' + token } })
        .then((response) => {
          setSongChoices(response.data.artists.items)
        })
        .catch((err) => {
          console.log('errr', err)
        })
    }
  }

  const getTrackFromSpotify = (e) => {


    if (songOrArtist === 'Song') {
      var selection = e.target.innerText.split(' - ')
      var songTitleStr = selection[0]
      var artist = selection[1]
      // console.log('selectionxxxx', selection )
      var selectedTrack = null
      for (var x = 0; x < songChoices.length; x++) {
        if (songChoices[x].name === songTitleStr && songChoices[x].artists[0].name === artist) {
          selectedTrack = songChoices[x]
          // console.log('selectionxxxx', selectedTrack )
        }
      }

      if (selectedTrack) {
        axios.get(`https://api.spotify.com/v1/tracks/${selectedTrack.id}?market=US`, { headers: { 'Authorization': 'Bearer ' + token } })
          .then((results) => {
            if (!results.data.preview_url) {
              setCantPreviewAlert(true)
              setPreviewUrl(results.data.preview_url)
              setSongTitle(songTitleStr)
              setArtistName(artist)
              setSelectedSongFromSpotify({ song: songTitleStr, artist: artist })
            } else {
              setPreviewUrl(results.data.preview_url)
              setSongTitle(songTitleStr)
              setArtistName(artist)
              setSelectedSongFromSpotify({ song: songTitleStr, artist: artist })
            }
          })
          .catch((err) => {
            console.log('err')
          })
      }
    } else {
      var choicesCopy = songChoices.filter(song => song.name === e.target.innerText)
      var artist = choicesCopy[0]
      axios.get(`https://api.spotify.com/v1/search?type=track&q=artist:${e.target.innerText}&limit=50&offset=0`, { headers: { 'Authorization': 'Bearer ' + token, 'Accept': 'application/json', "Content-Type": "application/json" } })
        .then((results) => {
          setSongChoices(results.data.tracks.items)
          setSongOrArtist('Song')
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }

  const addSongToQueue = (e) => {
    if (!selectedSongFromDatabase) {
      setNeedToSelectSongAlert(true)
    } else {
      props.addSongToExistingUserInQueue(singerUniqueID, selectedSongFromDatabase)
      setSongRef('')
    }
  }

  const skipAddSong = () => {
    props.skipAddSongToQueue()
  }

  const setCheckUserRequest = () => {
    if (songTitle) {
      setCheckToSeeIfUserWantsToRequestSong(true)
      setOpenSongSelectionModal(false)
    }
  }


  const trackClickSelected = (e) => {
    var selection = e.target.innerText.split(' - ')
    var songTitleStr = selection[0]
    var artist = selection[1]
    setSelectedSongFromDatabase({ song: songTitleStr, artist: artist })
  }

  const addSpotifySong = () => {

    props.addSpotifySongToDownloadRequest(singerUniqueID, selectedSongFromSpotify, previewUrl)
  }


  const searchDatabase = (e) => {
    var input = e.target.value
    if (input === '') {
      setFilteredSongLibrary([])
    } else {
      let data = [...songList]
      const filtered = songList.filter(d => {
        if (d.artist && d.artist.toLowerCase().includes(input.toLowerCase()) || d.song && d.song.toLowerCase().includes(input.toLowerCase())) {
          return d
        }
      })
      setFilteredSongLibrary(filtered)
      setSongRef(e.target.value)
    }
  }

  useEffect(() => {
    if (props.addedSongToBeDownloadRequested) {
      setOpenSongSelectionModal(false)
      setCheckToSeeIfUserWantsToRequestSong(false)
      setSongLibrary(props.songList)
      setToken(props.token)
    } else {
      setSongLibrary(props.songList)
      setToken(props.token)
    }
  }, [props])


  const navigateToNewPageWithSearchQuery = (e, str) => {
    e.preventDefault()
    if (str === 'spotify') {
      props.getQueryResults(spotifySongRef, songChoices, 'spotify')
      history.push('/pages/spotify-search-results')
    } else {
      props.getQueryResults(songRef, songList)
      history.push('/pages/pcl-search-results')
    }
  }



  if (songLibrary && !userHasSelectedSong && !addedSongToBeDownloadRequested) {
    return (
      <div className={classes.container}>
        <GridContainer>
          <GridItem className={classes.formGridItem} md={6} className={classes.formContainers}>
            <Card style={{ width: "100%" }} className={classes.requestsForm}>
              <CardHeader className={classes.cardHeader} color="primary">
                <h1 style={{ fontSize: "33px" }} className={classes.requestsFormHeader} >Browse Our Karaoke Library </h1>
              </CardHeader>
              <form className={classes.formWithPadding} noValidate autoComplete="off">
                <Autocomplete
                  disableClearable
                  options={filteredSongLibrary ? filteredSongLibrary.map((song, index) => {
                    return song.song + ' - ' + song.artist
                  }) : songLibrary.map((song, index) => {
                    return song.song + ' - ' + song.artist
                  })}
                  //  onInputChange={searchSpotify}
                  className={classes.formTextField} id="Song" label="Song Or Artist"
                  InputProps={{}}
                  onChange={trackClickSelected}
                  renderInput={(params) => (
                    <TextField
                      onKeyPress={(e) => {
                        if (e.which === 13) {
                          navigateToNewPageWithSearchQuery(e)
                        }
                      }}
                      onChange={searchDatabase}
                      {...params}
                      value={songRef} id="Song" label="Song Or Artist" />)} />
              </form>
              {/* <AudioPlayer
                src={previewUrl ? previewUrl : null}
                style={{ margin: '30px auto 27px auto', width: '95%' }}
                onPlay={(e) => console.log('onPlay')}

              /> */}
            </Card>
          </GridItem>


          <GridItem style={{ maxWidth: "100%" }} sm={4} md={6} className={classes.formContainers}>
            <Card style={{ width: "100%" }} className={classes.requestsForm}>
              <CardHeader className={classes.cardHeader} color="primary">
                <h1 style={{ fontSize: "33px" }} className={classes.requestsFormHeader} >All Songs</h1>
              </CardHeader>
              <form className={classes.previewFormDiv} noValidate autoComplete="off">
{/*
                <Select
                  style={{ margin: "20px" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={songOrArtist}
                  variant='outlined'
                  onChange={(e) => {
                    setSongOrArtist(e.target.value)
                    setSongChoices([])
                  }}
                >
                  <MenuItem value={'Artist'}>Artist</MenuItem>
                  <MenuItem value={'Song'}>Song</MenuItem>
                </Select> */}
                  <div className={classes.songArtistToggle}>
                  <p> Song </p>
                  <Switch
                    checked={songOrArtist === "Song" ? false : true}
                    onChange={() => {
                      if (songOrArtist === 'Song') {
                        setSongOrArtist('Artist')
                      } else {
                        setSongOrArtist('Song')
                      }
                    }}
                    value="checkedA" />
                  <p> Artist </p>
                </div>


                <div style={{ display: "flex", justifyContent: "space-between"}}>
                {songOrArtist === 'Song' ? (

                  <Autocomplete
                    options={songChoices.map((song, index) => {
                      return song.name + ' - ' + song.artists[0].name
                      //  + ', ' + song.album.name
                    })}
                    //  onInputChange={searchSpotify}
                    disableClearable
                    className={classes.formTextField} id="Song" label="Song"
                    InputProps={{}}
                    onChange={getTrackFromSpotify}
                    renderInput={(params) => (<TextField
                      onChange={searchSpotify}
                      {...params}
                      onKeyPress={(e) => {
                        if (e.which === 13) {
                          navigateToNewPageWithSearchQuery(e, 'spotify')
                        }
                      }}
                      value={spotifySongRef} id="Song" label="Song" />)} />
                ) : (
                  <Autocomplete
                    options={songChoices.map((artist, index) => {
                      return artist.name
                    })}
                    //  onInputChange={searchSpotify}
                    disableClearable
                    className={classes.formTextField} id="Artist" label="Artist"
                    InputProps={{}}
                    disableCloseOnSelect={true}
                    onChange={getTrackFromSpotify}
                    renderInput={(params) => (<TextField
                      onChange={searchSpotify}
                      {...params}
                      onKeyPress={(e) => {
                        if (e.which === 13) {
                          navigateToNewPageWithSearchQuery(e, 'spotify')
                        }
                      }}
                      value={spotifySongRef} id="Artist" label="Artist" />)} />
                )}
                </div>
              </form>
              <AudioPlayer
                src={previewUrl ? previewUrl : null}
                style={{ margin: '30px auto 27px auto', width: '95%' }}
                onPlay={(e) => console.log('onPlay')}

              />
            </Card>
          </GridItem>


          <Snackbar style={{ marginBottom: "50px" }} open={cantPreviewAlert} autoHideDuration={4000} onClose={() => setCantPreviewAlert(false)}>
            <Alert onClose={() => setCantPreviewAlert(false)} severity='error'>
              Unfortunately that song can't be previewed due to licensing
            </Alert>
          </Snackbar>


          <Snackbar open={needToSelectSongAlert} onClose={() => setNeedToSelectSongAlert(false)} autoHideDuration={4000} >
            <Alert onClose={() => setNeedToSelectSongAlert(false)} severity="error">
              Please select a song from our library or press Skip this
            </Alert>
          </Snackbar>



          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalLarge,
            }}
            open={openSongSelectionModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setOpenSongSelectionModal(false)
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >
            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => setOpenSongSelectionModal(false)}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              <h3 className={classes.searchThroughSpotifyHeader}>Search through Spotify to preview and request a download</h3>

            </DialogTitle>
            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >

              <form style={{ marginBottom: "60px" }} className={classes.previewForm} noValidate autoComplete="off">
                {songOrArtist === 'Song' ? (

                  <Autocomplete
                    options={songChoices.map((song, index) => {
                      return song.name + ' - ' + song.artists[0].name
                      //  + ', ' + song.album.name
                    })}
                    //  onInputChange={searchSpotify}
                    disableClearable
                    className={classes.formTextField} id="Song" label="Song"
                    InputProps={{}}
                    onChange={getTrackFromSpotify}
                    renderInput={(params) => (<TextField
                      onChange={searchSpotify}
                      {...params}
                      onKeyPress={(e) => {
                        if (e.which === 13) {
                          navigateToNewPageWithSearchQuery(e, 'spotify')
                        }
                      }}
                      value={spotifySongRef} id="Song" label="Song" />)} />
                ) : (
                  <Autocomplete
                    options={songChoices.map((artist, index) => {
                      return artist.name
                    })}
                    //  onInputChange={searchSpotify}
                    disableClearable
                    className={classes.formTextField} id="Artist" label="Artist"
                    InputProps={{}}
                    disableCloseOnSelect={true}
                    onChange={getTrackFromSpotify}
                    renderInput={(params) => (<TextField
                      onChange={searchSpotify}
                      {...params}
                      onKeyPress={(e) => {
                        if (e.which === 13) {
                          navigateToNewPageWithSearchQuery(e, 'spotify')
                        }
                      }}
                      value={spotifySongRef} id="Artist" label="Artist" />)} />
                )}
              </form>
              <AudioPlayer
                src={previewUrl ? previewUrl : null}
                style={{ margin: '30px auto 27px auto', width: '100%' }}
                onPlay={(e) => console.log('onPlay')}

              />

            </DialogContent>
          </Dialog>









          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalLarge,
            }}
            open={checkToSeeIfUserWantsToRequestSong}
            TransitionComponent={UpTransition}
            keepMounted
            onClose={() => {
              setOpenSongSelectionModal(false)
              setSongTitle(null)
              setArtistName(null)
              setCheckToSeeIfUserWantsToRequestSong(false)
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >

            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setOpenSongSelectionModal(false)
                  setSongTitle(null)
                  setArtistName(null)
                  setCheckToSeeIfUserWantsToRequestSong(false)
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>

              <h3 className={classes.searchThroughSpotifyHeader}>Are you sure you want to request the song {songTitle} by {artistName}?</h3>

            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                <Button onClick={addSpotifySong} color="primary">
                  Yes, this is my jam
                </Button>
                <Button onClick={() => {
                  setCheckToSeeIfUserWantsToRequestSong(false)
                  setOpenSongSelectionModal(true)
                }} color="danger">
                  Nevermind, I'll pick something else
                </Button>
              </div>
            </DialogContent>
          </Dialog>






        </GridContainer>

      </div >
    );
  }

  if (songLibrary && userHasSelectedSong) {
    return (
      <SongRequestCompleted name={props.displayName} />
    )
  }

  if (songLibrary && addedSongToBeDownloadRequested) {
    return (
      <SongDownloadRequestCompleted name={props.displayName} songTitle={songTitle} artistName={artistName} />
    )
  }

  return (
    <div className={classes.container}>
      <GridContainer className={classes.gridContainerDiv}>
        <GridItem xs={12} sm={12} md={12} className={classes.formContainers}>
          <div
            className={classes.loaderDiv}
          // style={{
          //   position: "absolute",
          //   // width: "100%",
          //   padding: 0,
          //   margin: 0,
          //   zIndex: "99999",
          //   width: "111vw",
          //   position: "relative",
          //   marginLeft: "-56vw",
          //   left: "65%",
          //   display: "flex",
          //   alignItems: "center",
          //   justifyContent: "center",
          //   flexDirection: "column"
          // }}
          >
            <h1 className={classes.loaderDivText} >Please wait while we load our song library...</h1>
            <img
              style={{
                // margin: "30% 0% 0% 33%",
                position: "relative",
                width: "13%"
              }}
              src={SongListLoader}
              alt="..."
            />
          </div>
        </GridItem>
      </GridContainer>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
    assessments: state.assessment,
    updateProfileModal: state.profile.updateProfileModal,
    singerUniqueID: state.queue.singerUniqueID,
    token: state.queue.token,
    songList: state.queue.songList,
    userHasSelectedSong: state.queue.userHasSelectedSong,
    addedSongToBeDownloadRequested: state.queue.addedSongToBeDownloadRequested,
    songList: state.queue.songList,
    loggedOutUserAddToQueueModal: state.queue.loggedOutUserAddToQueueModal
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setUpdateProfileModal: () => dispatch(setUpdateProfileModal()),
    searchDatabaseForSong: (input) => dispatch(searchDatabaseForSong(input)),
    addSongToExistingUserInQueue: (id, songChoice) => dispatch(addSongToExistingUserInQueue(id, songChoice)),
    skipAddSongToQueue: () => dispatch(skipAddSongToQueue()),
    addSpotifySongToDownloadRequest: (id, songChoice, previewUrl) => dispatch(addSpotifySongToDownloadRequest(id, songChoice, previewUrl)),
    getQueryResults: (query, songList) => dispatch(getQueryResults(query, songList)),
  }
}


export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(SongPreviewModal));



