/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { connect } from "react-redux";
import compose from "recompose/compose";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";

// material-ui icons
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";

// core components
import HeaderLinks from "./HeaderLinks";
import Button from "components/CustomButtons/Button.jsx";

import headerStyle from "assets/jss/material-dashboard-pro-react/components/headerStyle.jsx";
import { hideUpNext } from "store/actions/queueActions";

import dashBG from "assets/img/backgrounds/dashboard.jpeg";
import profileBG from "assets/img/backgrounds/profile.jpeg";
import coreBeliefsBG from "assets/img/backgrounds/core-beliefs.jpeg";

function Header({ ...props }) {
  function makeBrand() {
    var name;
    props.routes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      }
      if (prop.path === props.location.pathname) {
        name = prop.name;
      }
      return null;
    });
    if (name) {
      return name;
    } else {
      return "";
    }
  }

  const { classes, color, rtlActive, userNextSong } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color,
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive,
    });

  let name = makeBrand();
  let background = "";
  let backgroundPosition = "";
  if (name === "Dashboard") {
    background = dashBG;
    backgroundPosition = "0% 24%";
  } else if (name === "Profile") {
    background = profileBG;
    backgroundPosition = "24% 24%";
  } else if (name === "Core Beliefs") {
    background = coreBeliefsBG;
    backgroundPosition = "24% 10%";
  } else {
    background = "yoyos";
  }
  return (
    <AppBar
      className={classes.appBar + appBarClasses}
      style={{
        backgroundPosition: `${backgroundPosition}`,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className={classes.appBarOverlay}>
        .
        <Toolbar className={classes.container}>
          <Hidden smDown implementation="css">
            <div className={sidebarMinimize}>
              {props.miniActive ? (
                <Button
                  justIcon
                  round
                  color="primary"
                  onClick={props.sidebarMinimize}
                >
                  <ViewList className={classes.sidebarMiniIcon} />
                </Button>
              ) : (
                <Button
                  justIcon
                  round
                  color="primary"
                  onClick={props.sidebarMinimize}
                >
                  <MoreVert className={classes.sidebarMiniIcon} />
                </Button>
              )}
            </div>
          </Hidden>
          <div className={classes.flex} style={{ color: "white" }}>
            {/* Here we create navbar brand, based on route name */}
            <Button href="#" className={classes.title} color="transparent">
              {makeBrand()}
            </Button>

            <Hidden smDown implementation="css">
              <span
                style={{
                  float: "right",
                  marginTop: "-2rem",
                  textAlign: "right",
                  marginRight: "30px",
                }}
              >
                Welcome To PCL!
              </span>
            </Hidden>
          </div>
          <Hidden
            smDown
            className={classes.headerLinksWrapper}
            implementation="css"
          >
            <HeaderLinks rtlActive={rtlActive} />
          </Hidden>

          <Hidden mdUp implementation="css">
            <Menu
              onClick={() => {
                props.handleDrawerToggle();
              }}
              style={{ fontSize: "40px", color: "#fff" }}
            />
          </Hidden>
        </Toolbar>
      </div>
      {console.log('userNextSong', userNextSong)}
      {userNextSong && userNextSong.songChoice.song !== "N/A" ? (
        <div
          style={{
            width: "100%",
            height: "60px",
            backgroundColor: "#00C1F3",
            padding: "8px 30px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ fontSize: "17px", color: "white", fontWeight: 600 }}>
              {userNextSong.songChoice.song}
            </span>
            <span style={{ fontSize: "14px", color: "white", fontWeight: 300 }}>
              {userNextSong.songChoice.artist}
            </span>
          </div>
          <span
            onClick={() => props.hideUpNext()}
            style={{
              fontSize: "19px",
              color: "white",
              fontWeight: 600,
              margin: "auto 0px",
            }}
          >
            X
          </span>
        </div>
      ) : null}
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    uid: state.auth.user.uid,
    queueData: state.queue.data,
    userNextSong: state.queue.userNextSong,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideUpNext: () => dispatch(hideUpNext()),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(headerStyle)
)(Header);
