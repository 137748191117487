import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import axios from 'axios'
import { db } from "firebase/fbConfig.js"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import styles from "assets/jss/material-dashboard-pro-react/components/FeedbackFormStyle.jsx";

class RequestForm extends React.Component {
  state = {
    comment: '',
    messageSent: false,
    messageError: false
  }
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden"
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  handleChange = (e, uid) => {
    this.setState({
      [e.target.id]: e.target.value,
      uid: uid
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
      // update firestore document with PENDING and comment
      db.collection("contact-messages").doc().set({
        uid: this.state.uid,
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        message: this.state.message,
        updatedAt: new Date()
      }, {merge: true}).then(() => {
        console.log(``)
        this.setState({
          comment: '',       
          messageSent: true
        })       
      }).catch((err) => {
        console.log(`${err}`)
      })
  }

  resetRequest = () => {
    window.location.reload();
  }

  render() {
    const { classes, authError, passwordNotice, modalClose, authUser } = this.props;
    //console.log(authUser)
    // reset modal when closed
    if (this.state.messageSent) { this.resetRequest() }

    return (
      <div>
        {this.state.messageSent ? (
          <GridContainer justify="center">
            <GridItem xs={11}>
              <h4 className={classes.textPrimary}>Feedback Submitted!</h4>

            </GridItem>
          </GridContainer>
        ) : (
            <div>
            <form onSubmit={this.handleSubmit}>
              <Card login className={classes[this.state.cardAnimaton]} style={{backgroundColor: "#fff"}}>
                <CardHeader
                  className={classes.cardHeader}
                  color="primary"
                >
                  <h4 style={{textAlign: "center"}} className={classes.cardTitle}>Log in</h4>
                  {/* <div className={classes.socialLine}>
                    <Button
                      color="transparent"
                      justIcon
                      onClick={startGoogleLogin}
                      className={classes.customButtonClass}
                    >
                      <i className="fab fa-google" />
                    </Button>
                  </div> */}
                </CardHeader>
                <CardBody >
                  <p style={{color: "#333"}} className={classes.textCenter}>New? <Link to="/pages/signup-page" className={classes.linkText}>Create an account now.</Link></p>
                  <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: this.handleChange,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email />
                        </InputAdornment>
                      )
                    }}
                  />
                  <CustomInput
                    labelText="Password"
                    id="password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: this.handleChange,
                      type: "password",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      )
                    }}
                  />
                  {authError ? (
                    <div className={classes.justifyContentCenter}>
                      <p className={classes.textDanger}>Error: {authError.message}<br />Forgot your password?</p>
                      <Button
                        type="button"
                        onClick={this.handlePasswordReset}
                        color="danger"
                        simple size="lg"
                        block>
                        Click Here
                      </Button>
                      <p className={classes.textDanger}>to send a password reset email.</p>
                    </div>
                  ) : null}
                  {passwordNotice ? <p className={classes.textDanger}>{passwordNotice}</p> : null}
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button
                    type="submit"
                    color="primary"
                  >
                    Let's Go
                  </Button>
                </CardFooter>
              </Card>
            </form>      
            </div>
          )}
      </div>
    );
  }
}

RequestForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    //console.log(state)
    return {
      AuthBool: !!state.auth.user,
      authUser: state.auth.user,
      user: state.profile.user,
    }
  }
  
export default compose(
    connect(mapStateToProps),
    withStyles(styles)
  )(RequestForm);