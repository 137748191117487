/*eslint-disable*/
import React from 'react'
import Button from "components/CustomButtons/Button.jsx";
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { db } from "firebase/fbConfig.js"
import axios from 'axios'
import qs from 'qs';

import { setUserSpotifyToken } from './userActions'

import { setDownloadRequests } from './downloadRequestActions.jsx'


export const removeFavorite = (uid, songId) => {
  return (dispatch) => {
    return db.collection('TEST-users').doc(uid).collection('favorites').doc(songId).delete()
    .then(() => {
      // dispatch(setRemovedFavorite(songId))
    })
  }
}


export const getFavorites = (uid) => {
  return (dispatch) => {
    return db.collection('TEST-users').doc(uid).collection('favorites').onSnapshot((snapshot) => {
      if (!snapshot.empty) {
        snapshot = snapshot.docs
        var data = []
        snapshot.forEach((doc) => {
          doc = doc.data()
          data.push(doc)
        })
        dispatch(setFavoritesData(data))
      } else {
        dispatch(setFavoritesData([]))
      }
    })
  }
}

export const addNewFavorite = (uid, songSelection, str) => {
  return (dispatch) => {
    console.log('uid, songSelection, str', uid, songSelection, str)
    var toSet = {collectionSongId: songSelection.id ,songChoice: {
      song: songSelection.song, artist: songSelection.artist
    }}

    return db.collection('TEST-users').doc(uid).collection('favorites').doc(songSelection.id).set(toSet, { merge: true })
    .then(() => {
      dispatch(addedNewFavorite(toSet))
      if (str) {
        dispatch(showSnackbar(true))
      }
    })
  }
}

export const showSnackbar = (boolean) => {
  return {
    type: "SHOW_FAVORITE_SNACKBAR",
    boolean
  }
}

export const addedNewFavorite = (toSet) => {
  return {
    type: "ADDED_NEW_FAVORITE",
    toSet
  }
}

export const setRemovedFavorite = (id) => {
  return {
    type: "REMOVED_FAVORITE",
    id
  }
}

export const setFavoritesData = (data) => {
  return {
    type: "SET_FAVORITES_DATA",
    data
  }
}