/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { Redirect } from 'react-router-dom'
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import ReactTable from "react-table";
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { dataTable } from "variables/general.jsx";
import Fade from '@material-ui/core/Fade';
import Popper from "@material-ui/core/Popper";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import MicIcon from '@material-ui/icons/Mic';
import CheckIcon from '@material-ui/icons/Check';
import Autocomplete from '@material-ui/lab/Autocomplete';
import GetAppIcon from '@material-ui/icons/GetApp';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

// actions
import { emailSignIn, startGoogleLogin, passwordReset } from "../../store/actions/authActions"

import queueManagerStyle from "assets/jss/material-dashboard-pro-react/views/queueManagerStyle.jsx";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Logo from "assets/img/pclBigLogo.png";
import Loader from "assets/img/loader.gif";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import { getDownloadRequests, succesfullyDownloadedSong, denyDownloadRequest, deleteAllRequests } from "store/actions/downloadRequestActions"
import { getJustDatabaseSongList } from "store/actions/queueActions"

import AudioPlayer from 'react-h5-audio-player';


function filterCaseInsensitive(filter, row) {
  const id = filter.pivotId || filter.id;
  return (
    row[id] !== undefined ?
      String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
      :
      true
  );
}



// const nameData = [
//   { name: 'Timothy', song: "Oasis", artist: "Polo and Pan" },
//   { name: 'Marco', song: "Stronger", artist: "Kanye West" },
//   { name: 'Justin', song: "Roses", artist: "Outkast" },
//   { name: 'Olivia', song: "Applying Pressure", artist: "J. Cole" },
//   { name: 'Bao', song: "Alright", artist: "Kendrick Lamar" },
//   { name: 'Jonathan E', song: "Tequilla Shots", artist: "Kid Cudi" },
//   { name: 'Jonathan L', song: "Goldie", artist: "Asap Rocky" },
//   { name: 'Alex', song: "Nice For What", artist: "Drake" },
//   { name: 'Abe', song: "Corso", artist: "Tyler, The Creator" },
//   { name: 'Matt', song: "Float On", artist: "The Floaters" },
//   { name: 'Derek', song: "More Than A Woman", artist: "Bee Gees" },
//   { name: 'Tola', song: "Tadow", artist: "Masego" },
//   { name: 'Bertrand', song: "Moves", artist: "Big Sean" },
//   { name: 'Lauren', song: "Begin The Beguine", artist: "Artie Shaw" },
// ]


const tableHeaders = ['Name', 'Request', 'Actions']

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="down" ref={ref} {...props} />;
// });



const Transition = (props) => {
  return <Slide direction="down" {...props} />
}


const QueueManager = (props) => {

  const { classes, columns, dataLoaded } = props
  const [popperOpen, setPopperOpen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [singerIndex, setSingerIndex] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [tableData, setTableData] = useState([])
  const [filteredTableData, setFilteredTableData] = useState([])
  const [deleteSingerModal, setDeleteSingerModal] = useState(false)
  const [singerName, setSingerName] = useState(null)
  const [addNewSingerModal, setAddNewSingerModal] = useState(false)
  const [songList, setSongList] = useState(false)
  const [filteredSongLibrary, setFilteredSongLibrary] = useState([])
  const [songLibrary, setSongLibrary] = useState([])
  const [songRef, setSongRef] = useState('')
  const [nameRef, setNameRef] = useState('')
  const [selectedSongFromDatabase, setSelectedSongFromDatabase] = useState(null)
  const [deleteAllInQueueModal, setDeleteAllInQueueModal] = useState(false)
  const [previewSongModal, setPreviewSongModal] = useState(false)
  const [ableToDownloadModal, setAbleToDownloadModal] = useState(false)
  const [mediaSource, setMediaSource] = useState(null)
  const [deleteAllInRequestsModal, setDeleteAllInRequestsModal] = useState(false)

  console.log('poperrr', tableData[singerIndex])


  const setSpecificSinger = (event, index) => {
    setPopperOpen(!popperOpen)
    setAnchorEl(event.currentTarget);
    setSingerIndex(index)
    setSingerName(tableData[index].displayName)
  }


  const searchDatabase = (e) => {
    var input = e.target.value
    if (input === '') {
      setFilteredSongLibrary([])
    } else {
      let data = [...songList]
      const filtered = songList.filter(d => {
        if (d.artist && d.artist.toLowerCase().includes(input.toLowerCase()) || d.song && d.song.toLowerCase().includes(input.toLowerCase())) {
          return d
        }
      })
      setFilteredSongLibrary(filtered)
      setSongRef(e.target.value)
    }
  }

  const denyRequest = (index) => {
    let data = [...tableData]
    let singer = data[index]
    data.splice(index, 1)
    console.log('denyrequest', data)
    // props.denyDownloadRequest(singer, data)
    // setTableData(data)
    // setPopperOpen(false)
    // setAnchorEl(anchorEl ? null : event.currentTarget);
  }


  useEffect(() => {
    // setTableData(nameData)
    // setFinishedLoading(true)
    if (!props.dataLoaded) {
      props.getDownloadRequests()
      props.getJustDatabaseSongList()
    }
    if (props.downloadRequests.data) {
      setTableData(props.downloadRequests.data)
      console.log('propsqueuedata', props.queue.data)
    }
    if (props.queue.songList) {
      setSongList(props.queue.songList)
    }
  }, [props])


  const filterData = (e) => {
    var input = e.target.value
    if (input === '') {
      setFilteredTableData([])
    } else {
      let data = [...tableData]
      const filtered = data.filter(d => {
        if (d['requestedBy'].toLowerCase().includes(input.toLowerCase())) {
          return d
        }
      })
      setFilteredTableData(filtered)
    }
  }


  const succesfullyDownloadedSong = () => {
    var data = [...tableData]
    var singer = data[singerIndex]
    data.splice(singerIndex, 1)
    props.succesfullyDownloadedSong(singer, data, singerIndex, songList)
    setTableData(data)
    setAbleToDownloadModal(false)
  }

  const deleteAllRequests = () => {
    props.deleteAllRequests()
    setDeleteAllInRequestsModal(false)
  }


  const getSongForUrl = (param, data) => {
    console.log('dataaaap', data)
    if (data) {
      if (param === 'song') {
        var song = data.songRequest.song.split(' ')
        for (var x = 0; x < song.length; x++) {
          song[x] = song[x].toLowerCase()
        }
        var joinedSong = song.join('-')
        return joinedSong
      }
      if (param === 'artist') {
        var artist = data.songRequest.artist.split(' ')
        for (var x = 0; x < artist.length; x++) {
          artist[x] = artist[x].toLowerCase()
        }
        var joinedArtist = artist.join('-')
        return joinedArtist
      }



    }
  }



  const renderTableHeader = () => {
    // may need to add conditional to check if filter since singer index is changed
    return tableHeaders.map((key, index) => {
      // if (key === 'Name') {
      //   return (
      //     <th style={{ display: "flex", flexDirection: "column", fontSize: "26px", color: "white" }} key={index}>{key}   <TextField InputProps={{
      //       classes: {
      //         input: classes.textField,
      //       }
      //     }} onChange={filterData} /> </th>
      //   )
      // }
      return <th className={classes.tableHeader} key={index}>{key}   <TextField InputProps={{
        classes: {
          input: classes.textField
        }
      }}
        style={{ width: "100%", visibility: "hidden" }}
      /> </th>
    })
  }

  const renderTableData = () => {
    if (filteredTableData.length === 0 && tableData) {
      return tableData.map((singer, index) => {
        return (
          <tr style={singer.hasSung ? { fontWeight: 500, color: '#028392', height: "55px", fontSize: "20px" } : { fontWeight: 500, color: '#f9b518', height: "55px", fontSize: "20px" }} key={index}>
            <td style={{ textAlign: "center" }}>{singer.requestedBy}</td>
            <td style={{ textAlign: "center" }}>{singer.songRequest ? singer.songRequest.song + ' - ' + singer.songRequest.artist : 'N/A'}</td>
            <td style={{ textAlign: "center" }}>
              <div style={{ justifyContent: "space-around", display: "flex" }}>
                <Button
                  onClick={(event) => {
                    setSingerIndex(index)
                    setSpecificSinger(event, index)
                    setAbleToDownloadModal(true)
                    
                  }}
                  className={classes.actionBtn}>
                  Download
                  <GetAppIcon />
                </Button>
                <Button
                  onClick={(event) => {
                    setSingerIndex(index)
                    setSpecificSinger(event, index)
                    denyRequest(index)
                  }}
                  className={classes.actionBtn}>
                  Deny
                  <NotInterestedIcon />
                </Button>
              </div>

            </td>
            {/* <td style={{ display: "flex", justifyContent: "center", height: "100%", alignItems: "center", paddingTop: "8px" }}>
              {singer.previewUrl ? (
                <PlayArrowIcon onClick={() => {
                  setPreviewSongModal(true)
                  setSingerIndex(index)
                  setMediaSource(tableData[index].previewUrl)
                }} className={classes.micIcon} />
              ) : (<PlayArrowIcon style={{ visibility: "hidden" }} onClick={() => setPreviewSongModal(true)} className={classes.micIcon} />)}

              <MoreVertOutlinedIcon
                aria-describedby={'transitions-popper'}
                onClick={(event) => {
                  setSpecificSinger(event, index)
                  setAnchorEl(anchorEl ? null : event.currentTarget);
                }
                }
                className={classes.threeDots} />
              <Popper style={!anchorEl ? { display: "none" } : { zIndex: 1000 }} placement={'right'} id={'transitions-popper'} open={popperOpen} anchorEl={anchorEl} transition>
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} >
                    <Card style={{ width: "154px", display: "flex" }}>
                      <Button
                        style={{ marginTop: "-10px", marginBottom: "-15px" }}
                        onClick={() => {
                          setAnchorEl(anchorEl ? null : event.currentTarget);
                          setPopperOpen(!popperOpen)
                          setSingerIndex(index)
                        }}
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                      >
                        {" "}
                        <Close className={classes.modalClose} />
                      </Button>

                      <div onClick={() => {
                        setPopperOpen(false)
                        setAnchorEl(anchorEl ? null : event.currentTarget)
                        setAbleToDownloadModal(true)
                      }} className={classes.popperDiv}>
                        <p className={classes.popperSelections}>
                          Download
                        </p>
                        <GetAppIcon />
                      </div>

                      <div onClick={denyRequest} className={classes.popperDiv}>
                        <p className={classes.popperSelections}>
                          Deny
                        </p>
                        <NotInterestedIcon />

                      </div>
                    </Card>
                  </Fade>
                )}
              </Popper>
            </td> */}
          </tr>
        )
        // }
      })


    } else {


      if (filteredTableData.length > 0) {

        return filteredTableData.map((singer, index) => {
          return (
            <tr style={singer.hasSung ? { fontWeight: 500, color: '#028392', height: "55px", fontSize: "20px" } : { fontWeight: 500, color: '#f9b518', height: "55px", fontSize: "20px" }} key={index}>
              <td style={{ textAlign: "center" }}>{singer.requestedBy}</td>
              <td style={{ textAlign: "center" }}>{singer.songRequest ? singer.songRequest.song + ' - ' + singer.songRequest.artist : 'N/A'}</td>
              {/* <td style={{ textAlign: "center" }}>{singer.positionInQueue + 1 || 'N/A'}</td> */}
              {/* <ClickAwayListener onClickAway={() => setPopperOpen(!popperOpen)}> */}
              <td style={{ display: "flex", justifyContent: "center", height: "100%", alignItems: "center", paddingTop: "8px" }}>
                <PlayArrowIcon onClick={() => {
                  setPreviewSongModal(true)
                  setSingerIndex(index)
                  setMediaSource(tableData[index].previewUrl)
                }} className={classes.micIcon} />
                <MoreVertOutlinedIcon
                  aria-describedby={'transitions-popper'}
                  onClick={(event) => {
                    setSpecificSinger(event, index)
                    setAnchorEl(anchorEl ? null : event.currentTarget);
                  }
                  }
                  className={classes.threeDots} />
                <Popper style={!anchorEl ? { display: "none" } : { zIndex: 1000 }} placement={'right'} id={'transitions-popper'} open={popperOpen} anchorEl={anchorEl} transition>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} >
                      <Card style={{ width: "154px", display: "flex" }}>
                        <Button
                          style={{ marginTop: "-10px", marginBottom: "-15px" }}
                          onClick={() => {
                            setAnchorEl(anchorEl ? null : event.currentTarget);
                            setPopperOpen(!popperOpen)
                            setSingerIndex(index)
                          }}
                          simple
                          className={classes.modalCloseButton}
                          key="close"
                          aria-label="Close"
                        >
                          {" "}
                          <Close className={classes.modalClose} />
                        </Button>
                        <a style={{ color: "black" }} href={`https://www.karaoke-version.com/karaoke/${getSongForUrl('artist', tableData[singerIndex])}/${getSongForUrl('song', tableData[singerIndex])}.html`} target="blank">
                          <div onClick={() => {
                            setPopperOpen(false)
                            setAnchorEl(anchorEl ? null : event.currentTarget)
                            setAbleToDownloadModal(true)
                          }} className={classes.popperDiv}>
                            <p className={classes.popperSelections}>
                              Download
                            </p>
                            <GetAppIcon />
                          </div>
                        </a>
                        <div onClick={denyRequest} className={classes.popperDiv}>
                          <p className={classes.popperSelections}>
                            Deny
                          </p>
                          <NotInterestedIcon />

                        </div>
                      </Card>
                    </Fade>
                  )}
                </Popper>
              </td>
            </tr>
          )
        })
      }

    }
  }



  return (

    <div style={{ width: "100%" }} className={classes.container}>
      {props.downloadRequests.data ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} style={{ display: "flex", flexDirection: "column" }} className={classes.formContainer}>
            <Card style={tableData.length === 0 ? { height: "500px" } : { minHeight: "500px" }} className={classes.cardBody}>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr style={{ height: "50px" }}>{renderTableHeader()} </tr>
                  {renderTableData()}
                </tbody>
              </table>
            </Card>
            <Fab className={classes.deleteQueueBtn} onClick={() => {
              setDeleteAllInRequestsModal(true)
            }} aria-label="add">
              <DeleteForeverIcon style={{ fontSize: "xxx-large" }} />
            </Fab>
          </GridItem>



          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal

                + " " + classes.modalSmall,
            }}
            open={deleteAllInRequestsModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setDeleteAllInRequestsModal(false)
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >

            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setDeleteAllInRequestsModal(false)
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              <h3 style={{ textAlign: "center", marginTop: "50px" }}>Are you sure you want to delete all requests?</h3>

            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                <Button onClick={deleteAllRequests} color="danger">
                  Delete
                </Button>
                <Button onClick={() => {
                  setDeleteAllInRequestsModal(false)
                }} color="primary">
                  Cancel
                </Button>
              </div>
            </DialogContent>
          </Dialog>










          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal

                + " " + classes.modalSmall,
            }}
            open={ableToDownloadModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setAbleToDownloadModal(false)
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >

            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setAbleToDownloadModal(false)
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              <h3 style={{ textAlign: "center", marginTop: "50px" }}>Were you able to download the song?</h3>

            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                <Button onClick={succesfullyDownloadedSong} color="primary">
                  Yes, I got it
                </Button>
                <Button onClick={() => {
                  setAbleToDownloadModal(false)
                  denyRequest(singerIndex)
                }} color="danger">
                  No, It's Unavailable
                </Button>
              </div>
            </DialogContent>
          </Dialog>







          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal

                + " " + classes.modalSmall,
            }}
            open={previewSongModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setPreviewSongModal(false)
              setMediaSource(null)
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >

            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setPreviewSongModal(false)
                  setMediaSource(null)
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              <h3 style={{ textAlign: "center", marginTop: "50px", color: "#00C1F3", fontSize: "20px", fontWeight: 500 }}>Here is a preview of the download request</h3>

            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <AudioPlayer
                src={mediaSource}
                style={{ margin: '30px auto 27px auto', width: '100%' }}
                onPlay={(e) => console.log('onPlay')}
                autoPlay={false}
                autoPlayAfterSrcChange={false}
              />
            </DialogContent>
          </Dialog>

        </GridContainer>
      ) : (
        <div className={classes.easeInOut}>
          <div
            style={{
              // // background: "black",
              // // position: "absolute",
              // // width: "110%",
              // padding: "50px 0 0 0 0",
              height: "180%",
              zIndex: "99999",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              marginTop: "10%"
            }}
          >
            <img
              className={classes.loaderImg}
              src={Logo}
              alt="..."
            />
            <img
              style={{
                // margin: "30% 0% 0% 33%",
                position: "relative",
                width: "15%"
              }}
              src={Loader}
              alt="..."
            />
          </div>
        </div>
      )}
    </div>
  )
}

QueueManager.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    queue: state.queue,
    downloadRequests: state.downloadRequests,
    dataLoaded: state.downloadRequests.downloadRequestDetails.dataLoaded
    // songList: state.queue.songList
  }
}

const mapDispatchToProps = (dispatch) => ({
  getDownloadRequests: () => dispatch(getDownloadRequests()),
  succesfullyDownloadedSong: (singer, data, singerIndex, songList) => dispatch(succesfullyDownloadedSong(singer, data, singerIndex, songList)),
  denyDownloadRequest: (singer, tableData) => dispatch(denyDownloadRequest(singer, tableData)),
  deleteAllRequests: () => dispatch(deleteAllRequests()),
  getJustDatabaseSongList: () => dispatch(getJustDatabaseSongList()),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(queueManagerStyle)
)(QueueManager);
