/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

// @material-ui/icons
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import DeleteOutlineOutlined from "@material-ui/icons/DeleteOutlineOutlined";
import MicIcon from "@material-ui/icons/Mic";
import "react-h5-audio-player/lib/styles.css";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import AddToQueueBtns from "../Pages/AddToQueueBtns";

import queueManagerStyle from "assets/jss/material-dashboard-pro-react/views/queueManagerStyle.jsx";
import Logo from "assets/img/pclBigLogo.png";
import Loader from "assets/img/loader.gif";

import { deleteUserSong } from "store/actions/userActions";

import { getFavorites, removeFavorite } from "store/actions/favoritesActions";

import {
  addSongToExistingUserInQueue,
  loggedInUserAddToQueue,
} from "store/actions/queueActions.jsx";
import { getQueryResults } from "store/actions/searchActions.jsx";
import { db } from "../../firebase/fbConfig";

const tableHeaders = ["Song", "Artist", "Actions"];

const tableHeadersList = ["New Karaoke Request", "Download Status", "Actions"];

const UpTransition = (props) => {
  return <Slide direction="up" {...props} />;
};

const Favorites = (props) => {
  const { classes, dataLoaded, favoritesData, userData, userInRange } = props;
  const [popperOpen, setPopperOpen] = useState(false);
  const [popperOpenList, setPopperOpenList] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElList, setAnchorElList] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tableDataList, setTableDataList] = useState([]);
  const [songLibrary, setSongLibrary] = useState([]);
  const [selectedSongFromDatabase, setSelectedSongFromDatabase] = useState(
    null
  );
  const [userInQueueAlready, setUserInQueueAlready] = useState(false);
  const [currentSongChoice, setCurrentSongChoice] = useState({
    song: "N/A",
    artist: "N/A",
  });
  const [queueData, setQueueData] = useState([]);
  const [checkIfUserLikesSong, setCheckIfUserLikesSong] = useState(false);
  const [songTitle, setSongTitle] = useState("N/A");
  const [artistName, setArtistName] = useState("N/A");
  const [userName, setUserName] = useState("");
  const [removedFavorite, setRemovedFavorite] = useState(false);
  const [addedFavoriteToNextSong, setAddedFavoriteToNextSong] = useState(false);
  const [removeFavoriteModal, setRemoveFavoriteModal] = useState(false);
  const [userMustBeWithinRangeAlert, setUserMustBeWithinRangeAlert] = useState(
    false
  );

  const setSpecificSinger = (event) => {
    setPopperOpen(!popperOpen);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const addSongToQueue = async (e) => {
    e.preventDefault();
    if (!userInRange) {
      setUserMustBeWithinRangeAlert(true);
      return;
    }
    let data = [...props.queue.data];
    console.log("userInQueueAlready", userInQueueAlready);
    if (!userInQueueAlready) {
      await props.loggedInUserAddToQueue(
        selectedSongFromDatabase,
        userName,
        props.authUser.uid,
        data
      );
    } else {
      var userQueueId;
      for (var x = 0; x < data.length; x++) {
        if (data[x].uid && data[x].uid === props.authUser.uid) {
          userQueueId = data[x].id;
        }
      }
      console.log(
        "userQueueId, selectedSongFromDatabase,",
        userQueueId,
        selectedSongFromDatabase
      );
      await props.addSongToExistingUserInQueue(
        userQueueId,
        selectedSongFromDatabase,
        "updatingUserSong"
      );
    }
    setCheckIfUserLikesSong(false);
    setAddedFavoriteToNextSong(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (props.queue.data && props.authUser) {
        var inQueue = false;
        setQueueData(props.queue.data);

        for (var x = 0; x < props.queue.data.length; x++) {
          if (
            props.queue.data[x].uid &&
            props.queue.data[x].uid === props.authUser.uid
          ) {
            inQueue = true;
            if (props.queue.data[x].songChoice) {
              setCurrentSongChoice(props.queue.data[x].songChoice);
            } else {
              setCurrentSongChoice({ song: "N/A", artist: "N/A" });
            }
            setUserInQueueAlready(true);
          }
        }

        if (!inQueue) {
          setCurrentSongChoice({ song: "N/A", artist: "N/A" });
        }
      }

      if (!props.dataLoaded && props.authUser) {
        await props.getFavorites(props.authUser.uid);
      }

      if (props.dataLoaded && props.authUser) {
        setTableData(favoritesData);
      }

      if (props.queue.songList) {
        setSongLibrary(props.songList);
      }
      if (props.user.displayName) {
        setUserName(props.user.displayName);
      }
    };

    fetchData();
  }, [props]);

  useEffect(() => {
    const fetchData = async () => {
      if (props.authUser && !userData.data) {
        try {
          const querySnapshot = await db
            .collection("TEST-users")
            .doc(props.authUser.uid)
            .collection("user-songs")
            .get();

          const items = querySnapshot.docs.map((doc) => doc.data());
          console.log("🚀 ~ fetchData ~ items:", items);
          setTableDataList(items);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        setTableDataList(userData.data);
      }
    };

    fetchData();
  }, [userData, props.authUser]);

  const renderTableHeader = () => {
    return tableHeaders.map((key, index) => {
      return (
        <th className={classes.tableHeader} key={index}>
          {key}{" "}
        </th>
      );
    });
  };

  const renderTableData = () => {
    return tableData.map((userSong, index) => {
      return (
        <tr
          style={{
            fontWeight: 500,
            color: "#f9b518",
            height: "55px",
            fontSize: "20px",
          }}
          key={index}
        >
          <td style={{ textAlign: "center" }}>{userSong.songChoice.song}</td>
          <td style={{ textAlign: "center" }}>{userSong.songChoice.artist}</td>

          <td
            style={{
              display: "flex",
              justifyContent: "space-around",
              height: "100%",
              alignItems: "center",
              paddingTop: "8px",
            }}
          >
            <FavoriteBorderIcon
              className={classes.iconCursor}
              key={index}
              onClick={() => {
                setSelectedSongFromDatabase({
                  song: tableData[index].songChoice.song,
                  artist: tableData[index].songChoice.artist,
                  id: tableData[index].collectionSongId,
                });
                setSongTitle(tableData[index].songChoice.song);
                setArtistName(tableData[index].songChoice.artist);
                setRemoveFavoriteModal(true);
              }}
            />
            <MicIcon
              className={classes.iconCursor}
              key={index}
              onClick={(e) => {
                setSpecificSinger(e, index);
                setAnchorEl(anchorEl ? null : e.currentTarget);
                setSelectedSongFromDatabase({
                  song: tableData[index].songChoice.song,
                  artist: tableData[index].songChoice.artist,
                  id: tableData[index].collectionSongId,
                });
                setSongTitle(tableData[index].songChoice.song);
                setArtistName(tableData[index].songChoice.artist);
                setCheckIfUserLikesSong(true);
              }}
            />
          </td>
        </tr>
      );
    });
  };

  const renderTableHeaderList = () => {
    return tableHeadersList.map((key, index) => {
      return (
        <th className={classes.tableHeader} key={index}>
          {key}
        </th>
      );
    });
  };

  const renderTableDataList = () => {
    return tableDataList.map((userSong, index) => {
      if (!userSong.downloadRequest) {
        return null;
      }

      if (userSong.songChoice.song === "N/A") {
        return null;
      }
      return (
        <tr
          style={
            userSong.hasSung
              ? {
                  fontWeight: 500,
                  color: "#028392",
                  height: "55px",
                  fontSize: "20px",
                }
              : userSong.downloadStatus === "Denied"
              ? {
                  fontWeight: 500,
                  color: "red",
                  height: "55px",
                  fontSize: "20px",
                }
              : {
                  fontWeight: 500,
                  color: "#f9b518",
                  height: "55px",
                  fontSize: "20px",
                }
          }
          key={index}
        >
          <td style={{ textAlign: "center" }}>
            {userSong.songChoice
              ? userSong.songChoice.song + " - " + userSong.songChoice.artist
              : "N/A"}
          </td>
          <td style={{ textAlign: "center" }}>{userSong.downloadStatus}</td>

          <td
            style={{
              display: "flex",
              justifyContent: "space-around",
              height: "100%",
              alignItems: "center",
              paddingTop: "8px",
            }}
          >
            <DeleteOutlineOutlined
              className={classes.iconCursor}
              onClick={async (e) => {
                setPopperOpenList(!popperOpenList);
                setAnchorElList(anchorElList ? null : e.currentTarget);
                await props.deleteUserSong(
                  tableDataList[index],
                  tableDataList,
                  index,
                  props.authUser.uid
                );
              }}
            />
            {userSong.collectionSongId && (
              <MicIcon
                className={classes.iconCursor}
                key={index}
                onClick={(e) => {
                  setSpecificSinger(e, index);
                  setAnchorElList(anchorElList ? null : e.currentTarget);
                  const { song, artist } = tableDataList[index].songChoice;
                  console.log("tableDataList[index]", tableDataList[index]);
                  // need to figure out how to get the collectionSongId
                  setSelectedSongFromDatabase({
                    song: tableDataList[index].songChoice.song,
                    artist: tableDataList[index].songChoice.artist,
                    id: tableDataList[index].collectionSongId,
                  });
                  setSongTitle(song);
                  setArtistName(artist);
                  setCheckIfUserLikesSong(true);
                }}
              />
            )}
          </td>
        </tr>
      );
    });
  };

  return (
    <div style={{ width: "100%" }} className={classes.container}>
      {dataLoaded && songLibrary && tableDataList ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.formContainer}>
            <Card
              style={
                tableData.length === 0
                  ? { height: "300px", width: "95%" }
                  : { width: "95%" }
              }
              className={classes.cardBody}
            >
              <table className={classes.table}>
                <tbody>
                  <tr style={{ height: "50px" }}>{renderTableHeader()} </tr>
                  {renderTableData()}
                </tbody>
              </table>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12} className={classes.formContainer}>
            <Card
              style={
                tableData.length === 0
                  ? { height: "300px", width: "95%" }
                  : { width: "95%" }
              }
              className={classes.cardBody}
            >
              <table className={classes.table}>
                <tbody>
                  <tr style={{ height: "50px" }}>{renderTableHeaderList()} </tr>
                  {renderTableDataList()}
                </tbody>
              </table>
            </Card>
          </GridItem>

          <AddToQueueBtns
            currentSongChoice={currentSongChoice}
            userInQueueAlready={userInQueueAlready}
            tableData={queueData}
            songList={songLibrary}
          />

          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalLarge,
            }}
            open={checkIfUserLikesSong}
            TransitionComponent={UpTransition}
            keepMounted
            onClose={() => {
              setCheckIfUserLikesSong(false);
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >
            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setCheckIfUserLikesSong(false);
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>

              <h3 className={classes.searchThroughSpotifyHeader}>
                Are you sure you want to choose {songTitle} by {artistName} as
                your next song?
              </h3>
            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Button onClick={addSongToQueue} color="primary">
                  Yes, This Is My Jam
                </Button>
                <Button
                  onClick={() => {
                    setCheckIfUserLikesSong(false);
                  }}
                  color="danger"
                >
                  {`Nevermind, I'll pick something else`}
                </Button>
              </div>
            </DialogContent>
          </Dialog>

          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalLarge,
            }}
            open={removeFavoriteModal}
            TransitionComponent={UpTransition}
            keepMounted
            onClose={() => {
              setRemoveFavoriteModal(false);
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >
            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setRemoveFavoriteModal(false);
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>

              <h3 className={classes.searchThroughSpotifyHeader}>
                Are you sure you want to unfavorite {songTitle} by {artistName}?{" "}
              </h3>
            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Button
                  onClick={async () => {
                    await props.removeFavorite(
                      props.authUser.uid,
                      selectedSongFromDatabase.id
                    );
                    setRemovedFavorite(true);
                    setRemoveFavoriteModal(false);
                  }}
                  color="primary"
                >
                  {`Yes, I'm over this song.`}
                </Button>
                <Button
                  onClick={() => {
                    setRemoveFavoriteModal(false);
                  }}
                  color="danger"
                >
                  {`No, I'd like to keep this song.`}
                </Button>
              </div>
            </DialogContent>
          </Dialog>

          <Snackbar
            style={{ marginBottom: "30px" }}
            open={userMustBeWithinRangeAlert}
            onClose={() => {
              setUserMustBeWithinRangeAlert(false);
            }}
            autoHideDuration={4000}
          >
            <Alert
              onClose={() => {
                setUserMustBeWithinRangeAlert(false);
              }}
              severity="error"
            >
              {`Unfortunately you must be connected PCL's wifi to be added to the
              queue. Please connect and then refresh the app.`}
            </Alert>
          </Snackbar>

          <Snackbar
            style={{ marginBottom: "50px" }}
            open={removedFavorite}
            autoHideDuration={4000}
            onClose={() => setRemovedFavorite(false)}
          >
            <Alert onClose={() => setRemovedFavorite(false)} severity="success">
              Favorite Removed
            </Alert>
          </Snackbar>

          <Snackbar
            style={{ marginBottom: "50px" }}
            open={addedFavoriteToNextSong}
            autoHideDuration={4000}
            onClose={() => setAddedFavoriteToNextSong(false)}
          >
            <Alert
              onClose={() => setAddedFavoriteToNextSong(false)}
              severity="success"
            >
              Your next song choice has been picked
            </Alert>
          </Snackbar>
        </GridContainer>
      ) : (
        <div className={classes.easeInOut}>
          <div
            style={{
              height: "180%",
              zIndex: "99999",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              marginTop: "10%",
            }}
          >
            <img className={classes.loaderImg} src={Logo} alt="..." />
            <img
              style={{
                position: "relative",
                width: "15%",
              }}
              src={Loader}
              alt="..."
            />
          </div>
        </div>
      )}
    </div>
  );
};

Favorites.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    queue: state.queue,
    authUser: state.auth.user,
    user: state.profile.user,
    dataLoaded: state.favorites.dataLoaded,
    spotifyToken: state.user.spotifyToken,
    loadedOnce: state.user.loadedOnce,
    songList: state.queue.songList,
    userInRange: state.auth.isUserInRange,
    favoritesData: state.favorites.favoritesData,
    userData: state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getFavorites: (uid) => dispatch(getFavorites(uid)),
  deleteUserSong: (song, allSongs, indexOfSong, uid) =>
    dispatch(deleteUserSong(song, allSongs, indexOfSong, uid)),
  getQueryResults: (query, songList) =>
    dispatch(getQueryResults(query, songList)),
  removeFavorite: (uid, songId) => dispatch(removeFavorite(uid, songId)),
  loggedInUserAddToQueue: (song, userName, UID, data) =>
    dispatch(loggedInUserAddToQueue(song, userName, UID, data)),
  addSongToExistingUserInQueue: (id, songChoice, str, previewUrl) =>
    dispatch(addSongToExistingUserInQueue(id, songChoice, str, previewUrl)),
});

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(queueManagerStyle)
  )(Favorites)
);
