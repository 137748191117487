
  // Orders Data
  const groupCalls = {
    columns: [        
      {
        Header: "TYPE",
        accessor: "type"      
      },
      {
        Header: "LENGTH",
        accessor: "length"      
      },
      {
        Header: "DATE",
        accessor: "date"      
      },
      {
        Header: "TIME",
        accessor: "time"      
      },

      {
        Header: "TITLE",
        accessor: "title"      
      },
      {
        Header: "PRESENTERS",
        accessor: "presenters"      
      },

      {
        Header: "DESCRIPTION",
        accessor: "description"      
      },
      {
        Header: "ZOOM",
        accessor: "zoomData"
      },
      {
        Header: "ADD TO CALENDAR",
        accessor: "register"
      }
    ],  
    dataLoaded: false
  };

  
  export const initState = {
    groupCalls
  }
  
  export default (state = initState, action) => {
    switch (action.type) { 
      case 'SET_GROUP_CALLS_LIST':
        console.log('SET_GROUP_CALLS_LIST')     
        return {
          ...state,
          groupCalls: {
            ...state.groupCalls,
            dataRows: action.dataRows,
            dataLoaded: true
          }
        };    
      default:
        return state
    }
  }
  