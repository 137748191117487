/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import styles from "assets/jss/material-dashboard-pro-react/components/ActionSubmitFormStyle.jsx";

class Form extends React.Component {
  state = {
    comment: "",
    messageSent: false,
    messageError: false,
    defaultLoaded: false,
  };
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
  };

  resetRequest = () => {
    window.location.reload();
  };
  setDefaults = () => {
    console.log("SET_FORM_DEFAULTS");
    this.setState({
      comment: this.props.comment ? this.props.comment : "",
      defaultLoaded: true,
    });
  };
  resetForm = () => {
    this.setState({
      comment: "",
      defaultLoaded: false,
    });
  };

  render() {
    const { classes, modalClose } = this.props;

    // reset modal when closed
    if (modalClose && this.state.messageSent) {
      this.resetRequest();
    }
    if (modalClose && this.state.defaultLoaded) {
      this.resetForm();
    }
    if (!modalClose && !this.state.defaultLoaded) {
      this.setDefaults();
    }

    return (
      <div>
        {this.state.messageSent ? (
          <GridContainer justify="center">
            <GridItem xs={11}>
              <h4 className={classes.textPrimary}>Action Confirmed!</h4>
            </GridItem>
          </GridContainer>
        ) : (
          <div className={classes.formContainer}>
            <form onSubmit={this.handleSubmit}>
              <GridContainer justify="center">
                <GridItem xs={11}>
                  <h5 className={classes.bodyText}>
                    Update comment and confirm?
                  </h5>
                  {this.state.messageError ? (
                    <h5 className={classes.bodyText}>
                      There Has Been An Error
                    </h5>
                  ) : null}
                  <p
                    className={classes.bodyText}
                    style={{
                      marginBottom: "0",
                      marginTop: "20px",
                    }}
                  >
                    Comment
                  </p>
                  <CustomInput
                    id="comment"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      multiline: true,
                      rows: 5,
                      value: this.state.comment,
                      onChange: (event) => this.handleChange(event),
                    }}
                  />
                </GridItem>
              </GridContainer>
              <Button
                type="submit"
                color="primary"
                style={{
                  marginLeft: "10px",
                  marginBottom: "10px",
                }}
              >
                SUBMIT
              </Button>
            </form>
          </div>
        )}
      </div>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Form);
