/*eslint-disable*/
import SignupPage from "views/Pages/SignupPage.jsx";
import LoginPage from "views/Pages/LoginPage.jsx";
import KaraokeList from "views/Pages/KaraokeList.jsx";
import Requests from "views/Pages/Requests.jsx";
import SongPreview from "views/Preview/SongPreview.jsx";
import About from "views/Pages/About.jsx"


// @material-ui/icons
import PersonAdd from "@material-ui/icons/PersonAdd";
import Fingerprint from "@material-ui/icons/Fingerprint";
import SettingsVoiceIcon from '@material-ui/icons/SettingsVoice';
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import InfoIcon from '@material-ui/icons/Info';


// just putting here to get functionality
import QueueManager from "../views/Admin/QueueManager.jsx";
import LoggedOutPCLResults from "../views/LoggedOutSearchResults/LoggedOutPCLResults.jsx"
import LoggedOutSpotifyResults from "../views/LoggedOutSearchResults/LoggedOutSpotifyResults.jsx"

const pagesRoutes = [
  {
    path: "/pages/create-profile",
    name: "Create Profile",
    short: "Create Profile",
    mini: "HM",
    icon: PersonAdd,
    component: SignupPage
  },
  {
    path: "/pages/login-page",
    name: "Login Page",
    short: "Login",
    mini: "LP",
    icon: Fingerprint,
    component: LoginPage
  },
  {
    path: "/pages/singer-List",
    name: "Singer's List",
    short: "Singer's List",
    mini: "Q",
    icon: QueueMusicIcon,
    component: KaraokeList
  },
  // {
  //   path: "/pages/song-preview",
  //   name: "Song Preview",
  //   short: "Song Preview",
  //   mini: "Q",
  //   icon: QueueMusicIcon,
  //   component: KaraokeList
  // },
  // {
  //   path: "/pages/karaoke-list",
  //   name: "Queue",
  //   short: "Queue",
  //   mini: "Q",
  //   icon: QueueMusicIcon,
  //   component: QueueManager
  // },
  {
    path: "/pages/preview-song",
    name: "Preview Song",
    short: "Preview Song",
    mini: "R",
    icon: SettingsVoiceIcon,
    component: SongPreview
  },
  {
    path: "/pages/about",
    name: "About",
    short: "About",
    mini: "R",
    icon: InfoIcon,
    component: About
  },
  {
    path: "/pages/spotify-search-results",
    name: "Spotify Search Results",
    short: "Spotify Search Results",
    mini: "R",
    icon: InfoIcon,
    component: LoggedOutSpotifyResults
  },
  {
    path: "/pages/pcl-search-results",
    name: "PCL Search Results",
    short: "PCL Search Results",
    mini: "R",
    icon: InfoIcon,
    component: LoggedOutPCLResults
  },
  // {
  //   path: "/pages/song-preview",
  //   name: "Song Preview",
  //   short: "Preview",
  //   mini: "SP",
  //   icon: LibraryMusicIcon,
  //   component: SongPreview
  // }
];

export default pagesRoutes;
