import { db } from "firebase/fbConfig.js";

// Get User
export const startSetUser = (userObj, user) => {
  return (dispatch) => {
    console.log("userObjuserObj", userObj);
    if (!userObj.email) {
      userObj = {
        ...userObj,
        email: user.email,
        groups: [],
        termGroups: [],
        displayName: user.displayName,
      };

      updateUser(userObj, user.uid);
    }
    dispatch(setUser(userObj));
  };
};

export const setUser = (userObj) => ({
  type: "SET_USER",
  userObj,
});

export const setUpdateProfileModal = () => {
  console.log("profilemodalreducer", "clicked");
  return {
    type: "SETUPDATEPROFILEMODAL",
  };
};

export const updateUser = (userObj, uid) => {
  return db
    .collection("TEST-users")
    .doc(uid)
    .set(
      {
        ...userObj,
        updatedAt: new Date(),
      },
      { merge: true }
    )
    .then(() => {
      // window.location.reload()
    })
    .catch((err) => {
      return `Update TEST-users with id: ${uid} with error: ${err}`;
    });
};

export const setUserNeedsToAddNameModal = (boolean) => {
  return {
    type: "SET_USERNEEDSTOADDNAME",
    boolean,
  };
};
