
  
  // Orders Data
  const messages = {
    columns: [        
      {
        Header: "TITLE",
        accessor: "title"      
      },
      {
        Header: "DESCRIPTION",
        accessor: "description"      
      },
      {
        Header: "IMAGE",
        accessor: "image"      
      },
      {
        Header: "LINK",
        accessor: "link"      
      },
      {
        Header: "CATEGORY",
        accessor: "category"      
      },
      {
        Header: "LINK",
        accessor: "link"
      }
    ],  
    dataLoaded: false
  };
  
  export const initState = {
    messages 
  }
  
  export default (state = initState, action) => {
    switch (action.type) { 
      case 'SET_MESSAGES_LIST':
        console.log('SET_MESSAGES_LIST')     
        return {
          ...state,
          messages: {
            ...state.messages,
            dataRows: action.dataRows,
            dataLoaded: true
          }
        };    
      default:
        return state
    }
  }
  