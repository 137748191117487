/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from "firebase/fbConfig.js";
import { withRouter } from 'react-router-dom';
import axios from 'axios'
import qs from 'qs';
// Preloader
import Loader from "assets/img/loader.gif";
import SongListLoader from "assets/img/loader.gif";
import Hidden from "@material-ui/core/Hidden";
import Logo from "assets/img/pclBigLogo.png";

// react component for creating dynamic tables
import ReactTable from "react-table";

// audio player
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Autocomplete from '@material-ui/lab/Autocomplete';

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Edit from '@material-ui/icons/Edit';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import SearchIcon from '@material-ui/icons/Search';
import MicIcon from '@material-ui/icons/Mic';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import AddIcon from '@material-ui/icons/Add';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ProfilePicUpload from "components/CustomUpload/profilePicUpload"
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";
import TextField from '@material-ui/core/TextField';
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/searchResultsStyle";
import { Grid } from "@material-ui/core";
import { getQueryResults, setSearchDataLoaded, getSpotifyQueryResults } from "store/actions/searchActions.jsx"
import { getJustDatabaseSongList, loggedInUserAddToQueue, addSongToExistingUserInQueue, getSpotifyToken, addSpotifySongToDownloadRequestLoggedInUser } from "store/actions/queueActions"
import { newDanceRequest, setRequestCreated } from "store/actions/danceRequestActions.jsx"



const tableHeaders = ['Song', 'Artist', 'Actions']

const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

const LoggedOutSpotifyResults = (props) => {
  const { classes, searchDataLoaded, searchResults, query, songList, user, requestCreated, history } = props


  const [searchInput, setSearchInput] = useState(query)
  const [loaded, setLoaded] = useState(false)
  const [selectSongModal, setSelectSongModal] = useState(false)
  const [chosenSong, setChosenSong] = useState(false)
  const [currentSongChoice, setCurrentSongChoice] = useState(false)
  const [queueData, setQueueData] = useState([])
  const [userInQueueAlready, setUserInQueueAlready] = useState(false)
  const [UID, setUID] = useState(null)
  const [queueId, setQueueId] = useState(false)
  const [token, setToken] = useState('')
  const [successSnackBar, setSuccessSnackBar] = useState(false)
  const [needsToBeDownloadedModal, setNeedsToBeDownloadedModal] = useState(false)
  const [selectSongForDanceRequestModal, setSelectSongForDanceRequestModal] = useState(false)
  const [needToSignUpModal, setNeedToSignUpModal] = useState(false)



  useEffect(() => {
    if (!searchDataLoaded) {
      props.getSpotifyToken()
      props.setSearchDataLoaded()
    }
    if (searchDataLoaded) {
      setLoaded(true)
      setToken(props.token)
    }
  }, [props])


  const addNewDanceRequest = () => {
    var adminData = {}
    var userData = {}
      adminData.song = chosenSong.song
      adminData.artist = chosenSong.artist
      adminData.requestedBy = user.displayName
      adminData.preview_url = false
      adminData.downloadRequest = true

      userData.song = chosenSong.song
      userData.artist = chosenSong.artist
      userData.downloadStatus = "Pending"
      userData.requestId = ""
    props.newDanceRequest(userData, adminData, UID)
    setSelectSongForDanceRequestModal(false)
  }


  const confirmedSongChoice = (str) => {
    if (str === 'changing') {
      props.addSongToExistingUserInQueue(queueId, chosenSong, 'updatingUserSongWithSpotify', 'N/A')
      setNeedsToBeDownloadedModal(false)
    } else {
      var data = [...queueData]
      // props.loggedInUserAddToQueue(chosenSong, props.user.displayName, UID, data)
      props.addSpotifySongToDownloadRequestLoggedInUser(queueId, chosenSong, 'N/A', UID, user.displayName, props.queue.data)
      setNeedsToBeDownloadedModal(false)
    }
  }


  const filterAgain = () => {
    props.getSpotifyQueryResults(searchInput, token)
  }


  const renderTableData = () => {
    return searchResults.map((result, index) => {
      return (
        <tr
          id='tableRow'
          style={{ fontWeight: 500, color: '#f9b518', height: "55px", fontSize: "20px" }} key={index}>
          <td style={{ textAlign: "center" }}>{result.name}</td>
          <td style={{ textAlign: "center" }}>{result.artists[0].name}</td>
          <td className={classes.actionBtns}>
            <MicIcon onClick={() => {
              setNeedToSignUpModal(true)
            }} className={classes.addBackToQueueBtn} />
            <MusicNoteIcon
              onClick={() => {
                setNeedToSignUpModal(true)
              }}
              className={classes.addBackToQueueBtn} />
          </td>
        </tr>
      )
    })
  }

  const renderTableHeader = () => {
    return tableHeaders.map((key, index) => {
      return <th className={classes.tableHeader} key={index}>{key}   <TextField InputProps={{
        classes: {
          input: classes.textField
        }
      }}
        style={{ width: "100%", visibility: "hidden" }}
      /> </th>
    })
  }

  return (
    <div className={classes.container} style={{ width: "100% !important", display: "flex", alignItems: "center", justifyContent: "center", margin: "0px auto"  }}>
      {loaded ? (
        <GridContainer>
          <GridItem className={classes.searchGridItem} style={{ display: "flex", width: "100%" }} xs={12} sm={12} md={12}>
          <Card className={classes.searchCard}>
            <TextField
              onKeyPress={(e) => {
                if (e.which === 13) {
                  filterAgain()
                }
              }}
              onChange={(e) => setSearchInput(e.target.value)}
              className={classes.searchField}
              defaultValue={query}
              label="Song or Artist" variant="standard" />
            <Button
              type="submit"
              color="primary"
              style={{ maxHeight: "50px", marginLeft: "20px" }}
              onClick={filterAgain}
            >
              <SearchIcon />
            </Button>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} className={classes.formContainer}>
            <Card style={{ height: "700px", width: "95%", overflow: "scroll" }} className={classes.cardBody}>
              <table className={classes.table}>
                <tbody>
                  <tr style={{ height: "50px" }}>{renderTableHeader()} </tr>
                  {renderTableData()}
                </tbody>
              </table>
            </Card>
          </GridItem>


          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal

                + " " + classes.modalSmall,
            }}
            open={selectSongModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setSelectSongModal(false)
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >

            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setSelectSongModal(false)
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              {currentSongChoice ? (
                <h3 style={{ textAlign: "center", marginTop: "50px" }}>
                  You are already on track to perform {currentSongChoice.song} by {currentSongChoice.artist}. Are you sure you want to choose {chosenSong.song} by {chosenSong.artist} instead?</h3>
              ) : (
                <h3 style={{ textAlign: "center", marginTop: "50px" }}>Are you sure you want to choose {chosenSong.song} by {chosenSong.artist} as your next karaoke song?</h3>
              )}
            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                {currentSongChoice ? (
                  <Button onClick={() => {
                    setSelectSongModal(false)
                    setNeedsToBeDownloadedModal(true)
                  }} color="primary">
                    Yes, I Want To Change This Song.
                  </Button>
                ) : (
                  <Button onClick={() => {
                    setSelectSongModal(false)
                    setNeedsToBeDownloadedModal(true)
                  }} color="primary">
                    Yes, I Love This Song.
                  </Button>
                )}

                <Button onClick={() => {
                  setSelectSongModal(false)
                }} color="danger">
                  No, Nevermind.
                </Button>
              </div>
            </DialogContent>
          </Dialog>





          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal

                + " " + classes.modalSmall,
            }}
            open={needsToBeDownloadedModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setNeedsToBeDownloadedModal(false)
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >

            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setNeedsToBeDownloadedModal(false)
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              <h3 style={{ textAlign: "center", marginTop: "50px" }}>Heads up, this song still needs to be approved for download by the KJ and selecting it doesn't guarantee availability. Are you sure you still want to choose this song?</h3>
            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                <Button onClick={() => {
                  if (currentSongChoice) {
                    confirmedSongChoice('changing')
                    setSuccessSnackBar(true)
                  } else {
                    confirmedSongChoice()
                    setSuccessSnackBar(true)
                  }
                }} color="primary">
                  Yes, I Want To Submit the request.
                </Button>

                <Button onClick={() => {
                  setNeedsToBeDownloadedModal(false)
                }} color="danger">
                  No, Nevermind.
                </Button>
              </div>
            </DialogContent>
          </Dialog>


          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal

                + " " + classes.modalSmall,
            }}
            open={selectSongForDanceRequestModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setSelectSongForDanceRequestModal(false)
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >

            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setSelectSongForDanceRequestModal(false)
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              <h3 style={{ textAlign: "center", marginTop: "50px" }}>Do you sure you want to request {chosenSong.song} by {chosenSong.artist} as a dance request?</h3>
            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                <Button onClick={addNewDanceRequest} color="primary">
                  Yes, I Love This Song.
                </Button>

                <Button onClick={() => {
                  setSelectSongForDanceRequestModal(false)
                }} color="danger">
                  No, Nevermind.
                </Button>
              </div>
            </DialogContent>
          </Dialog>




          <Dialog
          className={classes.headsupModal}
            style={{ marginTop: "35px", overflow: "hidden", padding: "35px" }}
            classes={{
              root: classes.overflowHidden,
              paper: classes.overflowHidden

                + " " + classes.overflowHidden,
            }}
            open={needToSignUpModal}
            fullWidth
            maxWidth="md"
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setNeedToSignUpModal(false)
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >

            <Card style={{ boxShadow: "none" }} className={classes.requestsForm}>
              <CardHeader color="primary">
                <h1 className={classes.requestsFormHeader} >
                  Heads Up
                </h1>
              </CardHeader>
              <CardBody>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", height: "100%" }}>
                  <h5 style={{ fontSize: "24px", fontWeight: 400, paddingTop: "30px" }}>
                    You must be logged in to add yourself. Would you like to sign up or log in?
                  </h5>
                  <div style={{ display: "flex", width: "75%", justifyContent: "space-evenly" }}>
                    <Button onClick={() => {
                      history.push(`/pages/create-profile/`)
                    }} style={{ width: "45%", fontSize: '17px' }} color='primary'>
                      Sign up
                    </Button>
                    <Button onClick={() => {
                      history.push(`/pages/login-page/`)
                    }} style={{ width: "45%", fontSize: '17px' }} color='primary'>
                      Log in
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Dialog>




          <Snackbar style={{ marginBottom: "50px" }} open={requestCreated} autoHideDuration={4000} onClose={() => props.setRequestCreated(false)}>
            <Alert onClose={() => props.setRequestCreated(false)} severity='success'>
              Dance request created succesfully!
            </Alert>
          </Snackbar>


        </GridContainer>
      ) : (
        <div className={classes.easeInOut}>
          <Hidden smDown implementation="css">
            <div
              style={{
                // // background: "black",
                // // position: "absolute",
                // // width: "110%",
                // padding: "50px 0 0 0 0",
                height: "180%",
                zIndex: "99999",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "10%"
              }}
            >
              <img
                style={{
                  // margin: "30% 0% 0% 33%",
                  // position: "relative",
                  width: "30%",
                  marginBottom: "50px",
                  marginTop: "50px"
                }}
                src={Logo}
                alt="..."
              />
              <img
                style={{
                  // margin: "30% 0% 0% 33%",
                  position: "relative",
                  width: "15%"
                }}
                src={Loader}
                alt="..."
              />
            </div>
          </Hidden>
        </div>
      )
      }


      <Snackbar style={{ marginBottom: "30px" }} open={successSnackBar} onClose={() => {
        setSuccessSnackBar(false)
        history.push('/queue')
      }} autoHideDuration={2000} >
        <Alert onClose={() => {
          setSuccessSnackBar(false)
          history.push('/queue')
        }} severity="success">
          Your request has been created. Please verify with the KJ that your song is available.
        </Alert>
      </Snackbar>

    </div >
  )
}


const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
    assessments: state.assessment,
    updateProfileModal: state.profile.updateProfileModal,
    singerUniqueID: state.queue.singerUniqueID,
    query: state.search.searchQuery,
    searchResults: state.search.searchResults,
    searchDataLoaded: state.search.searchDataLoaded,
    songList: state.queue.songList,
    queue: state.queue,
    token: state.queue.token,
    requestCreated: state.dance.userDanceRequestDetails.requestCreated
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getQueryResults: (query, songList) => dispatch(getQueryResults(query, songList)),
    getJustDatabaseSongList: () => dispatch(getJustDatabaseSongList()),
    loggedInUserAddToQueue: (song, userName, UID, data) => dispatch(loggedInUserAddToQueue(song, userName, UID, data)),
    addSongToExistingUserInQueue: (queueId, currentSongChoice, str, previewUrl) => dispatch(addSongToExistingUserInQueue(queueId, currentSongChoice, str, previewUrl)),
    getSpotifyToken: () => dispatch(getSpotifyToken()),
    setSearchDataLoaded: () => dispatch(setSearchDataLoaded()),
    getSpotifyQueryResults: (searchInput, token) => dispatch(getSpotifyQueryResults(searchInput, token)),
    addSpotifySongToDownloadRequestLoggedInUser: (id, songChoice, previewUrl, uid, userName, data) => dispatch(addSpotifySongToDownloadRequestLoggedInUser(id, songChoice, previewUrl, uid, userName, data)),
    newDanceRequest: (userData, adminData, UID) => dispatch(newDanceRequest(userData, adminData, UID)),
    setRequestCreated: (boolean) => dispatch(setRequestCreated(boolean))
  }
}


export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(LoggedOutSpotifyResults));
