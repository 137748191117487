import Card from "../Card/Card";
import TextField from "@material-ui/core/TextField";
import Button from "../CustomButtons/Button";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useRef, useState } from "react";
import { ClipLoader } from "react-spinners";
import PropTypes from "prop-types";

export function AddSinger(props) {
  const overrideButtonLoader = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const [displayName, setDisplayName] = useState("");
  const [song, setSong] = useState("N/A");
  const [artist, setArtist] = useState("N/A");
  const [awaitingSubmit, setAwaitingSubmit] = useState(false);
  const singerNameRef = useRef();

  useEffect(() => {
    if (!awaitingSubmit && singerNameRef.current) {
      singerNameRef.current.focus();
    }
  }, [awaitingSubmit]);

  const submitValues = () => {
    setAwaitingSubmit(true);

    props
      .onAddSinger({
        displayName,
        song,
        artist,
      })
      .then((ret) => {
        console.log("OnAddSinger returned: ", ret);
        if (ret) {
          setDisplayName("");
          setSong("N/A");
          setArtist("N/A");
        }
      })
      .finally(() => {
        setAwaitingSubmit(false);
      });
  };

  const handleKeyPress = (e) => {
    if (e.which === 13) {
      e.preventDefault();
      submitValues();
    }
  };

  const addIcon = awaitingSubmit ? (
    <></>
  ) : (
    <AddIcon style={{ fontSize: "xx-large", color: "black" }} />
  );
  return (
    <div xs={12} className={props.classes.singerInputFab} aria-label="add">
      <Card
        style={{ display: "flex", flexDirection: "row" }}
        className={props.classes.cardBodyForSingerInput}
      >
        <TextField
          className={props.classes.formTextField}
          onChange={(e) => setDisplayName(e.target.value)}
          value={displayName}
          onKeyDown={handleKeyPress}
          disabled={awaitingSubmit}
          inputRef={singerNameRef}
          InputLabelProps={{
            style: {
              color: "white",
              fontWeight: 600,
              fontSize: "18px",
            }, // Apply the custom styles to the label
          }}
          InputProps={{
            style: {
              borderBottom: "1px solid white",
              borderRadius: "5px",
              fontWeight: 600,
              color: "white", // Add this line to set the text color to white
            },
          }}
          style={{ color: "white !important" }}
          label="Singer Name"
        />
        <TextField
          className={props.classes.formTextField}
          onChange={(e) => setSong(e.target.value)}
          value={song}
          disabled={awaitingSubmit}
          InputLabelProps={{
            style: {
              color: "white",
              fontWeight: 600,
              fontSize: "18px",
            }, // Apply the custom styles to the label
          }}
          InputProps={{
            style: {
              borderBottom: "1px solid white",
              borderRadius: "5px",
              fontWeight: 600,
              color: "white", // Add this line to set the text color to white
            },
          }}
          style={{ color: "white !important" }}
          label="Song"
          onFocus={(e) => e.target.select()}
        />
        <TextField
          InputLabelProps={{
            style: {
              color: "white",
              fontWeight: 600,
              fontSize: "18px",
            }, // Apply the custom styles to the label
          }}
          InputProps={{
            style: {
              borderBottom: "1px solid white",
              borderRadius: "5px",
              fontWeight: 600,
              color: "white", // Add this line to set the text color to white
            },
          }}
          style={{ color: "white !important" }}
          className={props.classes.formTextField}
          onChange={(e) => setArtist(e.target.value)}
          value={artist}
          disabled={awaitingSubmit}
          onKeyPress={handleKeyPress}
          label="Artist"
          onFocus={(e) => e.target.select()}
        />
        <Button
          className={props.classes.addSingerButton}
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            submitValues();
          }}
        >
          {addIcon}

          <ClipLoader
            loading={awaitingSubmit}
            css={overrideButtonLoader}
            size={30}
            color={"#123abc"}
          />
        </Button>
      </Card>
    </div>
  );
}

AddSinger.propTypes = {
  classes: PropTypes.object.isRequired,
  onAddSinger: PropTypes.func.isRequired,
};
