// Orders Data
const actions = {
    columns: [    
      {
        Header: "ACTION ID",
        accessor: "actionID",
        show: false
      },
      {
        Header: "TITLE",
        accessor: "title"
      },
      {
        Header: "BODY",
        accessor: "description",
        show: false
      },
      {
        Header: "DUE",
        accessor: "dueDate"
      },
      {
        Header: "DAYS DUE",
        accessor: "days",
        show: false
      },
      {
        Header: "POINTS",
        accessor: "points"
      },    
      {
        Header: "STATUS",
        accessor: "status"
      },    
      {
        Header: "",
        accessor: "actions",
        sortable: false,
        filterable: false
      }
    ],  
    dataLoaded: false
  }
  ;
  // Orders Data
  const pendingActions = {
    columns: [  
      {
        Header: "VOL EMAIL",
        accessor: "email"
      },  
      {
        Header: "VOL UID",
        accessor: "volUID"      
      },
      {
        Header: "ACTION ID",
        accessor: "actionId"      
      },
      {
        Header: "TITLE",
        accessor: "title"
      },
      {
        Header: "COMMENT",
        accessor: "comment"     
      },
      {
        Header: "DAYS DUE",
        accessor: "days",
        show: false
      },
      {
        Header: "POINTS",
        accessor: "points"
      },  
      {
        Header: "",
        accessor: "actions",
        sortable: false,
        filterable: false
      }
    ],  
    dataLoaded: false
  };
  
  // Orders Data
  const sharedBlog = {
    columns: [        
        {
          Header: "AUTHOR",
          accessor: "author"      
        },
        {
          Header: "CATEGORY",
          accessor: "category"      
        },
        {
          Header: "TITLE",
          accessor: "title"      
        },
        {
          Header: "TYPE",
          accessor: "type"      
        },
        {
          Header: "SUMMARY",
          accessor: "description"      
        },
        {
          Header: "LINK",
          accessor: "docURL"      
        },
        {
          Header: "USER",
          accessor: "displayName"      
        }
      ],  
  
    dataLoaded: false
  };
  
  export const initState = {
    actions,
    pendingActions,
    sharedBlog
  }
  
  export default (state = initState, action) => {
    switch (action.type) { 
      case 'SET_SHARED_BLOG_LIST':
        console.log('SET_SHARED_BLOG_LIST')     
        return {
          ...state,
          sharedBlog: {
            ...state.sharedBlog,
            dataRows: action.dataRows,
            dataLoaded: true
          }
        };    
      default:
        return state
    }
  }
  