import { getFirestoreData, updateFirestoreWithOneObject } from 'store/actions/firestoreActions.jsx'
import moment from "moment"
import { db, firebase } from "firebase/fbConfig.js"
 
export const setFirstAssessment = (user) => {
  let username
  !!user.displayName ? ( username = user.displayName ):( 
    username = user.email.substring(0, user.email.indexOf("@"))
   ) 
  // create a first action
  let assessmentData = {
    pePercent: 0,
    sePercent: 0,
    saPercent: 0,
    aaPercent: 0,
    updatedAt: new Date()
  }  

  // create userData
  let userData = {
    loggedIn: true,
  }
  console.log(user);
  db.collection("TEST-users").doc(user.uid).collection("assessments").add(assessmentData)
    .then(() => {
    //setUserScore to 0    
    updateUser(userData, user.uid)          
  }).catch((err) => {
    console.log(`error ${err}`)
  })
}


// Get User
export const startSetUser = (userObj, user) => {   
  return (dispatch) => {
    if (!userObj.email || !userObj.groups || !userObj.termGroups){
      userObj = {
        ...userObj,
        email: user.email,
        groups: [],
        termGroups: []
      }   
      updateUser(userObj, user.uid)
    }  
    dispatch(setUser(userObj))
  }
}

export const setUser = (userObj) => ({
  type: 'SET_ASSESSMENT',
  userObj
})

export const updateUser = (userObj, uid) => { 
  db.collection("TEST-users").doc(uid).set({
    ...userObj,
    updatedAt: new Date()
  }, { merge: true }).then(() => {
    window.location.reload() 
  }).catch((err) => {
    return (`Update TEST-users with id: ${uid} with error: ${err}`)
  })
}
 
export const startSetAssessmentsList = (userID) => {
  // Get firestore Data and convert it to tableData format  
  return (dispatch) => {
    db.collection("TEST-users").doc(userID).collection("assessments").orderBy("updatedAt", "desc").get()
      .then(snapshot => {
        const values = snapshot.docs.map(doc => doc.data())
        //console.log(values)
        dispatch(setAssessmentsList(values))
      })
  }
}

export const setAssessmentsList = (values) => ({
  type: 'SET_ASSESSMENTS_LIST',
  values
})

export const updateAssessment = (assessmentObj, uid) => { 
  console.log(assessmentObj)
  console.log(assessmentObj.question1)
  const pe = assessmentObj.question1 + assessmentObj.question2 + assessmentObj.question3 + assessmentObj.question4 + assessmentObj.question6 + assessmentObj.question7;
  const se = assessmentObj.question5 + assessmentObj.question8 + assessmentObj.question9 + assessmentObj.question10 + assessmentObj.question11 + assessmentObj.question12 + assessmentObj.question13 + assessmentObj.question14 + assessmentObj.question15;
  const sa = assessmentObj.question16 + assessmentObj.question17 + assessmentObj.question18 + assessmentObj.question19 + assessmentObj.question20 + assessmentObj.question21 + assessmentObj.question22;
  const aa = assessmentObj.question23 + assessmentObj.question24 + assessmentObj.question25 + assessmentObj.question26 + assessmentObj.question27 + assessmentObj.question28 + assessmentObj.question29 + assessmentObj.question30;
  const pe1 = assessmentObj.question2 + assessmentObj.question3;
  const pe2 = assessmentObj.question1 + assessmentObj.question7 + assessmentObj.question4;
  const pe3 = assessmentObj.question6;
  const pe4 = assessmentObj.question5;
  const se1 = assessmentObj.question9;
  const se2 = assessmentObj.question10;
  const se3 = assessmentObj.question11 + assessmentObj.question15 + assessmentObj.question14;
  const se4 = assessmentObj.question8 + assessmentObj.question13;
  const se5 = assessmentObj.question12 + assessmentObj.question19;
  const sa1 = assessmentObj.question17;
  const sa2 = assessmentObj.question21;
  const sa3 = assessmentObj.question16 + assessmentObj.question18 + assessmentObj.question20;
  const sa4 = assessmentObj.question22;
  const aa1 = assessmentObj.question30;
  const aa2 = 0;
  const aa3 = assessmentObj.question25;
  const aa4 = assessmentObj.question24 + assessmentObj.question29;
  const aa5 = assessmentObj.question23 + assessmentObj.question26 + assessmentObj.question27 + assessmentObj.question28;
  const pePercent = pe / 24;
  const sePercent = se / 36;
  const saPercent = sa / 28;
  const aaPercent = aa / 32;
  let peLevel = 0;
  let seLevel = 0;
  let saLevel = 0;
  let aaLevel = 0;
  let peLevelName = 0;
  let seLevelName = 0;
  let saLevelName = 0;
  let aaLevelName = 0;
  pePercent < .20 ? peLevel = 0 : pePercent < .3 ? peLevel = 1 : pePercent < .40 ? peLevel = 2 : pePercent < .9 ? peLevel = 3 : peLevel = 4;
  sePercent < .20 ? seLevel = 0 : sePercent < .3 ? seLevel = 1 : sePercent < .40 ? seLevel = 2 : sePercent < .9 ? seLevel = 3 : seLevel = 4;
  saPercent < .20 ? saLevel = 0 : saPercent < .3 ? saLevel = 1 : saPercent < .40 ? saLevel = 2 : saPercent < .9 ? saLevel = 3 : saLevel = 4;
  aaPercent < .20 ? aaLevel = 0 : aaPercent < .3 ? aaLevel = 1 : aaPercent < .40 ? aaLevel = 2 : aaPercent < .9 ? aaLevel = 3 : aaLevel = 4;
  pePercent < .20 ? peLevelName = "Pre-Inquiry" : pePercent < .3 ? peLevelName = "Inquiry" : pePercent < .40 ? peLevelName = "Commitment" : pePercent < .9 ? peLevelName = "Development" : peLevelName = "Mastery";
  sePercent < .20 ? seLevelName = "Pre-Inquiry" : sePercent < .3 ? seLevelName = "Inquiry" : sePercent < .40 ? seLevelName = "Commitment" : sePercent < .9 ? seLevelName = "Development" : seLevelName = "Mastery";
  saPercent < .20 ? saLevelName = "Pre-Inquiry" : saPercent < .3 ? saLevelName = "Inquiry" : saPercent < .40 ? saLevelName = "Commitment" : saPercent < .9 ? saLevelName = "Development" : saLevelName = "Mastery";
  aaPercent < .20 ? aaLevelName = "Pre-Inquiry" : aaPercent < .3 ? aaLevelName = "Inquiry" : aaPercent < .40 ? aaLevelName = "Commitment" : aaPercent < .9 ? aaLevelName = "Development" : aaLevelName = "Mastery";
  db.collection("TEST-users").doc(uid).collection("assessments").doc().set({
    ...assessmentObj,
    pePercent: pePercent,
    sePercent: sePercent,
    saPercent: saPercent,
    aaPercent: aaPercent,
    peLevel: peLevel,
    seLevel: seLevel,
    saLevel: saLevel,
    aaLevel: aaLevel,
    peLevelName: peLevelName,
    seLevelName: seLevelName,
    saLevelName: saLevelName,
    aaLevelName: aaLevelName,
    pe: pe,
    se: se,
    sa: sa,
    aa: aa,
    pe1: pe1,
    pe2: pe2,
    pe3: pe3,
    pe4: pe4,
    se1: se1,
    se2: se2,
    se3: se3,
    se4: se4,
    se5: se5,
    sa1: sa1,
    sa2: sa2,
    sa3: sa3,
    sa4: sa4,
    aa1: aa1,
    aa2: aa2,
    aa3: aa3,
    aa4: aa4,
    aa5: aa5,
    uid: uid,
    updatedAt: new Date()
  }, { merge: true }).then(() => {
    db.collection("TEST-users").doc(uid).set({
      pePercent: pePercent,
      sePercent: sePercent,
      saPercent: saPercent,
      aaPercent: aaPercent,  
      peLevel: peLevel,
      seLevel: seLevel,
      saLevel: saLevel,
      aaLevel: aaLevel,  
      peLevelName: peLevelName,
      seLevelName: seLevelName,
      saLevelName: saLevelName,
      aaLevelName: aaLevelName,
      levelUpdatedAt: new Date()
    }, { merge: true }).then(() => {
     window.location.replace("/dashboard")
    }).catch((err) => {
      return (`Error`) 
    })   
  }).catch((err) => {
    return (`Error`)
  })
}



