// ##############################
// // // Config Page view styles
// #############################

import {
  container,
  cardTitle,
  dangerColor,
  whiteColor,
  linkText
} from "assets/jss/material-dashboard-pro-react.jsx";

const configPageStyle = theme => ({
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.up("md")]: {
      minHeight: "1000px"
    }
  },
  formContainer: {
    [theme.breakpoints.up("md")]: {
      minHeight: "1000px"
    }
  },
  card: {
    padding: "20px",
    width: "90%",
    margin: "0px auto",
    background: "#2A2A2A",
    // height: "75%",
    overflow: "scroll",
    marginTop: "60px",
    minHeight: "900px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "0px",
      height: "100%",
      overflow: "hidden",
    }
  },
  howToUse: {
    textAlign: "center",
    fontSize: "40px",
    color: "white",
    lineHeight: "50px",
    paddingLeft: '30px',
    [theme.breakpoints.down("sm")]: {
      lineHeight: "50px"
    }
  },
  cardBodyDiv: {
    display: "flex",
    flexDirection: "column",
    minHeight: "600px",
    paddingTop: "50px",
    fontSize: "25px",
    color: "white",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "50px"
    }
  },
  configItemDiv: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "50px",
    fontSize: "25px",
    color: "white",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "50px"
    }
  },
  stepSelection: {
    height: "16%",
    fontSize: "23px",
    color: "rgb(249, 181, 24)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    }
  },
  clickHere: {
    color: "#80E3FC",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
      color: "#80E3FC",
    }
  }

});

export default configPageStyle;
