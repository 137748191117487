const favoritesData = []
const dataLoaded = false
const addedFavoriteSnackbar = false


export const initState = {
  favoritesData,
  dataLoaded,
  addedFavoriteSnackbar
}


export default (state = initState, action) => {
  switch (action.type) {
    case "SET_FAVORITES_DATA":
      var toReturn = { ...state }
      toReturn.favoritesData = action.data
      toReturn.dataLoaded = true
      return toReturn

    case "REMOVED_FAVORITE":
      var toReturn = { ...state }
      var data = toReturn.favoritesData
      for (var x = 0; x < data.length; x++) {
        var current = data[x]
        if (current.id === action.id) {
          data.splice(x, 1)
        }
      }
      toReturn.favoritesData = [...data]
      return toReturn

    case "ADDED_NEW_FAVORITE":
      var toReturn = { ...state }
      const favData = [...new Set(toReturn.favoritesData)]
      toReturn.favoritesData = favData
      return toReturn

      case "SHOW_FAVORITE_SNACKBAR":
        var toReturn = { ...state }
        toReturn.addedFavoriteSnackbar = action.boolean
        return toReturn


    default:
      return state
  }
}
