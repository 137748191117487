// Orders Data
const actions = {
    columns: [    
      {
        Header: "ACTION ID",
        accessor: "actionID",
        show: false
      },
      {
        Header: "TITLE",
        accessor: "title"
      },
      {
        Header: "BODY",
        accessor: "description",
        show: false
      },
      {
        Header: "DUE",
        accessor: "dueDate"
      },
      {
        Header: "DAYS DUE",
        accessor: "days",
        show: false
      },
      {
        Header: "POINTS",
        accessor: "points"
      },    
      {
        Header: "STATUS",
        accessor: "status"
      },    
      {
        Header: "",
        accessor: "actions",
        sortable: false,
        filterable: false
      }
    ],  
    dataLoaded: false
  }
  ;
  // Orders Data
  const pendingActions = {
    columns: [  
      {
        Header: "VOL EMAIL",
        accessor: "email"
      },  
      {
        Header: "VOL UID",
        accessor: "volUID"      
      },
      {
        Header: "ACTION ID",
        accessor: "actionId"      
      },
      {
        Header: "TITLE",
        accessor: "title"
      },
      {
        Header: "COMMENT",
        accessor: "comment"     
      },
      {
        Header: "DAYS DUE",
        accessor: "days",
        show: false
      },
      {
        Header: "POINTS",
        accessor: "points"
      },  
      {
        Header: "",
        accessor: "actions",
        sortable: false,
        filterable: false
      }
    ],  
    dataLoaded: false
  };
  
  // Orders Data
  const consultants = {
    columns: [        
      {
        Header: "NAME",
        accessor: "name"      
      },
      {
        Header: "EXPERTISE",
        accessor: "expertise"      
      },
      {
        Header: "FIRM",
        accessor: "firm"      
      },
      {
        Header: "PHONE",
        accessor: "phone"      
      },
      {
        Header: "EMAIL",
        accessor: "email"      
      },
      {
        Header: "LINK",
        accessor: "link"
      },
      {
        Header: "SCHEDULE",
        accessor: "schedule"
      }
    ],  
    dataLoaded: false
  };
  
  export const initState = {
    actions,
    pendingActions,
    consultants
  }
  
  export default (state = initState, action) => {
    switch (action.type) { 
      case 'SET_CONSULTANTS_LIST':
        console.log('SET_CONSULTANTS_LIST')     
        return {
          ...state,
          consultants: {
            ...state.consultants,
            dataRows: action.dataRows,
            dataLoaded: true
          }
        };    
      default:
        return state
    }
  }
  