// Assesments for Users
const assessments = {
  columns:[
    {
      Header: "BROKER",
      accessor: "broker"
    },
    {
      Header: "ORDERS (QT)",
      accessor: "orders"
    },
    {
      Header: "AMOUNT",
      accessor: "amount"
    },
    {
      Header: "PERFORMANCE",
      accessor: "performance"
    },
    {
      Header: "PROFIT RATING",
      accessor: "profit"
    },
    {
      Header: "",
      accessor: "actions",
      sortable: false,
      filterable: false
    }
  ],
  dataLoaded: false
};

export const initState = {  
  assessments
}

export default (state = initState, action) => {
  switch (action.type) {    
    case 'SET_ASSESSMENTS_LIST':
      console.log('SET_ASSESSMENTS_LIST')
      return {
        ...state,
        assessments: action.values,
        dataLoaded: true
      }
    default:
      return state
  }
}
