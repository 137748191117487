/*eslint-disable*/
const searchQuery = ''
const searchResults = []


export const initState = {
  searchQuery,
  searchResults
}


export default (state = initState, action) => {
  switch (action.type) {
    case "SET_SEARCH_QUERY":
    var toReturn = {...state}
    toReturn.searchQuery = action.query
    toReturn.searchResults = action.results
    console.log('searchResultssearchResults state', toReturn.searchResults)
    toReturn.searchDataLoaded = true
    return toReturn

    case "SET_SEARCH_DATA_LOADED":
      var toReturn = {...state}
      toReturn.searchDataLoaded = true
      return toReturn

    default:
      return state
  }
}