/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import configPageStyle from "assets/jss/material-dashboard-pro-react/views/configPageStyle.jsx";

import { setIPAddress, setRetrievedIpAddress } from "store/actions/authActions";
import DeleteAllInQueue from "../../components/PCL/DeleteAllInQueue";

const Config = (props) => {
  const { classes, retrievedIpAdress } = props;

  return (
    <div style={{ width: "100%" }} className={classes.container}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} className={classes.formContainer}>
          <Card className={classes.card}>
            <CardHeader className={classes.howToUse}>
              Use This Page To Configure The App
            </CardHeader>
            <CardBody style={{ display: "flex" }}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div className={classes.configItemDiv}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h5> Grab your IP Address </h5>
                      <Button
                        onClick={async () => await props.setIPAddress()}
                        color="primary"
                      >
                        Get
                      </Button>
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div className={classes.configItemDiv}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h5> Delete All Singers </h5>
                      <DeleteAllInQueue classes={classes}/>
                    </div>
                  </div>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.formContainer}
                ></GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <Snackbar
          style={{ marginBottom: "30px" }}
          open={retrievedIpAdress}
          onClose={() => {
            props.setRetrievedIpAddress(false);
          }}
          autoHideDuration={4000}
        >
          <Alert
            onClose={async () => {
              props.setRetrievedIpAddress(false);
            }}
            severity="success"
          >
            Succesfully Reset Ip Address!
          </Alert>
        </Snackbar>
      </GridContainer>
    </div>
  );
};

Config.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    userCreated: state.queue.queueDetails.userCreated,
    userInRange: state.auth.isUserInRange,
    singerUniqueID: state.queue.singerUniqueID,
    nameSubmitted: state.queue.nameSubmitted,
    retrievedIpAdress: state.auth.retrievedIpAdress,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setIPAddress: () => dispatch(setIPAddress()),
  setRetrievedIpAddress: (boolean) => dispatch(setRetrievedIpAddress(boolean)),
});

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(configPageStyle)
  )(Config)
);
