/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from "firebase/fbConfig.js";
import { withRouter } from 'react-router-dom';
import axios from 'axios'
import qs from 'qs';
// Preloader
import Loader from "assets/img/preloader.gif";
import SongListLoader from "assets/img/loader.gif";
import Hidden from "@material-ui/core/Hidden";
import Logo from "assets/img/pclBigLogo.png";

// react component for creating dynamic tables
import ReactTable from "react-table";

// audio player
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Autocomplete from '@material-ui/lab/Autocomplete';

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Edit from '@material-ui/icons/Edit';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ProfilePicUpload from "components/CustomUpload/profilePicUpload"
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";
import TextField from '@material-ui/core/TextField';
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/songPreviewStyle";
import { Grid } from "@material-ui/core";

// Forms
import ProfileForm from "components/Forms/profileForm.jsx"

// Assets
import DefaultProfile from "assets/img/default-avatar.png"

// Firebase Redux Integration
import { tableToFirestoreObjects, updateFirestoreWithOneObject, updateFirestoreWithTableData, addFieldToDocument } from "store/actions/firestoreActions"
import { startSetActionsList, startToggleCompleted, setUserScore } from "store/actions/actionsActions"
import ReferralForm from "components/Forms/ReferralForm"
import { updateUser, setUpdateProfileModal } from "store/actions/profileActions.jsx"
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { searchDatabaseForSong, addSongToExistingUserInQueue, resetSubmitUserForm } from "store/actions/queueActions.jsx"

const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

const SongRequestCompletedLoggedIn = (props) => {

  const [dataLoaded, setDataLoaded] = useState(false)
  const { classes, singerUniqueID, userHasSelectedSong, name, userChangingSongSelection, history } = props;
  const [spotifySongRef, setSpotifySongRef] = useState('')
  const [songRef, setSongRef] = useState('')
  const [token, setToken] = useState('')
  const [songChoices, setSongChoices] = useState([])
  const [chosenSong, setChosenSong] = useState(null)
  const [previewUrl, setPreviewUrl] = useState(null)
  const [cantPreviewAlert, setCantPreviewAlert] = useState(false)
  const [songLibrary, setSongLibrary] = useState([])
  const [openSongSelectionModal, setOpenSongSelectionModal] = useState(false)
  const [filteredSongLibrary, setFilteredSongLibrary] = useState([])
  const [selectedSongFromDatabase, setSelectedSongFromDatabase] = useState(null)
  const [needToSelectSongAlert, setNeedToSelectSongAlert] = useState(false)




  return (
    <div className={classes.container}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style={{ maxWidth: "100%" }} className={classes.formContainer}>
          <Card style={{boxShadow: "none"}} className={classes.requestsForm}>
            <CardHeader color="primary">
              <h1 className={classes.requestsFormHeader} >Congratulations {name}!</h1>
            </CardHeader>
            <CardBody>
              {!userChangingSongSelection ? (
                <h6 className={classes.madeAGreatSelection}>
                  You have been added to the queue. Look for your name on the list and good luck!
                </h6>
              ) : (
                <h6 className={classes.madeAGreatSelection}>
                  You have updated your song selection. Look for your name on the list and good luck!
                </h6>
              )}
              <div style={{ display: "none" }}>
              {setTimeout(
                  function () {
                    props.resetSubmitUserForm()
                    history.push('/queue')
                  }, 3000
                )}
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
    assessments: state.assessment,
    updateProfileModal: state.profile.updateProfileModal,
    singerUniqueID: state.queue.singerUniqueID,
    token: state.queue.token,
    songList: state.queue.songList,
    userHasSelectedSong: state.queue.userHasSelectedSong
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setUpdateProfileModal: () => dispatch(setUpdateProfileModal()),
    searchDatabaseForSong: (input) => dispatch(searchDatabaseForSong(input)),
    addSongToExistingUserInQueue: (id, songChoice) => dispatch(addSongToExistingUserInQueue(id, songChoice)),
    resetSubmitUserForm: () => dispatch(resetSubmitUserForm())
  }
}


export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(SongRequestCompletedLoggedIn));
