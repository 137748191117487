/*eslint-disable*/

// Orders Data
const engagementWorksheets = {
  columns: [
    {
      Header: "Stakeholder Name",
      accessor: "stakeholderName"
    },
    {
      Header: "Accountability",
      accessor: "accountability"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "Actions",
      accessor: "actions",
      filterable: false,
      sortable: false,
    }
  ],
  dataLoaded: false,
  deleteModal: false
};

export const initState = {
  engagementWorksheets,
}

export default (state = initState, action) => {
  switch (action.type) {
    case 'SET_ENGAGEMENTWORKSHEETS_LIST':
      console.log('snapzzz','SET_ENGAGEMENTWORKSHEETS_LIST')
      return {
        ...state,
        engagementWorksheets: {
          ...state.engagementWorksheets,
          dataRows: action.dataRows,
          dataLoaded: true
        }
      };
      case 'SET_TRIGGERDELETEMODAL':
        console.log('stateeeez', 'clicked', action.data)
        return {
          ...state,
          engagementWorksheets: {
            ...state.engagementWorksheets,
            dataRows: action.dataRows,
            dataLoaded: true,
            deleteModal: action.boolean,
            data: action.data
          }
        };
    default:
      return state
  }
}
