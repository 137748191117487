
const downloadRequestDetails = {
  dataLoaded: false,
}

export const initState = {
  downloadRequestDetails
}


export default (state = initState, action) => {
  switch (action.type) {
    case 'SET_DOWNLOADREQUESTS':
    var toReturn = {...state}
    toReturn.data = action.data
    toReturn.downloadRequestDetails.dataLoaded = true
    return toReturn


    default:
      return state
  }
}
