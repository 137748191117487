/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import Button from "components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import EditIcon from "@material-ui/icons/Edit";

import { setUserNeedsToAddNameModal } from "store/actions/profileActions.jsx";

import { getFavorites } from "store/actions/favoritesActions";

// actions
import {
  getJustDatabaseSongList,
  loggedInUserAddToQueue,
  setLoggedInUserAddToQueueModal,
  deleteSelfFromQueue,
  setNameAlreadyExists,
} from "store/actions/queueActions";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

const AddToQueueBtns = (props) => {
  const {
    classes,
    userInQueueAlready,
    currentSongChoice,
    history,
    favoriteDataLoaded,
  } = props;
  const [loaded, setLoaded] = useState(false);
  const [UID, setUID] = useState(null);
  const [userMustBeWithinRangeAlert, setUserMustBeWithinRangeAlert] = useState(
    false
  );
  const [editSongModal, setEditSongModal] = useState(false);
  const [addedToQueueSnackbar, setAddedToQueueSnackbar] = useState(false);

  useEffect(() => {
    if (props.authUser && !UID) {
      setUID(props.authUser.uid);
    }

    if (!loaded && !favoriteDataLoaded && props.authUser) {
      props.getFavorites(props.authUser.uid);
      setLoaded(true);
    }
  }, [props]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        marginTop: "41px",
        paddingRight: "86px",
        zIndex: 1000,
        marginBottom: "50px",
      }}
    >
      {userInQueueAlready ? (
        <Fab
          className={classes.anchoredFabEditSong}
          onClick={() => {
            if (props.userInRange) {
              setEditSongModal(true);
            } else {
              setUserMustBeWithinRangeAlert(true);
            }
          }}
          aria-label="add"
        >
          <EditIcon style={{ fontSize: "medium" }} />
          Edit Song
        </Fab>
      ) : null}

      {!userInQueueAlready ? (
        <Fab
          className={classes.anchoredFabLoggedIn}
          onClick={() => {
            history.push("/browse-songs");
          }}
          aria-label="add"
        >
          <AddIcon style={{ fontSize: "medium" }} />
          Pick Song
        </Fab>
      ) : null}

      <Dialog
        style={{ marginTop: "35px" }}
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalSmall,
        }}
        open={editSongModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setEditSongModal(false);
        }}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <DialogTitle
          id="shipment-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            onClick={() => {
              setEditSongModal(false);
            }}
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            {" "}
            <Close className={classes.modalClose} />
          </Button>
          {currentSongChoice.song !== "N/A" ? (
            <h3 style={{ textAlign: "center", marginBottom: "60px" }}>
              {" "}
              Your current song choice is {currentSongChoice.song} by{" "}
              {currentSongChoice.artist}. Would you like to update your
              selection?
            </h3>
          ) : (
            <h3 style={{ textAlign: "center", marginBottom: "60px" }}>
              {" "}
              You have not yet selected a song choice, would you like to make a
              selection?
            </h3>
          )}

          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <Button
              onClick={() => {
                setEditSongModal(false);
                history.push("/browse-songs");
              }}
              color="primary"
            >
              {currentSongChoice.song !== "N/A" ? (
                <span>{`Yes, let's change it.`}</span>
              ) : (
                <span>{`Yes, I'm ready.`}</span>
              )}
            </Button>
            <Button onClick={() => setEditSongModal(false)} color="danger">
              {currentSongChoice.song !== "N/A" ? (
                <span>No, keep this song.</span>
              ) : (
                <span>No I need more time.</span>
              )}
            </Button>
          </div>
        </DialogTitle>

        <DialogContent
          id="shipment-modal-slide-description"
          className={classes.modalBody}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          ></div>
        </DialogContent>
      </Dialog>

      <Snackbar
        style={{ marginBottom: "30px" }}
        open={props.userNameAlreadyExistsInQueue}
        onClose={() => {
          props.setLoggedInUserAddToQueueModal(false);
          props.setNameAlreadyExists(false);
        }}
        autoHideDuration={4000}
      >
        <Alert
          onClose={() => {
            props.setLoggedInUserAddToQueueModal(false);
            props.setNameAlreadyExists(false);
          }}
          severity="error"
        >
          {`Unfortunately your name already exists in the queue and can't be added
          twice.`}
        </Alert>
      </Snackbar>

      <Snackbar
        style={{ marginBottom: "30px" }}
        open={userMustBeWithinRangeAlert}
        onClose={() => {
          setUserMustBeWithinRangeAlert(false);
        }}
        autoHideDuration={4000}
      >
        <Alert
          onClose={() => {
            setUserMustBeWithinRangeAlert(false);
          }}
          severity="error"
        >
          {`Unfortunately you must be connected PCL's wifi to be added to the
          queue. Please connect and then refresh the app.`}
        </Alert>
      </Snackbar>

      <Snackbar
        style={{ marginBottom: "30px" }}
        open={addedToQueueSnackbar}
        onClose={() => {
          setAddedToQueueSnackbar(false);
          history.push("/browse-songs");
        }}
        autoHideDuration={3000}
      >
        <Alert
          onClose={() => {
            setAddedToQueueSnackbar(false);
            history.push("/browse-songs");
          }}
          severity="success"
        >
          Congratulations! You have beeen added to the queue.
        </Alert>
      </Snackbar>
    </div>
  );
};

AddToQueueBtns.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authUser: state.auth.user,
    queue: state.queue,
    loggedInUserAddToQueueModal: state.queue.loggedInUserAddToQueueModal,
    userNameAlreadyExistsInQueue: state.queue.userNameAlreadyExistsInQueue,
    userInRange: state.auth.isUserInRange,
    user: state.profile.user,
    displayName: state.profile.user.displayName,
    otherDisplayName: state.auth.displayName,
    displayNameState: state.auth.displayName,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getJustDatabaseSongList: () => dispatch(getJustDatabaseSongList()),
  loggedInUserAddToQueue: () => dispatch(loggedInUserAddToQueue()),
  setLoggedInUserAddToQueueModal: (boolean) =>
    dispatch(setLoggedInUserAddToQueueModal(boolean)),
  deleteSelfFromQueue: (selfData, allData) =>
    dispatch(deleteSelfFromQueue(selfData, allData)),
  setNameAlreadyExists: (boolean) => dispatch(setNameAlreadyExists(boolean)),
  setUserNeedsToAddNameModal: (boolean) =>
    dispatch(setUserNeedsToAddNameModal(boolean)),
  getFavorites: (uid) => dispatch(getFavorites(uid)),
});

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(loginPageStyle)
  )(AddToQueueBtns)
);
