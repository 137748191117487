/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
// Preloader
import Loader from "assets/img/loader.gif";
import Hidden from "@material-ui/core/Hidden";
import Logo from "assets/img/pclBigLogo.png";

// audio player
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Switch from "@material-ui/core/Switch";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import TextField from "@material-ui/core/TextField";
import GetAppIcon from "@material-ui/icons/GetApp";
import Style from "assets/jss/material-dashboard-pro-react/views/searchResultsStyle";
import {
  getQueryResults,
  setSearchDataLoaded,
  getSpotifyQueryResults,
} from "store/actions/searchActions.jsx";
import {
  getJustDatabaseSongList,
  loggedInUserAddToQueue,
  addSongToExistingUserInQueue,
  addSpotifySongToDownloadRequestLoggedInUser,
  addDownloadRequest,
} from "store/actions/queueActions";
import {
  newDanceRequest,
  setRequestCreated,
} from "store/actions/danceRequestActions.jsx";

const tableHeaders = ["Song", "Artist", "Actions"];

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

const SpotifySearchResults = (props) => {
  const {
    classes,
    searchDataLoaded,
    searchResults,
    query,
    user,
    requestCreated,
    history,
  } = props;

  const [searchErr, setSearchErr] = useState(false);
  const [searchInput, setSearchInput] = useState(query);
  const [loaded, setLoaded] = useState(false);
  const [selectSongModal, setSelectSongModal] = useState(false);
  const [chosenSong, setChosenSong] = useState(false);
  const [currentSongChoice, setCurrentSongChoice] = useState(false);
  const [UID, setUID] = useState(null);
  const [queueId, setQueueId] = useState(false);
  const [token, setToken] = useState("");
  const [successSnackBar, setSuccessSnackBar] = useState(false);
  const [needsToBeDownloadedModal, setNeedsToBeDownloadedModal] = useState(
    false
  );
  const [
    selectSongForDanceRequestModal,
    setSelectSongForDanceRequestModal,
  ] = useState(false);
  const [songOrArtist, setSongOrArtist] = useState("Song");
  const [mediaSource, setMediaSource] = useState(null);
  const [previewSongModal, setPreviewSongModal] = useState(false);

  useEffect(() => {
    if (!searchDataLoaded) {
      props.setSearchDataLoaded();
    }
    if (searchDataLoaded) {
      setLoaded(true);
      setToken(props.token);
    }
    if (props.queue.data && props.authUser) {
      for (var x = 0; x < props.queue.data.length; x++) {
        if (
          props.queue.data[x].uid &&
          props.queue.data[x].uid === props.authUser.uid
        ) {
          setQueueId(props.queue.data[x].id);
          if (props.queue.data[x].songChoice) {
            setCurrentSongChoice(props.queue.data[x].songChoice);
          }
        }
      }
    }
    if (props.authUser) {
      setUID(props.authUser.uid);
    }
  }, [props]);

  const addNewDanceRequest = () => {
    var adminData = {};
    var userData = {};
    adminData.song = chosenSong.song;
    adminData.artist = chosenSong.artist;
    adminData.requestedBy = user.displayName;
    adminData.preview_url = false;
    adminData.downloadRequest = true;

    userData.song = chosenSong.song;
    userData.artist = chosenSong.artist;
    userData.downloadStatus = "Pending";
    userData.requestId = "";
    props.newDanceRequest(userData, adminData, UID);
    setSelectSongForDanceRequestModal(false);
  };

  const confirmedSongChoice = async () => {
    await props.addDownloadRequest(queueId, chosenSong, UID);
  };

  const filterAgain = async () => {
    if (!searchInput) {
      setSearchErr(true);
    } else {
      await props.getSpotifyQueryResults(searchInput, token, songOrArtist);
    }
  };

  const renderTableData = () => {
    if (searchResults.length === 0) {
      return (
        <tr
          id="tableRow"
          style={{
            fontWeight: 500,
            color: "#f9b518",
            height: "55px",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          <td style={{ textAlign: "center", width: "33%" }}></td>
          <td style={{ textAlign: "center", width: "33%", fontSize: "22px" }}>
            No Results
          </td>
          <td style={{ textAlign: "center", width: "33%" }}></td>
        </tr>
      );
    }
    return searchResults.map((result, index) => {
      return (
        <tr
          id="tableRow"
          style={{
            fontWeight: 500,
            color: "#f9b518",
            height: "55px",
            fontSize: "20px",
          }}
          key={index}
        >
          <td style={{ textAlign: "center", fontSize: "20px" }}>
            {result.name}
          </td>
          <td style={{ textAlign: "center", fontSize: "20px" }}>
            {result.artists[0].name}
          </td>
          <td
            style={{ textAlign: "center", fontSize: "20px" }}
            className={classes.actionBtns}
          >
            <GetAppIcon
              onClick={() => {
                setChosenSong({
                  song: result.name,
                  artist: result.artists[0].name,
                  previewUrl: searchResults[index].preview_url || null,
                });
                setSelectSongModal(true);
              }}
              className={classes.addBackToQueueBtn}
            />
            {result.preview_url ? (
              <PlayArrowIcon
                onClick={() => {
                  setPreviewSongModal(true);
                  setChosenSong({
                    song: result.name,
                    artist: result.artists[0].name,
                    previewUrl: searchResults[index].preview_url || null,
                  });
                  setMediaSource(searchResults[index].preview_url);
                }}
                className={classes.micIcon}
              />
            ) : (
              <PlayArrowIcon
                style={{ visibility: "hidden" }}
                onClick={() => setPreviewSongModal(true)}
                className={classes.micIcon}
              />
            )}
          </td>
        </tr>
      );
    });
  };

  const renderTableHeader = () => {
    return tableHeaders.map((key, index) => {
      return (
        <th className={classes.tableHeader} key={index}>
          {key}
        </th>
      );
    });
  };

  return (
    <div className={classes.container} style={{ width: "100%" }}>
      {loaded ? (
        <GridContainer>
          <GridItem
            className={classes.searchGridItem}
            style={{ display: "flex" }}
            xs={12}
            sm={12}
            md={12}
          >
            <Card
              style={{ flexDirection: "column" }}
              className={classes.searchCard}
            >
              <div className={classes.songArtistToggle}>
                <p style={{ margin: "0px", color: "white" }}> Song </p>
                <Switch
                  checked={songOrArtist === "Song" ? false : true}
                  onChange={() => {
                    if (songOrArtist === "Song") {
                      setSongOrArtist("Artist");
                    } else {
                      setSongOrArtist("Song");
                    }
                  }}
                  value="checkedA"
                />
                <p style={{ margin: "0px", color: "white" }}> Artist </p>
              </div>

              <div style={{ display: "flex" }}>
                <TextField
                  onKeyPress={(e) => {
                    if (e.which === 13) {
                      filterAgain();
                    }
                  }}
                  onChange={(e) => setSearchInput(e.target.value)}
                  className={classes.searchField}
                  InputLabelProps={{
                    style: { color: "white" }, // Apply the custom styles to the label
                  }}
                  InputProps={{
                    classes: {
                      underline: classes.underline,
                    },
                    style: { color: "white" }, // Apply the custom styles to the text input
                  }}
                  defaultValue={query}
                  label={songOrArtist === "Song" ? "Song" : "Artist"}
                  variant="standard"
                />
                <Button
                  type="submit"
                  color="primary"
                  style={{ maxHeight: "50px", marginLeft: "20px" }}
                  onClick={filterAgain}
                >
                  <SearchIcon />
                </Button>
              </div>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} className={classes.formContainer}>
            <Card
              style={{ height: "700px", width: "95%", overflow: "scroll" }}
              className={classes.cardBody}
            >
              <table className={classes.table}>
                <tr style={{ height: "50px" }}>{renderTableHeader()} </tr>
                {renderTableData()}
              </table>
            </Card>
          </GridItem>

          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalSmall,
            }}
            open={selectSongModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setSelectSongModal(false);
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >
            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setSelectSongModal(false);
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              {currentSongChoice ? (
                <h3 style={{ textAlign: "center", marginTop: "50px" }}>
                  You are already on track to perform {currentSongChoice.song}{" "}
                  by {currentSongChoice.artist}. Are you sure you want to choose{" "}
                  {chosenSong.song} by {chosenSong.artist} instead?
                </h3>
              ) : (
                <h3 style={{ textAlign: "center", marginTop: "50px" }}>
                  Are you sure you want to choose {chosenSong.song} by{" "}
                  {chosenSong.artist} as your next karaoke song?
                </h3>
              )}
            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                {currentSongChoice ? (
                  <Button
                    onClick={() => {
                      setSelectSongModal(false);
                      setNeedsToBeDownloadedModal(true);
                    }}
                    color="primary"
                  >
                    Yes, I Want To Change This Song.
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setSelectSongModal(false);
                      setNeedsToBeDownloadedModal(true);
                    }}
                    color="primary"
                  >
                    Yes, I Love This Song.
                  </Button>
                )}

                <Button
                  onClick={() => {
                    setSelectSongModal(false);
                  }}
                  color="danger"
                >
                  No, Nevermind.
                </Button>
              </div>
            </DialogContent>
          </Dialog>

          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalSmall,
            }}
            open={previewSongModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setPreviewSongModal(false);
              setMediaSource(null);
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >
            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setPreviewSongModal(false);
                  setMediaSource(null);
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              <h3
                style={{
                  textAlign: "center",
                  marginTop: "50px",
                  color: "#00C1F3",
                  fontSize: "20px",
                  fontWeight: 500,
                }}
              >
                Here is a preview of {chosenSong.song} by {chosenSong.artist}
              </h3>
            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <AudioPlayer
                src={mediaSource}
                style={{ margin: "30px auto 27px auto", width: "100%" }}
                onPlay={() => console.log("onPlay")}
                autoPlay={false}
                autoPlayAfterSrcChange={false}
              />
            </DialogContent>
          </Dialog>

          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalSmall,
            }}
            open={needsToBeDownloadedModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setNeedsToBeDownloadedModal(false);
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >
            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setNeedsToBeDownloadedModal(false);
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              <h3 style={{ textAlign: "center", marginTop: "50px" }}>
                {`Heads up, this song still needs to be approved for download by
                the KJ and selecting it doesn't guarantee availability. Are you
                sure you still want to choose this song?`}
              </h3>
            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Button
                  onClick={() => {
                    confirmedSongChoice();
                    setSuccessSnackBar(true);
                  }}
                  color="primary"
                >
                  Yes, I Want To Submit the request.
                </Button>

                <Button
                  onClick={() => {
                    setNeedsToBeDownloadedModal(false);
                  }}
                  color="danger"
                >
                  No, Nevermind.
                </Button>
              </div>
            </DialogContent>
          </Dialog>

          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalSmall,
            }}
            open={selectSongForDanceRequestModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setSelectSongForDanceRequestModal(false);
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >
            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setSelectSongForDanceRequestModal(false);
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              <h3 style={{ textAlign: "center", marginTop: "50px" }}>
                Do you sure you want to request {chosenSong.song} by{" "}
                {chosenSong.artist} as a dance request?
              </h3>
            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Button onClick={addNewDanceRequest} color="primary">
                  Yes, I Love This Song.
                </Button>

                <Button
                  onClick={() => {
                    setSelectSongForDanceRequestModal(false);
                  }}
                  color="danger"
                >
                  No, Nevermind.
                </Button>
              </div>
            </DialogContent>
          </Dialog>

          <Snackbar
            style={{ marginBottom: "50px" }}
            open={requestCreated}
            autoHideDuration={4000}
            onClose={() => props.setRequestCreated(false)}
          >
            <Alert
              onClose={() => props.setRequestCreated(false)}
              severity="success"
            >
              Dance request created succesfully!
            </Alert>
          </Snackbar>
        </GridContainer>
      ) : (
        <div className={classes.easeInOut}>
          <Hidden smDown implementation="css">
            <div
              style={{
                // // background: "black",
                // // position: "absolute",
                // // width: "110%",
                // padding: "50px 0 0 0 0",
                height: "180%",
                zIndex: "99999",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "10%",
              }}
            >
              <img
                style={{
                  // margin: "30% 0% 0% 33%",
                  // position: "relative",
                  width: "30%",
                  marginBottom: "50px",
                  marginTop: "50px",
                }}
                src={Logo}
                alt="..."
              />
              <img
                style={{
                  // margin: "30% 0% 0% 33%",
                  position: "relative",
                  width: "15%",
                }}
                src={Loader}
                alt="..."
              />
            </div>
          </Hidden>
        </div>
      )}

      <Snackbar
        style={{ marginBottom: "30px" }}
        open={successSnackBar}
        onClose={() => {
          setSuccessSnackBar(false);
          history.push("/queue");
        }}
        autoHideDuration={2000}
      >
        <Alert
          onClose={() => {
            setSuccessSnackBar(false);
            history.push("/queue");
          }}
          severity="success"
        >
          Your request has been created. Please verify with the KJ that your
          song is available.
        </Alert>
      </Snackbar>

      <Snackbar
        style={{ marginBottom: "50px" }}
        open={searchErr}
        autoHideDuration={4000}
        onClose={() => setSearchErr(false)}
      >
        <Alert onClose={() => setSearchErr(false)} severity="error">
          {`Error! Input can't be empty.`}
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
    assessments: state.assessment,
    updateProfileModal: state.profile.updateProfileModal,
    singerUniqueID: state.queue.singerUniqueID,
    query: state.search.searchQuery,
    searchResults: state.search.searchResults,
    searchDataLoaded: state.search.searchDataLoaded,
    songList: state.queue.songList,
    queue: state.queue,
    token: state.queue.token,
    requestCreated: state.dance.userDanceRequestDetails.requestCreated,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getQueryResults: (query, songList) =>
      dispatch(getQueryResults(query, songList)),
    getJustDatabaseSongList: () => dispatch(getJustDatabaseSongList()),
    loggedInUserAddToQueue: (song, userName, UID, data) =>
      dispatch(loggedInUserAddToQueue(song, userName, UID, data)),
    addSongToExistingUserInQueue: (
      queueId,
      currentSongChoice,
      str,
      previewUrl
    ) =>
      dispatch(
        addSongToExistingUserInQueue(
          queueId,
          currentSongChoice,
          str,
          previewUrl
        )
      ),
    setSearchDataLoaded: () => dispatch(setSearchDataLoaded()),
    getSpotifyQueryResults: (searchInput, token, songOrArtist) =>
      dispatch(getSpotifyQueryResults(searchInput, token, songOrArtist)),
    addSpotifySongToDownloadRequestLoggedInUser: (
      id,
      songChoice,
      previewUrl,
      uid,
      userName,
      data
    ) =>
      dispatch(
        addSpotifySongToDownloadRequestLoggedInUser(
          id,
          songChoice,
          previewUrl,
          uid,
          userName,
          data
        )
      ),
    newDanceRequest: (userData, adminData, UID) =>
      dispatch(newDanceRequest(userData, adminData, UID)),
    setRequestCreated: (boolean) => dispatch(setRequestCreated(boolean)),
    addDownloadRequest: (queueIdd, songChoice, UID) =>
      dispatch(addDownloadRequest(queueIdd, songChoice, UID)),
  };
};

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(Style)
  )(SpotifySearchResults)
);
