/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { Redirect } from 'react-router-dom'
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import { Link } from "react-router-dom";

// @material-ui/core components
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import ReactTable from "react-table";
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Hidden from "@material-ui/core/Hidden";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import SongPreviewModal from '../Preview/SongPreviewModal.jsx'




import { dataTable } from "variables/general.jsx";

// actions

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import Loader from "assets/img/loader.gif";

import { deleteFromQueue, editSingerInQueue, changingPositionsInQueue, setUserHasSung, getJustDatabaseSongList, addNewSingerAndSong, deleteAll, addToQueue, setUserCreatedFalse, setNameAlreadyExists, setLoggedOutAddToQueueModal } from "store/actions/queueActions"

import { useMediaQuery } from 'react-responsive';





const Transition = (props) => {
  return <Slide direction="down" {...props} />
}





const KaraokeList = (props) => {

  const { authError, classes, nameSubmitted, singerUniqueID, userNameAlreadyExistsInQueue, history } = props;
  const [tableData, setTableData] = useState([])
  const [addNewSingerModal, setAddNewSingerModal] = useState(false)
  const [songList, setSongList] = useState(false)
  const [songRef, setSongRef] = useState('')
  const [nameRef, setNameRef] = useState('')
  const [filteredSongLibrary, setFilteredSongLibrary] = useState([])
  const [songLibrary, setSongLibrary] = useState([])
  const [selectedSongFromDatabase, setSelectedSongFromDatabase] = useState({})
  const [userNotInRangeAlert, setUserNotInRangeAlert] = useState(false)
  const [userMustBeWithinRangeAlert, setUserMustBeWithinRangeAlert] = useState(false)
  const [fieldsNotFilled, setFieldsNotFilled] = useState(false)
  const [needToSignUpModal, setNeedToSignUpModal] = useState(false)

  const isLaptop = useMediaQuery({ query: '(max-width: 1440px)' });

  const isIphonePlus = useMediaQuery({ query: '(max-width: 450px)' });

  const smallPhone = useMediaQuery({ query: '(max-width: 320px)' });


  useEffect(() => {
    if (props.queue.data) {
      setTableData(props.queue.data)
    }
    if (props.queue.songList) {
      setSongList(props.queue.songList)
    }
  }, [props])


  const trackClickSelected = (e) => {
    var selection = e.target.innerText.split(' - ')
    var songTitleStr = selection[0]
    var artist = selection[1]
    setSelectedSongFromDatabase({ song: songTitleStr, artist: artist })
  }


  const searchDatabase = (e) => {
    var input = e.target.value
    if (input === '') {
      setFilteredSongLibrary([])
      setSongRef(e.target.value)
    } else {
      let data = [...songList]
      const filtered = songList.filter(d => {
        if (d.artist && d.artist.toLowerCase().includes(input.toLowerCase()) || d.song && d.song.toLowerCase().includes(input.toLowerCase())) {
          return d
        }
      })
      setFilteredSongLibrary(filtered)
      setSongRef(e.target.value)
    }
  }


  const submitRequest = (e) => {
    e.preventDefault()
    var data = [...props.data]
    if (!nameRef) {
      setFieldsNotFilled(true)
    } else {
      var toSubmit = { displayName: nameRef }

      data.push(toSubmit)
      props.addToQueue(toSubmit, data)
      setNameRef('')

    }
  }

  const addUserToQueueFormOpen = () => {
    if (!props.userInRange) {
      setUserMustBeWithinRangeAlert(true)
    } else {
      props.setLoggedOutAddToQueueModal(true)
    }
  }


  return (
    <div style={{ width: "100%" }} className={classes.container}>
      {props.userInRange !== undefined ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.formContainer}>
            <Card
            //  style={tableData.length >= 20 ? {height: "calc(100% + 20%)"} : {}}
             className={classes.tableCardKaraokeList} >
              <ReactTable
                   defaultPageSize={100}
                noDataText='No Singers In The Queue Yet!'
                className="-striped"
                showPageJump={false}
                showPagination={false}
                data={tableData}
                filterable={false}
                defaultSorted={[
                  {
                  }
                ]}
                getTdProps={(state, rowInfo, column) => {
                  return {
                    style: {
                      width: "100px",
                      letterSpacing: "8px",
                      height: "100%",
                      fontSize: smallPhone ? "50px" : "65px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }
                  }
                }}
                getTrProps={(state, rowInfo, column) => {
                  return {
                    style: {
                      color: rowInfo && rowInfo.row._original.hasSung ? '#028392' : '#f9b518',
                      // fontSize: "80px",
                      fontWeight: 400,
                      height: isIphonePlus ? "110px" : "70px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      marginBottom: rowInfo && rowInfo.row._original.displayName.length > 6 && isIphonePlus ? "30px" : "10px",
                      lineHeight: isIphonePlus ? "60px" : "0px"
                    }
                  }



                }}
                columns={[
                  {
                    Header: () => <div style={{ textAlign: "center", fontSize: "30px", marginBottom: "30px", display: "none" }}>{ }</div>,
                    accessor: "displayName",
                    filterable: false,
                    sortable: false
                  }
                ]}
              />
            </Card>
          </GridItem>

          <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%", marginTop: "41px", paddingRight: "86px", zIndex: 1000, marginBottom: "50px", }}>
            <Button className={classes.anchoredFab} onClick={() => setNeedToSignUpModal(true)} aria-label="add">
              <AddIcon style={{ fontSize: "32px" }} />
              Join List
            </Button>
          </div>







          <Dialog
          className={classes.headsupModal}
            style={{ marginTop: "35px", overflow: "hidden", padding: "35px" }}
            classes={{
              root: classes.overflowHidden,
              paper: classes.overflowHidden

                + " " + classes.overflowHidden,
            }}
            open={needToSignUpModal}
            fullWidth
            maxWidth="md"
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setNeedToSignUpModal(false)
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >

            <Card style={{ boxShadow: "none" }} className={classes.requestsForm}>
              <CardHeader color="primary">
                <h1 className={classes.requestsFormHeader} >
                  Heads Up
                </h1>
              </CardHeader>
              <CardBody>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", height: "100%" }}>
                  <h5 style={{ fontSize: "24px", fontWeight: 400, paddingTop: "30px" }}>
                    You must be logged in to add yourself. Would you like to sign up or log in?
                  </h5>
                  <div style={{ display: "flex", width: "75%", justifyContent: "space-evenly" }}>
                    <Button onClick={() => {
                      history.push(`/pages/create-profile/`)
                    }} style={{ width: "45%", fontSize: '17px' }} color='primary'>
                      Sign up
                    </Button>
                    <Button onClick={() => {
                      history.push(`/pages/login-page/`)
                    }} style={{ width: "45%", fontSize: '17px' }} color='primary'>
                      Log in
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Dialog>



          <Snackbar style={{ marginBottom: "30px" }} open={userMustBeWithinRangeAlert} onClose={() => {
            setUserMustBeWithinRangeAlert(false)
          }} autoHideDuration={4000} >
            <Alert onClose={() => {
              setUserMustBeWithinRangeAlert(false)
            }} severity="error">
                Unfortunately you must be connected PCL's wifi to be added to the queue. Please connect and then refresh the app.
            </Alert>
          </Snackbar>


          <Snackbar style={{ marginBottom: "30px" }} open={props.userCreated} onClose={() => props.setUserCreatedFalse()} autoHideDuration={4000} >
            <Alert onClose={() => props.setUserCreatedFalse()} severity="success">
              Your Name Has Been Added!
            </Alert>
          </Snackbar>

          <Snackbar style={{ marginBottom: "30px" }} open={userNameAlreadyExistsInQueue} onClose={() => props.setNameAlreadyExists(false)} autoHideDuration={4000} >
            <Alert onClose={() => props.setNameAlreadyExists(false)} severity="error">
              Unfortunately that name already exists in queue, please slightly modify your name.
            </Alert>
          </Snackbar>

          <Snackbar open={fieldsNotFilled} autoHideDuration={4000} onClose={() => setFieldsNotFilled(false)}>
            <Alert onClose={() => setFieldsNotFilled(false)} severity='error'>
              Name Field Must Be Filled Out
            </Alert>
          </Snackbar>






        </GridContainer>
      ) : (
        <div
          style={{
            // // background: "black",
            // // position: "absolute",
            // // width: "110%",
            // padding: "50px 0 0 0 0",
            height: "180%",
            zIndex: "99999",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginTop: "10%",

          }}
        >

          <h1 style={{ textAlign: "center", marginTop: "50px", color: "#00C1F3", fontSize: "30px", fontWeight: 500, marginBottom: "60px", width: "1200px" }}>
            Please Wait While We Check Your Location. If Your Location Services Are Turned Off, Please Enable Them And Try Again.
          </h1>
          {/* <img
          style={{
            // margin: "30% 0% 0% 33%",
            // position: "relative",
            width: "30%",
            marginBottom: "50px",
            marginTop: "50px"
          }}
          src={Logo}
          alt="..."
        /> */}
          <img
            style={{
              // margin: "30% 0% 0% 33%",
              position: "relative",
              width: "15%"
            }}
            src={Loader}
            alt="..."
          />
        </div>
      )}
    </div>
  );
}

KaraokeList.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    userCreated: state.queue.queueDetails.userCreated,
    userInRange: state.auth.isUserInRange,
    singerUniqueID: state.queue.singerUniqueID,
    nameSubmitted: state.queue.nameSubmitted,
    data: state.queue.data,
    queue: state.queue,
    userNameAlreadyExistsInQueue: state.queue.userNameAlreadyExistsInQueue,
    loggedOutUserAddToQueueModal: state.queue.loggedOutUserAddToQueueModal
  }
}

const mapDispatchToProps = (dispatch) => ({
  addToQueue: (inputs, data) => dispatch(addToQueue(inputs, data)),
  setUserCreatedFalse: () => dispatch(setUserCreatedFalse()),
  setNameAlreadyExists: (boolean) => dispatch(setNameAlreadyExists(boolean)),
  setLoggedOutAddToQueueModal: (boolean) => dispatch(setLoggedOutAddToQueueModal(boolean))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(loginPageStyle)
)(KaraokeList);
