/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from "firebase/fbConfig.js";
import { withRouter } from 'react-router-dom';
import axios from 'axios'
import qs from 'qs';
// Preloader
import Loader from "assets/img/loader.gif";
import SongListLoader from "assets/img/loader.gif";
import Hidden from "@material-ui/core/Hidden";
import Logo from "assets/img/pclBigLogo.png";

// react component for creating dynamic tables
import ReactTable from "react-table";

// audio player
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Autocomplete from '@material-ui/lab/Autocomplete';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Edit from '@material-ui/icons/Edit';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import AddIcon from '@material-ui/icons/Add';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Fade from '@material-ui/core/Fade';
import Popper from "@material-ui/core/Popper";
import NotInterestedIcon from '@material-ui/icons/NotInterested';
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ProfilePicUpload from "components/CustomUpload/profilePicUpload"
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";
import TextField from '@material-ui/core/TextField';
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/danceRequestsStyle";
import { Grid } from "@material-ui/core";
import { getJustDatabaseSongList, loggedInUserAddToQueue, addSongToExistingUserInQueue, addNewSingerAndSong } from "store/actions/queueActions"

import { getDanceRequests, updateRequestStatus } from "store/actions/danceRequestActions"



const tableHeaders = ['Song', 'Artist', 'Requested By','Actions']

const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

const UpTransition = (props) => {
  return <Slide direction="up" {...props} />
}

const AdminDanceRequests = (props) => {
  const { classes, danceRequests, dataLoaded } = props


  const [loaded, setLoaded] = useState(false)
  const [data, setData] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const [specificRequest, setSpecificRequest] = useState(null)
  const [popperOpen, setPopperOpen] = useState(false)
  const [deniedModal, setDeniedModal] = useState(false)
  const [confirmedModal, setConfirmedModal] = useState(false)
  const [requestIndex, setRequestIndex] = useState(null)
  const [testArr, setTestArr] = useState([])


  useEffect(() => {
    if (!dataLoaded) {
      props.getDanceRequests()
    } else {
      setLoaded(true)
    }
    // if (props.danceRequests.dataLoaded) {
    //   setLoaded(true)
    // }
  }, [props])

  useEffect(() => {
    setData(danceRequests)
  }, [danceRequests])


  const renderTableData = () => {
    return data.map((result, index) => {
      return (
        <tr
          id='tableRow'
          style={{ fontWeight: 500, color: '#f9b518', height: "55px", fontSize: "20px" }} key={index}>
          <td style={{ textAlign: "center" }}>{result.song}</td>
          <td style={{ textAlign: "center" }}>{result.artist}</td>
          <td style={{ textAlign: "center" }}>{result.requestedBy}</td>
          <td style={{ textAlign: "center" }}>
            <div style={{ justifyContent: "space-around", display: "flex" }}>
              <Button
                onClick={() => {
                  setSpecificRequest(result)
                  setRequestIndex(index)
                  setConfirmedModal(true)
                }}
                className={classes.actionBtn}>
                Confirm
                <AddIcon />
              </Button>
              <Button
                onClick={() => {
                  setSpecificRequest(result)
                  setRequestIndex(index)
                  setDeniedModal(true)
                }}
                className={classes.actionBtn}>
                Deny
                <NotInterestedIcon />
              </Button>
            </div>
          </td>
        </tr>
      )
    })
  }

  const renderTableHeader = () => {
    return tableHeaders.map((key, index) => {
      return <th className={classes.tableHeader} key={index}>{key}   <TextField InputProps={{
        classes: {
          input: classes.textField
        }
      }}
        style={{ width: "100%", visibility: "hidden" }}
      /> </th>
    })
  }

  return (
    <div className={classes.container} style={{ width: "100%" }}>
      {loaded ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.formContainer}>
            <Card style={{ height: "700px", width: "95%", overflow: "auto" }} className={classes.cardBody}>
              <table className={classes.table}>
                <tbody>
                  <tr style={{ height: "50px" }}>{renderTableHeader()} </tr>
                  {renderTableData()}
                </tbody>
              </table>
            </Card>
          </GridItem>



          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalLarge,
            }}
            open={confirmedModal}
            TransitionComponent={UpTransition}
            keepMounted
            onClose={() => {
              setConfirmedModal(false)
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >

            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setConfirmedModal(false)
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>

                {specificRequest ? (
              <h3 className={classes.searchThroughSpotifyHeader}>Are you sure you want to confirm {specificRequest.requestedBy}'s dance request?</h3>
                ) : (null)}

            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                <Button onClick={(event) => {
                      var newData = [...data]
                      newData.splice(requestIndex, 1)
                      props.updateRequestStatus('Confirmed', specificRequest, newData)
                      setAnchorEl(anchorEl ? null : event.currentTarget);
                      setPopperOpen(!popperOpen)
                      setConfirmedModal(false)
                }} color="primary">
                  Positive
                </Button>
                <Button onClick={() => {
                  setConfirmedModal(false)
                  setAnchorEl(anchorEl ? null : event.currentTarget);
                  setPopperOpen(!popperOpen)
                }} color="danger">
                  I've reconsidered
                </Button>
              </div>
            </DialogContent>
          </Dialog>



          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalLarge,
            }}
            open={deniedModal}
            TransitionComponent={UpTransition}
            keepMounted
            onClose={() => {
              setDeniedModal(false)
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >

            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setDeniedModal(false)
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>


                {specificRequest ? (
              <h3 className={classes.searchThroughSpotifyHeader}>Are you sure you want to deny {specificRequest.requestedBy}'s dance request?</h3>
                ) : (null)}

            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                <Button onClick={(event) => {
                     var newData = [...data]
                     newData.splice(requestIndex, 1)
                     props.updateRequestStatus('Denied', specificRequest, newData)
                     setAnchorEl(anchorEl ? null : event.currentTarget);
                     setPopperOpen(!popperOpen)
                     setDeniedModal(false)
                }} color="primary">
                  Positive
                </Button>
                <Button onClick={() => {
                  setDeniedModal(false)
                  setAnchorEl(anchorEl ? null : event.currentTarget);
                  setPopperOpen(!popperOpen)
                }} color="danger">
                  I've reconsidered
                </Button>
              </div>
            </DialogContent>
          </Dialog>



        </GridContainer>
      ) : (
        <div className={classes.easeInOut}>
            <div
              style={{
                // // background: "black",
                // // position: "absolute",
                // // width: "110%",
                // padding: "50px 0 0 0 0",
                height: "180%",
                zIndex: "99999",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "10%"
              }}
            >
              <img
              className={classes.loaderImg}
                src={Logo}
                alt="..."
              />
              <img
                style={{
                  // margin: "30% 0% 0% 33%",
                  position: "relative",
                  width: "15%"
                }}
                src={Loader}
                alt="..."
              />
            </div>
        </div>
      )
      }



    </div >
  )
}


const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
    assessments: state.assessment,
    updateProfileModal: state.profile.updateProfileModal,
    singerUniqueID: state.queue.singerUniqueID,
    query: state.search.searchQuery,
    searchResults: state.search.searchResults,
    searchDataLoaded: state.search.searchDataLoaded,
    songList: state.queue.songList,
    queue: state.queue,
    displayName: state.profile.user.displayName,
    danceRequests: state.dance.danceRequestDetails.danceRequests,
    dataLoaded: state.dance.danceRequestDetails.dataLoaded
    // uid: state.auth.user.uid
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getJustDatabaseSongList: () => dispatch(getJustDatabaseSongList()),
    getDanceRequests: () => dispatch(getDanceRequests()),
    updateRequestStatus: (status, requestInfo, data) => dispatch(updateRequestStatus(status, requestInfo, data))
  }
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(AdminDanceRequests);
