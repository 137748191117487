/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { Redirect } from 'react-router-dom'
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Link, withRouter } from "react-router-dom";

// @material-ui/core components
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import ReactTable from "react-table";
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Hidden from "@material-ui/core/Hidden";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import SongPreviewModal from '../Preview/SongPreviewModal.jsx'




import { dataTable } from "variables/general.jsx";

// actions

import aboutPageStyle from "assets/jss/material-dashboard-pro-react/views/aboutPageStyle.jsx";

import Loader from "assets/img/loader.gif";

import { deleteFromQueue, editSingerInQueue, changingPositionsInQueue, setUserHasSung, getJustDatabaseSongList, addNewSingerAndSong, deleteAll, addToQueue, setUserCreatedFalse, setNameAlreadyExists, setLoggedOutAddToQueueModal } from "store/actions/queueActions"

import { useMediaQuery } from 'react-responsive';





const Transition = (props) => {
  return <Slide direction="down" {...props} />
}





const About = (props) => {

  const { classes, history } = props;

  // Complete Your Profile
  // Login To Track History
  // Preview Songs
  // Join The List
  // Check Submission Status
  // Need Help?

  return (
    <div style={{ width: "100%" }} className={classes.container}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} className={classes.formContainer}>
          <Card className={classes.card} >
            <CardHeader className={classes.howToUse}>
              How To Use Our App
            </CardHeader>
            <CardBody style={{display: "flex"}}>
              <div className={classes.cardBodyDiv}>
               <h6 className={classes.stepSelection}>
               1. Complete Your Profile: <p style={{textTransform: "none"}}> You will not be able to join the list without a live profile so <a className={classes.clickHere} onClick={() => history.push('/pages/create-profile')}> click here </a>  to sign up. Complete your profile with a display name that you want people to see publicly from the karaoke singer list.
                 </p>
                 </h6>
                 <h6 className={classes.stepSelection}>
               2. Login To Track Song Request History: <p style={{textTransform: "none"}}>Once logged in, you will be able to track the previous submissions of songs under browse songs tab. Use this to quickly resubmit a song you'd like to sing.
                 </p>
                 </h6>
                 <h6 className={classes.stepSelection}>
               3. Preview Songs: <p style={{textTransform: "none"}}>Allows you to listen to a song before you get up to sing it so you can sing with your best voice.
                 </p>
                 </h6>
                 <h6 className={classes.stepSelection}>
               4. Join The List: <p style={{textTransform: "none"}}>Submit your display name to the list and it will automatically (and fairly) be ordered in relation to other singers that join afterwards.
                 </p>
                 </h6>
                 <h6 className={classes.stepSelection}>
               5. Check Submission Status: <p style={{textTransform: "none"}}>Know exactly when you are about to sing by viewing the Singer List once logged in.
                 </p>
                 </h6>
                 <h6 className={classes.stepSelection}>
               5. Need Help? <p style={{textTransform: "none"}}> Speak with your KJ if you need help with anything that the app doesn't already do for you.
                 </p>
                 </h6>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

    </div>
  )
}

About.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    userCreated: state.queue.queueDetails.userCreated,
    userInRange: state.auth.isUserInRange,
    singerUniqueID: state.queue.singerUniqueID,
    nameSubmitted: state.queue.nameSubmitted,
  }
}

const mapDispatchToProps = (dispatch) => ({
})

export default  withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(aboutPageStyle)
)(About));
