import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { startLogout } from "../../store/actions/authActions"
import { createBrowserHistory } from 'history';

// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";

// core components
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.jsx";
import DefaultProfile from "assets/img/default-avatar.png"
import headerLinksStyle from "assets/jss/material-dashboard-pro-react/components/headerLinksStyle";
import firebase from "firebase";

class HeaderLinks extends React.Component {
  state = {
    open: false
  };
  handleClick = () => {
    this.setState({ open: !this.state.open });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  donate = () => {
    window.open('https://secure.actblue.com/donate/khalilpeter?utf8=%E2%9C%93&amount=&commit=Contribute', "_blank")
  }

  render() {
    const {
      AuthBool,
      authUser,
      classes,
      rtlActive,
      startLogout,
      user
    } = this.props;

    const { open } = this.state;
    const searchButton =
      classes.top +
      " " +
      classes.searchButton +
      " " +
      classNames({
        [classes.searchRTL]: rtlActive
      });
    const dropdownItem = classNames(
      classes.dropdownItem,
      classes.primaryHover,
      { [classes.dropdownItemRTL]: rtlActive }
    );
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive
    });
    const managerClasses = classNames({
      [classes.managerClasses]: true
    });

    let profilePhoto = DefaultProfile
    if (AuthBool) {
      (user.dataLoaded && user.profileURL) ? (
        profilePhoto = user.profileURL
      ) : (
        // add link to profile photo to firestore
        profilePhoto = !!this.props.authUser.photoURL ? this.props.authUser.photoURL : DefaultProfile
        // add profile photo to firebase storage
        // update redux with link
      )
    }

    return (
      <div className={wrapper}>
        <div className={managerClasses}>
        </div>
        {AuthBool ? (
          <div className={classes.logoutBtn}>
            {/* <Link to="/profile">
              <img
                style={{ borderRadius: "4px" }}
                className={classes.profilePhoto}
                src={profilePhoto}
                alt="User Photo"
              />
            </Link> */}
            {/*<Button
              onClick={this.donate}
              className={classes.profilePhoto}
              type="button"
              color="danger"
            >DONATE</Button>*/}
            <Button
              style={{ fontSize: "13px", fontWeight: 600 }}
              onClick={startLogout}
              type="button"
              color="primary"
            >LOGOUT</Button>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startLogout: () => dispatch(startLogout())
})

const mapStateToProps = (state) => ({
  AuthBool: !!state.auth.user,
  authUser: state.auth.user,
  user: state.profile.user
})

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(headerLinksStyle)
)(HeaderLinks);
