/*eslint-disable*/
import React from "react";
import Button from "components/CustomButtons/Button.jsx";
import firebase, { db, storage } from "firebase/fbConfig.js"
const squareImage = firebase.functions().httpsCallable('squareImage');

class PictureUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.currentPic,
      uploadProgress: "",
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
   var blob =  reader.readAsDataURL(file);

    reader.onloadend = (result) => {
      console.log('readerrrr', result.currentTarget.result)
            this.setState({
        file: file,
        imagePreviewUrl: result.currentTarget.result,
        imageChanged: true
      });
    };
  }


  handleSubmit() {
    console.log('imagepreveiw', this.state.imagePreviewUrl.slice(23))
    const pic = this.state.file
    console.log('pic', pic)
    // create storage ref
    var storageRef = storage.ref()

    // upload file
    // var uploadTask = storageRef.put(pic)
    storageRef.child('profilePics/' + this.props.uid + "/" + pic.name).putString(this.state.imagePreviewUrl.slice(23), 'base64', {contentType: `${pic.type}` }).then(function(snapshot) {
      console.log('Uploaded', snapshot.totalBytes, 'bytes.');
      console.log('File metadata:', snapshot.metadata);
      // Let's get a download URL for the file.
      snapshot.ref.getDownloadURL().then(function(url) {
        console.log('File available at', url);
        // [START_EXCLUDE]
        document.getElementById('linkbox').innerHTML = '<a href="' +  url + '">Click For File</a>';
        // [END_EXCLUDE]
      });
    }).catch(function(error) {
      // [START onfailure]
      console.error('Upload failed:', error);
      // [END onfailure]
    });
    // update progress bar
    // uploadTask.on('state_changed', (snapshot) => {

    //   // Observe state change events such as progress, pause, and
    //   // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    //   var Progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //   this.setState({ uploadProgress: 'Progress is ' + Progress + '% done' });
    //   switch (snapshot.state) {
    //     case firebase.storage.TaskState.PAUSED: // or 'paused'
    //       this.setState({ uploadProgress: ' Upload is paused' });
    //       break;
    //     case firebase.storage.TaskState.RUNNING: // or 'running'
    //       this.setState({ uploadProgress: 'Upload is running' });
    //       break;
    //   }
    // }, (error) => {
    //   // Handle unsuccessful uploads
    //   console.log(error)
    // }, () => {
    //   // Handle successful uploads on complete
    //   // For instance, get the download URL: https://firebasestorage.googleapis.com/...
    //   uploadTask.snapshot.ref.getDownloadURL()
    //     .then((downloadURL) => {
    //       console.log('downloadurl', downloadURL)
    //       this.photoDialogSave(downloadURL)
    //       this.setState({ uploadProgress: 'Upload finished. Cropping image.' });
    //       return squareImage({name: pic.name, type: pic.type, storageFolder: 'profilePics/' + this.props.uid })
    //         .then(()=>{
    //           this.setState({ uploadProgress: 'Cropped image. Updating database' });
    //           // this.props.handleSubmit(this.props.uid, downloadURL);
    //         });
    //     })
    // })
  }



  // handleSubmit() {
  //   const pic = this.state.file
  //   // create storage ref
  //   var storageRef = firebase.storage().ref()
  //   console.log('storageref', storageRef)
  //   // upload file
  //   var uploadTask = storageRef.child('profilePics/' + this.props.username + pic.name).put(pic);
  // // Register three observers:
  // // 1. 'state_changed' observer, called any time the state changes
  // // 2. Error observer, called on failure
  // // 3. Completion observer, called on successful completion
  // uploadTask.on('state_changed',
  //   (snapshot) => {
  //     console.log('snappshottt', snapshot)
  //     // Observe state change events such as progress, pause, and resume
  //     // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
  //     var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //     console.log('Upload is ' + progress + '% done');
  //     switch (snapshot.state) {
  //       case firebase.storage.TaskState.PAUSED: // or 'paused'
  //       console.log('snapstate', snapshot.state)
  //         this.setState({ uploadProgress: ' Upload is paused' });
  //         break;
  //       case firebase.storage.TaskState.RUNNING: // or 'running'
  //       console.log('snapstate', snapshot.state)
  //         this.setState({ uploadProgress: 'Upload is running' });
  //         break;
  //     }
  //   },
  //   (error) => {
  //     console.log('error', error)
  //   },
  //   () => {
  //     console.log('success')
  //     uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
  //       this.setState({ uploadProgress: 'Upload finished. Updating Firestore' });

  //       db.collection("TEST-users").doc(this.props.uid).set({
  //         profileURL: downloadURL
  //       }, { merge:true }).then(() => {
  //         window.location.reload()
  //       })
  //     });
  //   }
  // );




  // }














  render() {
    return (
      <div className="picture-container">
        <div className="picture">
          <img
            src={this.state.imagePreviewUrl}
            className="picture-src"
            alt="..."
          />
          <input type="file" onChange={e => this.handleImageChange(e)} />
        </div>

        {
          this.state.file ? (
            this.state.uploadProgress ? (
              <p>{this.state.uploadProgress}</p>
            ) : (
              <Button
                color="primary"
                onClick={() => this.handleSubmit()}
              >UPDATE</Button>
            )
          ) : (
            <h6 className="description">Choose Picture</h6>
          )}
      </div>
    );
  }
}

export default PictureUpload;
