// Users for Leaderboard
const user = {
  email: "user email",
  name: "user name",
  groups: ["Phone Banking", "Door Knocking", "IT"],
  dataLoaded: false,
};

var updateProfileModal = false;
var userNeedsToAddNameModal = false;

export const initState = {
  user,
  updateProfileModal,
  userNeedsToAddNameModal,
};

export default (state = initState, action) => {
  switch (action.type) {
    case "SET_USER":
      console.log("SET_USER");
      return {
        ...state,
        user: {
          ...action.userObj,
          dataLoaded: true,
        },
      };
    case "SETUPDATEPROFILEMODAL":
      var toReturn = { ...state };
      toReturn.updateProfileModal = !state.updateProfileModal;
      return toReturn;

    case "SET_USERNEEDSTOADDNAME":
      var toReturn = { ...state };
      toReturn.userNeedsToAddNameModal = action.boolean;
      return toReturn;
    default:
      return state;
  }
};
