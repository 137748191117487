/* eslint-disable no-dupe-keys */
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import actionsReducer from "./actionsReducer";
import adminReducer from "./adminReducer";
import authReducer from "./authReducer";
import brokeringReducer from "./brokeringReducer";
import contentReducer from "./contentReducer";
import profileReducer from "./profileReducer";
import assessmentReducer from "./assessmentReducer";
import practicesReducer from "./practicesReducer";
import articlesReducer from "./articlesReducer";
import booksReducer from "./booksReducer";
import coursesReducer from "./coursesReducer";
import blogsReducer from "./blogsReducer";
import podcastsReducer from "./podcastsReducer";
import contactMessagesReducer from "./contactMessagesReducer";
import feedbackReducer from "./feedbackReducer";
import sharedResourcesReducer from "./sharedResourcesReducer";
import sharedCoursesReducer from "./sharedCoursesReducer";
import sharedGlossaryReducer from "./sharedGlossaryReducer";
import sharedPracticesReducer from "./sharedPracticesReducer";
import sharedBlogReducer from "./sharedBlogReducer";
import sharedNewslettersReducer from "./sharedNewslettersReducer";
import consultantsReducer from "./consultantsReducer";
import professionalsReducer from "./professionalsReducer";
import groupCallsReducer from "./groupCallsReducer";
import newsletterReducer from "./newsletterReducer";
import messagesReducer from "./messagesReducer";
import threadsReducer from "./threadsReducer";
import studiesReducer from "./studiesReducer";
import glossaryReducer from "./glossaryReducer";
import playbookListReducer from "./playbookListReducer";
import SESReducer from "./SESReducer.jsx";
import engagmentWorksheetsReducer from "./engagementWorksheetsReducer";
import queueReducer from "./queueReducer";
import downloadReducer from "./downloadRequestReducer";
import userReducer from "./userReducer.jsx";
import searchReducer from "./searchReducer.jsx";
import danceRequestReducer from "./danceRequestReducer.jsx";
import favoriteReducer from "./favoritesReducer.jsx";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    actions: actionsReducer,
    admin: adminReducer,
    auth: authReducer,
    practices: practicesReducer,
    articles: articlesReducer,
    studies: studiesReducer,
    books: booksReducer,
    brokering: brokeringReducer,
    content: contentReducer,
    courses: coursesReducer,
    podcasts: podcastsReducer,
    consultants: consultantsReducer,
    professionals: professionalsReducer,
    messages: contactMessagesReducer,
    feedback: feedbackReducer,
    resources: sharedResourcesReducer,
    sharedCourses: sharedCoursesReducer,
    sharedGlossary: sharedGlossaryReducer,
    sharedPractices: sharedPracticesReducer,
    sharedBlog: sharedBlogReducer,
    sharedNewsletters: sharedNewslettersReducer,
    profile: profileReducer,
    blogs: blogsReducer,
    assessment: assessmentReducer,
    groupCalls: groupCallsReducer,
    newsletters: newsletterReducer,
    messages: messagesReducer,
    glossary: glossaryReducer,
    threads: threadsReducer,
    playbookList: playbookListReducer,
    SES: SESReducer,
    SESWorksheetsTable: engagmentWorksheetsReducer,
    queue: queueReducer,
    downloadRequests: downloadReducer,
    user: userReducer,
    search: searchReducer,
    dance: danceRequestReducer,
    favorites: favoriteReducer,
  });
