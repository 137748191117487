import React from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';

// react component for creating dynamic tables
import ReactTable from "react-table";
import createTableObject from "components/Table/createReactTableObject.jsx"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/brokering/ordersStyle";

// Creates table object to render on ReactTable from data formatted thus:
// see Orders.jsx for example.

const exampleData = {
  columns: [
    {
      Header: "ORDER #",
      accessor: "order"
    },
    {
      Header: "CUSTOMER",
      accessor: "customer"
    },
    {
      Header: "PALLET CT",
      accessor: "pallet"
    },
    {
      Header: "WEIGHT",
      accessor: "weight"
    },
    {
      Header: "PICKUP ZIP",
      accessor: "zip"
    },
    {
      Header: "BILLABLE",
      accessor: "billable"
    },
    {
      Header: "",
      accessor: "actions",
      sortable: false,
      filterable: false
    }
  ],
  dataRows: [
    [50196, "Ball Aerospace", 40, 500, "80303", "$1,067.59"],
    [50196, "Ball Aerospace", 40, 500, "80303", "$1,067.59"],
    [50196, "Ball Aerospace", 40, 500, "80303", "$1,067.59"]
  ]
};

//With Buttons
export default (dataObject, buttons = (
  // we've added some custom button actions
  <div className="actions-right">
    {/* use this button to add a edit kind of action */}
    <Button
      justIcon
      round
      simple
      onClick={() => {console.log("clicked")}}
      color="warning"
      className="edit"
    >
      <Dvr />
    </Button>{" "}
    {/* use this button to remove the data row */}
    <Button
      justIcon
      round
      simple
      onClick={() => {console.log("clicked")}}        
      color="danger"
      className="remove"
    >
      <Close />
    </Button>{" "}
  </div>
)) => {
  let colObj = {}
  const acc = dataObject.dataRows.map((prop, key) => {
    for (var i = 0; i < dataObject.columns.length; i++) {
      colObj = {
        ...colObj,
        [dataObject.columns[i].accessor]: prop[i]
      }
    }
    return {
      ...colObj,
      id: key,
      actions: buttons
    };
  })
  return acc
}

//Without Buttons
export const simpleTableObject = (dataObject) => {
  let colObj = {}
  const acc = dataObject.dataRows.map((prop, key) => {
    for (var i = 0; i < dataObject.columns.length; i++) {
      colObj = {
        ...colObj,
        [dataObject.columns[i].accessor]: prop[i]
      }
    }
    return {
      ...colObj,
      id: key
    }
  })
  return acc
}