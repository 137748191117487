/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { db, firebase } from "firebase/fbConfig.js";
import { withRouter } from 'react-router-dom';
import axios from 'axios'
import qs from 'qs';
// Preloader
import Loader from "assets/img/loader.gif";
import SongListLoader from "assets/img/loader.gif";
import Hidden from "@material-ui/core/Hidden";
import Logo from "assets/img/pclBigLogo.png";

// react component for creating dynamic tables
import ReactTable from "react-table";

// audio player
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Autocomplete from '@material-ui/lab/Autocomplete';

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Check from '@material-ui/icons/Check';
import Edit from '@material-ui/icons/Edit';
import Close from "@material-ui/icons/Close";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import AddIcon from '@material-ui/icons/Add';
import Snackbar from '@material-ui/core/Snackbar';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import Alert from '@material-ui/lab/Alert';
import Fade from '@material-ui/core/Fade';
import Popper from "@material-ui/core/Popper";
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ProfilePicUpload from "components/CustomUpload/profilePicUpload"
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";
import TextField from '@material-ui/core/TextField';
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Style from "assets/jss/material-dashboard-pro-react/views/danceRequestsStyle";
import { Grid } from "@material-ui/core";
import { getJustDatabaseSongList, loggedInUserAddToQueue, addSongToExistingUserInQueue, addNewSingerAndSong } from "store/actions/queueActions"
import { getUsersDanceRequests, setRequestCreated, deleteUserRequest } from "store/actions/danceRequestActions"


import Fab from '@material-ui/core/Fab';
import DanceRequestPreviewLoggedIn from "./DanceRequestPreviewLoggedIn.jsx"


const tableHeaders = ['Song', 'Artist', 'Status', 'Actions']

const Transition = (props) => {
  return <Slide direction="down" {...props} />
}

const UserDanceRequests = (props) => {
  const { classes, songList, authUser, danceRequestData, dataLoaded, requestCreated, history } = props


  const [loaded, setLoaded] = useState(false)
  const [data, setData] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const [specificRequest, setSpecificRequest] = useState(null)
  const [popperOpen, setPopperOpen] = useState(false)
  const [requestingSongModal, setRequestingSongModal] = useState(false)
  const [deleteSongModal, setDeleteSongModal] = useState(false)
  const [chosenSong, setChosenSong] = useState(null)
  const [requestIndex, setRequestIndex] = useState(null)

  useEffect(() => {
    if (!songList) {
      props.getJustDatabaseSongList()
    }
    if (authUser && !dataLoaded) {
      props.getUsersDanceRequests(authUser.uid)
    }
    if (!loaded && songList && dataLoaded) {
      setLoaded(true)
      setData(danceRequestData)
    }
    if (requestCreated) {
      setRequestingSongModal(false)
    }
  }, [props])

  useEffect(() => {
    setData(danceRequestData)
  }, [danceRequestData])


  const renderTableData = () => {
    return data.map((result, index) => {
      return (
        <tr
          id='tableRow'
          style={result.downloadStatus === 'Confirmed' ? { fontWeight: 500, color: 'green', height: "55px", fontSize: "20px" } : result.downloadStatus === 'Denied' ? { fontWeight: 500, color: 'red', height: "55px", fontSize: "20px" } : { fontWeight: 500, color: '#f9b518', height: "55px", fontSize: "20px" }} key={index}>
          <td style={{ textAlign: "center" }}>{result.song}</td>
          <td style={{ textAlign: "center" }}>{result.artist}</td>
          <td style={{ textAlign: "center" }}>{result.downloadStatus}</td>
          <td style={{ display: "flex", justifyContent: "center", height: "100%", alignItems: "center", paddingTop: "8px" }}>
            <DeleteForeverIcon onClick={() => {
              setRequestIndex(index)
              setChosenSong(result)
              setDeleteSongModal(true)
            }} className={classes.addBackToQueueBtn} />
          </td>
        </tr>
      )
    })
  }

  const renderTableHeader = () => {
    return tableHeaders.map((key, index) => {
      return <th className={classes.tableHeader} key={index}>{key}</th>
    })
  }

  return (
    <div className={classes.container} style={{ width: "100%" }}>
      {loaded ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.formContainer}>
            <Card style={{ height: "700px", width: "95%", overflow: "scroll" }} className={classes.cardBody}>
              <table className={classes.table}>
                <tbody>
                  <tr style={{ height: "50px" }}>{renderTableHeader()} </tr>
                  {renderTableData()}
                </tbody>
              </table>
            </Card>
          </GridItem>
          <Fab className={classes.anchoredFabLoggedIn} onClick={() => {
            // setRequestingSongModal(true)
            history.push('/dance-requests/choose-song')
          }} aria-label="add">
            <AddIcon style={{ fontSize: "medium" }} />
            <p style={{ fontSize: "12px", margin: "0px" }}>Request Song</p>
          </Fab>


          <Dialog
            fullWidth={true}
            maxWidth={'md'}
            style={!songList ? { marginTop: "35px", minHeight: "500px", overflow: "hidden !important" } : { minHeight: "500px", overflow: "hidden !important" }}
            classes={{
              paperWidthSm: classes.paperWidth
            }}
            open={requestingSongModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setRequestingSongModal(false)
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >
            <DialogTitle
              classes={{
                root: classes.modalTitleRoot,
              }}
            >
              <Button
                style={{ color: "black" }}
                onClick={() => {
                  setRequestingSongModal(false)
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
            </DialogTitle>
            {songList && songList.length > 0 ? (
              // now that we added userChangingSongSelection prop, we can check if it is true, if it is, run funciton that only updates song instead of adding name to queue as well

              <DanceRequestPreviewLoggedIn
                songList={songList}
                modalOpen={requestingSongModal}
              />
            ) :
              (
                <div
                  style={{
                    zIndex: "99999",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "400px"
                  }}
                >
                  <h1
                    className={classes.pleaseWait}
                  >Please wait while we load the song library...</h1>
                  <img
                    style={{
                      // margin: "30% 0% 0% 33%",
                      position: "relative",
                      width: "15%"
                    }}
                    src={Loader}
                    alt="..."
                  />
                </div>
              )
            }
          </Dialog>

          <Snackbar style={{ marginBottom: "50px" }} open={requestCreated} autoHideDuration={4000} onClose={() => props.setRequestCreated(false)}>
            <Alert onClose={() => props.setRequestCreated(false)} severity='success'>
              Dance request created succesfully!
            </Alert>
          </Snackbar>

          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal

                + " " + classes.modalSmall,
            }}
            open={deleteSongModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setDeleteSongModal(false)
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >

            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setDeleteSongModal(false)
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>

              {chosenSong ? (
                <h3 style={{ textAlign: "center", marginTop: "50px" }}>Are you sure you want to delete {chosenSong.song} by {chosenSong.artist} as a dance request?</h3>
              ) : (
                null
              )}
            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>

                <Button onClick={() => {
                  var newData = [...data]
                  newData.splice(requestIndex, 1)
                  props.deleteUserRequest(authUser.uid, chosenSong, newData)
                  setChosenSong(null)
                  setDeleteSongModal(false)
                  // add a snackbar boolean
                }} color="primary">
                  Yes, I Want To Delete This Request.
                </Button>
                <Button onClick={() => {
                  setDeleteSongModal(false)
                }} color="danger">
                  No, Nevermind.
                </Button>
              </div>
            </DialogContent>
          </Dialog>



        </GridContainer>
      ) : (
        <div className={classes.easeInOut}>
          {/* <Hidden smDown implementation="css"> */}
            <div
            className={classes.loaderDiv}
              // style={{
              //   // // background: "black",
              //   // // position: "absolute",
              //   // // width: "110%",
              //   // padding: "50px 0 0 0 0",
              //   height: "180%",
              //   zIndex: "99999",
              //   display: "flex",
              //   alignItems: "center",
              //   justifyContent: "center",
              //   flexDirection: "column",
              //   marginTop: "10%"
              // }}
            >
              <img
                     className={classes.loaderImg}
                src={Logo}
                alt="..."
              />
              <img
                style={{
                  // margin: "30% 0% 0% 33%",
                  position: "relative",
                  width: "15%"
                }}
                src={Loader}
                alt="..."
              />
            </div>
          {/* </Hidden> */}
        </div>
      )
      }
    </div >
  )
}


const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
    assessments: state.assessment,
    updateProfileModal: state.profile.updateProfileModal,
    singerUniqueID: state.queue.singerUniqueID,
    query: state.search.searchQuery,
    searchResults: state.search.searchResults,
    searchDataLoaded: state.search.searchDataLoaded,
    songList: state.queue.songList,
    queue: state.queue,
    displayName: state.profile.user.displayName,
    dataLoaded: state.dance.userDanceRequestDetails.dataLoaded,
    danceRequestData: state.dance.userDanceRequestDetails.danceRequests,
    requestCreated: state.dance.userDanceRequestDetails.requestCreated
    // uid: state.auth.user.uid
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getJustDatabaseSongList: () => dispatch(getJustDatabaseSongList()),
    getUsersDanceRequests: (uid) => dispatch(getUsersDanceRequests(uid)),
    setRequestCreated: (boolean) => dispatch(setRequestCreated(boolean)),
    deleteUserRequest: (uid, data, allData) => dispatch(deleteUserRequest(uid, data, allData))
  }
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(Style)
)(UserDanceRequests);
