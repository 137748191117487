/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { Redirect } from 'react-router-dom'
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import ReactTable from "react-table";
import TextField from '@material-ui/core/TextField';
import Hidden from "@material-ui/core/Hidden";
import Logo from "assets/img/pclBigLogo.png";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Loader from "assets/img/loader.gif";
import { dataTable } from "variables/general.jsx";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Popper from "@material-ui/core/Popper";
import Fade from '@material-ui/core/Fade';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import EditIcon from '@material-ui/icons/Edit';

import SongPreviewLoggedIn from '../Preview/SongPreviewLoggedIn.jsx'

import { useMediaQuery } from 'react-responsive';


// actions
import { getJustDatabaseSongList, loggedInUserAddToQueue, setLoggedInUserAddToQueueModal, deleteSelfFromQueue, setNameAlreadyExists } from "store/actions/queueActions"


import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import AddToQueueBtns from '../Pages/AddToQueueBtns'



const Transition = (props) => {
  return <Slide direction="down" {...props} />
}



const PrivateQueue = (props) => {

  const { classes, loggedInUserAddToQueueModal } = props;
  const [tableData, setTableData] = useState([])
  const [songList, setSongList] = useState([])
  const [addNewSingerModal, setAddNewSingerModal] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filteredSongLibrary, setFilteredSongLibrary] = useState([])
  const [popperOpen, setPopperOpen] = useState(false)
  const [songRef, setSongRef] = useState('')
  const [nameRef, setNameRef] = useState('')
  const [songLibrary, setSongLibrary] = useState([])
  const [selectedSongFromDatabase, setSelectedSongFromDatabase] = useState(null)
  const [UID, setUID] = useState(null)
  const [selfIndex, setSelfIndex] = useState(null)
  const [deleteSelfModal, setDeleteSelfModal] = useState(false)
  const [userMustBeWithinRangeAlert, setUserMustBeWithinRangeAlert] = useState(false)
  const [userInQueueAlready, setUserInQueueAlready] = useState(false)
  const [userInQueueAlreadyModal, setUserInQueueAlreadyModal] = useState(false)
  const [editSongModal, setEditSongModal] = useState(false)
  const [currentSongChoice, setCurrentSongChoice] = useState({})
  const [userChangingSongSelection, setUserChangingSongSelection] = useState(false)
  const [loggedInUserQueueId, setLoggedInUserQueueId] = useState(null)

  const isLaptop = useMediaQuery({ query: '(max-width: 1440px)' });

  const isIphonePlus = useMediaQuery({ query: '(max-width: 450px)' });

  const smallPhone = useMediaQuery({ query: '(max-width: 320px)' });
  useEffect(() => {
    if (props.queue.data && props.authUser) {
      setTableData(props.queue.data)
      for (var x = 0; x < props.queue.data.length; x++) {
        if (props.queue.data[x].uid && props.queue.data[x].uid === props.authUser.uid) {
          if (props.queue.data[x].songChoice) {
            setCurrentSongChoice(props.queue.data[x].songChoice)
          } else {
            setCurrentSongChoice({ song: "N/A", artist: "N/A" })
          }
          setLoggedInUserQueueId(props.queue.data[x].id)
          setUserInQueueAlready(true)
        }
      }
    }
    if (props.queue.songList) {
      setSongList(props.queue.songList)
    }
    if (props.authUser) {
      setUID(props.authUser.uid)
    }
  }, [props])



  const trackClickSelected = (e) => {
    var selection = e.target.innerText.split(' - ')
    var songTitleStr = selection[0]
    var artist = selection[1]
    setSelectedSongFromDatabase({ song: songTitleStr, artist: artist })
  }

  const addLoggedInUserToQueue = () => {
    if (!props.userInRange) {
      setUserMustBeWithinRangeAlert(true)
    } else {
      props.setLoggedInUserAddToQueueModal(true)
      props.getJustDatabaseSongList()

    }

  }


  const searchDatabase = (e) => {
    var input = e.target.value
    if (input === '') {
      setFilteredSongLibrary([])
    } else {
      let data = [...songList]
      const filtered = songList.filter(d => {
        if (d.artist && d.artist.toLowerCase().includes(input.toLowerCase()) || d.song && d.song.toLowerCase().includes(input.toLowerCase())) {
          return d
        }
      })
      setFilteredSongLibrary(filtered)
      setSongRef(e.target.value)
    }
  }

  const removeSelfFromQueue = (index) => {
    var self = tableData[selfIndex]
    let data = [...tableData]
    data.splice(selfIndex, 1)
    props.deleteSelfFromQueue(tableData[selfIndex], data)
    setDeleteSelfModal(false)
    setUserInQueueAlready(false)
  }


  const addNewSinger = () => {

    var newSingerName = nameRef.charAt(0).toUpperCase() + nameRef.slice(1)

    selectedSongFromDatabase
    var toReturn = { displayName: newSingerName, hasSung: false, positionInQueue: tableData.length, songChoice: { song: selectedSongFromDatabase.song, artist: selectedSongFromDatabase.artist } }

    var data = [...tableData]
    data.push(toReturn)
    props.loggedInUserAddToQueue(toReturn, data)
    setTableData(data)
    setAddNewSingerModal(false)
    setSelectedSongFromDatabase(null)
    setNameRef('')
    setSongRef('')
  }


  return (
    <div style={props.queue.data ? { width: "100%", marginTop: "20px" } : null}
    //  className={classes.container}
    >
      {props.queue.data ? (
        <GridContainer xs={12} sm={12} md={12} >
          <GridItem xs={12} sm={12} md={12} className={classes.formContainer}>
            <Card className={classes.tableCard} >
              <ReactTable
                defaultPageSize={100}
                noDataText='No Singers In The Queue Yet!'
                showPageJump={false}
                showPagination={false}
                className="-striped"
                style={tableData.length >= 12 ? { height: "100%" } : { height: "800px" }}
                data={tableData.map((row, index) => {
                  if (row.uid && row.uid === UID) {
                    return {
                      displayName: <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{index + 1}.</span>
                        <span className={classes.loggedInName}>
                          {row.displayName}
                        </span>
                        <div onClick={() => {
                          setSelfIndex(index)
                          setDeleteSelfModal(true)
                        }}>
                          <RemoveCircleOutlineIcon />
                        </div>
                      </div>,
                      downloadStatus: row.downloadStatus ? row.downloadStatus : '',
                      hasSung: row.hasSung
                    }
                  } else {
                    return {
                      displayName: <span className={classes.loggedInOtherSingers}>
                          <span>{index + 1}.</span>
                          <span>{row.displayName}</span>
                      </span>,
                      downloadStatus: row.downloadStatus ? row.downloadStatus : '',
                      hasSung: row.hasSung
                    }
                  }
                })}
                filterable={false}
                getTdProps={(state, rowInfo, column) => {
                  return {
                    style: {
                      width: "100px",
                      letterSpacing: "8px",
                      height: (smallPhone || isIphonePlus) ? "55px" : "75px",
                      lineHeight: (smallPhone || isIphonePlus) ? "30px" : "auto",
                      fontSize: (smallPhone || isIphonePlus) ? "25px" : "65px",
                      margin: (smallPhone || isIphonePlus) ? "0px" : "auto",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }
                  }
                }}
                getTrProps={(state, rowInfo, column) => {
                  return {
                    style: {
                      color: rowInfo && rowInfo.row._original.hasSung ? '#028392' : '#f9b518',
                      // fontSize: "80px",
                      fontWeight: 400,
                      height: (smallPhone || isIphonePlus) ? "50px" : "100%",
                      // height: isIphonePlus ? "110px" : "70px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      marginBottom: (isIphonePlus || smallPhone) ? "0px" : "20px",
                      // borderBottom: rowInfo?.index === 4 ? '1px solid red' : 'none'
                      // lineHeight: isIphonePlus ? "60px" : "0px"
                    }
                  }
                }}
                columns={[
                  {
                    Header: () => <div style={{display: 'none !important' }}>{ }</div>,
                    accessor: "displayName",
                    filterable: false,
                    sortable: false
                  }
                ]}
              />
            </Card>
          </GridItem>

          <AddToQueueBtns currentSongChoice={currentSongChoice} userInQueueAlready={userInQueueAlready} tableData={tableData} songList={songList} />



          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal

                + " " + classes.modalSmall,
            }}
            open={editSongModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setEditSongModal(false)
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >

            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setEditSongModal(false)
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              <h3 style={{ textAlign: "center", marginBottom: "60px" }}>Your current song choice is {currentSongChoice.song} by {currentSongChoice.artist}. Would you like to update your selection?</h3>

              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <Button onClick={() => {
                  setEditSongModal(false)
                  props.setLoggedInUserAddToQueueModal(true)
                  setUserChangingSongSelection(true)
                }} color='primary'>
                  Yes, let's change it.
                </Button>
                <Button onClick={() => setEditSongModal(false)} color='danger'>
                  No, keep this song.
                </Button>
              </div>

            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
              </div>
            </DialogContent>
          </Dialog>


          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal

                + " " + classes.modalSmall,
            }}
            open={deleteSelfModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setDeleteSelfModal(false)
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >

            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setDeleteSelfModal(false)
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              <h3 style={{ textAlign: "center" }}>Are you sure you want to remove yourself from the queue?</h3>

            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                <Button onClick={removeSelfFromQueue} color="danger">
                  Delete
                </Button>
                <Button onClick={() => {
                  setDeleteSelfModal(false)
                }} color="primary">
                  Cancel
                </Button>
              </div>
            </DialogContent>
          </Dialog>

        </GridContainer>
      ) : (
        <div className={classes.easeInOut}>
            <div
              style={{
                // // background: "black",
                // // position: "absolute",
                // // width: "110%",
                // padding: "50px 0 0 0 0",
                height: "180%",
                zIndex: "99999",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "10%",
                maxWidth: "none"
              }}
            >
              <img
              className={classes.loaderImg}
                src={Logo}
                alt="..."
              />
              <img
                style={{
                  // margin: "30% 0% 0% 33%",
                  position: "relative",
                  width: "15%"
                }}
                src={Loader}
                alt="..."
              />
            </div>
        </div>
      )}
    </div>
  );



}

PrivateQueue.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authUser: state.auth.user,
    queue: state.queue,
    loggedInUserAddToQueueModal: state.queue.loggedInUserAddToQueueModal,
    userNameAlreadyExistsInQueue: state.queue.userNameAlreadyExistsInQueue,
    userInRange: state.auth.isUserInRange,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getJustDatabaseSongList: () => dispatch(getJustDatabaseSongList()),
  loggedInUserAddToQueue: () => dispatch(loggedInUserAddToQueue()),
  setLoggedInUserAddToQueueModal: (boolean) => dispatch(setLoggedInUserAddToQueueModal(boolean)),
  deleteSelfFromQueue: (selfData, allData) => dispatch(deleteSelfFromQueue(selfData, allData)),
  setNameAlreadyExists: (boolean) => dispatch(setNameAlreadyExists(boolean)),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(loginPageStyle)
)(PrivateQueue);
