import {
  firebase,
  googleAuthProvider,
  db,
  facebookAuthProvider,
  twitterAuthProvider,
} from "../../firebase/fbConfig";
import { startSetUser } from "store/actions/profileActions.jsx";
import { push } from "connected-react-router";
import axios from "axios";

export const emailSignup = (authObject, user) => {
  return (dispatch) => {
    dispatch(
      setDisplayNameAndEmail(authObject.displayName, authObject.email, user)
    );
    dispatch(startSetUser(authObject, user));
    dispatch(push("/browse-songs"));
  };
};

export const setDisplayNameAndEmail = (displayName, email, user) => {
  return {
    type: "SET_DISPLAY_NAME_AND_EMAIL",
    displayName,
    email,
    user,
  };
};

export const signupError = (error) => ({
  type: "SIGNUP_ERROR",
  error,
});

export const setIPAddress = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get("https://geolocation-db.com/json/");
      const ipAddress = response.data.IPv4;

      const snapshot = await db.collection("config").get();

      if (snapshot.empty) {
        await db.collection("config").doc().set({ ipAddress });
      } else {
        const docId = snapshot.docs[0].id;
        await db.collection("config").doc(docId).set({ ipAddress });
      }

      dispatch(setRetrievedIpAddress(true));

      setTimeout(function () {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error("Error setting IP address:", error);
    }
  };
};

export const setRetrievedIpAddress = (boolean) => {
  return {
    type: "SET_RETRIEVED_IP_ADDRESS",
    boolean,
  };
};

export const startGetUserLocation = () => {
  return async (dispatch) => {
    try {
      const snapshot = await db.collection("config").get();
      const usableIps = snapshot.docs.map((doc) => doc.data().ipAddress);

      const response = await axios.get("https://api.ipify.org");
      if (usableIps.includes(response.data)) {
        dispatch(setUserLocationBoolean(true));
      } else {
        dispatch(setUserLocationBoolean(false));
      }
    } catch (error) {
      console.error("Error fetching user location:", error);
    }
  };
};

export const emailSignIn = (credentials) => {
  return (dispatch) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        dispatch(push("/actions"));
      })
      .catch((error) => {
        dispatch(SignError(error));
      });
  };
};

export const setUserLocationBoolean = (boolean) => {
  return {
    type: "SET_USERLOCATIONBOOLEAN",
    boolean,
  };
};

export const SignError = (error) => ({
  type: "SIGNIN_ERROR",
  error,
});

export const loginError = (error) => ({
  type: "LOGIN_ERROR",
  error,
});

export const passwordReset = (emailAddress) => {
  var auth = firebase.auth();
  auth
    .sendPasswordResetEmail(emailAddress)
    .then(() => {
      console.log("Reset email sent");
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
};

export const startGoogleLogin = () => {
  return (dispatch) => {
    return firebase
      .auth()
      .signInWithPopup(googleAuthProvider)
      .then((result) => {
        return db
          .collection("TEST-users")
          .get()
          .then((snapshot) => {
            snapshot = snapshot.docs;
            var displayNameAlreadyExists = false;
            snapshot.forEach((doc) => {
              doc = doc.data();
              if (doc.displayName === result.user.displayName) {
                displayNameAlreadyExists = true;
              }
            });
            if (displayNameAlreadyExists) {
              dispatch(
                setDisplayNameAndEmail(
                  `${
                    result.user.displayName +
                    Math.floor(Math.random() * (100000 - 1 + 1)) +
                    1
                  }`,
                  result.user.email
                )
              );
              dispatch(push("/actions"));
            } else {
              dispatch(
                setDisplayNameAndEmail(
                  `${result.user.displayName}`,
                  result.user.email
                )
              );
              dispatch(push("/actions"));
            }
          });
      });
  };
};

export const startFacebookLogin = () => {
  return (dispatch) => {
    firebase
      .auth()
      .signInWithPopup(facebookAuthProvider)
      .then((result) => {
        return db
          .collection("TEST-users")
          .get()
          .then((snapshot) => {
            snapshot = snapshot.docs;
            var displayNameAlreadyExists = false;
            snapshot.forEach((doc) => {
              doc = doc.data();
              if (doc.displayName === result.user.displayName) {
                displayNameAlreadyExists = true;
              }
            });

            if (displayNameAlreadyExists) {
              dispatch(
                setDisplayNameAndEmail(
                  `${
                    result.user.displayName +
                    Math.floor(Math.random() * (100000 - 1 + 1)) +
                    1
                  }`,
                  result.user.email
                )
              );
              dispatch(push("/actions"));
            } else {
              dispatch(
                setDisplayNameAndEmail(
                  `${result.user.displayName}`,
                  result.user.email
                )
              );
              dispatch(push("/actions"));
            }
          });
      });
  };
};

export const startTwitterLogin = () => {
  return (dispatch) => {
    firebase
      .auth()
      .signInWithPopup(twitterAuthProvider)
      .then(() => {
        dispatch(push("/actions"));
      });
  };
};

export const login = (user = {}) => ({
  type: "LOGIN",
  user,
});

export const startLogout = () => {
  return (dispatch) => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch(logout());
        push("/pages/login-page");
      })
      .catch((err) => {
        console.log("the error is", err);
      });
  };
};

export const logout = () => ({ type: "LOGOUT" });
