import Pages from "layouts/Pages.jsx";
import Dashboard from "layouts/Dashboard.jsx";
import Blog from "views/Connect/Blog.jsx";
import SpotifyAuthorization from '../views/SpotifyAuthorization';
import SongPreview from "views/Preview/SongPreview.jsx";
import DisplayQueue from "views/Pages/DisplayQueue.jsx";


var indexRoutes = [
  { path: "/pages/browse-songs", name: "Browse Songs", component: SongPreview },
  { path: "/pages/display", name: "Display Queue", component: DisplayQueue },
  { path: "/pages", name: "Pages", component: Pages },
  { path: "/", name: "Home", component: Dashboard },
  {
    path: '/spotify-authorization/callback',
    component: SpotifyAuthorization,
  },
];

export default indexRoutes;
