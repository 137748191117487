const retrievedIpAdress = false;
const displayName = "";

export const initState = {
  authError: null,
  retrievedIpAdress,
  displayName,
  user: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case "LOGIN":
      console.log("HERERERERER", action.user);
      return {
        ...state,
        user: action.user,
      };
    case "SET_RETRIEVED_IP_ADDRESS":
      var toReturn = { ...state };
      toReturn.retrievedIpAdress = action.boolean;
      return toReturn;
    case "SET_DISPLAY_NAME_AND_EMAIL":
      var toReturn = { ...state };
      console.log("checkingreducer", action.displayName);
      toReturn.displayName = action.displayName;
      toReturn.email = action.email;
      toReturn.user = action.user;
      return toReturn;
    case "SET_USERLOCATIONBOOLEAN":
      console.log("SET_USERLOCATIONBOOLEAN");
      return {
        ...state,
        isUserInRange: action.boolean,
      };
    case "LOGIN_ERROR":
      console.log("LOGIN_ERROR");
      return {
        ...state,
        error: action.error,
      };
    case "SIGNIN_ERROR":
      console.log({ case: "SIGNIN_ERROR", error: action.error });
      return {
        ...state,
        error: action.error,
      };
    case "SIGNUP_ERROR":
      console.log({ case: "SIGNUP_ERROR", error: action.error });
      return {
        ...state,
        error: action.error,
      };
    case "LOGOUT":
      console.log("LOGOUT");
      return {
        initState,
        user: null,
      };
    default:
      return state;
  }
};
