
  
  // Orders Data
  const threads = {
    columns: [        
      {
        Header: "TITLE",
        accessor: "title"      
      },
      {
        Header: "DESCRIPTION",
        accessor: "description"      
      },
      {
        Header: "IMAGE",
        accessor: "image"      
      },
      {
        Header: "LINK",
        accessor: "link"      
      },
      {
        Header: "CATEGORY",
        accessor: "category"      
      },
      {
        Header: "LINK",
        accessor: "link"
      }
    ],  
    dataLoaded: false
  };
  
  export const initState = {
    threads 
  }
  
  export default (state = initState, action) => {
    switch (action.type) { 
      case 'SET_THREADS_LIST':
        console.log('SET_THREADS_LIST')     
        return {
          ...state,
          threads: {
            ...state.threads,
            dataRows: action.dataRows,
            dataLoaded: true
          }
        };    
        case 'GET_CURRENT_THREAD':
            console.log('GET_CURRENT_THREAD')     
            return {
              ...state,
              currentThread: {
                ...state.currentThread,
                dataRows: action.dataRows,
                dataLoaded: true
              }
            };    
    
      default:
        return state
    }
  }
  