import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { reduxFirestore, getFirestore } from "redux-firestore";
import { reactReduxFirebase, getFirebase } from "react-redux-firebase";
import { routerMiddleware } from "connected-react-router";
import { ConnectedRouter } from "connected-react-router";

import { firebase } from "./firebase/fbConfig";
import { login } from "./store/actions/authActions";
import { startSetUsersList } from "./store/actions/actionsActions";
import rootReducer from "./store/reducers/rootReducer";
import indexRoutes from "routes/index.jsx";
import fbConfig from "./firebase/fbConfig";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import "assets/scss/material-dashboard-pro-react.css?v=1.4.0";

import { startGetUserLocation } from "./store/actions/authActions.jsx";
import { getSpotifyToken, getQueue } from "./store/actions/queueActions";

const hist = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer(hist),
  composeEnhancers(
    applyMiddleware(
      thunk.withExtraArgument({ getFirebase, getFirestore }),
      routerMiddleware(hist)
      // logger
    ),
    reduxFirestore(fbConfig),
    reactReduxFirebase(fbConfig)
  )
);

ReactDOM.render(
  <Provider store={store}>
    <DndProvider backend={HTML5Backend}>
      <ConnectedRouter history={hist}>
        <Router history={hist}>
          <Switch>
            {indexRoutes.map((prop, key) => {
              return (
                <Route path={prop.path} component={prop.component} key={key} />
              );
            })}
          </Switch>
        </Router>
      </ConnectedRouter>
    </DndProvider>
  </Provider>,
  document.getElementById("root")
);

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    store.dispatch(login(user));
    store.dispatch(startSetUsersList(user));
    store.dispatch(startGetUserLocation());
    store.dispatch(getSpotifyToken("user"));
    store.dispatch(getQueue(user.uid));
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/application" ||
      window.location.pathname === "/actions"
    ) {
      hist.push("/browse-songs");
    }
  } else if (hist.location.pathname === "/pages/song-preview") {
    hist.push("/pages/song-preview");
  } else if (hist.location.pathname === "/pages/display") {
    hist.push("/pages/display");
  } else if (hist.location.pathname === "/pages/preview-song") {
    hist.push("/pages/preview-song");
  } else if (hist.location.pathname === "/pages/login-page") {
    hist.push("/pages/login-page");
  } else {
    hist.push("/pages/create-profile");
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
