/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import axios from "axios";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Switch from "@material-ui/core/Switch";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import GetAppIcon from "@material-ui/icons/GetApp";
import TextField from "@material-ui/core/TextField";
import MicIcon from "@material-ui/icons/Mic";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import AddToQueueBtns from "../Pages/AddToQueueBtns";

import queueManagerStyle from "assets/jss/material-dashboard-pro-react/views/queueManagerStyle.jsx";
import Logo from "assets/img/pclBigLogo.png";
import Loader from "assets/img/loader.gif";

import { loggedInUserAddToQueue } from "store/actions/queueActions";

import { getUserSongs, deleteUserSong } from "store/actions/userActions";

import {
  addSongToExistingUserInQueue,
  addSpotifySongToDownloadRequestLoggedInUser,
} from "store/actions/queueActions";
import {
  getQueryResults,
  getSpotifyQueryResults,
} from "store/actions/searchActions.jsx";

import {
  getFavorites,
  addNewFavorite,
  removeFavorite,
} from "store/actions/favoritesActions";

const tableHeaders = ["New Karaoke Request", "Download Status", "Actions"];

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

const UpTransition = (props) => {
  return <Slide direction="up" {...props} />;
};

const UserSongs = (props) => {
  const {
    classes,
    history,
    favoriteDataLoaded,
    userProfile,
    profileInfo,
  } = props;
  const [popperOpen, setPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [filteredSongLibrary, setFilteredSongLibrary] = useState([]);
  const [songLibrary, setSongLibrary] = useState([]);
  const [songRef, setSongRef] = useState("");
  const [selectedSongFromDatabase, setSelectedSongFromDatabase] = useState(
    false
  );
  const [previewUrl, setPreviewUrl] = useState("");
  const [token, setToken] = useState("");
  const [songChoices, setSongChoices] = useState([]);
  const [spotifySongRef, setSpotifySongRef] = useState("");
  const [cantPreviewAlert, setCantPreviewAlert] = useState(false);
  const [songWasDeniedModal, setSongWasDeniedModal] = useState(false);
  const [userInQueueAlready, setUserInQueueAlready] = useState(false);
  const [currentSongChoice, setCurrentSongChoice] = useState({
    song: "N/A",
    artist: "N/A",
  });
  const [queueData, setQueueData] = useState([]);
  const [songOrArtist, setSongOrArtist] = useState("Song");
  const [queueId, setQueueId] = useState(false);
  const [needsToBeDownloadedModal, setNeedsToBeDownloadedModal] = useState(
    false
  );
  const [requestCreated, setRequestCreated] = useState(false);
  const [selectedSongFromSpotify, setSelectedSongFromSpotify] = useState(null);
  const [
    checkIfUserLikesSpotifySong,
    setCheckIfUserLikesSpotifySong,
  ] = useState(false);
  const [checkIfUserLikesSong, setCheckIfUserLikesSong] = useState(false);
  const [userName, setUserName] = useState("");
  const [addedFavoriteToNextSong, setAddedFavoriteToNextSong] = useState(false);
  const [songTitle, setSongTitle] = useState("N/A");
  const [artistName, setArtistName] = useState("N/A");

  const trackClickSelected = (e) => {
    var selection = e.target.innerText.split(" - ");
    var songTitleStr = selection[0];
    var artist = selection[1];

    var id;
    const filtered = songLibrary.filter((d) => {
      if (
        d.artist &&
        d.artist === artist &&
        d.song &&
        d.song === songTitleStr
      ) {
        return d;
      }
    });
    id = filtered[0].id;
    setSelectedSongFromDatabase({ song: songTitleStr, artist: artist, id });
  };

  const searchDatabase = (e) => {
    var input = e.target.value;
    if (input === "") {
      setFilteredSongLibrary([]);
    } else {
      const filtered = songLibrary.filter((d) => {
        if (
          (d.artist && d.artist.toLowerCase().includes(input.toLowerCase())) ||
          (d.song && d.song.toLowerCase().includes(input.toLowerCase()))
        ) {
          return d;
        }
      });
      setFilteredSongLibrary(filtered);
      setSongRef(e.target.value);
    }
  };

  const addSongToQueue = (e) => {
    e.preventDefault();
    let data = [...props.queue.data];
    if (!userInQueueAlready) {
      props.loggedInUserAddToQueue(
        selectedSongFromDatabase,
        userName,
        props.authUser.uid,
        data
      );
      setSongRef("");
    } else {
      var userQueueId;
      for (var x = 0; x < data.length; x++) {
        if (data[x].uid && data[x].uid === props.authUser.uid) {
          userQueueId = data[x].id;
        }
      }
      props.addSongToExistingUserInQueue(
        userQueueId,
        selectedSongFromDatabase,
        "updatingUserSong"
      );
      setSongRef("");
    }
    setCheckIfUserLikesSong(false);
    setAddedFavoriteToNextSong(true);
  };

  const navigateToNewPageWithSearchQuery = async (e, str) => {
    e.preventDefault();
    if (str === "spotify") {
      await props.getSpotifyQueryResults(spotifySongRef, token, songOrArtist);
      history.push("/spotify-search-results");
    } else if (str === "danceRequest") {
      await props.getSpotifyQueryResults(spotifySongRef, token, songOrArtist);
      history.push("/dance-request-search-results");
    } else {
      await props.getQueryResults(songRef, songLibrary);
      history.push("/pcl-search-results");
    }
  };

  const searchSpotifySong = async (e) => {
    setSpotifySongRef(e.target.value);
    if (songOrArtist === "Song") {
      const response = await axios.get(
        `https://api.spotify.com/v1/search?q=track:${e.target.value}&type=track`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      setSongChoices(response.data.tracks.items);
    } else {
      const response = await axios.get(
        `https://api.spotify.com/v1/search?query=${e.target.value}&type=artist&market=us&limit=50&offset=0`,
        { headers: { Authorization: "Bearer " + token } }
      );
      setSongChoices(response.data.artists.items);
    }
  };

  const getTrackFromSpotify = (e) => {
    if (songOrArtist === "Song") {
      var selection = e.target.innerText.split(" - ");
      var songTitleStr = selection[0];
      var artist = selection[1];
      var selectedTrack = null;
      for (var x = 0; x < songChoices.length; x++) {
        if (
          songChoices[x].name === songTitleStr &&
          songChoices[x].artists[0].name === artist
        ) {
          selectedTrack = songChoices[x];
        }
      }

      if (selectedTrack) {
        axios
          .get(
            `https://api.spotify.com/v1/tracks/${selectedTrack.id}?market=US`,
            { headers: { Authorization: "Bearer " + token } }
          )
          .then((results) => {
            if (!results.data.preview_url) {
              setCantPreviewAlert(true);
              setPreviewUrl(results.data.preview_url);
              setSongTitle(songTitleStr);
              setArtistName(artist);
              setSelectedSongFromSpotify({
                song: songTitleStr,
                artist: artist,
              });
            } else {
              setPreviewUrl(results.data.preview_url);
              setSongTitle(songTitleStr);
              setArtistName(artist);
              setSelectedSongFromSpotify({
                song: songTitleStr,
                artist: artist,
              });
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    } else {
      axios
        .get(
          `https://api.spotify.com/v1/search?type=track&q=artist:${e.target.innerText}&limit=50&offset=0`,
          {
            headers: {
              Authorization: "Bearer " + token,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((results) => {
          setSongChoices(results.data.tracks.items);
          setSongOrArtist("Song");
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  useEffect(() => {
    if (profileInfo.displayName) {
      setUserName(profileInfo.displayName);
    }
  }, [profileInfo]);

  useEffect(() => {
    if (!props.dataLoaded && props.authUser) {
      props.getUserSongs(props.authUser.uid);
    }
    if (props.user.data) {
      setTableData(props.user.data);
      console.log("props data", props.user.data);
    }
    if (props.queue.songList) {
      setSongLibrary(props.queue.songList);
    }
    if (props.queue.data && props.authUser) {
      var inQueue = false;
      setQueueData(props.queue.data);
      for (var x = 0; x < props.queue.data.length; x++) {
        if (
          props.queue.data[x].uid &&
          props.queue.data[x].uid === props.authUser.uid
        ) {
          inQueue = true;
          setQueueId(props.queue.data[x].id);
          if (props.queue.data[x].songChoice) {
            setCurrentSongChoice(props.queue.data[x].songChoice);
          } else {
            setCurrentSongChoice({ song: "N/A", artist: "N/A" });
          }
          setUserInQueueAlready(true);
        }
      }
      if (!inQueue) {
        setCurrentSongChoice({ song: "N/A", artist: "N/A" });
      }
    }
    if (!favoriteDataLoaded && props.authUser) {
      props.getFavorites(props.authUser.uid);
    }
  }, [props]);

  useEffect(() => {
    setToken(props.spotifyToken);
  }, [props.spotifyToken]);

  const renderTableHeader = () => {
    return tableHeaders.map((key, index) => {
      return (
        <th className={classes.tableHeader} key={index}>
          {key}{" "}
          <TextField
            InputProps={{
              classes: {
                input: classes.textField,
              },
            }}
            style={{ width: "100%", visibility: "hidden" }}
          />{" "}
        </th>
      );
    });
  };

  const renderTableData = () => {
    return tableData.map((userSong, index) => {
      if (!userSong.downloadRequest) {
        return null;
      }

      if (userSong.songChoice.song === "N/A") {
        return null;
      }
      return (
        <tr
          style={
            userSong.hasSung
              ? {
                  fontWeight: 500,
                  color: "#028392",
                  height: "55px",
                  fontSize: "20px",
                }
              : userSong.downloadStatus === "Denied"
              ? {
                  fontWeight: 500,
                  color: "red",
                  height: "55px",
                  fontSize: "20px",
                }
              : {
                  fontWeight: 500,
                  color: "#f9b518",
                  height: "55px",
                  fontSize: "20px",
                }
          }
          key={index}
        >
          <td style={{ textAlign: "center" }}>
            {userSong.songChoice
              ? userSong.songChoice.song + " - " + userSong.songChoice.artist
              : "N/A"}
          </td>
          <td style={{ textAlign: "center" }}>{userSong.downloadStatus}</td>

          <td
            style={{
              display: "flex",
              justifyContent: "space-around",
              height: "100%",
              alignItems: "center",
              paddingTop: "8px",
            }}
          >
            <DeleteOutlineOutlinedIcon
              onClick={async (event) => {
                setPopperOpen(!popperOpen);
                setAnchorEl(anchorEl ? null : event.currentTarget);
                await props.deleteUserSong(
                  tableData[index],
                  tableData,
                  index,
                  props.authUser.uid
                );
              }}
            />
            <MicIcon
              onClick={() => {
                setNeedsToBeDownloadedModal(true);
              }}
            />
          </td>
        </tr>
      );
    });
  };

  return (
    <div style={{ width: "100%" }} className={classes.container}>
      {props.user.data && songLibrary ? (
        <GridContainer>
          <GridItem
            style={{ maxWidth: "95%" }}
            xs={12}
            sm={6}
            md={6}
            className={classes.formContainer}
          >
            <Card style={{ width: "100%" }} className={classes.requestsForm}>
              <CardHeader
                className={classes.cardHeaderBorderRadius}
                color="primary"
              >
                <h1 className={classes.requestsFormHeader}>
                  Browse Our Karaoke Library
                </h1>
              </CardHeader>
              <form
                style={{ paddingBottom: "140px" }}
                className={classes.previewFormCollection}
                noValidate
                autoComplete="off"
              >
                <Autocomplete
                  disableClearable
                  options={
                    filteredSongLibrary
                      ? filteredSongLibrary.map((song) => {
                          return song.song + " - " + song.artist;
                        })
                      : songLibrary.map((song) => {
                          return song.song + " - " + song.artist;
                        })
                  }
                  className={classes.formTextField}
                  id="Song"
                  label="Song or Artist"
                  InputProps={{}}
                  onChange={trackClickSelected}
                  renderInput={(params) => {
                    return (
                      <TextField
                        onChange={searchDatabase}
                        {...params}
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            navigateToNewPageWithSearchQuery(e);
                          }
                        }}
                        InputLabelProps={{
                          className: classes.label,
                        }}
                        value={songRef}
                        id="Song"
                        label="Song or Artist"
                      />
                    );
                  }}
                />
                {selectedSongFromDatabase ? (
                  <Button
                    type="submit"
                    color="primary"
                    style={{ maxHeight: "50px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setCheckIfUserLikesSong(true);
                    }}
                  >
                    <MicIcon style={{ fontSize: "xx-large" }} />
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    color="primary"
                    style={{ maxHeight: "50px" }}
                    onClick={navigateToNewPageWithSearchQuery}
                  >
                    <SearchIcon style={{ fontSize: "xx-large" }} />
                  </Button>
                )}
              </form>
            </Card>
          </GridItem>

          <GridItem
            style={{ maxWidth: "95%" }}
            xs={12}
            sm={6}
            md={6}
            className={classes.formContainer}
          >
            <Card style={{ width: "100%" }} className={classes.requestsForm}>
              <CardHeader
                className={classes.cardHeaderBorderRadius}
                color="primary"
              >
                <h1 className={classes.requestsFormHeader}>All Songs</h1>
              </CardHeader>
              <form
                style={{ display: "flex", alignItems: "center" }}
                className={classes.previewFormDiv}
                noValidate
                autoComplete="off"
              >
                <div className={classes.songArtistToggle}>
                  <p> Song </p>
                  <Switch
                    checked={songOrArtist === "Song" ? false : true}
                    onChange={() => {
                      if (songOrArtist === "Song") {
                        setSongOrArtist("Artist");
                      } else {
                        setSongOrArtist("Song");
                      }
                    }}
                    value="checkedA"
                  />
                  <p> Artist </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "95%",
                    margin: "0px auto",
                  }}
                >
                  {songOrArtist === "Song" ? (
                    <Autocomplete
                      options={songChoices.map((song) => {
                        return song.name + " - " + song.artists[0].name;
                        //  + ', ' + song.album.name
                      })}
                      //  onInputChange={searchSpotify}
                      disableClearable
                      className={classes.formTextField}
                      id="Song"
                      label="Song"
                      InputProps={{}}
                      onChange={getTrackFromSpotify}
                      renderInput={(params) => (
                        <TextField
                          className={classes.formTextField}
                          // InputProps={{ classes: { underline: classes.underline,
                          //   input: classes.formTextField } }}
                          onChange={searchSpotifySong}
                          {...params}
                          //   InputProps={{ classes: {underline: classes.underline
                          //   },
                          onKeyPress={(e) => {
                            if (e.which === 13) {
                              navigateToNewPageWithSearchQuery(e, "spotify");
                            }
                          }}
                          // } }

                          InputLabelProps={{
                            className: classes.label,
                          }}
                          value={spotifySongRef}
                          id="Song"
                          label="Song"
                        />
                      )}
                    />
                  ) : (
                    <Autocomplete
                      options={songChoices.map((artist) => {
                        return artist.name;
                      })}
                      disableClearable
                      className={classes.formTextField}
                      id="Artist"
                      label="Artist"
                      InputProps={{}}
                      disableCloseOnSelect={true}
                      onChange={getTrackFromSpotify}
                      renderInput={(params) => (
                        <TextField
                          className={classes.formTextField}
                          onChange={searchSpotifySong}
                          {...params}
                          onKeyPress={(e) => {
                            if (e.which === 13) {
                              navigateToNewPageWithSearchQuery(e, "spotify");
                            }
                          }}
                          InputLabelProps={{
                            className: classes.label,
                          }}
                          value={spotifySongRef}
                          id="Artist"
                          label="Artist"
                        />
                      )}
                    />
                  )}
                  {selectedSongFromSpotify ? (
                    <Button
                      type="submit"
                      color="primary"
                      style={{ maxHeight: "50px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        setCheckIfUserLikesSpotifySong(true);
                      }}
                    >
                      {/* <p style={{ fontSize: "xx-large" }}> > </p> */}
                      <GetAppIcon style={{ fontSize: "xx-large" }} />
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      style={{ maxHeight: "50px" }}
                      onClick={(e) =>
                        navigateToNewPageWithSearchQuery(e, "spotify")
                      }
                    >
                      <SearchIcon style={{ fontSize: "xxx-large" }} />
                    </Button>
                  )}
                </div>
              </form>
              <AudioPlayer
                src={previewUrl ? previewUrl : null}
                style={{ margin: "30px auto 27px auto", width: "95%" }}
                onPlay={() => console.log("onPlay")}
              />
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12} className={classes.formContainer}>
            <Card
              style={
                tableData.length === 0
                  ? { height: "300px", width: "95%" }
                  : { width: "95%" }
              }
              className={classes.cardBody}
            >
              <table className={classes.table}>
                <tbody>
                  <tr style={{ height: "50px" }}>{renderTableHeader()} </tr>
                  {renderTableData()}
                </tbody>
              </table>
            </Card>
          </GridItem>

          <AddToQueueBtns
            currentSongChoice={currentSongChoice}
            userInQueueAlready={userInQueueAlready}
            tableData={queueData}
            songList={songLibrary}
          />

          <Snackbar
            style={{ marginBottom: "50px" }}
            open={cantPreviewAlert}
            autoHideDuration={4000}
            onClose={() => setCantPreviewAlert(false)}
          >
            <Alert onClose={() => setCantPreviewAlert(false)} severity="error">
              {`Unfortunately that song can't be previewed due to licensing`}
            </Alert>
          </Snackbar>

          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalSmall,
            }}
            open={songWasDeniedModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setSongWasDeniedModal(false);
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >
            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setSongWasDeniedModal(false);
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              <h3 style={{ textAlign: "center", marginTop: "50px" }}>
                Your song request was denied by the KJ, do you want to pick
                another song?
              </h3>
            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Button color="primary">Yes, pick a new song</Button>
                <Button
                  onClick={() => {
                    // props.deleteUserSong(
                    //   tableData[singerIndex],
                    //   tableData,
                    //   singerIndex,
                    //   props.authUser.uid
                    // );
                    setSongWasDeniedModal(false);
                  }}
                  color="danger"
                >
                  No, just delete the request
                </Button>
              </div>
            </DialogContent>
          </Dialog>

          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalLarge,
            }}
            open={checkIfUserLikesSpotifySong}
            TransitionComponent={UpTransition}
            keepMounted
            onClose={() => {
              setCheckIfUserLikesSpotifySong(false);
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >
            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setCheckIfUserLikesSpotifySong(false);
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>

              <h3 className={classes.searchThroughSpotifyHeader}>
                Are you sure you want to request the song {songTitle} by{" "}
                {artistName}?
              </h3>
            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Button
                  onClick={() => {
                    setCheckIfUserLikesSpotifySong(false);
                    setNeedsToBeDownloadedModal(true);
                  }}
                  color="primary"
                >
                  Yes, This Is My Jam
                </Button>
                <Button
                  onClick={() => {
                    setCheckIfUserLikesSpotifySong(false);
                  }}
                  color="danger"
                >
                  {`Nevermind, I'll pick something else`}
                </Button>
              </div>
            </DialogContent>
          </Dialog>

          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalSmall,
            }}
            open={needsToBeDownloadedModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setNeedsToBeDownloadedModal(false);
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >
            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setNeedsToBeDownloadedModal(false);
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              <h3 style={{ textAlign: "center", marginTop: "50px" }}>
                {`Heads up, this song still needs to be approved for download by
                the KJ and selecting it doesn't guarantee availability. Are you
                sure you still want to choose this song?`}
              </h3>
            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Button
                  onClick={() => {
                    var songSelection = { song: songTitle, artist: artistName };
                    if (userInQueueAlready) {
                      props.addSongToExistingUserInQueue(
                        queueId,
                        songSelection,
                        "updatingUserSongWithSpotify",
                        "N/A"
                      );
                      setNeedsToBeDownloadedModal(false);
                      setRequestCreated(true);
                    } else {
                      props.addSpotifySongToDownloadRequestLoggedInUser(
                        queueId,
                        songSelection,
                        "N/A",
                        props.authUser.uid,
                        userProfile.displayName,
                        props.queue.data
                      );
                      setNeedsToBeDownloadedModal(false);
                      setRequestCreated(true);
                    }
                  }}
                  color="primary"
                >
                  Yes, I Want To Submit the request.
                </Button>

                <Button
                  onClick={() => {
                    setNeedsToBeDownloadedModal(false);
                  }}
                  color="danger"
                >
                  No, Nevermind.
                </Button>
              </div>
            </DialogContent>
          </Dialog>

          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalLarge,
            }}
            open={checkIfUserLikesSong}
            TransitionComponent={UpTransition}
            keepMounted
            onClose={() => {
              setCheckIfUserLikesSong(false);
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >
            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setCheckIfUserLikesSong(false);
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              <h3 className={classes.searchThroughSpotifyHeader}>
                Are you sure you want to choose{" "}
                {selectedSongFromDatabase.song && selectedSongFromDatabase.song}{" "}
                by{" "}
                {selectedSongFromDatabase.artist &&
                  selectedSongFromDatabase.artist}{" "}
                as your next song?
              </h3>
            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Button onClick={addSongToQueue} color="primary">
                  Yes, This Is My Jam
                </Button>
                <Button
                  onClick={() => {
                    setCheckIfUserLikesSong(false);
                  }}
                  color="danger"
                >
                  {`Nevermind, I'll pick something else`}
                </Button>
              </div>
            </DialogContent>
          </Dialog>

          <Snackbar
            open={requestCreated}
            onClose={() => setRequestCreated(false)}
            autoHideDuration={4000}
          >
            <Alert onClose={() => setRequestCreated(false)} severity="success">
              Your song has been changed and the request has bee created
            </Alert>
          </Snackbar>

          <Snackbar
            style={{ marginBottom: "50px" }}
            open={addedFavoriteToNextSong}
            autoHideDuration={4000}
            onClose={() => setAddedFavoriteToNextSong(false)}
          >
            <Alert
              onClose={() => setAddedFavoriteToNextSong(false)}
              severity="success"
            >
              Your next song choice has been picked
            </Alert>
          </Snackbar>
        </GridContainer>
      ) : (
        <div className={classes.easeInOut}>
          <div
            style={{
              // // background: "black",
              // // position: "absolute",
              // // width: "110%",
              // padding: "50px 0 0 0 0",
              height: "180%",
              zIndex: "99999",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              marginTop: "10%",
            }}
          >
            <img className={classes.loaderImg} src={Logo} alt="..." />
            <img
              style={{
                // margin: "30% 0% 0% 33%",
                position: "relative",
                width: "15%",
              }}
              src={Loader}
              alt="..."
            />
          </div>
        </div>
      )}
    </div>
  );
};

UserSongs.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    queue: state.queue,
    authUser: state.auth.user,
    user: state.user,
    userProfile: state.profile.user,
    dataLoaded: state.user.userDetails.dataLoaded,
    spotifyToken: state.user.spotifyToken,
    loadedOnce: state.user.loadedOnce,
    songList: state.queue.songList,
    userInRange: state.auth.isUserInRange,
    favoritesData: state.favorites.favoritesData,
    favoriteDataLoaded: state.favorites.dataLoaded,
    profileInfo: state.profile.user,
    singerUniqueID: state.queue.singerUniqueID,
    // songList: state.queue.songList
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUserSongs: (uid) => dispatch(getUserSongs(uid)),
  deleteUserSong: (song, allSongs, indexOfSong, uid) =>
    dispatch(deleteUserSong(song, allSongs, indexOfSong, uid)),
  getQueryResults: (query, songList) =>
    dispatch(getQueryResults(query, songList)),
  getFavorites: (uid) => dispatch(getFavorites(uid)),
  addNewFavorite: (uid, songSelection) =>
    dispatch(addNewFavorite(uid, songSelection)),
  removeFavorite: (uid, songId) => dispatch(removeFavorite(uid, songId)),
  addSongToExistingUserInQueue: (queueId, currentSongChoice, str, previewUrl) =>
    dispatch(
      addSongToExistingUserInQueue(queueId, currentSongChoice, str, previewUrl)
    ),
  addSpotifySongToDownloadRequestLoggedInUser: (
    id,
    songChoice,
    previewUrl,
    uid,
    userName,
    data
  ) =>
    dispatch(
      addSpotifySongToDownloadRequestLoggedInUser(
        id,
        songChoice,
        previewUrl,
        uid,
        userName,
        data
      )
    ),
  getSpotifyQueryResults: (searchInput, token, songOrArtist) =>
    dispatch(getSpotifyQueryResults(searchInput, token, songOrArtist)),
  loggedInUserAddToQueue: (song, userName, UID, data) =>
    dispatch(loggedInUserAddToQueue(song, userName, UID, data)),
});

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(queueManagerStyle)
  )(UserSongs)
);
