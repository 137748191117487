/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import Datetime from "react-datetime";
import moment from "moment";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import styles from "assets/jss/material-dashboard-pro-react/components/ActionSubmitFormStyle.jsx";

class Form extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      queryString: "",
      title: "",
      description: "",
      dueDate: moment(),
      days: "",
      points: "",
      actionAssigned: false,
      assignedError: false,
      defaultLoaded: false,
      assigningAction: "",
      cardAnimaton: "cardHidden",
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({
          queryString: this.props.queryString ? this.props.queryString : "",
          cardAnimaton: "",
        });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleDateChange = (dueDate) => {
    if (dueDate) {
      this.setState(() => ({ dueDate }));
    }
  };

  // only make future selectable
  valid = (current) => {
    return current.isAfter(Datetime.moment().subtract(1, "day"));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { queryString } = this.state;

    this.setState({
      assigningAction: "Starting assignment",
    });

    const { usersData } = this.props;

    let UIDarray = [];
    // Get any volUID where a user object value === queryString
    // and push to UIDarray
    usersData.forEach((user) => {
      const relKeys = ["volUID", "username", "email", "county"];
      if (queryString === "All") {
        UIDarray.push(user.volUID);
      } else {
        relKeys.forEach((key) => {
          if (user[key] === queryString) {
            UIDarray.push(user.volUID);
          }
        });
        let groups = user.groups.split(", ");
        groups.forEach((group) => {
          if (group === queryString) {
            UIDarray.push(user.volUID);
          }
        });
      }
    });
  };
  setDefaults = () => {
    this.setState({
      queryString: this.props.queryString ? this.props.queryString : "",
      defaultLoaded: true,
    });
  };

  resetForm = () => {
    this.setState({
      title: "",
      description: "",
      dueDate: moment(),
      days: "",
      points: "",
      actionAssigned: false,
      assignedError: false,
      defaultLoaded: false,
      cardAnimaton: "cardHidden",
    });
  };

  resetRequest = () => {
    window.location.reload();
  };

  render() {
    const { classes, modalOpen } = this.props;

    // set queryString

    if (modalOpen && !this.state.defaultLoaded) {
      this.setDefaults();
    }

    if (!modalOpen && this.state.defaultLoaded) {
      this.resetForm();
    }

    // reset modal when closed
    if (!modalOpen && this.state.actionAssigned) {
      this.resetRequest();
    }

    return (
      <div>
        {this.state.actionAssigned ? (
          <GridContainer justify="center">
            <GridItem xs={11}>
              <h4 className={classes.textPrimary}>Action Assigned!</h4>
            </GridItem>
          </GridContainer>
        ) : (
          <div className={classes.formContainer}>
            <form onSubmit={this.handleSubmit}>
              <GridContainer justify="center">
                <GridItem xs={11}>
                  {this.state.messageError ? (
                    <h5 className={classes.bodyText}>
                      There Has Been An Error: {this.state.messageError}
                    </h5>
                  ) : null}
                  <p
                    className={classes.bodyText}
                    style={{
                      marginBottom: "0",
                      marginTop: "20px",
                    }}
                  >
                    Username, Email, County, Group, or volUID
                  </p>
                  <CustomInput
                    id="queryString"
                    style={{
                      marginTop: "0",
                      paddingTop: "0",
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      required: true,
                      type: "text",
                      value: this.state.queryString,
                      onChange: (event) => this.handleChange(event),
                    }}
                  />
                  <CustomInput
                    labelText="Title"
                    id="title"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      required: true,
                      type: "text",
                      onChange: (event) => this.handleChange(event),
                    }}
                  />
                  <CustomInput
                    labelText="Description"
                    id="description"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      multiline: true,
                      rows: 5,
                      onChange: (event) => this.handleChange(event),
                    }}
                  />
                  <br />
                  <Datetime
                    selected={this.state.dueDate}
                    onChange={this.handleDateChange}
                    timeFormat={false}
                    isValidDate={this.valid}
                    renderInput={(dateInputProps, open, close) => (
                      <CustomInput
                        inputProps={{ ...dateInputProps, required: true }}
                        onBlur={close}
                        onFocus={open}
                        labelText="Due Date"
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    )}
                  />
                  <CustomInput
                    labelText="Points"
                    id="points"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      required: true,
                      type: "number",
                      min: "0",
                      step: "1",
                      onChange: (event) => this.handleChange(event),
                    }}
                  />
                </GridItem>
              </GridContainer>
              {this.state.assigningAction ? (
                <div>
                  <p className={classes.bodyText}>
                    {this.state.assigningAction}
                  </p>
                </div>
              ) : (
                <Button
                  type="submit"
                  color="primary"
                  style={{
                    marginLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  SUBMIT
                </Button>
              )}
            </form>
          </div>
        )}
      </div>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Form);
