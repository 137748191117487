// ##############################
// // // How to use Page view styles
// #############################

import { container } from "assets/jss/material-dashboard-pro-react.jsx";

const howToUseStyle = (theme) => ({
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.up("md")]: {
      minHeight: "1000px",
    },
  },
  formContainer: {
    padding: "0px !important",
    [theme.breakpoints.up("md")]: {
      minHeight: "1000px",
    },
  },
  card: {
    padding: "20px",
    width: "90%",
    margin: "0px auto",
    background: "#2A2A2A",
    // height: "75%",
    overflow: "scroll",
    marginTop: "60px",
    minHeight: "900px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
      overflow: "hidden",
      padding: "0px !important",
      marginTop: "20px"
    },
  },
  howToUse: {
    textAlign: "center",
    fontSize: "40px",
    color: "white",
    lineHeight: "50px",
    paddingLeft: "30px",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "50px",
      marginTop: "50px",
    },
  },
  cardBodyDiv: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
    minHeight: "600px",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "50px",
    },
  },
  stepSelection: {
    fontSize: "23px",
    color: "rgb(249, 181, 24)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  stepSelectionProminent: {
    fontSize: "23px",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  clickHere: {
    color: "#80E3FC",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
      color: "#80E3FC",
    },
  },
});

export default howToUseStyle;
