import axios from "axios";

export const getQueryResults = (query, songList, str) => {
  return (dispatch) => {
    const lowerCaseQuery = query.toLowerCase();
    let filtered = songList.filter((d) => {
      if (str) {
        return (
          (d.name && d.name.toLowerCase().includes(lowerCaseQuery)) ||
          (d.artists &&
            d.artists[0].name.toLowerCase().includes(lowerCaseQuery))
        );
      } else {
        return (
          (d.artist && d.artist.toLowerCase().includes(lowerCaseQuery)) ||
          (d.song && d.song.toLowerCase().includes(lowerCaseQuery))
        );
      }
    });

    dispatch(setQueryResults(query, filtered));
  };
};

export const setSearchDataLoaded = () => {
  return {
    type: "SET_SEARCH_DATA_LOADED",
  };
};

export const getSpotifyQueryResults = (searchInput, token, songOrArtist) => {
  return async (dispatch) => {
    try {
      let url = "";
      if (songOrArtist === "Song") {
        url = `https://api.spotify.com/v1/search?q=track:${searchInput}&type=track`;
      } else {
        url = `https://api.spotify.com/v1/search?type=track&q=artist:${searchInput}&limit=50&offset=0`;
      }

      const response = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      dispatch(setQueryResults(searchInput, response.data.tracks.items));
    } catch (error) {
      console.log("Error fetching Spotify query results:", error);
    }
  };
};

export const setQueryResults = (query, results) => {
  return {
    type: "SET_SEARCH_QUERY",
    query,
    results,
  };
};
