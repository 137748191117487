/*eslint-disable*/
import React, { useRef, useEffect, useState } from "react";
import { Redirect } from 'react-router-dom'
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Link } from "react-router-dom";
import axios from 'axios'
import qs from 'qs';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Autocomplete from '@material-ui/lab/Autocomplete';

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import RequestForm from "components/Forms/QueueSubmitForm.jsx";
import TextField from '@material-ui/core/TextField';
import Hidden from "@material-ui/core/Hidden";
import Logo from "assets/img/pclBigLogo.png";
import Loader from "assets/img/loader.gif";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { getDistance, isPointInPolygon } from 'geolib';


// actions
import { addToQueue, setUserCreatedFalse, setNameAlreadyExists } from "store/actions/queueActions"

import requestsStyles from "assets/jss/material-dashboard-pro-react/views/requestsStyle.jsx";
import SongPreview from '../Preview/SongPreview.jsx'

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }


const Requests = (props) => {


  const { authError, classes, nameSubmitted, singerUniqueID, userNameAlreadyExistsInQueue } = props;
  const [nameRef, setNameRef] = useState('')
  const [songRef, setSongRef] = useState('')
  const [artistRef, setArtistRef] = useState('')
  const [fieldsNotFilled, setFieldsNotFilled] = useState(false)
  const [userNotInRangeAlert, setUserNotInRangeAlert] = useState(false)
  const [token, setToken] = useState('')
  const [songChoices, setSongChoices] = useState([])

  // const searchSpotify = (e) => {
  //   console.log('e.target', e.target.value)
  //   axios.get(`https://api.spotify.com/v1/search?q=track:${e.target.value}&type=track`, { headers: { 'Authorization': 'Bearer ' + token } })
  //     .then((response) => {
  //       console.log('searchresponse', response.data.tracks.items)
  //       setSongChoices(response.data.tracks.items)
  //     })
  //     .catch((err) => {
  //       console.log('errr', err)
  //     })
  // }

  const submitRequest = () => {
    var data = [...props.data]
    if (!props.userInRange) {
      setUserNotInRangeAlert(true)
    } else if (!nameRef) {
      setFieldsNotFilled(true)
    } else {
      var toSubmit = { name: nameRef }
      data.push(toSubmit)
      props.addToQueue(toSubmit, data)
      setNameRef('')
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.container}>
        {props.userInRange !== undefined ? (
           <GridContainer>

           {nameSubmitted ? (
             <SongPreview name={nameRef} />
           ) : (
             <GridItem xs={12} sm={12} md={12} className={classes.formContainer}>
               <Card className={classes.requestsForm}>
                 <CardHeader color="primary">
                   <h1 className={classes.requestsFormHeader} >Add Yourself To The List</h1>
                 </CardHeader>
                 <CardBody>
                   <div className={classes.formDiv}>
                     <form className={classes.form} noValidate autoComplete="off">
                       <TextField value={nameRef}
                       onChange={(e) => {
                        if (e.which === 13) {
                          submitRequest()
                        } else {
                         setNameRef(e.target.value)
                        }
                       }}
                        className={classes.formTextField} id="Name" label="Your Name" />
                     </form>
                     <Button style={{fontSize: "17px", fontWeight: 500}} onClick={submitRequest} color="primary" className={classes.submitBtn} >Choose A Song</Button>
                   </div>
                 </CardBody>
               </Card>
             </GridItem>
           )}

<Snackbar style={{marginBottom: "30px"}} open={props.userCreated} onClose={() => props.setUserCreatedFalse()} autoHideDuration={4000} >
           <Alert onClose={() => props.setUserCreatedFalse()} severity="success">
             Your Name Has Been Added!
           </Alert>
         </Snackbar>


         <Snackbar style={{marginBottom: "30px"}} open={userNameAlreadyExistsInQueue} onClose={() => props.setNameAlreadyExists(false)} autoHideDuration={4000} >
           <Alert onClose={() => props.setNameAlreadyExists(false)} severity="error">
            Unfortunately that name already exists in queue, please slightly modify your name.
           </Alert>
         </Snackbar>

         <Snackbar open={fieldsNotFilled} autoHideDuration={4000}  onClose={() => setFieldsNotFilled(false)}>
           <Alert onClose={() => setFieldsNotFilled(false)} severity='error'>
             Name Field Must Be Filled Out
           </Alert>
         </Snackbar>


         <Snackbar style={{marginBottom: "30px"}} autoHideDuration={4000}  open={userNotInRangeAlert} onClose={() => setUserNotInRangeAlert(false)}>
           <Alert onClose={() => setUserNotInRangeAlert(false)} severity='error'>
           Unfortunately you must be connected PCL's wifi to be added to the queue. Please connect and then refresh the app.
           </Alert>
         </Snackbar>

         </GridContainer>



        ) : (
            <div
              style={{
                // // background: "black",
                // // position: "absolute",
                // // width: "110%",
                // padding: "50px 0 0 0 0",
                height: "180%",
                zIndex: "99999",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "10%"
              }}
            >

              <h1 style={{ textAlign: "center", marginTop: "50px", color: "#00C1F3", fontSize: "30px", fontWeight: 500, marginBottom: "60px" }}>
                Please Wait While We Check Your Location. If Your Location Services Are Turned Off, Please Enable Them And Try Again.
              </h1>
              {/* <img
                style={{
                  // margin: "30% 0% 0% 33%",
                  // position: "relative",
                  width: "30%",
                  marginBottom: "50px",
                  marginTop: "50px"
                }}
                src={Logo}
                alt="..."
              /> */}
              <img
                style={{
                  // margin: "30% 0% 0% 33%",
                  position: "relative",
                  width: "15%"
                }}
                src={Loader}
                alt="..."
              />
            </div>
        ) }

      </div>
    </div>
  );
}

Requests.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    userCreated: state.queue.queueDetails.userCreated,
    userInRange: state.auth.isUserInRange,
    singerUniqueID: state.queue.singerUniqueID,
    nameSubmitted: state.queue.nameSubmitted,
    data: state.queue.data,
    queue: state.queue,
    userNameAlreadyExistsInQueue: state.queue.userNameAlreadyExistsInQueue
  }
}

const mapDispatchToProps = (dispatch) => ({
  addToQueue: (inputs, data) => dispatch(addToQueue(inputs, data)),
  setUserCreatedFalse: () => dispatch(setUserCreatedFalse()),
  setNameAlreadyExists: (boolean) => dispatch(setNameAlreadyExists(boolean)),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(requestsStyles)
)(Requests);
