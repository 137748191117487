// Orders Data
const actions = {
    columns: [    
      {
        Header: "ACTION ID",
        accessor: "actionID",
        show: false
      },
      {
        Header: "TITLE",
        accessor: "title"
      },
      {
        Header: "BODY",
        accessor: "description",
        show: false
      },
      {
        Header: "DUE",
        accessor: "dueDate"
      },
      {
        Header: "DAYS DUE",
        accessor: "days",
        show: false
      },
      {
        Header: "POINTS",
        accessor: "points"
      },    
      {
        Header: "STATUS",
        accessor: "status"
      },    
      {
        Header: "",
        accessor: "actions",
        sortable: false,
        filterable: false
      }
    ],  
    dataLoaded: false
  }
  ;
  // Orders Data
  const pendingActions = {
    columns: [  
      {
        Header: "VOL EMAIL",
        accessor: "email"
      },  
      {
        Header: "VOL UID",
        accessor: "volUID"      
      },
      {
        Header: "ACTION ID",
        accessor: "actionId"      
      },
      {
        Header: "TITLE",
        accessor: "title"
      },
      {
        Header: "COMMENT",
        accessor: "comment"     
      },
      {
        Header: "DAYS DUE",
        accessor: "days",
        show: false
      },
      {
        Header: "POINTS",
        accessor: "points"
      },  
      {
        Header: "",
        accessor: "actions",
        sortable: false,
        filterable: false
      }
    ],  
    dataLoaded: false
  };
  
  // Orders Data
  const blogs = {
    columns: [        
      {
        Header: "TITLE",
        accessor: "title"      
      },
      {
        Header: "AUTHOR(S)",
        accessor: "author"      
      },
      {
        Header: "DATE",
        accessor: "date"      
      },
      {
        Header: "LINK",
        accessor: "link"
      }
    ],  
    dataLoaded: false
  };

  const blogComments = {
    columns: [        
      {
        Header: "COMMENTS",
        accessor: "comments"      
      },
    ],  
    dataLoaded: false
  };

  
  export const initState = {
    actions,
    pendingActions,
    blogs
  }
  
  export default (state = initState, action) => {
    switch (action.type) { 
      case 'SET_BLOGS_LIST':
        console.log('SET_BLOGS_LIST')     
        return {
          ...state,
          blogs: {
            ...state.blogs,
            dataRows: action.dataRows,
            dataLoaded: true
          }
        };    
        case 'SET_BLOG_LIST':
          console.log('SET_BLOG_LIST')     
          return {
            ...state,
            blog: {
              ...state.blog,
              dataRows: action.dataRows,
              dataLoaded: true
            }
          };    
  
        case 'SET_BLOGS_COMMENTS':
        console.log('SET_BLOGS_COMMENTS')     
        return {
          ...state,
          blogComments: {
            ...state.blogComments,
            dataRows: action.dataRows,
            dataLoaded: true
          }
        };    
      default:
        return state
    }
  }
  