import {
  container,
  cardTitle,
  dangerColor,
  whiteColor,
  linkText,
  primaryColor
} from "assets/jss/material-dashboard-pro-react.jsx";


import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import cardImagesStyles from "assets/jss/material-dashboard-pro-react/cardImagesStyles.jsx";
import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.jsx";


const songPreviewStyle = theme => ({
  ...modalStyle(theme),
  ...cardImagesStyles,
  container: {
    ...container,
    zIndex: "4",
    // [theme.breakpoints.down("sm")]: {
    //   paddingBottom: "100px"
    // }
  },
  gridContainerDiv: {
    display: "flex", justifyContent: "center", paddingLeft: "150px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px"
      // height: "300px"
    }
  },
  danceFormContainer: {
    maxWidth: "82%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "93%",
      margin: "0px auto",
      // height: "300px"
    }
  },
  formContainer: {
    maxWidth: "none",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "93%",
      // height: "300px"
    }
  },
  formContainers: {
    maxWidth: "none",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      // height: "300px"
    }
  },
  formGridItem: {
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      // height: "600px"
    }
  },
  cardHeader: {
    borderRadius: "10px"
  },
  formDiv: {
    paddingBottom: "140px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0px",
    }
  },
  danceRequestForm: {
    height: "500px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
    }
  },
  requestsForm: {
    height: "400px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
    }
  },
  requestsFormHeader: {
    textAlign: "center",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
      padding: "15px"
    }
  },
  formWithPadding: {
    paddingBottom: "140px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0px",
    }
  },
  previewForm: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px"
    }
  },
  previewFormDiv: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingTop: "35px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "35px"
    }
  },
  songArtistToggle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "40px",
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    }
  },
  formTextField: {
    width: "95%",
    margin: "0px auto"
  },
  arrowBtn: {
    fontSize: "23px",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "35px"
    }
  },
  searchBtn: {
    height: "33px",
    width: "30px"
  },
  lowerOptionsDiv: {
    display: "flex",
    width: "95%",
    alignItems: "center",
    justifyContent: "space-around",
    marginBottom: "15px",
    [theme.breakpoints.down("sm")]: {
      // marginBottom: "-150px",
      paddingTop: "75px"
    }
  },
  cantFind: {
    backgroundColor: "rgb(249, 181, 24)",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgb(255, 217, 128)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "99%"
    }
  },
  skipThis: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgb(249, 181, 24)",
    justifyContent: "space-around",
    "&:hover": {
      // color: primaryColor,
      cursor: "pointer",
      backgroundColor: "rgb(255, 217, 128)",
    }
  },
  searchThroughSpotifyHeader: {
    textAlign: "center",
    color: primaryColor,
    fontSize: "30px",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
    }
  },
  madeAGreatSelection: {
    textTransform: "capitalize",
    fontSize: "25px",
    height: "95%",
    textAlign: "center",
    padding: "50px 30px",
    [theme.breakpoints.down("sm")]: {
      padding: "50px 15px",
      fontSize: "23px",
    }

  },
  cardBody: {
    padding: "20px",
    width: "90%",
    margin: "0px auto",
    background: "#2A2A2A",
    //  height: "calc(150px + 70%)",
    //  overflow: "scroll",
    marginTop: "50px"
  },
  loaderDiv: {
    position: "absolute",
    // width: "100%",
    padding: 0,
    margin: 0,
    zIndex: "99999",
    width: "111vw",
    position: "relative",
    marginLeft: "-56vw",
    left: "43%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "125vw",
    }
  },
  loaderDivText: {
    fontSize: "35px",
    color: "#00C1F3",
    width: "40%",
    marginBottom: "40px",
    textAlign: "center",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    }
  },
  searchCard: {
    display: "flex",
    flexDirection: "revert",
    margin: "20px auto 0px auto",
    padding: "25px",
    width: "95%",
    background: "#2A2A2A",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },


});

export default songPreviewStyle;
