// ##############################
// // // LoginPage view styles
// #############################

import {
  container,
  cardTitle,
  dangerColor,
  whiteColor,
  linkText,
} from "assets/jss/material-dashboard-pro-react.jsx";

const loginPageStyle = (theme) => ({
  container: {
    ...container,
    zIndex: "4",
  },
  formContainer: {
    margin: "0 auto",
  },
  tableCard: {
    height: "100%",
    padding: "20px",
    width: "90%",
    margin: "0px auto",
    background: "#2A2A2A",
    [theme.breakpoints.down("sm")]: {
      width: "109%",
      padding: "0px 20px 20px 20px",
    },
  },
  tableCardKaraokeList: {
    padding: "20px",
    width: "90%",
    margin: "0px auto",
    background: "#2A2A2A",
    height: "75%",
    overflow: "scroll",
    marginTop: "60px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "0px",
      height: "100%",
      overflow: "hidden",
    },
  },
  cardTitle: {
    ...cardTitle,
    color: "#fff",
    fontSize: "24px",
  },
  textCenter: {
    textAlign: "center",
    // fontSize: "20px"
  },
  linkText,
  textDanger: {
    fontSize: "12px",
    color: dangerColor,
    textAlign: "center",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: "#FFFFFF",
    },
    marginLeft: "5px",
    marginRight: "5px",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: whiteColor,
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  cardHeader: {
    margin: "20px",
    padding: "10px 100px",
  },
  socialLine: {
    padding: "0.9375rem 0",
  },
  paperWidth: {
    maxWidth: "none",
    width: "1170px",
  },
  modalTitleRoot: {
    padding: "0px",
    display: "flex",
    justifyContent: "flex-end",
  },
  removeFromQueue: {
    "&:hover": {
      cursor: "pointer",
      color: "red",
    },
  },
  requestsForm: {
    height: "400px",
  },
  requestsFormHeader: {
    textAlign: "center",
    fontWeight: 500,
  },
  formDiv: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  form: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    height: "100%",
  },
  formTextField: {
    width: "60%",
  },
  submitBtn: {
    width: "33%",
    margin: "58px auto 0px auto",
    [theme.breakpoints.down("sm")]: {
      width: "45%",
    },
  },
  overflowHidden: {
    overflow: "hidden",
  },
  headsupModal: {
    [theme.breakpoints.down("sm")]: {
      minWidth: "100% !important",
    },
  },
  addBtn: {
    background: "#00C1F3",
    float: "right",
    color: "white",
    position: "absolute",
    height: "80px",
    width: "80px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "-50px",
      marginTop: "-50px",
    },
  },
  anchoredFab: {
    background: "#00C1F3",
    bottom: "20px",
    right: "20px",
    color: "white",
    position: "fixed",
    width: "146px",
    minHeight: "50px",
    borderRadius: "56px",
    fontSize: "12px",
    paddingLeft: "40px",
    // bottom: "100px",
    display: "flex",
    "&:hover": {
      background: "#80E3FC",
    },
    [theme.breakpoints.down("sm")]: {
      bottom: "20px",
      right: "20px",
      width: "33%",
      maxHeight: "40px",
      borderRadius: "56px",
      paddingLeft: "40px",
      fontSize: "12px",
    },
  },
  anchoredFabEditSong: {
    left: "288px",
    color: "white",
    float: "left",
    width: "10%",
    bottom: "20px",
    display: "flex",
    position: "fixed",
    fontSize: "12px",
    background: "#f9b518",
    minHeight: "50px",
    borderRadius: "56px",
    "&:hover": {
      background: "#f9b518",
    },
    [theme.breakpoints.down("sm")]: {
      left: "20px",
      width: "33%",
      bottom: "80px",
      fontSize: "12px",
      maxHeight: "40px",
      borderRadius: "56px",
      padding: "10px",
    },
  },
  anchoredFabLoggedIn: {
    color: "white",
    right: "20px",
    width: "10%",
    bottom: "20px",
    display: "flex",
    position: "fixed",
    fontSize: "12px",
    background: "#00C1F3",
    minHeight: "50px",
    borderRadius: "56px",
    "&:hover": {
      background: "#80E3FC",
    },
    [theme.breakpoints.down("sm")]: {
      right: "20px",
      width: "33%",
      bottom: "80px",
      fontSize: "12px",
      maxHeight: "40px",
      borderRadius: "56px",
    },
  },
  addNewSingerModal: {
    height: "500px",
    [theme.breakpoints.down("sm")]: {
      height: "500px",
    },
  },
  addNewSingerModalDiv: {
    [theme.breakpoints.down("sm")]: {
      height: "400px",
    },
  },
  pleaseWait: {
    fontSize: "26px",
    color: "#00C1F3",
    width: "100%",
    marginBottom: "40px",
    textAlign: "center",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  loggedInName: {
    marginRight: "40px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "17px",
    },
  },
  threeDots: {
    fontSize: "xx-large",
    float: "right",
    "&:hover": {
      cursor: "pointer",
    },
  },
  popperDiv: {
    display: "flex",
    // height: "85px",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 12px",
    height: "80px",
    "&:hover": {
      borderRadius: "5px",
    },
    // margin: "0px auto",
  },
  popperSelections: {
    height: "50%",
    width: "100%",
    margin: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
    "&:hover": {
      cursor: "pointer",
      background: "#F6F6F7",
    },
  },
  requestsForm: {
    height: "400px",
  },
  requestsFormHeader: {
    textAlign: "center",
    fontWeight: 500,
  },
  loaderImg: {
    width: "30%",
    marginBottom: "50px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  tableRow: {
    fontWeight: 400,
    maxHeight: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "50px",
    },
  },
});

export default loginPageStyle;
