import { db } from "firebase/fbConfig.js";
import axios from "axios";
import qs from "qs";

import { setUserSpotifyToken } from "./userActions";

import { setDownloadRequests } from "./downloadRequestActions.jsx";
import { addNewFavorite } from "./favoritesActions";

export const getSpotifyToken = (value) => {
  const clientId = "6b2e7893e638420ab38ac0d9a305e5de";
  const clientSecret = "ba56db6be2564a95a881259af3dbc897";
  const headers = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    auth: {
      username: clientId,
      password: clientSecret,
    },
  };
  const data = {
    grant_type: "client_credentials",
  };
  return (dispatch) => {
    axios
      .post(
        "https://accounts.spotify.com/api/token",
        qs.stringify(data),
        headers
      )
      .then((response) => {
        var token = response.data.access_token;
        if (value === "user") {
          dispatch(setUserSpotifyToken(token));
          dispatch(getSongList(token));
        } else {
          dispatch(getSongList(token));
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};

export const getJustDatabaseSongList = () => {
  return (dispatch) => {
    return db
      .collection("test-songs")
      .get()
      .then((results) => {
        results = results.docs;
        var toReturn = [];
        results.forEach((doc) => {
          var id = doc.id;
          doc = doc.data();
          doc.id = id;
          toReturn.push(doc);
        });
        dispatch(setDatabaseSongList(toReturn));
      });
  };
};

export const deleteCollectionSong = (id, allSongs) => {
  return async (dispatch) => {
    await db.collection("test-songs").doc(id).delete();
    console.log("before", allSongs.length);
    const filteredSongs = allSongs.filter((s) => s.id !== id);
    console.log("after", filteredSongs.length);
    dispatch(setDatabaseSongList(filteredSongs));
  };
};

export const setDatabaseSongList = (data) => {
  return {
    type: "SET_DATABASESONGLIST",
    data,
  };
};

export const getSongList = (token) => {
  return (dispatch) => {
    return db
      .collection("test-songs")
      .get()
      .then((results) => {
        results = results.docs;
        var toReturn = [];
        results.forEach((doc) => {
          var id = doc.id;
          doc = doc.data();
          doc.id = id;
          toReturn.push(doc);
        });
        dispatch(setSongListAndToken(token, toReturn));
      });
  };
};

export const addSongToExistingUserInQueue = (
  id,
  songChoice,
  str,
  previewUrl
) => {
  return (dispatch) => {
    return db
      .collection("queue")
      .doc(id)
      .get()
      .then((results) => {
        results = results.data();
        results.songChoice = songChoice;
        var positionInQueue = results.positionInQueue;
        var name = results.displayName;
        var uid;
        if (results.uid) {
          uid = results.uid;
        }
        return db
          .collection("queue")
          .doc(id)
          .set(results)
          .then(() => {
            if (str === "updatingUserSong") {
              var userDoc = db
                .collection("TEST-users")
                .doc(uid)
                .collection("user-songs")
                .doc();
              var userDocId = userDoc.id;
              var toReturnUser = {
                queueId: id,
                songChoice: {
                  song: songChoice.song,
                  artist: songChoice.artist,
                },
                userSongId: userDocId,
                downloadRequest: false,
                downloadStatus: "Downloaded",
              };
              return db
                .collection("TEST-users")
                .doc(uid)
                .collection("user-songs")
                .doc(userDocId)
                .set(toReturnUser)
                .then(() => {
                  dispatch(setAddedSongToExistingUserInQueue());
                  dispatch(addNewFavorite(uid, songChoice));
                });
            }

            if (str === "updatingUserSongWithSpotify") {
              var requestsDoc = db.collection("requests").doc();
              let userDoc = db
                .collection("TEST-users")
                .doc(uid)
                .collection("user-songs")
                .doc();
              let userDocId = userDoc.id;
              let toReturnUser = {
                queueId: id,
                songChoice: {
                  song: songChoice.song,
                  artist: songChoice.artist,
                },
                userSongId: userDocId,
                downloadRequest: true,
                downloadStatus: "Pending",
                requestId: requestsDoc.id,
              };
              return db
                .collection("TEST-users")
                .doc(uid)
                .collection("user-songs")
                .doc(userDocId)
                .set(toReturnUser)
                .then(() => {
                  var toReturnRequest = {};
                  toReturnRequest.songRequest = songChoice;
                  toReturnRequest.requestedBy = name;
                  toReturnRequest.positionInQueue = positionInQueue;
                  toReturnRequest.previewUrl = previewUrl;
                  toReturnRequest.queueId = id;
                  toReturnRequest.downloadStatus = "Pending";
                  toReturnRequest.uid = uid;
                  toReturnRequest.userSongId = userDocId;
                  toReturnRequest.requestId = requestsDoc.id;
                  return db
                    .collection("requests")
                    .doc(requestsDoc.id)
                    .set({ ...toReturnRequest })
                    .then(() => {
                      dispatch(setAddedSongToBeDownloadRequested());
                      dispatch(setAddedSongToExistingUserInQueue());
                    });
                });
            }

            if (!str) {
              dispatch(setAddedSongToExistingUserInQueue());
              dispatch(addNewFavorite(uid, songChoice));
            }
            // });
          });
      });
  };
};

export const addSpotifySongToDownloadRequest = (id, songChoice, previewUrl) => {
  return (dispatch) => {
    return db
      .collection("queue")
      .doc(id)
      .get()
      .then((results) => {
        results = results.data();
        var toReturn = {};
        toReturn.songRequest = songChoice;
        toReturn.requestedBy = results.displayName;
        toReturn.positionInQueue = results.positionInQueue;
        toReturn.previewUrl = previewUrl;
        toReturn.queueId = id;
        toReturn.downloadStatus = "Pending";
        var requestsDoc = db.collection("requests").doc();
        var requestId = requestsDoc.id;
        toReturn.requestId = requestId;
        return db
          .collection("requests")
          .doc(requestId)
          .set({ ...toReturn })
          .then(() => {
            dispatch(setAddedSongToBeDownloadRequested());
          });
      });
  };
};

export const addSpotifySongToDownloadRequestLoggedInUser = (
  id,
  songChoice,
  previewUrl,
  uid,
  userName,
  data
) => {
  const queueRef = db.collection("queue");
  var queueBatch = db.batch();
  return (dispatch) => {
    var doesUserExistAlready = false;
    var userWhoExists;
    data.forEach((doc) => {
      if (doc.uid && doc.uid === uid) {
        doesUserExistAlready = true;
        userWhoExists = doc;
      }
    });
    if (doesUserExistAlready) {
      var requestsDoc = db.collection("requests").doc();
      var userDoc = db
        .collection("TEST-users")
        .doc(uid)
        .collection("user-songs")
        .doc();
      var userDocId = userDoc.id;
      return db
        .collection("queue")
        .doc(id)
        .update({
          songChoice: {
            song: songChoice.song,
            artist: songChoice.artist,
            requestId: requestsDoc.id,
          },
          userSongId: userDocId,
        })
        .then(() => {
          var toReturnRequest = {};
          toReturnRequest.songRequest = songChoice;
          toReturnRequest.requestedBy = userName;
          toReturnRequest.positionInQueue = data.length;
          toReturnRequest.previewUrl = previewUrl ? previewUrl : "N/A";
          toReturnRequest.queueId = id;
          toReturnRequest.downloadStatus = "Pending";
          toReturnRequest.uid = uid;
          toReturnRequest.userSongId = userDocId;
          toReturnRequest.requestId = requestsDoc.id;
          return db
            .collection("requests")
            .doc(requestsDoc.id)
            .set({ ...toReturnRequest })
            .then(() => {
              if (!userWhoExists.songChoice) {
                userWhoExists.songChoice = {
                  song: songChoice.song,
                  artist: songChoice.artist,
                  requestId: requestsDoc.id,
                };
              } else {
                userWhoExists.songChoice.song = songChoice.song;
                userWhoExists.songChoice.artist = songChoice.artist;
                userWhoExists.songChoice.requestId = requestsDoc.id;
              }
              userWhoExists.userSongId = userDocId;
              data.forEach((doc) => {
                if (doc.id === userWhoExists.id) {
                  doc = userWhoExists;
                }
              });
              dispatch(setLoggedInQueueAndRequest(data));
            });
        });
    } else {
      var queueDoc = db.collection("queue").doc();
      let requestsDoc = db.collection("requests").doc();
      var queueId = queueDoc.id;
      let userDoc = db
        .collection("TEST-users")
        .doc(uid)
        .collection("user-songs")
        .doc();
      let userDocId = userDoc.id;
      var toReturnUser = {
        queueId: queueId,
        requestId: requestsDoc.id,
        hasSung: false,
        songChoice: { song: songChoice.song, artist: songChoice.artist },
        userSongId: userDocId,
        downloadRequest: true,
        downloadStatus: "Pending",
      };
      return db
        .collection("TEST-users")
        .doc(uid)
        .collection("user-songs")
        .doc(userDocId)
        .set(toReturnUser)
        .then(() => {
          var toReturnRequest = {};
          toReturnRequest.songRequest = songChoice;
          toReturnRequest.requestedBy = userName;
          toReturnRequest.positionInQueue = data.length;
          toReturnRequest.previewUrl = previewUrl;
          toReturnRequest.queueId = queueId;
          toReturnRequest.downloadStatus = "Pending";
          toReturnRequest.uid = uid;
          toReturnRequest.userSongId = userDocId;
          toReturnRequest.requestId = requestsDoc.id;
          return db
            .collection("requests")
            .doc(requestsDoc.id)
            .set({ ...toReturnRequest })
            .then(() => {
              var toReturn = {
                uid: uid,
                id: queueId,
                hasSung: false,
                displayName: userName,
                userSongId: userDocId,
                positionInQueue: data.length,
                songChoice: {
                  song: songChoice.song,
                  artist: songChoice.artist,
                  requestId: requestsDoc.id,
                },
              };

              if (data.length >= 12) {
                if (
                  data[data.length - 1].hasSung &&
                  data[data.length - 2].hasSung
                ) {
                  var indexOfFirstOccurenceWhereSingerHasNotSung = 10;
                  for (var x = data.length - 1; x >= 0; x--) {
                    if (!data[x].hasSung && x >= 10) {
                      indexOfFirstOccurenceWhereSingerHasNotSung = x;
                      break;
                    }
                  }
                  var positionToRemember;
                  if (indexOfFirstOccurenceWhereSingerHasNotSung === 10) {
                    positionToRemember = 12;
                  } else {
                    positionToRemember =
                      data[indexOfFirstOccurenceWhereSingerHasNotSung + 2]
                        .positionInQueue;
                  }
                  toReturn.positionInQueue = positionToRemember;
                  var newSingerDocRef = queueRef.doc(toReturn.id);
                  queueBatch.set(newSingerDocRef, toReturn);

                  for (var y = 0; y < data.length; y++) {
                    if (y >= indexOfFirstOccurenceWhereSingerHasNotSung + 2) {
                      data[y].positionInQueue += 1;
                      var docRef = queueRef.doc(data[y].id);
                      queueBatch.set(docRef, data[y]);
                    }
                  }
                  data.push(toReturn);
                  data.sort((a, b) =>
                    a.positionInQueue > b.positionInQueue ? 1 : -1
                  );
                  queueBatch.commit().then(() => {
                    dispatch(setLoggedInQueueAndRequest(data));
                  });
                } else {
                  return db
                    .collection("queue")
                    .doc(queueId)
                    .set(toReturn)
                    .then(() => {
                      data.push(toReturn);
                      dispatch(setLoggedInQueueAndRequest(data));
                    });
                }
              } else {
                return db
                  .collection("queue")
                  .doc(queueId)
                  .set(toReturn)
                  .then(() => {
                    data.push(toReturn);
                    dispatch(setLoggedInQueueAndRequest(data));
                  });
              }
            });
        });
    }
  };
};

export const skipAddSongToQueue = () => {
  return (dispatch) => {
    dispatch(setAddedSongToExistingUserInQueue());
  };
};

export const addDownloadRequest = (
  queueId,
  { previewUrl, ...songChoice },
  UID
) => {
  return async (dispatch) => {
    let userQueueId = queueId;
    const userDoc = await db.collection("TEST-users").doc(UID).get();
    const { displayName } = userDoc.data();
    if (!userQueueId) {
      let queueSnapshot = await db.collection("queue").get();
      var data = [];
      queueSnapshot.docs.forEach((doc) => {
        var id = doc.id;
        var newDoc = doc.data();
        newDoc.id = id;
        newDoc.displayName =
          newDoc.displayName.charAt(0).toUpperCase() +
          newDoc.displayName.slice(1);
        data.push(newDoc);
      });
      const queueDoc = await db.collection("queue").doc();
      userQueueId = queueDoc.id;
      await db
        .collection("queue")
        .doc(userQueueId)
        .set({
          displayName,
          hasSung: false,
          id: userQueueId,
          songChoice: { artist: "N/A", song: "N/A" },
          uid: UID,
          positionInQueue: data.length,
        });
    }
    var toReturn = {
      queueId: userQueueId,
      previewUrl,
      songRequest: songChoice,
      requestedBy: displayName,
      downloadStatus: "Pending",
    };
    var requestsDoc = db.collection("requests").doc();
    const userSongDoc = db
      .collection("TEST-users")
      .doc(UID)
      .collection("user-songs")
      .doc();
    var requestId = requestsDoc.id;
    toReturn.requestId = requestId;
    await db
      .collection("requests")
      .doc(requestId)
      .set({ ...toReturn, uid: UID, userSongId: userSongDoc.id });
    await db
      .collection("TEST-users")
      .doc(UID)
      .collection("user-songs")
      .doc(userSongDoc.id)
      .set({
        queueId: userQueueId,
        songChoice: {
          song: songChoice.song,
          artist: songChoice.artist,
        },
        userSongId: userSongDoc.id,
        downloadRequest: true,
        downloadStatus: "Pending",
        requestId,
        uid: UID,
      });

    dispatch(setAddedSongToBeDownloadRequested());
  };
};

export const editSingerInQueue = (singerId, singer) => {
  return (dispatch) => {
    return db
      .collection("queue")
      .doc(singerId)
      .update({
        songChoice: {
          song: singer.songChoice.song,
          artist: singer.songChoice.artist,
        },
        downloadStatus: "Downloaded",
        displayName: singer.displayName,
      })
      .then(() => {
        dispatch(setEditedSingerInQueue());
      });
  };
};

export const setEditedSingerInQueue = () => {
  return {
    type: "SET_EDITED_SINGER_IN_QUEUE",
  };
};

export const changingPositionsInQueue = (direction, singer, index) => {
  const ref = db.collection("queue");
  var batch = db.batch();
  return (dispatch) => {
    dispatch(settingSingerMoving(true));
    return ref
      .orderBy("positionInQueue")
      .get()
      .then((results) => {
        results = results.docs;
        var toReturn = [];
        results.forEach((doc) => {
          var docId = doc.id;
          doc = doc.data();
          doc.id = docId;
          var docRef = ref.doc(doc.id);
          batch.set(docRef, doc);
          toReturn.push(doc);
        });

        if (direction === "up") {
          toReturn[index].positionInQueue -= 1;
          toReturn[index - 1].positionInQueue += 1;
          var currentRef = ref.doc(toReturn[index].id);
          var prevRef = ref.doc(toReturn[index - 1].id);
          batch.set(currentRef, toReturn[index]);
          batch.set(prevRef, toReturn[index - 1]);
        }
        if (direction === "down") {
          toReturn[index].positionInQueue += 1;
          toReturn[index + 1].positionInQueue -= 1;
          let currentRef = ref.doc(toReturn[index].id);
          var nextRef = ref.doc(toReturn[index + 1].id);
          batch.set(currentRef, toReturn[index]);
          batch.set(nextRef, toReturn[index + 1]);
        }

        batch.commit().then(() => {
          dispatch(settingSingerMoving(false));
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};

export const deleteSelfFromQueue = (selfData, allData) => {
  const queueId = selfData.id;
  const uid = selfData.uid;
  const userSongId = selfData.userSongId;
  const selfPositionInQueue = selfData.positionInQueue;
  selfData.hasSung = true;
  return (dispatch) => {
    if (selfData.songChoice && selfData.songChoice.requestId) {
      const requestId = selfData.songChoice.requestId;
      return db
        .collection("requests")
        .doc(requestId)
        .delete()
        .then(() => {
          return db
            .collection("queue")
            .doc(queueId)
            .delete()
            .then(() => {
              // return db
              //   .collection("queue-archive")
              //   .doc(queueId)
              //   .delete()
              //   .then(() => {
              return db
                .collection("TEST-users")
                .doc(uid)
                .collection("user-songs")
                .doc(userSongId)
                .delete()
                .then(() => {
                  return db
                    .collection("Position-Cache")
                    .doc(uid)
                    .set(selfData)
                    .then(() => {
                      var otherUserPositionsPromiseArray = [];
                      allData.forEach((d) => {
                        if (d.positionInQueue > selfPositionInQueue) {
                          d.positionInQueue--;
                          otherUserPositionsPromiseArray.push(
                            db.collection("queue").doc(d.id).update({
                              positionInQueue: d.positionInQueue,
                            })
                          );
                        }
                      });
                      Promise.all(otherUserPositionsPromiseArray).then(() => {
                        dispatch(setNewQueue(allData));
                        dispatch(hideUpNext());
                      });
                    });
                });
            });
        });
    } else {
      return db
        .collection("queue")
        .doc(queueId)
        .delete()
        .then(() => {
          if (userSongId) {
            return db
              .collection("TEST-users")
              .doc(uid)
              .collection("user-songs")
              .doc(userSongId)
              .delete()
              .then(() => {
                return db
                  .collection("Position-Cache")
                  .doc(uid)
                  .set(selfData)
                  .then(() => {
                    var otherUserPositionsPromiseArray = [];
                    allData.forEach((d) => {
                      if (d.positionInQueue > selfPositionInQueue) {
                        d.positionInQueue--;
                        otherUserPositionsPromiseArray.push(
                          db.collection("queue").doc(d.id).update({
                            positionInQueue: d.positionInQueue,
                          })
                        );
                      }
                    });
                    Promise.all(otherUserPositionsPromiseArray).then(() => {
                      dispatch(setNewQueue(allData));
                      dispatch(hideUpNext());
                    });
                    dispatch(setNewQueue(allData));
                    dispatch(hideUpNext());
                  });
              });
          } else {
            return db
              .collection("Position-Cache")
              .doc(uid)
              .set(selfData)
              .then(() => {
                var otherUserPositionsPromiseArray = [];
                allData.forEach((d) => {
                  if (d.positionInQueue > selfPositionInQueue) {
                    d.positionInQueue--;
                    otherUserPositionsPromiseArray.push(
                      db.collection("queue").doc(d.id).update({
                        positionInQueue: d.positionInQueue,
                      })
                    );
                  }
                });
                Promise.all(otherUserPositionsPromiseArray).then(() => {
                  dispatch(setNewQueue(allData));
                  dispatch(hideUpNext());
                });
                dispatch(setNewQueue(allData));
                dispatch(hideUpNext());
              });
          }
          // });
        });
    }
  };
};

export const deleteFromQueue = (singer) => {
  const id = singer.id;
  const ref = db.collection("queue");
  const requestsRef = db.collection("requests");
  const queueArchiveRef = db.collection("queue-archive");
  var batch = db.batch();
  return async (dispatch) => {
    await queueArchiveRef.doc(id).set(singer);
    await ref.doc(id).delete();
    let currentRequests = await requestsRef.get();
    var requestsBatch = db.batch();
    const currentQueue = await ref.orderBy("positionInQueue").get();

    var toReturn = [];
    currentQueue.docs.forEach((doc, index) => {
      var docId = doc.id;
      doc = doc.data();
      doc.id = docId;
      doc.positionInQueue = index;
      var docRef = ref.doc(doc.id);
      batch.set(docRef, doc);
      toReturn.push(doc);
    });
    await batch.commit();
    if (currentRequests.docs.length === 0) {
      dispatch(setNewQueue(toReturn));
    } else {
      const currentRequestQuery = await requestsRef
        .where("queueId", "==", id)
        .get();
      if (!currentRequestQuery.empty) {
        await requestsRef.doc(currentRequestQuery.docs[0].id).delete();
        currentRequests = await requestsRef.get();
      }
      currentRequests.docs.forEach((doc, index) => {
        var docId = doc.id;
        doc = doc.data();
        doc.id = docId;
        doc.positionInQueue = index;
        var docRef = requestsRef.doc(doc.id);
        requestsBatch.set(docRef, doc);
      });
      await requestsBatch.commit();
      dispatch(setNewQueue(toReturn));
    }
  };
};

export const setUserHasSung = (singerId, currentSinger, tableData) => {
  var batch = db.batch();
  const ref = db.collection("queue");
  const requestsRef = db.collection("requests");
  return async (dispatch) => {
    let currentRequests = await requestsRef.get();
    var requestsBatch = db.batch();

    await ref.doc(singerId).update({
      positionInQueue: tableData.length,
      hasSung: true,
      songChoice: false,
    });

    const currentQueue = await ref.orderBy("positionInQueue").get();
    var toReturn = [];
    currentQueue.docs.forEach((doc, index) => {
      var docId = doc.id;
      doc = doc.data();
      doc.id = docId;
      doc.positionInQueue = index;
      var docRef = ref.doc(doc.id);
      batch.set(docRef, doc);
      toReturn.push(doc);
    });
    await batch.commit();

    if (currentRequests.docs.length === 0) {
      dispatch(setNewQueue(toReturn));
    } else {
      const currentRequestQuery = await requestsRef
        .where("queueId", "==", singerId)
        .get();
      if (!currentRequestQuery.empty) {
        await requestsRef.doc(currentRequestQuery.docs[0].id).delete();
        currentRequests = await requestsRef.get();
      }
      currentRequests.docs.forEach((doc, index) => {
        var docId = doc.id;
        doc = doc.data();
        doc.id = docId;
        doc.positionInQueue = index;
        var docRef = requestsRef.doc(doc.id);
        requestsBatch.set(docRef, doc);
      });

      dispatch(setEditing(tableData));
    }
  };
};

export const deleteAllInArchiveOld = () => {
  const ref = db.collection("queue-archive");
  var batch = db.batch();
  return (dispatch) => {
    return ref.get().then((results) => {
      results = results.docs;
      results.forEach((doc) => {
        var docId = doc.id;
        doc = doc.data();
        var docRef = ref.doc(docId);
        batch.delete(docRef);
      });
      batch.commit().then(() => {
        dispatch(setArchivedQueue([]));
      });
    });
  };
};

export const deleteAllInArchive = async () => {

  const ref = db.collection("queue-archive");
  var batch = db.batch();

  const queueArchiveSnapshot = await ref.get();
  if (!queueArchiveSnapshot.empty) {
    const queueArchiveDocs = queueArchiveSnapshot.docs;
    queueArchiveDocs.forEach((d) => {
      batch.delete(ref.doc(d.id));
    });
    await batch.commit();
  }
}

export const deleteAllInPositionCacheOld = async () => {
  const positionCacheSnapshot = await db.collection("Position-Cache").get();

  var deleteAllInPositionCachePromiseArray = [];
  if (!positionCacheSnapshot.empty) {
    const positionCacheDocs = positionCacheSnapshot.docs;
    positionCacheDocs.forEach((d) => {
      deleteAllInPositionCachePromiseArray.push(
        db.collection("Position-Cache").doc(d.id).delete()
      );
    });
    await Promise.all(deleteAllInPositionCachePromiseArray);
    return;
  }
};

export const deleteAllInPositionCache = async () => {
  const ref = db.collection("Position-Cache");
  const snapshot = await ref.get();

  if (!snapshot.empty) {
    const batch = db.batch();
    snapshot.docs.forEach((d) => {
      batch.delete(ref.doc(d.id));
    });
    await batch.commit();
  }
};

export const deleteAllOld = () => {
  const ref = db.collection("queue");
  var batch = db.batch();
  return async (dispatch) => {
    const queueSnapshot = await ref.get();
    const results = queueSnapshot.docs;
    results.forEach((doc) => {
      var docId = doc.id;
      doc = doc.data();
      var docRef = ref.doc(docId);
      batch.delete(docRef);
    });
    await batch.commit();
    dispatch(setQueue([]));
    const requestsRef = db.collection("requests");
    var requestsBatch = db.batch();
    const requestsSnapshot = await requestsRef.get();
    const requestsResults = requestsSnapshot.docs;
    if (requestsResults.length > 0) {
      requestsResults.forEach((doc) => {
        var docId = doc.id;
        doc = doc.data();
        var docRef = requestsRef.doc(docId);
        requestsBatch.delete(docRef);
      });
      await requestsBatch.commit();
    }
    await deleteAllInPositionCache();
    dispatch(setDownloadRequests([]));
  };
};

export const deleteAllInQueue = async () => {

  const ref = db.collection("queue");
  const batch = db.batch();
  const queueSnapshot = await ref.get();
  const queueDocs = queueSnapshot.docs;

  if (queueDocs && (!queueDocs.empty)) {
    queueDocs.forEach((d) => {
      batch.delete(ref.doc(d.id));
    });
    await batch.commit();
  }

  await deleteAllInPositionCache();
}


export const addNewSingerAndSong = (newSinger) => {
  const queueRef = db.collection("queue");
  const queueBatch = db.batch();

  return async (dispatch) => {
    const currentQueueSnapshot = await queueRef
      .orderBy("positionInQueue")
      .get();
    const currentQueueData = currentQueueSnapshot.docs.map((doc, index) => ({
      ...doc.data(),
      id: doc.id,
      positionInQueue: index,
    }));

    if (currentQueueData.length >= 12) {
      if (
        currentQueueData[currentQueueData.length - 1].hasSung &&
        currentQueueData[currentQueueData.length - 2].hasSung
      ) {
        let indexOfFirstOccurrenceWhereSingerHasNotSung = 10;
        for (let x = currentQueueData.length - 1; x >= 0; x--) {
          if (!currentQueueData[x].hasSung && x >= 10) {
            indexOfFirstOccurrenceWhereSingerHasNotSung = x;
            break;
          }
        }

        let positionToRemember =
          indexOfFirstOccurrenceWhereSingerHasNotSung === 10
            ? 12
            : currentQueueData[indexOfFirstOccurrenceWhereSingerHasNotSung + 2]
                .positionInQueue;

        newSinger.positionInQueue = positionToRemember;
        let newSingerDocRef = queueRef.doc(newSinger.id);
        queueBatch.set(newSingerDocRef, newSinger);

        for (let y = 0; y < currentQueueData.length; y++) {
          if (y >= indexOfFirstOccurrenceWhereSingerHasNotSung + 2) {
            currentQueueData[y].positionInQueue += 1;
            let docRef = queueRef.doc(currentQueueData[y].id);
            queueBatch.set(docRef, currentQueueData[y]);
          }
        }

        currentQueueData.push(newSinger);
        currentQueueData.sort((a, b) =>
          a.positionInQueue > b.positionInQueue ? 1 : -1
        );

        await queueBatch.commit();
        dispatch(setQueue(currentQueueData));
      } else {
        await queueRef.doc(newSinger.id).set(newSinger);
        currentQueueData.push(newSinger);
        dispatch(setQueue(currentQueueData));
      }
    } else {
      await queueRef.doc(newSinger.id).set(newSinger);
      currentQueueData.push(newSinger);
      dispatch(setQueue(currentQueueData));
    }
  };
};

export const setNewOrderForQueue = (newQueue) => {
  return async (dispatch) => {
    var batch = db.batch();
    const ref = db.collection("queue");
    newQueue.forEach((doc, index) => {
      var docRef = ref.doc(doc.id);
      doc.positionInQueue = index;
      batch.set(docRef, doc);
    });
    await batch.commit();
    dispatch(setEditing(newQueue));
  };
};

export const addToQueue = (inputs, data) => {
  inputs.displayName =
    inputs.displayName.charAt(0).toUpperCase() + inputs.displayName.slice(1);
  inputs.hasSung = false;
  var queueBatch = db.batch();
  const ref = db.collection("queue");
  data[0].displayName =
    data[0].displayName.charAt(0).toUpperCase() + data[0].displayName.slice(1);
  return (dispatch) => {
    return db
      .collection("queue")
      .orderBy("positionInQueue")
      .get()
      .then((results) => {
        if (results.empty) {
          inputs.positionInQueue = 0;
          var doc = db.collection("queue").doc();
          return doc.set(inputs).then(() => {
            dispatch(setAddedToQueue(data, doc.id));
          });
        } else {
          results = results.docs;
          var toReturn = [];
          var userNameAlreadyExists = false;
          results.forEach((doc) => {
            var id = doc.id;
            doc = doc.data();
            if (
              doc.displayName.toLowerCase() === inputs.displayName.toLowerCase()
            ) {
              userNameAlreadyExists = true;
            }
            doc.id = id;
            toReturn.push(doc);
          });
          var numberOfSingers = toReturn.length;
          if (userNameAlreadyExists) {
            dispatch(setNameAlreadyExists(true));
          } else {
            const document = db.collection("queue").doc();
            if (numberOfSingers < 12) {
              inputs.positionInQueue = numberOfSingers;
              return document.set(inputs).then(() => {
                dispatch(setAddedToQueue(data, document.id));
              });
            } else {
              if (
                toReturn[numberOfSingers - 1].hasSung &&
                toReturn[numberOfSingers - 2].hasSung
              ) {
                var indexOfFirstOccurenceWhereSingerHasNotSung = 10;
                for (var x = toReturn.length - 1; x >= 0; x--) {
                  if (!toReturn[x].hasSung && x >= 10) {
                    indexOfFirstOccurenceWhereSingerHasNotSung = x;
                    break;
                  }
                }
                var positionToRemember;
                if (indexOfFirstOccurenceWhereSingerHasNotSung === 10) {
                  positionToRemember = 12;
                } else {
                  positionToRemember =
                    data[indexOfFirstOccurenceWhereSingerHasNotSung + 2]
                      .positionInQueue;
                }
                inputs.positionInQueue = positionToRemember;
                inputs.id = document.id;
                var newSingerDocRef = ref.doc(inputs.id);
                queueBatch.set(newSingerDocRef, inputs);

                for (var y = 0; y < toReturn.length; y++) {
                  if (y >= indexOfFirstOccurenceWhereSingerHasNotSung + 2) {
                    toReturn[y].positionInQueue += 1;
                    var docRef = ref.doc(toReturn[y].id);
                    queueBatch.set(docRef, toReturn[y]);
                  }
                }
                toReturn.push(inputs);
                toReturn.sort((a, b) =>
                  a.positionInQueue > b.positionInQueue ? 1 : -1
                );

                queueBatch.commit().then(() => {
                  dispatch(setAddedToQueue(toReturn, document.id));
                });
              } else {
                inputs.positionInQueue = numberOfSingers;
                inputs.id = document.id;
                return document.set(inputs).then(() => {
                  dispatch(setAddedToQueue(data, document.id));
                });
              }
            }
          }
        }
      });
  };
};

export const moveToTopInQueue = (toReturnData) => {
  var batch = db.batch();
  const ref = db.collection("queue");
  return (dispatch) => {
    toReturnData.forEach((doc) => {
      var docRef = ref.doc(doc.id);
      batch.set(docRef, doc);
    });
    batch.commit().then(() => {
      dispatch(setEditing(toReturnData));
    });
  };
};

export const setNameAlreadyExists = (boolean) => {
  return {
    type: "SET_NAMEALREADYEXISTS",
    boolean,
  };
};

export const searchDatabaseForSong = () => {
  return () => {};
};

export const setArchivedQueue = (data) => {
  return {
    type: "SET_ARCHIVED_QUEUE",
    data,
  };
};

export const getQueue = (uid) => {
  const ref = db.collection("queue");
  return (dispatch) => {
    return ref.orderBy("positionInQueue").onSnapshot((snapshot) => {
      snapshot = snapshot.docs;
      var data = [];
      snapshot.forEach((doc) => {
        var id = doc.id;
        var newDoc = doc.data();
        newDoc.id = id;
        newDoc.displayName =
          newDoc.displayName.charAt(0).toUpperCase() +
          newDoc.displayName.slice(1);
        data.push(newDoc);
      });
      dispatch(setQueue(data, uid));
    });
  };
};

export const getArchivedQueue = () => {
  const archiveRef = db.collection("queue-archive");
  return (dispatch) => {
    return archiveRef.orderBy("positionInQueue").onSnapshot((snapshot) => {
      snapshot = snapshot.docs;
      var data = [];
      snapshot.forEach((doc) => {
        var id = doc.id;
        var newDoc = doc.data();
        newDoc.id = id;
        newDoc.displayName =
          newDoc.displayName.charAt(0).toUpperCase() +
          newDoc.displayName.slice(1);
        data.push(newDoc);
      });
      dispatch(setArchivedQueue(data));
    });
  };
};

export const addSingerBackToQueue = (singer) => {
  return async (dispatch) => {
    const queueRef = db.collection("queue");
    const currentQueueSnapshot = await queueRef
      .orderBy("positionInQueue")
      .get();
    const currentQueueData = currentQueueSnapshot.docs.map((doc, index) => ({
      ...doc.data(),
      id: doc.id,
      positionInQueue: index,
    }));

    singer.positionInQueue = currentQueueData.length;
    currentQueueData.push(singer);
    console.log("🚀 ~ return ~ currentQueueData:", currentQueueData);
    await db.collection("queue").doc(singer.id).set(singer);
    await db.collection("queue-archive").doc(singer.id).delete();
    dispatch(setEditing(currentQueueData));
  };
};

export const loggedInUserAddToQueue = (song, userName, UID) => {
  return async (dispatch) => {
    const userDocRef = db
      .collection("TEST-users")
      .doc(UID)
      .collection("user-songs")
      .doc();
    const userDocId = userDocRef.id;

    const queueRef = db.collection("queue");
    const queueBatch = db.batch();

    const currentQueueSnapshot = await queueRef
      .orderBy("positionInQueue")
      .get();
    const currentQueueData = currentQueueSnapshot.docs.map((doc, index) => ({
      ...doc.data(),
      id: doc.id,
      positionInQueue: index,
    }));

    const userExists = currentQueueData.some(
      (doc) => doc.uid && doc.uid === UID
    );
    if (userExists) {
      dispatch(setNameAlreadyExists(true));
      dispatch(setLoggedInUserAddToQueueModal(false));
      return;
    }

    const queueDocRef = queueRef.doc();
    const queueId = queueDocRef.id;

    let positionToRemember = currentQueueData.length;
    if (currentQueueData.length >= 12) {
      const lastTwoSung = currentQueueData
        .slice(-2)
        .every((doc) => doc.hasSung);
      if (!lastTwoSung) {
        positionToRemember = currentQueueData.findIndex(
          (doc) => !doc.hasSung && doc.positionInQueue >= 10
        );
        positionToRemember =
          positionToRemember === -1
            ? 12
            : currentQueueData[positionToRemember + 2].positionInQueue;
      }
    }

    const toReturn = {
      uid: UID,
      id: queueId,
      hasSung: false,
      displayName: userName,
      positionInQueue: positionToRemember,
      userSongId: userDocId,
      songChoice: { song: song.song, artist: song.artist },
    };

    currentQueueData.push(toReturn);
    currentQueueData.sort((a, b) =>
      a.positionInQueue > b.positionInQueue ? 1 : -1
    );

    queueBatch.set(queueDocRef, toReturn);
    for (let i = positionToRemember + 2; i < currentQueueData.length; i++) {
      currentQueueData[i].positionInQueue++;
      queueBatch.set(queueRef.doc(currentQueueData[i].id), currentQueueData[i]);
    }

    await queueBatch.commit();

    const toReturnUser = {
      hasSung: false,
      songChoice: { song: song.song, artist: song.artist },
      userSongId: userDocId,
      downloadRequest: false,
      downloadStatus: "Accepted",
    };

    await userDocRef.set(toReturnUser);

    dispatch(setLoggedInQueue(currentQueueData));
    dispatch(addNewFavorite(UID, song));
  };
};

export const setAddedToQueue = (data, uniqueId) => {
  return {
    type: "SET_ADDEDTOQUEUE",
    data,
    uniqueId,
  };
};

export const settingSingerMoving = (boolean) => {
  return {
    type: "SETTING_SINGER_MOVING",
    boolean,
  };
};

export const setEditing = (toReturnData) => {
  return {
    type: "SET_EDITING",
    toReturnData,
  };
};

export const setLoggedInQueue = (data) => {
  return {
    type: "SET_LOGGEDINQUEUE",
    data,
  };
};

export const setLoggedInQueueAndRequest = (data) => {
  return {
    type: "SET_LOGGEDINQUEUEANDREQUEST",
    data,
  };
};

export const setLoggedInUserAddToQueueModal = (boolean) => {
  return {
    type: "setLoggedInUserAddToQueueModal",
    boolean,
  };
};

export const setLoggedOutAddToQueueModal = (boolean) => {
  return {
    type: "SET_LOGGED_OUT_USER_ADD_TO_QUEUE_MODAL",
    boolean,
  };
};

export const resetSubmitUserForm = () => {
  return {
    type: "RESET_SUBMIT_USER_FORM",
  };
};

export const setQueue = (data, uid) => {
  return {
    type: "SET_QUEUE",
    data,
    uid,
  };
};

export const setNewQueue = (data) => {
  return {
    type: "SET_NEWQUEUE",
    data,
  };
};

export const hideUpNext = () => {
  return {
    type: "HIDE_UP_NEXT",
  };
};

export const setAddedSongToBeDownloadRequested = () => {
  return {
    type: "SET_ADDEDSONGTOBEDOWNLOADREQUESTED",
  };
};

export const setAddedSongToExistingUserInQueue = () => {
  return {
    type: "SET_ADDEDSONGTOEXISTINGUSERINQUEUE",
  };
};

export const setSongListAndToken = (token, songList) => {
  return {
    type: "SET_SONGLISTANDTOKEN",
    token,
    songList,
  };
};

export const setUserCreatedFalse = () => {
  return {
    type: "SET_USERCREATEDFALSE",
  };
};
