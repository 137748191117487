import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { FooterText } from "./FooterText";

import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PersonIcon from '@material-ui/icons/Person';
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import FavoriteIcon from '@material-ui/icons/Favorite';
import DanceIcon from "assets/img/DanceIconBlue.png";
import { FaBook } from 'react-icons/fa';

import footerStyle from "assets/jss/material-dashboard-pro-react/components/footerStyle";
import FeedbackForm from "../Forms/FeedbackForm";


function Footer({ ...props }) {
  const { classes, fluid, white, rtlActive, pushChat, uid } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
  });
  var anchor =
    classes.a +
    cx({
      [' ' + classes.whiteColor]: white,
    });

  if (
    window.location.pathname.includes('investorthreads') ||
    window.location.pathname.includes('create-investment')
  ) {
    return <div />;
  } else {
    return (
      <footer className={classes.footer} style={{ width: '100%', backgroundColor: "#2a2a2a" }}>
        <div className={container}>
          <div
            className={classes.right}
            style={{ textAlign: 'center', width: '100%', padding: '5px' }}
          >
            <GridContainer style={{ marginTop: '15px', display: 'flex', justifyContent: "space-around"}}>
              {/* <GridItem xs={2}>
                <Link to='/profile' >
                  <PersonIcon style={{ color: "#00C1F3", fontSize: "30px"}} />
                </Link>
              </GridItem> */}
              <GridItem xs={2}>
                <Link to='/queue'>
                  <QueueMusicIcon style={{ color: "#00C1F3", fontSize: "30px"}} />
                </Link>
              </GridItem>
              <GridItem xs={2}>
              <Link to='/browse-songs'>
                  <FaBook style={{ color: "#00C1F3", fontSize: "30px"}} />
                </Link>
              </GridItem>
              <GridItem xs={2}>
              <Link to='/dance-requests'>
                  {/* <MusicNoteIcon style={{ color: "#00C1F3", fontSize: "30px"}} /> */}
                  <img src={DanceIcon} style={{width:"30px", height:"30px"}}/>
                </Link>
              </GridItem>
              <GridItem xs={2}>
                <Link to='/favorites' >
                  <FavoriteIcon style={{ color: "#00C1F3", fontSize: "30px"}} />
                </Link>
              </GridItem>
              <GridItem xs={2}>
              <Link to='/how-it-works'>
                  <ContactSupportIcon style={{ color: "#00C1F3", fontSize: "30px"}} />
                </Link>
              </GridItem>
            </GridContainer>
            <FooterText anchor={anchor} rtlActive={rtlActive} />
          </div>
        </div>
      </footer>
    );
  }
}


Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
