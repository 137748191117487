/*eslint-disable*/

const userDetails = {
  dataLoaded: false
}

export const initState = {
  userDetails
}



export default (state = initState, action) => {
  switch (action.type) {
    case 'SET_USERSONGS':
      var toReturn = {...state}
      toReturn.data = action.data
      toReturn.userDetails.dataLoaded = true
      return toReturn

      case "SET_SPOTIFYTOKEN":
        var toReturn = {...state}
       toReturn.spotifyToken = action.spotifyToken
        return toReturn

    default:
      return state
  }
}
