/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import ReactTable from "react-table";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

const DisplayQueue = (props) => {
  const { classes } = props;
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (props.queue.data) {
      setTableData(props.queue.data);
    }
  }, [props]);

  return (
    <div
      style={{ width: "100%", background: "black", height: "100%" }}
      className={classes.container}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} className={classes.formContainer}>
          <Card
            style={{
              padding: "20px",
              width: "90%",
              margin: "0px auto",
              background: "#2A2A2A",
              height: "1015px",
              overflow: "hidden",
              marginBottom: "100px",
              marginTop: "100px",
            }}
          >
            <ReactTable
              noDataText="No Singers In The Queue Yet!"
              className="-striped"
              showPageJump={false}
              showPagination={false}
              data={tableData}
              filterable={false}
              defaultSorted={[{}]}
              getTdProps={() => {
                return {
                  style: {
                    width: "100px",
                    letterSpacing: "8px",
                    height: "100%",
                    fontSize: "65px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                };
              }}
              getTrProps={(state, rowInfo) => {
                // console.log('rowwwwinfo', rowInfo.row._index)
                return {
                  style: {
                    color:
                      rowInfo && rowInfo.row._original.hasSung
                        ? "#028392"
                        : "#f9b518",
                    // fontSize: "80px",
                    fontWeight: 400,
                    height: "70px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    marginBottom: "12px",
                  },
                };
              }}
              columns={[
                {
                  // eslint-disable-next-line react/display-name
                  Header: () => (
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "30px",
                        marginBottom: "30px",
                        display: "none",
                      }}
                    >
                      {}
                    </div>
                  ),
                  accessor: "displayName",
                  filterable: false,
                  sortable: false,
                },
              ]}
            />
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

DisplayQueue.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    queue: state.queue,
  };
};

export default compose(
  connect(mapStateToProps),
  withStyles(loginPageStyle)
)(DisplayQueue);
