/*eslint-disable*/
import React from 'react'
import Button from "components/CustomButtons/Button.jsx";
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { db, firebase } from "firebase/fbConfig.js"
import axios from 'axios'
import qs from 'qs';





export const updateRequestStatus = (status, requestInfo, data) => {
  return (dispatch) => {
    return db.collection("TEST-users").doc(requestInfo.requestedById).collection('dance-request-songs').doc(requestInfo.requestId).update({downloadStatus: status})
    .then(() => {
      return db.collection('dance-requests').doc(requestInfo.requestId).delete()
      .then(() => {
        dispatch(setDanceRequests(data))
      })
    })
  }
}


export const newDanceRequest = (userData, adminData, UID) => {
  return (dispatch) => {
    var danceId = db.collection("TEST-users").doc(UID).collection('dance-request-songs').doc().id
    userData.danceId = danceId
    adminData.requestId = danceId
    adminData.requestedById = UID
    userData.timeCreated = firebase.firestore.FieldValue.serverTimestamp()
    adminData.timeCreated = firebase.firestore.FieldValue.serverTimestamp()

    return db.collection("TEST-users").doc(UID).collection('dance-request-songs').doc(danceId).set(userData)
      .then(() => {
        return db.collection('dance-requests').doc(danceId).set(adminData)
          .then(() => {
            dispatch(setRequestCreated(true))
          })
      })
  }
}

export const setRequestCreated = (boolean) => {
  return {
    type: "SET_REQUEST_CREATED",
    boolean
  }
}


export const getUsersDanceRequests = (uid) => {
  return (dispatch) => {
    return db.collection('TEST-users').doc(uid).collection('dance-request-songs').orderBy('timeCreated').onSnapshot((snapshot) => {
      if (!snapshot.empty) {
        snapshot = snapshot.docs
        var data = []
        snapshot.forEach((doc) => {
          doc = doc.data()
          data.push(doc)
        })
        dispatch(setUsersDanceRequests(data))
      } else {
        dispatch(setUsersDanceRequests([]))
      }
    })
  }
}


export const deleteUserRequest = (uid, data, allData) => {
  return (dispatch) => {
    return db.collection('TEST-users').doc(uid).collection('dance-request-songs').doc(data.danceId).delete()
    .then(() => {
      return db.collection('dance-requests').doc(data.danceId).delete()
      .then(() => {
        dispatch(setUsersDanceRequests(allData))
      })
    })
  }
}


export const setUsersDanceRequests = (data) => {
  return {
    type: "SET_USERS_DANCE_REQUESTS",
    data
  }
}


export const getDanceRequests = () => {
  return (dispatch) => {
    return db.collection('dance-requests').orderBy('timeCreated').onSnapshot((snapshot) => {
      snapshot = snapshot.docs
      var data = []
      snapshot.forEach((doc) => {
        doc = doc.data()
        data.push(doc)
      })
      dispatch(setDanceRequests(data))
    })
  }
}

export const setDanceRequests = (data) => {
  return {
    type: 'SET_DANCE_REQUESTS',
    data
  }
}
