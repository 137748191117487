/*eslint-disable*/
import { v4 as uuid } from 'uuid';


export const initState = {
  accountability: { values: [{ id: '1', text: 'first accountable individual' }], submitted: false, dialogOpen: false },
  engagementMetric: { values: [{ id: '1', text: 'first engagement metric' }], submitted: false, dialogOpen: false },
  stakeholderRelationship: { values: [{ id: '1', text: 'first relationship metric' }], submitted: false, dialogOpen: false },
  valueProp: { values: [{ id: '1', text: 'first value proposition' }], submitted: false, dialogOpen: false },
  actions: { values: [{ id: '1', text: 'first action' }], submitted: false, dialogOpen: false },
  communicationStrategy: { values: [{ id: '1', text: 'first communication strategy' }], submitted: false, dialogOpen: false },
  finishedLoading: false,
  finishedUpdating: false,
  stakeholderHasBeenEdited: false
}

export default (state = initState, action) => {
  // console.log('actionzzz', action)
  switch (action.type) {
    case 'SET_STATE':
      var toReturn = { ...state }
      var stateObj = action.stateList
      console.log('updatedx', stateObj)
      for (var key in stateObj) {
        if (stateObj[key].length >= 1) {
          toReturn[key].values = stateObj[key]
          toReturn[key].values.push({id: '2', text: ''})
        }
      }
      toReturn.finishedLoading = true
      toReturn.finishedUpdating = true
      console.log('updatedx', toReturn)
      return toReturn
    case 'PLAYBOOK_SetSES_LIST':
      var toReturn = { ...state }
      toReturn.stakeholderName = action.list.name
      toReturn.finishedLoading = true
      toReturn.members = action.members
      console.log('toReturnx', toReturn)
      for (var name in action.list) {
        toReturn[name] = { ...state[name], values: action.list[name] }
      }
      return toReturn

    case 'PLAYBOOK_SetSES_STAKEHOLDERS':
      var toReturn = { ...state }
      toReturn.stakeholderName = action.updatedStakeholders
      toReturn.finishedLoading = true
      toReturn.finishedUpdating = true
      console.log('updatedx', toReturn)
      return toReturn


    case 'RESET_LOADING':
      console.log('stateeeez', 'alsomadeit')
      var toReturn = { ...state }
      toReturn.finishedLoading = false
      toReturn.finishedUpdating = false
      return toReturn

    case 'RESET_STAKEHOLDER':
      console.log('stateeeez', 'reset')
      var toReturn = { ...state }
      toReturn.stakeholderName = action.stakeholderName
      return toReturn

    case 'STAKEHOLDERHASBEENEDITED':
      console.log('stateeeez', 'reset')
      var toReturn = { ...state }
      toReturn.stakeholderHasBeenEdited = action.boolean
      return toReturn

      case 'PLAYBOOK_SET_SES_LIST_ITEM':
        let values = [ ...state[action.name].values ];
        console.log('valzz', values)
        if (action.item.text == "" && action.index < values.length-1) {
            // remove any blank items not at the end
            values.splice(action.index, 1);
        } else {
            values[action.index] = { ...values[action.index], ...action.item };
            // ensure there is always a blank at the end of the list
            if (values[values.length-1].text) values.push({ id: uuid(), text: ""});
        }
        return {
            ...state,
            [action.name]: { ...state[action.name], values }
        };


    case 'PLAYBOOK_LIST_OPEN_DIALOG':
      return {
        ...state,
        [action.name]: { ...state[action.name], submitted: false, dialogOpen: true }
      }
    case 'PLAYBOOK_LIST_CLOSE_DIALOG':
      return {
        ...state,
        [action.name]: { ...state[action.name], dialogOpen: false }
      }
      case 'RESET_STATE_FROM_PLAYBOOK':
        console.log('updated',
      'mounted', 'resetingxdsf', initState)
      return {
        accountability: { values: [{ id: '1', text: 'first accountable individual' }], submitted: false, dialogOpen: false },
        engagementMetric: { values: [{ id: '1', text: 'first engagement metric' }], submitted: false, dialogOpen: false },
        stakeholderRelationship: { values: [{ id: '1', text: 'first relationship metric' }], submitted: false, dialogOpen: false },
        valueProp: { values: [{ id: '1', text: 'first value proposition' }], submitted: false, dialogOpen: false },
        actions: { values: [{ id: '1', text: 'first action' }], submitted: false, dialogOpen: false },
        communicationStrategy: { values: [{ id: '1', text: 'first communication strategy' }], submitted: false, dialogOpen: false },
        finishedLoading: false,
        finishedUpdating: false,
        stakeholderHasBeenEdited: false
      }
    default:
      return state;
  }
}
