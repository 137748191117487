import {
  container,
  cardTitle,
  dangerColor,
  whiteColor,
  linkText,
  primaryColor,
} from "assets/jss/material-dashboard-pro-react.jsx";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.jsx";

const danceRequestsStyle = (theme) => ({
  ...modalStyle(theme),
  container: {
    // ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
    },
  },
  tableGridItem: {
    padding: "0px 85px !important",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 15px !important",
    },
  },
  formContainer: {
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      padding: "0px !important",
    },
  },
  // tableHeader: {
  //   display: "flex",
  //   flexDirection: "column",
  //   fontSize: "26px",
  //   color: "white",
  //   [theme.breakpoints.down("sm")]: {
  //     fontSize: "20px",
  //   }
  // },
  cardBody: {
    padding: "20px",
    margin: "0px auto",
    background: "#2A2A2A",
    //  height: "calc(150px + 70%)",
    //  overflow: "scroll",
    marginTop: "40px",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
      marginTop: "20px",
    },
  },
  previewForm: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    color: "#fff",
    paddingBottom: "140px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
    },
  },
  cardTitle: {
    ...cardTitle,
    color: "#fff",
    fontSize: "24px",
  },
  formTextField: {
    width: "96%",
    // marginRight: "-25px",
    color: "white",
    borderBottom: "1px solid white !important",
    "&:before": {
      borderBottom: "1px solid white !important",
    },
    "&:hover": {
      borderBottom: "1px solid white !important",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  linkText,
  textDanger: {
    fontSize: "12px",
    color: dangerColor,
    textAlign: "center",
  },
  table: {
    width: "100%",
  },
  tableHeader: {
    fontSize: "26px",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "19px",
      padding: "15px",
    },
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: "#FFFFFF",
    },
    marginLeft: "5px",
    marginRight: "5px",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: whiteColor,
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  cardHeader: {
    margin: "20px",
    padding: "10px 100px",
  },
  socialLine: {
    padding: "0.9375rem 0",
  },
  headers: {
    borderBottom: "1px solid #D7D7D7",
    fontSize: "40px",
  },
  threeDots: {
    fontSize: "xx-large",
    float: "right",
    "&:hover": {
      cursor: "pointer",
    },
  },
  popperDiv: {
    display: "flex",
    // height: "85px",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 12px",
    height: "50px",
    // margin: "0px auto",
    margin: "5px",
  },
  popperSelections: {
    width: "100%",
    margin: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
    "&:hover": {
      cursor: "pointer",
      background: "#F6F6F7",
    },
  },
  textField: {
    marginTop: "30px",
  },
  micIcon: {
    marginRight: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  deleteAllBtn: {
    display: "flex",
    justifyContent: "flex-end",
    width: "95%",
    marginTop: "30px",
  },
  searchThroughSpotifyHeader: {
    textAlign: "center",
    fontWeight: 500,
    color: primaryColor,
  },
  underline: {
    color: "white !important",
    "&:before": {
      borderBottom: "2px solid white",
    },
    "&:after": {
      borderBottom: "2px solid #00C1F3",
    },
    "&:hover": {
      borderBottom: "1px solid white !important",
    },
  },
  label: {
    color: "white",
  },
  root: {
    color: "white",
  },
  requestsForm: {
    height: "400px",
  },
  requestsFormHeader: {
    textAlign: "center",
    fontWeight: 500,
    borderRadius: "5px",
    fontSize: "30px",
  },
  cardHeaderBorderRadius: {
    borderRadius: "5px",
  },
  previewForm: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  formTextField: {
    width: "95%",
    marginRight: "15px",
    marginLeft: "15px",
  },
  searchBtn: {
    height: "33px",
    width: "30px",
  },
  label: {
    fontSize: "20px",
  },
  deleteQueueBtn: {
    background: "#a94442",
    color: "white",
    width: "80px",
    height: "80px",
    position: "fixed",
    left: "279px",
    bottom: "20px",
    [theme.breakpoints.down("sm")]: {
      left: "30px",
      bottom: "10px",
    },
  },
  addToQueueBtn: {
    background: "#00C1F3",
    float: "right",
    color: "white",
    width: "80px",
    height: "80px",
    position: "fixed",
    right: "20px",
    bottom: "20px",
    [theme.breakpoints.down("sm")]: {
      right: "30px",
      bottom: "10px",
    },
  },
  addNewSingerModal: {
    [theme.breakpoints.down("sm")]: {
      height: "500px",
    },
  },
  addNewSingerModalDiv: {
    [theme.breakpoints.down("sm")]: {
      height: "400px",
    },
  },
  pleaseWait: {
    fontSize: "26px",
    color: "#00C1F3",
    width: "100%",
    marginBottom: "40px",
    textAlign: "center",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  addBackToQueueBtn: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  searchGridItem: {
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
    },
  },
  searchCard: {
    display: "flex",
    flexDirection: "revert",
    margin: "0px auto",
    padding: "25px",
    width: "95%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  searchField: {
    marginLeft: "15px",
    width: "94%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      width: "92%",
    },
  },
  threeDots: {
    fontSize: "xx-large",
    float: "right",
    "&:hover": {
      cursor: "pointer",
    },
  },
  anchoredFabLoggedIn: {
    color: "white",
    right: "20px",
    width: "10%",
    bottom: "20px",
    display: "flex",
    position: "fixed",
    fontSize: "12px",
    alignItems: "center",
    background: "#00C1F3",
    minHeight: "50px",
    borderRadius: "56px",
    "&:hover": {
      background: "#80E3FC",
    },
    [theme.breakpoints.down("sm")]: {
      right: "20px",
      width: "33%",
      bottom: "80px",
      fontSize: "12px",
      maxHeight: "40px",
      borderRadius: "56px",
    },
  },
  loaderDiv: {
    height: "180%",
    zIndex: "99999",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: "10%",
  },
  loaderImg: {
    width: "30%",
    marginBottom: "50px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  actionBtn: {
    width: "45%",
    fontSize: "13px",
    backgroundColor: "#00c1f3",
    textAlign: "center",
    padding: "10px 15px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#00c1f3",
    },
  },
});

export default danceRequestsStyle;
