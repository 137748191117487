// ##############################
// // // Header styles
// #############################

import {
  containerFluid,
  darkColor,
  defaultFont,
  primaryColor,
  whiteColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
} from "assets/jss/material-dashboard-pro-react.jsx";

const headerStyle = (theme) => ({
  appBar: {
    backgroundSize: "cover !important",
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    padding: "0px 0",
    position: "absolute",
    width: "100%",
    color: "#555555",
    border: "0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block",
    zIndex: 9999,
    [theme.breakpoints.down("sm")]: {
      zIndex: 5,
      position: "fixed",
    },
  },
  appBarOverlay: {
    backgroundColor: "#2a2a2a",
    [theme.breakpoints.down("sm")]: {
      height: "86px",
    },
  },
  appResponsive: {
    color: "#fff",
  },
  container: {
    ...containerFluid,
    minHeight: "75px",
    paddingBottom: "20px",
  },
  flex: {
    flex: 1,
  },
  headerLinksWrapper: {
    marginTop: "-20px",
  },
  title: {
    ...defaultFont,
    fontWeight: 500,
    lineHeight: "30px",
    fontSize: "20px",
    borderRadius: "3px",
    textTransform: "none",
    color: whiteColor,
    paddingTop: "0.625rem",
    paddingBottom: "0.625rem",
    margin: "0 !important",
    "&:hover,&:focus": {
      background: "transparent",
    },
  },
  primary: {
    backgroundColor: primaryColor,
    color: "#FFFFFF",
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: infoColor,
    color: "#FFFFFF",
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor,
    color: "#FFFFFF",
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor,
    color: "#FFFFFF",
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor,
    color: "#FFFFFF",
    ...defaultBoxShadow,
  },
  sidebarMinimize: {
    float: "left",
    padding: "0 0 0 15px",
    display: "block",
    color: "#555555",
  },
  sidebarMinimizeRTL: {
    padding: "0 15px 0 0 !important",
  },
  sidebarMiniIcon: {
    width: "20px",
    height: "17px",
  },
});

export default headerStyle;
