/*eslint-disable*/
import { v4 as uuid } from 'uuid';

export const initState = {
    purposeEnvisioned: { values: [ { id: '1', text: 'first envisioned purpose' } ], submitted: false, dialogOpen: false },
    accountabilityStructure: { values: [ { id: '1', text: 'first accountable individual' } ], submitted: false, dialogOpen: false },
    members: { values: [{ id: '1', text: 'first member' }], submitted: false, dialogOpen: false },
    purposeMetrics: { values: [{ id: '1', text: 'first metric' }], submitted: false, dialogOpen: false },
    healthMetrics: { values: [{ id: '1', text: 'first metric' }], submitted: false, dialogOpen: false },
    targetMarkets: { values: [{ id: '1', text: 'first market' }], submitted: false, dialogOpen: false },
    keyResources: { values: [{ id: '1', text: 'first resource' }], submitted: false, dialogOpen: false },
    customerRelationships: { values: [{ id: '1', text: 'first relationship' }], submitted: false, dialogOpen: false },
    keyCompetencies: { values: [{ id: '1', text: 'first competency' }], submitted: false, dialogOpen: false },
    revenueStreams: { values: [{ id: '1', text: 'first stream' }], submitted: false, dialogOpen: false },
    costStructures: { values: [{ id: '1', text: 'first structure' }], submitted: false, dialogOpen: false },
    keyActivities: { values: [{ id: '1', text: 'first activity' }], submitted: false, dialogOpen: false },
    keyChannels: { values: [{ id: '1', text: 'first channel' }], submitted: false, dialogOpen: false },
    keyPartnerships: { values: [{ id: '1', text: 'first partnership' }], submitted: false, dialogOpen: false },
    coreValues: { values: [{ id: '1', text: 'first value' }], submitted: false, dialogOpen: false },
    coreGoals: { values: [{ id: '1', text: 'first goal' }], submitted: false, dialogOpen: false },
    customerValues: { values: [{ id: '1', text: 'first customer value' }], submitted: false, dialogOpen: false },
    staffValues: { values: [{ id: '1', text: 'first staff value' }], submitted: false, dialogOpen: false },
    coreValuesGoals: { values: [{ id: '1', text: 'first staff value' }], submitted: false, dialogOpen: false },
    customerStaffValues: { values: [{ id: '1', text: 'first staff value' }], submitted: false, dialogOpen: false },
    accountability: { values: [{ id: '1', text: 'first accountable individual' }], submitted: false, dialogOpen: false },
    engagementMetric: { values: [{ id: '1', text: 'first engagement metric' }], submitted: false, dialogOpen: false },
    stakeholderRelationship: { values: [{ id: '1', text: 'first relationship metric' }], submitted: false, dialogOpen: false },
    valueProp: { values: [{ id: '1', text: 'first value proposition' }], submitted: false, dialogOpen: false },
    actions: { values: [{ id: '1', text: 'first action' }], submitted: false, dialogOpen: false },
    communicationStrategy: { values: [{ id: '1', text: 'first communication strategy' }], submitted: false, dialogOpen: false },
    stakeholderCommunityForm: false
}

export default (state = initState, action) => {
    // console.log('actionzzz', action)
    switch (action.type) {
        case 'PLAYBOOK_SET_LIST':

            var firstLoadMembers;
            if (action.name === 'members') {
                firstLoadMembers = action.list
            }
            return {
                ...state,
                [action.name]: { ...state[action.name], values: action.list },
                firstLoadMembers
            };
        case 'PLAYBOOK_SET_LIST_ITEM':
            let values = [ ...state[action.name].values ];
            console.log('asdfasd', values)
            console.log('valzz', values)
            var noTextErrorString = ''
            if (action.item.text == ""
             && action.index < values.length-1
             ) {
                // remove any blank items not at the end
                // values.splice(action.index, 1);
                noTextErrorString = "This field can't be left blank once filled out. If you're trying to edit or delete the stakeholder, please do so from their respective worksheet"
                // noTextErrors[action.index] = noTextErrorString
            } else {
                values[action.index] = { ...values[action.index], ...action.item };
                // ensure there is always a blank at the end of the list
                if (values[values.length-1].text) values.push({ id: uuid(), text: ""});
            }
            return {
                ...state,
                [action.name]: { ...state[action.name], values },
                noTextErrorString
            };
        case 'PLAYBOOK_SetSES_LIST':
            console.log('here', action)
            var toReturn = {...state}
            var members = {...state.members}
            console.log('membersx', members)
            console.log('toReturnBefore', toReturn)
            for (var name in action.list) {
                toReturn[name] = {...state[name], values: action.list[name]}
            }
            console.log('toReturn', toReturn)
            return toReturn
            case 'PLAYBOOK_ADD_LIST_ITEM':
            return {
                ...state,
                [action.name]: { ...state[action.name], values: [ ...state[action.name].values, action.item ] }
            };
            // case 'PLAYBOOK_SET_UPDATEDNAME':
            // console.log('herrrreee', action.updatedStakeholderName, action.list)
            // var toReturn = {...state}
            // for (var x = 0; x < initState.members.values.length; x++) {
            //     if (initState.members.values[x].id === action.stakeholderId) {
            //     toReturn[members.values[x].text] = updatedStakeholderName
            //     }
            // }
            // return toReturn
        case 'PLAYBOOK_LIST_SUBMIT':
            console.log('herrrreee', action.name, action.list)
            return {
                ...state,
                [action.name]: { ...state[action.name], submitted: true }
            }
        case 'PLAYBOOK_LIST_OPEN_DIALOG':
            return {
                ...state,
                [action.name]: { ...state[action.name], submitted: false, dialogOpen: true }
            }
        case 'PLAYBOOK_LIST_CLOSE_DIALOG':
            return {
                ...state,
                [action.name]: { ...state[action.name], dialogOpen: false }
            }
            case 'PLAYBOOK_SET_COMMUNITYSTAKEHOLDERFORM':
                return {
                    ...state,
                    stakeholderCommunityForm: action.boolean
                }
        default:
            return state;
    }
}
