/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";

// @material-ui/icons
import MicIcon from "@material-ui/icons/Mic";
import MusicNoteIcon from "@material-ui/icons/MusicNote";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

import howToUseStyle from "assets/jss/material-dashboard-pro-react/views/howToUseStyle.jsx";

import AddToQueueBtns from "../Pages/AddToQueueBtns";

const HowToUse = (props) => {
  const { classes } = props;
  const [songList, setSongList] = useState([]);
  const [currentSongChoice, setCurrentSongChoice] = useState({});
  const [userInQueueAlready, setUserInQueueAlready] = useState(false);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (props.queue.data && props.authUser) {
      setTableData(props.queue.data);
      for (var x = 0; x < props.queue.data.length; x++) {
        if (
          props.queue.data[x].uid &&
          props.queue.data[x].uid === props.authUser.uid
        ) {
          if (props.queue.data[x].songChoice) {
            setCurrentSongChoice(props.queue.data[x].songChoice);
          } else {
            setCurrentSongChoice({ song: "N/A", artist: "N/A" });
          }
          setUserInQueueAlready(true);
        }
      }
    }
    if (props.queue.songList) {
      setSongList(props.queue.songList);
    }
  }, [props]);

  return (
    <div style={{ width: "100%" }} className={classes.container}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} className={classes.formContainer}>
          <Card className={classes.card}>
            <CardHeader className={classes.howToUse}>HOW IT WORKS</CardHeader>
            <CardBody style={{ display: "flex" }}>
              <div className={classes.cardBodyDiv}>
                <h6 className={classes.stepSelectionProminent}>
                  1. PUT YOUR NAME ON THE LIST{" "}
                  <p style={{ textTransform: "none" }} className={classes.stepSelection}>
                    Click the “Join List” button to sign up. Your name will be
                    the same as your Display Name in your profile.
                  </p>
                </h6>
                <h6 className={classes.stepSelectionProminent}>
                  2. PICK YOUR SONG{" "}
                  <p style={{ textTransform: "none" }} className={classes.stepSelection}>
                    You can pick your song right after adding your name to the
                    list or go to brose songs in the dropdown to keep searching
                    around. The
                    <MicIcon /> icon is to request to sing for karaoke, while
                    the <MusicNoteIcon /> icon is to request as a dance song
                    only.
                  </p>
                </h6>
                <h6 className={classes.stepSelectionProminent}>
                  3.VIEW THE SINGERS LIST{" "}
                  <p style={{ textTransform: "none" }} className={classes.stepSelection}>
                    See where you are on the list by going to “Singer’s List” in
                    the dropdown. You can edit your song here or delete your
                    name from the list if you are done singing.
                  </p>
                </h6>
                <h6
                  style={{ display: "flex", flexDirection: "column" }}
                  className={classes.stepSelection}
                >
                  KARAOKE RULES:{" "}
                  <p style={{ textTransform: "none" }}>
                    1. ABSOLUTELY NOBODY CAN BE BUMPED UP THE LIST. Don’t ask or
                    try to bribe the DJ. They do not have the ability to move
                    people up the list. The list operates the exact same way
                    every night so that it is completely fair for everyone.
                  </p>
                  <p style={{ textTransform: "none" }}>
                    2. SINGERS ARE ONLY ALLOWED TO SING ONCE PER ROTATION. This
                    cannot be manipulated in any way. Singers cannot take the
                    place of another singer, sign up twice under different
                    names, or wear a fake mustache and pretend to be another
                    person. However, singers are allowed to sing as a duet or
                    group with another person ONLY ONCE per rotation.
                  </p>
                  <p style={{ textTransform: "none" }}>
                    3. How the List works: The top 12 singers are locked into
                    position. After that, new singers that sign up (yellow) will
                    be bumped up the list in between repeat singers (blue). This
                    gives a fair wait time for anyone to sing their first song
                    of the night. Please be aware that wait times can vary
                    depending on the number of singers, which can be anywhere
                    between a half hour to 3 hours on very busy nights. Your
                    name will be put in the list as soon as you submit it via
                    the “Join List” button. If you don’t know your song right
                    away, make sure you put your name in with no song to save
                    your spot as you browse the library. Then, edit your song
                    before your name is at the top of the list so the DJ knows
                    what you are singing.
                  </p>
                  <p
                    style={{
                      textTransform: "none",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    4. Remember Karaoke is supposed to be FUN! We want everyone
                    to have a great time and have fun on stage. It isn’t
                    American Idol, it’s just Karaoke. Please keep these things
                    in mind:
                    <p>
                      - PICK A FUN, UPBEAT SONG TO SING IF YOU CAN AND PICK UP
                      THE CROWD! REMEMBER THIS IS A FUN BAR, NOT AN OPERA
                      HOUSE/DISNEY MOVIE/THEATRE, ETC..
                    </p>
                    <p>
                      - DON’T DROP THE MICS, THEY ARE VERY EXPENSIVE. IN
                      GENERAL, KEEP THE MICROPHONE ABOUT 2 IN AWAY WHILE YOU
                      SING AND SING DIRECTLY INTO IT. AVOID CUPPING OR COVERING
                      THE MIC WHILE YOU SING, AND IF YOU HOLD IT, HOLD IT
                      UPRIGHT AND AWAY FROM OTHER MICS AND SPEAKERS TO AVOID
                      FEEDBACK.
                    </p>
                  </p>
                </h6>
              </div>
            </CardBody>
          </Card>
        </GridItem>
        <AddToQueueBtns
          currentSongChoice={currentSongChoice}
          userInQueueAlready={userInQueueAlready}
          tableData={tableData}
          songList={songList}
        />
      </GridContainer>
    </div>
  );
};

HowToUse.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    authUser: state.auth.user,
    userCreated: state.queue.queueDetails.userCreated,
    userInRange: state.auth.isUserInRange,
    singerUniqueID: state.queue.singerUniqueID,
    nameSubmitted: state.queue.nameSubmitted,
    queue: state.queue,
  };
};

const mapDispatchToProps = () => ({});

export default withRouter(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(howToUseStyle)
  )(HowToUse)
);
