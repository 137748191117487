import { db } from "firebase/fbConfig.js";

export const succesfullyDownloadedSong = (
  singer,
  dataToReturnToDispatch,
  index,
  songList
) => {
  return async (dispatch) => {
    try {
      await db.collection("requests").doc(singer.requestId).delete();

      await db.collection("queue").doc(singer.queueId).update({
        songChoice: singer.songRequest,
        downloadStatus: "Downloaded",
      });
      const foundSongInLibrary = songList.find(
        (d) =>
          d.artist === singer.songRequest.artist &&
          d.song === singer.songRequest.song
      );
      console.log("🚀 ~ return ~ foundSongInLibrary:", foundSongInLibrary);
      const collectionSongId = foundSongInLibrary
        ? foundSongInLibrary.id
        : await db.collection("test-songs").doc().id;

      if (singer.userSongId && singer.uid) {
        await db
          .collection("TEST-users")
          .doc(singer.uid)
          .collection("user-songs")
          .doc(singer.userSongId)
          .update({
            downloadStatus: "Accepted",
            userSongId: singer.userSongId,
            collectionSongId: collectionSongId,
          });
      }

      // If song was not found in library, add it to 'test-songs' collection
      if (!foundSongInLibrary) {
        await db.collection("test-songs").doc(collectionSongId).set(
          {
            artist: singer.songRequest.artist,
            song: singer.songRequest.song,
            id: collectionSongId,
          },
          { merge: true }
        );
      }

      // Dispatch action to update download requests
      dispatch(setDownloadRequests(dataToReturnToDispatch));
    } catch (error) {
      console.error("Error processing download:", error);
    }
  };
};

export const denyDownloadRequest = (singer, data) => {
  console.log("🚀 ~ denyDownloadRequest ~ singer, data:", singer, data);
  return async (dispatch) => {
    try {
      const currentSongInQueueDoc = await db
        .collection("queue")
        .doc(singer.queueId)
        .get();
      const currentSongInQueueData = currentSongInQueueDoc.data();
      await db.collection("requests").doc(singer.requestId).delete();
      if (currentSongInQueueData.songChoice.artist === "N/A") {
        await db
          .collection("queue")
          .doc(singer.queueId)
          .update({ downloadStatus: "Denied" });
        await db
          .collection("queue-archive")
          .doc(singer.queueId)
          .update({ downloadStatus: "Denied" });
      }
      if (singer.userSongId) {
        await db
          .collection("TEST-users")
          .doc(singer.uid)
          .collection("user-songs")
          .doc(singer.userSongId)
          .update({ downloadStatus: "Denied" });
      }

      dispatch(setDownloadRequests(data));
    } catch (error) {
      console.error("Error denying download request:", error);
    }
  };
};

export const deleteAllRequests = () => {
  const ref = db.collection("requests");
  var batch = db.batch();
  return (dispatch) => {
    return ref.get().then((results) => {
      results = results.docs;
      results.forEach((doc) => {
        var docId = doc.id;
        doc = doc.data();
        var docRef = ref.doc(docId);
        batch.delete(docRef);
      });
      batch.commit().then(() => {
        dispatch(setDownloadRequests([]));
      });
    });
  };
};

export const getDownloadRequests = () => {
  const ref = db.collection("requests");
  return (dispatch) => {
    return ref.onSnapshot((results) => {
      results = results.docs;
      var toReturn = [];
      results.forEach((doc) => {
        var docId = doc.id;
        doc = doc.data();
        doc.id = docId;
        toReturn.push(doc);
      });
      dispatch(setDownloadRequests(toReturn));
    });
  };
};

export const setDownloadRequests = (data) => {
  return {
    type: "SET_DOWNLOADREQUESTS",
    data,
  };
};
