/*eslint-disable*/
const danceRequestDetails = {
  dataLoaded: false,
  danceRequests: []
}

const userDanceRequestDetails = {
  dataLoaded: false,
  danceRequests: [],
  requestCreated: false
}

export const initState = {
  danceRequestDetails,
  userDanceRequestDetails
}


export default (state = initState, action) => {
  switch (action.type) {
    case "SET_REQUEST_CREATED":
    var toReturn = {...state}
    toReturn.userDanceRequestDetails.requestCreated = action.boolean
    return toReturn

    case 'SET_DANCE_REQUESTS':
    var toReturn = {...state}
    toReturn.danceRequestDetails.danceRequests = action.data
    toReturn.danceRequestDetails.dataLoaded = true
    return toReturn

    case "SET_USERS_DANCE_REQUESTS":
      var toReturn = {...state}
      toReturn.userDanceRequestDetails.danceRequests = action.data
      toReturn.userDanceRequestDetails.dataLoaded = true
      return toReturn


    default:
      return state
  }
}
