import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink } from "react-router-dom";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Apps from "@material-ui/icons/Apps";
import PersonIcon from "@material-ui/icons/Person";
import ContactsIcon from "@material-ui/icons/Contacts";
import GetAppIcon from "@material-ui/icons/GetApp";
import PeopleIcon from "@material-ui/icons/People";
import QueueMusicIcon from "@material-ui/icons/QueueMusic";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import LibraryMusicIcon from "@material-ui/icons/LibraryMusic";
import CallMadeIcon from "@material-ui/icons/CallMade";
import BuildIcon from "@material-ui/icons/Build";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import SettingsIcon from "@material-ui/icons/Settings";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Dance from "assets/img/danceIcon.png";
import { FaBook } from "react-icons/fa";
// core components
import HeaderLinks from "components/Header/HeaderLinks.jsx";

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";

import avatar from "assets/img/faces/avatar.jpg";

import { setUserNeedsToAddNameModal } from "store/actions/profileActions.jsx";

var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    const { className, user, headerLinks, links } = this.props;
    return (
      <div className={className} ref="sidebarWrapper">
        {user}
        {links}
        {headerLinks}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      openComponents: this.activeRoute("/components"),
      openForms: this.activeRoute("/forms"),
      openTables: this.activeRoute("/tables"),
      openMaps: this.activeRoute("/maps"),
      openPages: this.activeRoute("-page"),
      miniActive: true,
    };
    this.activeRoute.bind(this);
    this.checkIfUserHasName = this.checkIfUserHasName.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  checkIfUserHasName(e) {
    // console.log('checkingwindow', window.location.pathname)
    // if (!this.props.firstName && !this.props.lastName) {
    //   e.preventDefault()
    //   this.props.setUserNeedsToAddNameModal(true)
    // }
    if (!this.props.user.displayName && !this.props.displayName) {
      e.preventDefault();
      this.props.setUserNeedsToAddNameModal(true);
    }
  }

  render() {
    const {
      classes,
      color,
      logo,
      image,
      logoText,
      routes,
      bgColor,
      rtlActive,
      micLogo,
      open,
      firstName,
    } = this.props;
    const itemText =
      classes.itemText +
      " " +
      cx({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
        [classes.itemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.itemTextRTL]: rtlActive,
      });
    const collapseItemText =
      classes.collapseItemText +
      " " +
      cx({
        [classes.collapseItemTextMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextRTL]: rtlActive,
      });
    const userWrapperClass =
      classes.user +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white",
      });
    const caret =
      classes.caret +
      " " +
      cx({
        [classes.caretRTL]: rtlActive,
      });
    const collapseItemMini =
      classes.collapseItemMini +
      " " +
      cx({
        [classes.collapseItemMiniRTL]: rtlActive,
      });
    const photo =
      classes.photo +
      " " +
      cx({
        [classes.photoRTL]: rtlActive,
      });

    // RENDERS USER INFO IN SIDEBAR
    // TO ENABLE UNCOMMENT this and <user> line in SidebarWrapper Components
    // var user = (
    //   <div className={userWrapperClass}>
    //     <div className={photo}>
    //       <img src={avatar} className={classes.avatarImg} alt="..." />
    //     </div>
    //     <List className={classes.list}>
    //       <ListItem className={classes.item + " " + classes.userItem}>
    //         <NavLink
    //           to={"#"}
    //           className={classes.itemLink + " " + classes.userCollapseButton}
    //           onClick={() => this.openCollapse("openAvatar")}
    //         >
    //           <ListItemText
    //             primary={rtlActive ? "تانيا أندرو" : "Marco Lopes"}
    //             secondary={
    //               <b
    //                 className={
    //                   caret +
    //                   " " +
    //                   classes.userCaret +
    //                   " " +
    //                   (this.state.openAvatar ? classes.caretActive : "")
    //                 }
    //               />
    //             }
    //             disableTypography={true}
    //             className={itemText + " " + classes.userItemText}
    //           />
    //         </NavLink>
    //         <Collapse in={this.state.openAvatar} unmountOnExit>
    //           <List className={classes.list + " " + classes.collapseList}>
    //             <ListItem className={classes.collapseItem}>
    //               <NavLink
    //                 to="#"
    //                 className={
    //                   classes.itemLink + " " + classes.userCollapseLinks
    //                 }
    //               >
    //                 <span className={collapseItemMini}>
    //                   {rtlActive ? "مع" : "MP"}
    //                 </span>
    //                 <ListItemText
    //                   primary={rtlActive ? "ملفي" : "My Profile"}
    //                   disableTypography={true}
    //                   className={collapseItemText}
    //                 />
    //               </NavLink>
    //             </ListItem>
    //             <ListItem className={classes.collapseItem}>
    //               <NavLink
    //                 to="#"
    //                 className={
    //                   classes.itemLink + " " + classes.userCollapseLinks
    //                 }
    //               >
    //                 <span className={collapseItemMini}>
    //                   {rtlActive ? "هوع" : "EP"}
    //                 </span>
    //                 <ListItemText
    //                   primary={
    //                     rtlActive ? "تعديل الملف الشخصي" : "Edit Profile"
    //                   }
    //                   disableTypography={true}
    //                   className={collapseItemText}
    //                 />
    //               </NavLink>
    //             </ListItem>
    //             <ListItem className={classes.collapseItem}>
    //               <NavLink
    //                 to="#"
    //                 className={
    //                   classes.itemLink + " " + classes.userCollapseLinks
    //                 }
    //               >
    //                 <span className={collapseItemMini}>
    //                   {rtlActive ? "و" : "S"}
    //                 </span>
    //                 <ListItemText
    //                   primary={rtlActive ? "إعدادات" : "Settings"}
    //                   disableTypography={true}
    //                   className={collapseItemText}
    //                 />
    //               </NavLink>
    //             </ListItem>
    //           </List>
    //         </Collapse>
    //       </ListItem>
    //     </List>
    //   </div>
    // );

    

    var links = (
      <List className={classes.list}>
        {routes.map((prop, key) => {
          console.log("🚀 ~ Sidebar ~ {routes.map ~ prop:", this.props.user)
          // only render admin if user is admin
          //console.log(this.props.user)
          if (prop.hide) {
            return null;
          }
          if (
            prop.name === "PCL Search Results" ||
            prop.name === "Spotify Search Results" ||
            prop.name === "Dance Request Search Results" ||
            prop.name === "Dance Request Search"
          ) {
            return null;
          }
          if (prop.admin && !this.props.user.admin) {
            return null;
          }
          if (prop.redirect) {
            return null;
          }
          if (prop.collapse) {
            const navLinkClasses =
              classes.itemLink +
              " " +
              cx({
                [" " + classes.collapseActive]: this.activeRoute(prop.path),
              });
            const itemText =
              classes.itemText +
              " " +
              cx({
                [classes.itemTextMini]:
                  this.props.miniActive && this.state.miniActive,
                [classes.itemTextMiniRTL]:
                  rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.itemTextRTL]: rtlActive,
              });
            const collapseItemText =
              classes.collapseItemText +
              " " +
              cx({
                [classes.collapseItemTextMini]:
                  this.props.miniActive && this.state.miniActive,
                [classes.collapseItemTextMiniRTL]:
                  rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.collapseItemTextRTL]: rtlActive,
              });
            {
              /* const itemIcon =
              classes.itemIcon +
              " " +
              cx({
                [classes.itemIconRTL]: rtlActive
              }); */
            }
            const caret =
              classes.caret +
              " " +
              cx({
                [classes.caretRTL]: rtlActive,
              });
            return (
              <ListItem key={key} className={classes.item}>
                <NavLink
                  to={firstName ? "#" : window.location.pathname}
                  className={navLinkClasses}
                  onClick={() => this.openCollapse(prop.state)}
                >
                  <ListItemIcon className={classes.itemIcon}>
                    <BuildIcon />
                  </ListItemIcon>

                  <ListItemText
                    style={{ fontWeight: 400, fontSize: "18px" }}
                    primary={prop.name}
                    secondary={
                      <b
                        className={
                          caret +
                          " " +
                          (this.state[prop.state] ? classes.caretActive : "")
                        }
                      />
                    }
                    disableTypography={true}
                    className={itemText}
                  />
                </NavLink>
                <Collapse in={this.state[prop.state]} unmountOnExit>
                  <List className={classes.list + " " + classes.collapseList}>
                    {prop.views.map((prop, key) => {
                      if (prop.redirect) {
                        return null;
                      }
                      const navLinkClasses =
                        classes.collapseItemLink +
                        " " +
                        cx({
                          [" " + classes[color]]: this.activeRoute(prop.path),
                        });
                      const collapseItemMini =
                        classes.collapseItemMini +
                        " " +
                        cx({
                          [classes.collapseItemMiniRTL]: rtlActive,
                        });
                      return prop.name !== "Actions" ? (
                        <ListItem key={key} className={classes.collapseItem}>
                          <NavLink
                            to={prop.path}
                            onClick={this.checkIfUserHasName}
                            className={navLinkClasses}
                          >
                            <span className={collapseItemMini}>
                              {prop.mini}
                            </span>
                            <ListItemIcon className={classes.itemIcon}>
                              {prop.name === "DL Requests" ? (
                                <GetAppIcon />
                              ) : prop.name === "Users" ? (
                                <PeopleIcon />
                              ) : prop.name === "KJ Dashboard" ? (
                                <ContactsIcon />
                              ) : prop.name === "Dance Requests" ? (
                                <MusicNoteIcon />
                              ) : prop.name === "Config" ? (
                                <SettingsIcon />
                              ) : prop.name === "What's New" ? (
                                <AnnouncementIcon />
                              ) : null}
                            </ListItemIcon>

                            <ListItemText
                              style={{ fontWeight: 400, fontSize: "18px" }}
                              primary={prop.name}
                              disableTypography={true}
                              className={collapseItemText}
                            />
                          </NavLink>
                        </ListItem>
                      ) : null;
                    })}
                  </List>
                </Collapse>
              </ListItem>
            );
          }
          const navLinkClasses =
            classes.itemLink +
            " " +
            cx({
              [" " + classes[color]]: this.activeRoute(prop.path),
            });
          const itemText =
            classes.itemText +
            " " +
            cx({
              [classes.itemTextMini]:
                this.props.miniActive && this.state.miniActive,
              [classes.itemTextMiniRTL]:
                rtlActive && this.props.miniActive && this.state.miniActive,
              [classes.itemTextRTL]: rtlActive,
            });
          {
            /* const itemIcon =
            classes.itemIcon +
            " " +
            cx({
              [classes.itemIconRTL]: rtlActive
            }); */
          }
          return (
            <ListItem key={key} className={classes.item}>
              <NavLink
                onClick={this.checkIfUserHasName}
                to={prop.path}
                className={navLinkClasses}
              >
                <ListItemIcon className={classes.itemIcon}>
                  {prop.name === "Profile" ? (
                    <PersonIcon />
                  ) : prop.name === "Queue" ? (
                    <QueueMusicIcon />
                  ) : prop.name === "Browse Songs" ? (
                    <FaBook style={{ fontSize: "22px" }} />
                  ) : prop.name === "Dance Requests" ? (
                    <img src={Dance} />
                  ) : prop.name === "How It Works" ? (
                    <ContactSupportIcon />
                  ) : prop.name === "Favorites" ? (
                    <FavoriteIcon />
                  ) : (
                    <QueueMusicIcon />
                  )}
                </ListItemIcon>

                <ListItemText
                  style={{ fontWeight: 400, fontSize: "18px" }}
                  primary={prop.name}
                  disableTypography={true}
                  className={itemText}
                />
              </NavLink>
            </ListItem>
          );
        })}
      </List>
    );

    const logoNormal =
      classes.logoNormal +
      " " +
      cx({
        [classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.logoNormalSidebarMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.logoNormalRTL]: rtlActive,
      });
    const logoMini =
      classes.logoMini +
      " " +
      cx({
        [classes.logoMiniRTL]: rtlActive,
      });
    const logoClasses =
      classes.logo +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white",
      });

    var brand = this.props.miniActive ? (
      <div className={logoClasses}>
        <a href="/" className={logoMini}>
          <img src={micLogo} alt="logo" className={classes.img} />
        </a>
        {/* <a href="/pages" className={logoNormal}>
        {logoText}
      </a> */}
      </div>
    ) : (
      <div style={{ zIndex: 9999 }} className={logoClasses}>
        <a style={{ zIndex: 9999 }} href="/" className={logoMini}>
          <img
            style={{ zIndex: 9999 }}
            src={logo}
            alt="logo"
            className={classes.img}
          />
        </a>
        {/* <a href="/pages" className={logoNormal}>
          {logoText}
        </a> */}
      </div>
    );
    const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: rtlActive,
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1,
      });
    return (
      <div ref="mainPanel">
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={rtlActive ? "left" : "right"}
            open={this.props.open}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              // user={user}
              headerLinks={<HeaderLinks rtlActive={rtlActive} />}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            // onMouseOver={() => this.setState({ miniActive: false })}
            // onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? "right" : "left"}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              // user={user}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "blue",
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose",
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (state) => {
  return {
    user: state.profile.user,
    firstName: state.profile.user.firstName,
    lastName: state.profile.user.lastName,
    displayName: state.auth.displayName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserNeedsToAddNameModal: (boolean) =>
      dispatch(setUserNeedsToAddNameModal(boolean)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(sidebarStyle)
)(Sidebar);
