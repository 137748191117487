/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { db } from "firebase/fbConfig.js";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ImportExportIcon from "@material-ui/icons/ImportExport";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Fade from "@material-ui/core/Fade";
import Popper from "@material-ui/core/Popper";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Tooltip from "@material-ui/core/Tooltip";

import queueManagerStyle from "assets/jss/material-dashboard-pro-react/views/queueManagerStyle.jsx";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Logo from "assets/img/pclBigLogo.png";
import Loader from "assets/img/loader.gif";

import {
  deleteFromQueue,
  editSingerInQueue,
  changingPositionsInQueue,
  setUserHasSung,
  getJustDatabaseSongList,
  addNewSingerAndSong,
  //deleteAll,
  moveToTopInQueue,
  getArchivedQueue,
  addSingerBackToQueue,
  //deleteAllInArchive,
  setNewOrderForQueue,
} from "store/actions/queueActions";
import {
  getDanceRequests,
  updateRequestStatus,
} from "store/actions/danceRequestActions";
import {
  getDownloadRequests,
  succesfullyDownloadedSong,
  denyDownloadRequest,
} from "store/actions/downloadRequestActions";
import { Check } from "@material-ui/icons";
import { AddSinger } from "../../components/PCL/AddSinger";

const tableHeaders = ["#", "Display Name", "Song", "Artist", "Actions"];
const requestHeaders = ["Name", "Request By", "Actions"];

const Transition = (props) => {
  return <Slide direction="down" {...props} />;
};

const UpTransition = (props) => {
  return <Slide direction="up" {...props} />;
};

const QueueManager = (props) => {
  const {
    classes,
    danceRequests,
    downloadRequests,
    danceDataLoaded,
    downloadDataLoaded,
  } = props;
  const [popperOpen, setPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedSinger, setSelectedSinger] = useState(null);
  const singerIndexForKeyUpAndDown = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [deleteSingerModal, setDeleteSingerModal] = useState(false);
  const [songList, setSongList] = useState(false);
  const [nameSearchRef, setNameSearchRef] = useState("");
  const [userMustBeWithinRangeAlert, setUserMustBeWithinRangeAlert] = useState(
    false
  );
  const [nameExistsAlreadyInQueue, setNameExistsAlreadyInQueue] = useState(
    false
  );
  const [nameCantBeEmpty, setNameCantBeEmpty] = useState(false);
  const [archivedTableData, setArchivedTableData] = useState([]);
  const [
    singerIsAlreadyInCurrentQueueModal,
    setSingerIsAlreadyInCurrentQueueModal,
  ] = useState(false);
  const [currentSingerInArchive, setCurrentSingerInArchive] = useState({});
  const [addSingerBackToQueueModal, setAddSingerBackToQueueModal] = useState(
    false
  );
  const [
    successfullyAddedSingerBackToCurrentQueue,
    setSuccessfullyAddedSingerBackToCurrentQueue,
  ] = useState(false);
  const [filteredArchiveTableData, setFilteredArchiveTableData] = useState([]);

  const [singerSelectedToMove, setSingerSelectedToMove] = useState(false);
  const [isSingerReadyToMove, setIsSingerReadyToMove] = useState(false);
  const [singerNameReadyToBeMoved, setSingerNameReadyToBeMoved] = useState(
    null
  );
  const [orderSavedSuccesfullyModal, setOrderSavedSuccesfullyModal] = useState(
    false
  );
  const [danceData, setDanceData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [bothData, setBothData] = useState([]);
  const dataRef = useRef(null);
  const [singerRequestIndex, setSingerRequestIndex] = useState(null);
  const [dancerRequestIndex, setDancerRequestIndex] = useState(null);
  const [ableToDownloadModal, setAbleToDownloadModal] = useState(false);
  const [deniedModal, setDeniedModal] = useState(false);
  const [confirmedModal, setConfirmedModal] = useState(false);
  const [specificRequest, setSpecificRequest] = useState(null);
  const [
    successfullyDownloadedRequest,
    setSuccessfullyDownloadedRequest,
  ] = useState(false);

  const [addedSingerSuccess, setAddedSingerSuccess] = useState(false);

  let editSingerInput = useRef({
    displayName: "N/A",
    song: "N/A",
    artist: "N/A",
  });

  const setSpecificSinger = (event, singer) => {
    console.log("what???", singer);
    const data = filteredTableData.length
      ? [...filteredTableData]
      : [...tableData];
    setPopperOpen(!popperOpen);
    setAnchorEl(event.currentTarget);
    setSelectedSinger(singer);

    const index = data.findIndex((d) => d.id === singer.id);
    singerIndexForKeyUpAndDown.current = index;
  };

  console.log("selected singer", selectedSinger);

  const setSpecificDownloadSinger = (_, index) => {
    setSingerRequestIndex(index);
  };

  const denyDownloadRequest = async (index) => {
    let data = [...bothData];
    let singer = data[index];
    const updatedData = [...data.slice(0, index), ...data.slice(index + 1)];
    const items = updatedData.filter((d) => d.type === "download");
    await props.denyDownloadRequest(singer, items);
    setDownloadData(items);
  };

  const succesfullyDownloadedSong = async (index) => {
    const singer = bothData[index];

    const findIndex = downloadData.findIndex(
      (item) => item.requestId === singer.requestId
    );

    if (findIndex !== -1) {
      const newData = [...bothData];
      newData.splice(findIndex, 1);
      const items = newData.filter((d) => d.type === "download");
      await props.succesfullyDownloadedSong(singer, items, findIndex, songList);
      setDownloadData(newData);
      setAbleToDownloadModal(false);
      setSuccessfullyDownloadedRequest(true);
    }
  };

  const handleKeyDown = async (e) => {
    var data = [...dataRef.current];
    var index = singerIndexForKeyUpAndDown.current;
    var current = data[index];
    let switchingWith;
    if (e.keyCode === 38 && singerIndexForKeyUpAndDown.current !== 0) {
      current.positionInQueue = index;
      switchingWith = data[index - 1];
      switchingWith.positionInQueue = index - 1;
      data[index - 1] = current;
      data[index - 1].positionInQueue = index - 1;
      data[index] = switchingWith;
      data[index].positionInQueue = index;
      setTableData(data);
      dataRef.current = data;
      singerIndexForKeyUpAndDown.current =
        singerIndexForKeyUpAndDown.current - 1;
    }
    if (
      e.keyCode === 40 &&
      singerIndexForKeyUpAndDown.current !== tableData.length - 1
    ) {
      switchingWith = data[index + 1];
      data[index + 1] = current;
      data[index + 1].positionInQueue = index + 1;
      data[index] = switchingWith;
      data[index].positionInQueue = index;
      setTableData(data);
      dataRef.current = data;
      singerIndexForKeyUpAndDown.current =
        singerIndexForKeyUpAndDown.current + 1;
    }
    if (e.which === 13) {
      await props.setNewOrderForQueue(dataRef.current);
      setSingerSelectedToMove(false);
      setSingerNameReadyToBeMoved(null);
      setOrderSavedSuccesfullyModal(true);
    }
  };

  useEffect(() => {
    if (singerSelectedToMove) {
      window.addEventListener("keydown", handleKeyDown, true);
      return () => window.removeEventListener("keydown", handleKeyDown, true);
    }
  }, [singerSelectedToMove]);

  const cancelEditUser = () => {
    setIsEditing(false);
    setPopperOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!downloadDataLoaded) {
        await props.getDownloadRequests();
      }
      if (downloadRequests) {
        setDownloadData(downloadRequests.data);
      }

      if (!danceDataLoaded) {
        await props.getDanceRequests();
      }

      if (!props.queue.archivedDataLoaded) {
        await props.getArchivedQueue();
      } else {
        setArchivedTableData(props.queue.archivedQueueData);
      }

      if (props.queue.data) {
        console.log("hereeeeee?");
        setTableData(props.queue.data);
        dataRef.current = props.queue.data;
      }
      if (props.queue.songList) {
        setSongList(props.queue.songList);
      }
    };

    fetchData();
  }, [props]);

  useEffect(() => {
    setDanceData(danceRequests);
  }, [danceRequests]);

  useEffect(() => {
    let newDownload = [];
    const newDance = danceData.map((d) => ({
      ...d,
      type: "dance",
    }));
    if (downloadData) {
      newDownload = downloadData.map((d) => ({
        ...d,
        type: "download",
      }));
    }
    const newArr = newDance.concat(newDownload);
    setBothData(newArr);
  }, [danceRequests, downloadData]);

  const setSingerSang = async (_, index) => {
    var currentSinger = tableData[index];
    var toReturnData = [...tableData];
    currentSinger.hasSung = true;
    if (!currentSinger.songChoice) {
      currentSinger.songChoice = { song: "N/A", artist: "N/A" };
    } else {
      currentSinger.songChoice.song = "N/A";
      currentSinger.songChoice.artist = "N/A";
    }
    toReturnData.splice(index, 1);
    toReturnData.push(currentSinger);
    for (var x = 0; x < toReturnData.length; x++) {
      toReturnData[x].positionInQueue = x;
    }

    var singerId = currentSinger.id;
    await props.setUserHasSung(singerId, currentSinger, toReturnData, index);
    setPopperOpen(false);
    setAnchorEl(null);
  };

  const addNewSinger = async (singer) => {

    if (!singer.displayName) {
      setNameCantBeEmpty(true);
      return false;
    }

    const newSingerName = singer.displayName.replace(/^[a-z]/, (match) =>
      match.toUpperCase()
    );

    const nameExistsAlready = tableData.some(td => td === newSingerName);

    if (nameExistsAlready) {
      setNameExistsAlreadyInQueue(true);
      return false;
    }

    const doc = db.collection("queue").doc();
    const entry = {
      displayName: newSingerName,
      hasSung: false,
      positionInQueue: tableData.length,
      id: doc.id,
      songChoice: {
        song: singer.song,
        artist: singer.artist
      },
    };

    await props.addNewSingerAndSong(entry);
    setAddedSingerSuccess(true);

    return true;

  };

  const checkIfSingerAlreadyInQueue = (singer) => {
    var alreadyInQueue = false;
    for (var x = 0; x < tableData.length; x++) {
      if (tableData[x].id === singer.id) {
        alreadyInQueue = true;
      }
    }

    setCurrentSingerInArchive(singer);
    if (alreadyInQueue) {
      setSingerIsAlreadyInCurrentQueueModal(true);
    } else {
      setAddSingerBackToQueueModal(true);
    }
  };

  const filterData = (e, header, table) => {
    var input = e.target.value;
    if (table === "current") {
      if (input === "") {
        setFilteredTableData([]);
        setNameSearchRef(input);
      } else {
        let data = [...tableData];
        const filtered = data.filter((d) => {
          if (d[header].toLowerCase().includes(input.toLowerCase())) {
            return d;
          }
        });
        setFilteredTableData(filtered);
        setNameSearchRef(input);
      }
    } else {
      if (input === "") {
        setFilteredArchiveTableData([]);
        setNameSearchRef(input);
      } else {
        let data = [...archivedTableData];
        const filtered = data.filter((d) => {
          if (d[header].toLowerCase().includes(input.toLowerCase())) {
            return d;
          }
        });
        setFilteredArchiveTableData(filtered);
        setNameSearchRef(input);
      }
    }
  };

  const deleteSinger = async () => {
    const singerId = selectedSinger.id;
    const newData = tableData.filter((singer) => singer.id !== singerId);

    await props.deleteFromQueue(selectedSinger);
    if (filteredTableData.length > 0) {
      setFilteredTableData([]);
      setNameSearchRef("");
    } else {
      setTableData(newData);
    }
    setDeleteSingerModal(false);
  };

  const moveRowIndex = async (e, direction) => {
    e.preventDefault();
    var data = [...tableData];
    const index = tableData.findIndex((d) => d.id === selectedSinger.id);
    if (direction === "up" && index !== 0) {
      await props.changingPositionsInQueue(direction, data, index);
      setPopperOpen(false);
      setAnchorEl(anchorEl ? null : e.currentTarget);
    }
    if (direction === "down" && index !== tableData.length - 1) {
      await props.changingPositionsInQueue(direction, data, index);
      setPopperOpen(false);
      setAnchorEl(anchorEl ? null : e.currentTarget);
    }
  };



  const moveToTopInQueue = async (e) => {
    const index = tableData.findIndex((d) => d.id === selectedSinger.id);
    if (index !== -1) {
      tableData.splice(index, 1);
    }
    tableData.unshift(selectedSinger);
    tableData.forEach((singer, index) => {
      singer.positionInQueue = index;
    });
    await props.moveToTopInQueue(tableData);
    setPopperOpen(false);
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleEditUserInput = (e, param) => {
    var input = e.target.value;
    editSingerInput.current[param] = input;
  };

  const submitEditMetric = async () => {
    let toReturn = filteredTableData.length
      ? [...filteredTableData]
      : [...tableData];
    const singerId = selectedSinger.id;
    var currentEdits = editSingerInput.current;
    currentEdits.songChoice = {
      song: currentEdits.song || selectedSinger.songChoice.song || "N/A",
      artist: currentEdits.artist || selectedSinger.songChoice.artist || "N/A",
    };
    const index = tableData.findIndex((d) => d.id === singerId);
    toReturn[index].songChoice = {
      song: currentEdits.song || toReturn[index].songChoice.song,
      artist: currentEdits.artist || toReturn[index].songChoice.artist,
    };

    toReturn[index] = {
      ...toReturn[index],
      displayName: currentEdits.displayName,
    };

    await props.editSingerInQueue(singerId, currentEdits);
    if (filteredTableData.length) {
      setFilteredTableData(toReturn);
    } else {
      setTableData(toReturn);
    }
    editSingerInput.current = { displayName: "", song: "", artist: "" };
    setIsEditing(false);
  };

  const editSinger = (event) => {
    setIsEditing(true);
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setPopperOpen(!popperOpen);
    editSingerInput.current = {
      displayName: selectedSinger.displayName,
      song: selectedSinger.songChoice?.song || "N/A",
      artist: selectedSinger.songChoice?.artist || "N/A",
    };
  };

  const renderTableHeader = (table) => {
    return tableHeaders.map((key, index) => {
      if (key === "Display Name") {
        return (
          <th
            style={{ display: "flex", flexDirection: "column" }}
            className={classes.tableHeader}
            key={index}
          >
            {key}{" "}
            <TextField
              value={nameSearchRef}
              InputProps={{
                classes: {
                  underline: classes.underline,
                },
              }}
              onChange={(e) => filterData(e, "displayName", table)}
            />{" "}
          </th>
        );
      }
      return (
        <th className={classes.tableHeader} key={index}>
          {key}{" "}
          <TextField
            InputProps={{
              classes: {
                input: classes.textField,
              },
            }}
            style={{ width: "100%", visibility: "hidden" }}
          />{" "}
        </th>
      );
    });
  };

  const renderTableData = (table) => {
    var data;
    if (table === "current") {
      data = tableData;
    } else {
      data = archivedTableData;
    }
    if (
      filteredTableData.length === 0 &&
      filteredArchiveTableData.length === 0 &&
      tableData &&
      archivedTableData
    ) {
      return data.map((singer, index) => {
        if (isEditing) {
          return singer.id === selectedSinger.id ? (
            <tr
              style={
                singer.downloadStatus === "Denied"
                  ? {
                      fontWeight: 500,
                      color: "red",
                      height: "55px",
                      fontSize: "16px",
                    }
                  : singer.hasSung
                  ? {
                      fontWeight: 500,
                      color: "#028392",
                      height: "55px",
                      fontSize: "16px",
                    }
                  : {
                      fontWeight: 500,
                      color: "#f9b518",
                      height: "55px",
                      fontSize: "16px",
                    }
              }
              key={index}
            >
              <td style={{ textAlign: "center" }}> {index + 1}</td>
              {singer.uid ? (
                <td style={{ textAlign: "center" }}>{singer.displayName}</td>
              ) : (
                <td style={{ textAlign: "center" }}>
                  <input
                    style={{ marginLeft: "20px" }}
                    className={classes.editTextInput}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        submitEditMetric("current");
                      }
                    }}
                    onChange={(e) => handleEditUserInput(e, "displayName")}
                    type="text"
                    defaultValue={singer.displayName || "N/A"}
                  ></input>
                </td>
              )}

              <td style={{ textAlign: "center" }}>
                {" "}
                <input
                  className={classes.editTextInput}
                  onKeyPress={(e) => {
                    if (e.which === 13) {
                      submitEditMetric("current");
                    }
                  }}
                  onChange={(e) => handleEditUserInput(e, "song")}
                  type="text"
                  defaultValue={
                    singer.songChoice ? singer.songChoice.song : "N/A"
                  }
                ></input>
              </td>
              <td style={{ textAlign: "center" }}>
                {" "}
                <input
                  className={classes.editTextInput}
                  onKeyPress={(e) => {
                    if (e.which === 13) {
                      submitEditMetric("current");
                    }
                  }}
                  onChange={(e) => handleEditUserInput(e, "artist")}
                  type="text"
                  defaultValue={
                    singer.songChoice ? singer.songChoice.artist : "N/A"
                  }
                ></input>
              </td>

              <td style={{ textAlign: "center" }}>
                {" "}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    onClick={() => submitEditMetric("current")}
                    className={classes.saveBtn}
                    style={{ marginRight: "15px" }}
                  >
                    Save
                  </button>
                  <button
                    className={classes.editCancelBtn}
                    onClick={cancelEditUser}
                  >
                    Cancel
                  </button>
                </div>
              </td>
            </tr>
          ) : (
            <tr
              style={
                singer.downloadStatus === "Denied"
                  ? {
                      fontWeight: 500,
                      color: "red",
                      height: "55px",
                      fontSize: "16px",
                    }
                  : singer.hasSung
                  ? {
                      fontWeight: 500,
                      color: "#028392",
                      height: "55px",
                      fontSize: "16px",
                    }
                  : {
                      fontWeight: 500,
                      color: "#f9b518",
                      height: "55px",
                      fontSize: "16px",
                    }
              }
              key={index}
            >
              <td style={{ textAlign: "center" }}> {index + 1}</td>
              <td style={{ textAlign: "center" }}>{singer.displayName}</td>
              <td style={{ textAlign: "center" }}>
                {singer.songChoice ? singer.songChoice.song : "N/A"}
              </td>
              <td style={{ textAlign: "center" }}>
                {singer.songChoice ? singer.songChoice.artist : "N/A"}
              </td>
              {/* <ClickAwayListener onClickAway={() => setPopperOpen(!popperOpen)}> */}
              <td
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                  alignItems: "center",
                  paddingTop: "8px",
                }}
              >
                {singer.downloadStatus !== "Denied" ? (
                  <CheckIcon
                    onClick={(event) => setSingerSang(event, index)}
                    className={classes.micIcon}
                  />
                ) : (
                  <CheckIcon
                    className={classes.micIcon}
                    style={{ visibility: "hidden" }}
                  />
                )}
                <MoreVertOutlinedIcon
                  aria-describedby={"transitions-popper"}
                  onClick={(event) => {
                    setSpecificSinger(event, singer);
                    setAnchorEl(anchorEl ? null : event.currentTarget);
                  }}
                  className={classes.threeDots}
                />
                <Popper
                  style={!anchorEl ? { display: "none" } : { zIndex: 1000 }}
                  placement={"right"}
                  id={"transitions-popper"}
                  open={popperOpen}
                  anchorEl={anchorEl}
                  transition
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps}>
                      <Card style={{ width: "154px", display: "flex" }}>
                        <Button
                          style={{ marginTop: "-10px", marginBottom: "-15px" }}
                          onClick={(e) => {
                            setAnchorEl(anchorEl ? null : e.currentTarget);
                            setPopperOpen(!popperOpen);
                          }}
                          simple
                          className={classes.modalCloseButton}
                          key="close"
                          aria-label="Close"
                        >
                          {" "}
                          <Close className={classes.modalClose} />
                        </Button>

                        <div
                          onClick={(event) => editSinger(event)}
                          className={classes.popperDiv}
                        >
                          <div className={classes.popperSelections}>
                            Edit
                            <EditOutlinedIcon />
                          </div>
                        </div>

                        {!filteredTableData ? (
                          <div>
                            <div
                              onClick={(e) => moveRowIndex(e, "up")}
                              className={classes.popperDiv}
                            >
                              <p className={classes.popperSelections}>
                                Move Up One
                              </p>
                              <ExpandLessIcon />
                            </div>
                            <div
                              onClick={(e) => moveRowIndex(e, "down")}
                              className={classes.popperDiv}
                            >
                              <p className={classes.popperSelections}>
                                Move Down One
                              </p>
                              <ExpandMoreIcon />
                            </div>

                            <div
                              onClick={moveToTopInQueue}
                              className={classes.popperDiv}
                            >
                              <div className={classes.popperSelections}>
                                Move To Top
                                <ArrowUpwardIcon />
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </Card>
                    </Fade>
                  )}
                </Popper>
              </td>
              {/* </ClickAwayListener> */}
            </tr>
          );
        } else {
          return (
            <tr
              id="tableRow"
              style={
                singer.downloadStatus === "Denied"
                  ? {
                      fontWeight: 500,
                      color: "red",
                      height: "55px",
                      fontSize: "16px",
                    }
                  : singer.hasSung &&
                    singerNameReadyToBeMoved !== singer.displayName
                  ? {
                      fontWeight: 500,
                      color: "#028392",
                      height: "55px",
                      fontSize: "16px",
                    }
                  : singerNameReadyToBeMoved === singer.displayName
                  ? {
                      fontWeight: 500,
                      color: "green",
                      height: "55px",
                      fontSize: "16px",
                    }
                  : {
                      fontWeight: 500,
                      color: "#f9b518",
                      height: "55px",
                      fontSize: "16px",
                    }
              }
              key={index}
            >
              <td style={{ textAlign: "center" }}> {index + 1}</td>
              <td style={{ textAlign: "center" }}>{singer.displayName}</td>
              <td style={{ textAlign: "center" }}>
                {singer.songChoice ? singer.songChoice.song : "N/A"}
              </td>
              <td style={{ textAlign: "center" }}>
                {singer.songChoice ? singer.songChoice.artist : "N/A"}
              </td>
              {table === "current" ? (
                <td
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100%",
                    alignItems: "center",
                    paddingTop: "8px",
                  }}
                >
                  {singer.downloadStatus !== "Denied" ? (
                    <CheckIcon
                      onClick={(event) => setSingerSang(event, index)}
                      className={classes.micIcon}
                    />
                  ) : (
                    <CheckIcon
                      className={classes.micIcon}
                      style={{ visibility: "hidden" }}
                    />
                  )}
                  {singerNameReadyToBeMoved === singer.displayName ? (
                    <ImportExportIcon
                      onClick={async () => {
                        await props.setNewOrderForQueue(dataRef.current);
                        setSingerSelectedToMove(false);
                        setSingerNameReadyToBeMoved(null);
                        setOrderSavedSuccesfullyModal(true);
                      }}
                      className={classes.micIcon}
                    />
                  ) : (
                    <ImportExportIcon style={{ visibility: "hidden" }} />
                  )}
                  <MoreVertOutlinedIcon
                    aria-describedby={"transitions-popper"}
                    onClick={(event) => {
                      setSpecificSinger(event, singer);
                      setAnchorEl(anchorEl ? null : event.currentTarget);
                    }}
                    className={classes.threeDots}
                  />
                  <Popper
                    style={!anchorEl ? { display: "none" } : { zIndex: 1000 }}
                    placement={"right"}
                    id={"transitions-popper"}
                    open={popperOpen}
                    anchorEl={anchorEl}
                    transition
                  >
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps}>
                        <Card style={{ width: "154px", display: "flex" }}>
                          <Button
                            style={{
                              marginTop: "-10px",
                              marginBottom: "-15px",
                            }}
                            onClick={(e) => {
                              setAnchorEl(anchorEl ? null : e.currentTarget);
                              setPopperOpen(!popperOpen);
                            }}
                            simple
                            className={classes.modalCloseButton}
                            key="close"
                            aria-label="Close"
                          >
                            {" "}
                            <Close className={classes.modalClose} />
                          </Button>
                          <div
                            onClick={(event) => editSinger(event)}
                            className={classes.popperDiv}
                          >
                            <div className={classes.popperSelections}>
                              Edit
                              <EditOutlinedIcon />
                            </div>
                          </div>

                          <div
                            onClick={(e) => {
                              setDeleteSingerModal(true);
                              setPopperOpen(!popperOpen);
                              setAnchorEl(anchorEl ? null : e.currentTarget);
                            }}
                            className={classes.popperDiv}
                          >
                            <div className={classes.popperSelections}>
                              Delete
                              <DeleteOutlineOutlinedIcon />
                            </div>
                          </div>

                          {filteredTableData.length === 0 ? (
                            <div>
                              <div
                                onClick={(e) => moveRowIndex(e, "up")}
                                className={classes.popperDiv}
                              >
                                <p className={classes.popperSelections}>
                                  Move Up One
                                </p>
                                <ExpandLessIcon />
                              </div>
                              <div
                                onClick={(e) => moveRowIndex(e, "down")}
                                className={classes.popperDiv}
                              >
                                <p className={classes.popperSelections}>
                                  Move Down One
                                </p>
                                <ExpandMoreIcon />
                              </div>

                              {!singerNameReadyToBeMoved && (
                                <div
                                  onClick={(e) => {
                                    setIsEditing(false);
                                    setPopperOpen(false);
                                    setSingerSelectedToMove(true);
                                    setAnchorEl(
                                      anchorEl ? null : e.currentTarget
                                    );
                                    setIsSingerReadyToMove(true);
                                    setSingerNameReadyToBeMoved(
                                      selectedSinger.displayName
                                    );
                                  }}
                                  className={classes.popperDiv}
                                >
                                  <p className={classes.popperSelections}>
                                    Select Singer
                                  </p>
                                  <ImportExportIcon />
                                </div>
                              )}

                              {/* )} */}

                              <div
                                onClick={moveToTopInQueue}
                                className={classes.popperDiv}
                              >
                                <div className={classes.popperSelections}>
                                  Move To Top
                                  <ArrowUpwardIcon />
                                </div>
                              </div>

                              {/* <div className={classes.popperDiv}
                            onClick={(event) => setSingerSang(event, index)}
                            >
                            <div className={classes.popperSelections}>
                                Singer Has Sung
                                <DoneIcon />
                              </div>
                            </div> */}
                            </div>
                          ) : null}
                        </Card>
                      </Fade>
                    )}
                  </Popper>
                </td>
              ) : (
                <td
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100%",
                    alignItems: "center",
                    paddingTop: "8px",
                  }}
                >
                  <AddIcon
                    onClick={() => checkIfSingerAlreadyInQueue(singer)}
                    className={classes.addBackToQueueBtn}
                  />
                </td>
              )}
            </tr>
          );
        }
      });
    } else {
      if (filteredTableData.length > 0 || filteredArchiveTableData.length > 0) {
        let data;
        if (table === "current") {
          data = filteredTableData;
        } else {
          data = filteredArchiveTableData;
        }

        return data.map((singer, index) => {
          if (isEditing) {
            return singer.id === selectedSinger.id ? (
              <tr
                style={
                  singer.downloadStatus === "Denied"
                    ? {
                        fontWeight: 500,
                        color: "red",
                        height: "55px",
                        fontSize: "16px",
                      }
                    : singer.hasSung
                    ? {
                        fontWeight: 500,
                        color: "#028392",
                        height: "55px",
                        fontSize: "16px",
                      }
                    : {
                        fontWeight: 500,
                        color: "#f9b518",
                        height: "55px",
                        fontSize: "16px",
                      }
                }
                key={index}
              >
                <td style={{ textAlign: "center" }}> {index + 1}</td>
                {singer.uid ? (
                  <td style={{ textAlign: "center" }}>{singer.displayName}</td>
                ) : (
                  <td
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        submitEditMetric("current");
                      }
                    }}
                    style={{ textAlign: "center" }}
                  >
                    {" "}
                    <input
                      className={classes.editTextInput}
                      onChange={(e) => handleEditUserInput(e, "displayName")}
                      type="text"
                      defaultValue={singer.displayName || "N/A"}
                    ></input>
                  </td>
                )}
                <td
                  onKeyPress={(e) => {
                    if (e.which === 13) {
                      submitEditMetric("current");
                    }
                  }}
                  style={{ textAlign: "center" }}
                >
                  {" "}
                  <input
                    className={classes.editTextInput}
                    onChange={(e) => handleEditUserInput(e, "song")}
                    type="text"
                    defaultValue={
                      singer.songChoice ? singer.songChoice.song : "N/A"
                    }
                  ></input>
                </td>
                <td
                  onKeyPress={(e) => {
                    if (e.which === 13) {
                      submitEditMetric("current");
                    }
                  }}
                  style={{ textAlign: "center" }}
                >
                  {" "}
                  <input
                    className={classes.editTextInput}
                    onChange={(e) => handleEditUserInput(e, "artist")}
                    type="text"
                    defaultValue={
                      singer.songChoice ? singer.songChoice.artist : "N/A"
                    }
                  ></input>
                </td>

                <td
                  onKeyPress={(e) => {
                    if (e.which === 13) {
                      submitEditMetric("current");
                    }
                  }}
                  style={{ textAlign: "center" }}
                >
                  {" "}
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      onClick={() => submitEditMetric("current")}
                      className={classes.saveBtn}
                      style={{ marginRight: "15px" }}
                    >
                      Save
                    </button>
                    <button
                      className={classes.editCancelBtn}
                      onClick={cancelEditUser}
                    >
                      Cancel
                    </button>
                  </div>
                </td>
              </tr>
            ) : (
              <tr
                style={
                  singer.hasSung
                    ? {
                        fontWeight: 500,
                        color: "#028392",
                        height: "55px",
                        fontSize: "16px",
                      }
                    : {
                        fontWeight: 500,
                        color: "#f9b518",
                        height: "55px",
                        fontSize: "16px",
                      }
                }
                key={index}
              >
                <td style={{ textAlign: "center" }}> {index + 1}</td>
                <td style={{ textAlign: "center" }}>{singer.displayName}</td>
                <td style={{ textAlign: "center" }}>
                  {singer.songChoice ? singer.songChoice.song : "N/A"}
                </td>
                <td style={{ textAlign: "center" }}>
                  {singer.songChoice ? singer.songChoice.artist : "N/A"}
                </td>
                {/* <ClickAwayListener onClickAway={() => setPopperOpen(!popperOpen)}> */}

                {table === "current" ? (
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "100%",
                      alignItems: "center",
                      paddingTop: "8px",
                    }}
                  >
                    {singer.downloadStatus !== "Denied" ? (
                      <CheckIcon
                        onClick={(event) => setSingerSang(event, index)}
                        className={classes.micIcon}
                      />
                    ) : (
                      <CheckIcon
                        className={classes.micIcon}
                        style={{ visibility: "hidden" }}
                      />
                    )}
                    <MoreVertOutlinedIcon
                      aria-describedby={"transitions-popper"}
                      onClick={(event) => {
                        setSpecificSinger(event, singer);
                        setAnchorEl(anchorEl ? null : event.currentTarget);
                      }}
                      className={classes.threeDots}
                    />
                    <Popper
                      style={!anchorEl ? { display: "none" } : { zIndex: 1000 }}
                      placement={"right"}
                      id={"transitions-popper"}
                      open={popperOpen}
                      anchorEl={anchorEl}
                      transition
                    >
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps}>
                          <Card style={{ width: "154px", display: "flex" }}>
                            <Button
                              style={{
                                marginTop: "-10px",
                                marginBottom: "-15px",
                              }}
                              onClick={(e) => {
                                setAnchorEl(anchorEl ? null : e.currentTarget);
                                setPopperOpen(!popperOpen);
                              }}
                              simple
                              className={classes.modalCloseButton}
                              key="close"
                              aria-label="Close"
                            >
                              {" "}
                              <Close className={classes.modalClose} />
                            </Button>

                            <div
                              onClick={(event) => editSinger(event)}
                              className={classes.popperDiv}
                            >
                              <div className={classes.popperSelections}>
                                Edit
                                <EditOutlinedIcon />
                              </div>
                            </div>
                          </Card>
                        </Fade>
                      )}
                    </Popper>
                  </td>
                ) : (
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "100%",
                      alignItems: "center",
                      paddingTop: "8px",
                    }}
                  >
                    <AddIcon
                      onClick={() => checkIfSingerAlreadyInQueue(singer)}
                      className={classes.addBackToQueueBtn}
                    />
                  </td>
                )}
              </tr>
            );
          } else {
            return (
              <tr
                style={
                  singer.hasSung
                    ? {
                        fontWeight: 500,
                        color: "#028392",
                        height: "55px",
                        fontSize: "16px",
                      }
                    : {
                        fontWeight: 500,
                        color: "#f9b518",
                        height: "55px",
                        fontSize: "16px",
                      }
                }
                key={index}
              >
                <td style={{ textAlign: "center" }}> {index + 1}</td>
                <td style={{ textAlign: "center" }}>{singer.displayName}</td>
                <td style={{ textAlign: "center" }}>
                  {singer.songChoice ? singer.songChoice.song : "N/A"}
                </td>
                <td style={{ textAlign: "center" }}>
                  {singer.songChoice ? singer.songChoice.artist : "N/A"}
                </td>
                {/* <ClickAwayListener onClickAway={() => setPopperOpen(!popperOpen)}> */}

                {table === "current" ? (
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "100%",
                      alignItems: "center",
                      paddingTop: "8px",
                    }}
                  >
                    {singer.downloadStatus !== "Denied" ? (
                      <CheckIcon
                        onClick={(event) => setSingerSang(event, index)}
                        className={classes.micIcon}
                      />
                    ) : (
                      <CheckIcon
                        className={classes.micIcon}
                        style={{ visibility: "hidden" }}
                      />
                    )}
                    <MoreVertOutlinedIcon
                      aria-describedby={"transitions-popper"}
                      onClick={(event) => {
                        setSpecificSinger(event, singer);
                        setAnchorEl(anchorEl ? null : event.currentTarget);
                      }}
                      className={classes.threeDots}
                    />
                    <Popper
                      style={!anchorEl ? { display: "none" } : { zIndex: 1000 }}
                      placement={"right"}
                      id={"transitions-popper"}
                      open={popperOpen}
                      anchorEl={anchorEl}
                      transition
                    >
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps}>
                          <Card style={{ width: "154px", display: "flex" }}>
                            <Button
                              style={{
                                marginTop: "-10px",
                                marginBottom: "-15px",
                              }}
                              onClick={(e) => {
                                setAnchorEl(anchorEl ? null : e.currentTarget);
                                setPopperOpen(!popperOpen);
                              }}
                              simple
                              className={classes.modalCloseButton}
                              key="close"
                              aria-label="Close"
                            >
                              {" "}
                              <Close className={classes.modalClose} />
                            </Button>

                            <div
                              onClick={(event) => editSinger(event)}
                              className={classes.popperDiv}
                            >
                              <div className={classes.popperSelections}>
                                Edit
                                <EditOutlinedIcon />
                              </div>
                            </div>

                            <div
                              onClick={(e) => {
                                setDeleteSingerModal(true);
                                setPopperOpen(!popperOpen);
                                setAnchorEl(anchorEl ? null : e.currentTarget);
                              }}
                              className={classes.popperDiv}
                            >
                              <div className={classes.popperSelections}>
                                Delete
                                <DeleteOutlineOutlinedIcon />
                              </div>
                            </div>
                          </Card>
                        </Fade>
                      )}
                    </Popper>
                  </td>
                ) : (
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "100%",
                      alignItems: "center",
                      paddingTop: "8px",
                    }}
                  >
                    <AddIcon
                      onClick={() => checkIfSingerAlreadyInQueue(singer)}
                      className={classes.addBackToQueueBtn}
                    />
                  </td>
                )}
                {/* </ClickAwayListener> */}
              </tr>
            );
          }
        });
      }
    }
  };

  const renderTableHeaderRequest = () => {
    return requestHeaders.map((key, index) => {
      return (
        <th
          className={classes.tableHeader}
          style={{ whiteSpace: "pre" }}
          key={index}
        >
          {key !== "Actions" ? <div>{key}</div> : <div>{key}</div>}
          <TextField
            InputProps={{
              classes: {
                input: classes.textField,
              },
            }}
            style={{ width: "100%", visibility: "hidden" }}
          />
        </th>
      );
    });
  };

  const renderTableDataRequest = () => {
    return bothData.map((result, index) => {
      return (
        <tr
          id="tableRow"
          style={{
            fontWeight: 500,
            height: "55px",
            fontSize: "16px",
          }}
          key={index}
        >
          <td style={{ textAlign: "center" }}>
            {result.type === "dance"
              ? result.song + result.artist
              : result.type === "download" && result.songRequest
              ? result.songRequest.song + " - " + result.songRequest.artist
              : null}
          </td>
          <td style={{ textAlign: "center" }}>{result.requestedBy}</td>
          <td style={{ textAlign: "center" }}>
            {result.type === "dance" ? (
              <div style={{ display: "flex" }}>
                <Tooltip
                  id="tooltip-top"
                  title="Confirm Dance Request"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <div>
                    <Button
                      onClick={() => {
                        setSpecificRequest(result);
                        setDancerRequestIndex(index);
                        setConfirmedModal(true);
                      }}
                      className={classes.actionBtn2}
                    >
                      <Check />
                    </Button>
                  </div>
                </Tooltip>

                <Tooltip
                  id="tooltip-top"
                  title="Deny Dance Request"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <div>
                    <Button
                      onClick={() => {
                        setSpecificRequest(result);
                        setDancerRequestIndex(index);
                        setDeniedModal(true);
                      }}
                      className={classes.actionBtn2}
                    >
                      <Close />
                    </Button>
                  </div>
                </Tooltip>
              </div>
            ) : (
              <div style={{ display: "flex" }}>
                <Tooltip
                  id="tooltip-top"
                  title="Confirm Download Request"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <div>
                    <Button
                      onClick={(event) => {
                        setAbleToDownloadModal(true);
                        setSingerRequestIndex(index);
                        setSpecificDownloadSinger(event, index);
                      }}
                      className={classes.actionBtn2}
                    >
                      <Check />
                    </Button>
                  </div>
                </Tooltip>

                <Tooltip
                  id="tooltip-top"
                  title="Deny Download Request"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <div>
                    <Button
                      onClick={(event) => {
                        setSpecificDownloadSinger(event, index);
                        setSingerRequestIndex(index);
                        denyDownloadRequest(index);
                      }}
                      className={classes.actionBtn2}
                    >
                      <Close />
                    </Button>
                  </div>
                </Tooltip>
              </div>
            )}
          </td>
        </tr>
      );
    });
  };

  return (
    <div style={{ width: "100%" }} className={classes.container}>
      {props.queue.data &&
      props.userInRange !== undefined &&
      danceDataLoaded &&
      downloadDataLoaded ? (
        <GridContainer>
          <GridItem className={classes.tableGridItem} xs={12} md={6}>
            <CustomTabs
              headerColor="primary"
              style={{ width: "100%", background: "#2A2A2A !important" }}
              tabs={[
                {
                  id: "current",
                  tabName: "Current Queue",
                  // tabIcon: () => ,
                  tabContent: (
                    <Card
                      style={{
                        height: "1200px",
                        overflow: "auto",
                        background: "#2A2A2A",
                      }}
                      className={classes.cardBody}
                    >
                      <table style={{ width: "100%" }}>
                        <tbody>
                          <tr style={{ height: "50px" }}>
                            {renderTableHeader("current")}
                          </tr>
                          {renderTableData("current")}
                        </tbody>
                      </table>
                    </Card>
                  ),
                },
                {
                  id: "archive",
                  tabName: "Queue Archive",
                  // tabIcon: () => ,
                  tabContent: (
                    <Card
                      style={{ height: "1200px", overflow: "auto" }}
                      className={classes.cardBody}
                    >
                      <table style={{ width: "100%" }}>
                        <tbody>
                          <tr style={{ height: "50px" }}>
                            {renderTableHeader("archive")}{" "}
                          </tr>
                          {renderTableData("archive")}
                        </tbody>
                      </table>
                    </Card>
                  ),
                },
              ]}
            />
          </GridItem>

          <GridItem xs={12} md={6}>
            <Card
              style={{ height: "1284px", width: "100%", marginTop: "30px" }}
              className={classes.cardBody}
            >
              <CardHeader className={classes.cardHeader2}>
                Dance & Download Requests
              </CardHeader>
              <table className={classes.table} style={{ marginTop: "35px" }}>
                <tbody>
                  <tr style={{ height: "50px" }}>
                    {renderTableHeaderRequest()}
                  </tr>
                  {renderTableDataRequest()}
                </tbody>
              </table>
            </Card>
          </GridItem>
          {/* <GridItem className={classes.inputGridItem} xs={12}> */}

          <Hidden smDown>
            <AddSinger
              classes={props.classes}
              onAddSinger={addNewSinger}
            />
          </Hidden>
          {/* </GridItem> */}



          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalSmall,
            }}
            open={addSingerBackToQueueModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setAddSingerBackToQueueModal(false);
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >
            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setAddSingerBackToQueueModal(false);
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              <h3 style={{ textAlign: "center", marginTop: "50px" }}>
                Are you sure you want to add{" "}
                {currentSingerInArchive.displayName} back to the current queue?
              </h3>
            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Button
                  onClick={async () => {
                    await props.addSingerBackToQueue(currentSingerInArchive);
                    setAddSingerBackToQueueModal(false);
                    setCurrentSingerInArchive({});
                    setSuccessfullyAddedSingerBackToCurrentQueue(true);
                  }}
                  color="danger"
                >
                  Yes, add them back.
                </Button>
                <Button
                  onClick={() => {
                    setAddSingerBackToQueueModal(false);
                    setCurrentSingerInArchive({});
                  }}
                  color="primary"
                >
                  No, nevermind.
                </Button>
              </div>
            </DialogContent>
          </Dialog>

          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalSmall,
            }}
            open={deleteSingerModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setDeleteSingerModal(false);
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >
            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setDeleteSingerModal(false);
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              <h3 style={{ textAlign: "center", marginTop: "50px" }}>
                Are you sure you want to delete {selectedSinger?.displayName}{" "}
                from the queue?
              </h3>
            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Button onClick={deleteSinger} color="danger">
                  Delete
                </Button>
                <Button
                  onClick={() => {
                    setDeleteSingerModal(false);
                  }}
                  color="primary"
                >
                  Cancel
                </Button>
              </div>
            </DialogContent>
          </Dialog>

          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalLarge,
            }}
            open={confirmedModal}
            TransitionComponent={UpTransition}
            keepMounted
            onClose={() => {
              setConfirmedModal(false);
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >
            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setConfirmedModal(false);
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>

              {specificRequest ? (
                <h3 className={classes.searchThroughSpotifyHeader}>
                  {`Are you sure you want to confirm ${specificRequest.requestedBy}'s dance request?`}
                </h3>
              ) : null}
            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Button
                  onClick={async () => {
                    var newData = danceData.filter(
                      (_, index) => index !== dancerRequestIndex
                    );
                    await props.updateRequestStatus(
                      "Confirmed",
                      specificRequest,
                      newData
                    );
                    setConfirmedModal(false);
                  }}
                  color="primary"
                >
                  Positive
                </Button>
                <Button
                  onClick={() => {
                    setConfirmedModal(false);
                  }}
                  color="danger"
                >
                  {`I've reconsidered`}
                </Button>
              </div>
            </DialogContent>
          </Dialog>

          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalLarge,
            }}
            open={deniedModal}
            TransitionComponent={UpTransition}
            keepMounted
            onClose={() => {
              setDeniedModal(false);
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >
            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setDeniedModal(false);
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>

              {specificRequest ? (
                <h3 className={classes.searchThroughSpotifyHeader}>
                  {`Are you sure you want to deny ${specificRequest.requestedBy}'s
                  dance request?`}
                </h3>
              ) : null}
            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Button
                  onClick={async (event) => {
                    var newData = danceData.filter(
                      (_, index) => index !== dancerRequestIndex
                    );
                    await props.updateRequestStatus(
                      "Denied",
                      specificRequest,
                      newData
                    );
                    setAnchorEl(anchorEl ? null : event.currentTarget);
                    setPopperOpen(!popperOpen);
                    setDeniedModal(false);
                  }}
                  color="primary"
                >
                  Positive
                </Button>
                <Button
                  onClick={(e) => {
                    setDeniedModal(false);
                    setAnchorEl(anchorEl ? null : e.currentTarget);
                    setPopperOpen(!popperOpen);
                  }}
                  color="danger"
                >
                  {`I've reconsidered`}
                </Button>
              </div>
            </DialogContent>
          </Dialog>

          <Dialog
            style={{ marginTop: "35px" }}
            classes={{
              root: classes.modalRoot,
              paper: classes.modal + " " + classes.modalSmall,
            }}
            open={ableToDownloadModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setAbleToDownloadModal(false);
            }}
            aria-labelledby="shipment-modal-slide-title"
            aria-describedby="shipment-modal-slide-description"
          >
            <DialogTitle
              id="shipment-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                onClick={() => {
                  setAbleToDownloadModal(false);
                }}
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
              <h3 style={{ textAlign: "center", marginTop: "50px" }}>
                Were you able to download the song?
              </h3>
            </DialogTitle>

            <DialogContent
              id="shipment-modal-slide-description"
              className={classes.modalBody}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                {songList.length > 0 && (
                  <>
                    <Button
                      onClick={() =>
                        succesfullyDownloadedSong(singerRequestIndex)
                      }
                      color="primary"
                    >
                      Yes, I got it
                    </Button>
                    <Button
                      onClick={() => {
                        setAbleToDownloadModal(false);
                      }}
                      color="danger"
                    >
                      {`No, It's Unavailable`}
                    </Button>
                  </>
                )}
              </div>
            </DialogContent>
          </Dialog>

          <Snackbar
            style={{ marginBottom: "30px" }}
            open={addedSingerSuccess}
            onClose={() => {
              setAddedSingerSuccess(false);
            }}
            autoHideDuration={4000}
          >
            <Alert
              onClose={() => {
                setAddedSingerSuccess(false);
              }}
              severity="success"
            >
              Added singer to the queue successfully!
            </Alert>
          </Snackbar>

          <Snackbar
            style={{ marginBottom: "30px" }}
            open={orderSavedSuccesfullyModal}
            onClose={() => {
              setOrderSavedSuccesfullyModal(false);
            }}
            autoHideDuration={4000}
          >
            <Alert
              onClose={() => {
                setOrderSavedSuccesfullyModal(false);
              }}
              severity="success"
            >
              The order of the queue was saved successfully!
            </Alert>
          </Snackbar>

          <Snackbar
            style={{ marginBottom: "30px" }}
            open={userMustBeWithinRangeAlert}
            onClose={() => {
              setUserMustBeWithinRangeAlert(false);
            }}
            autoHideDuration={4000}
          >
            <Alert
              onClose={() => {
                setUserMustBeWithinRangeAlert(false);
              }}
              severity="error"
            >
              {`Unfortunately you must be connected PCL's wifi to be added to the
              queue. Please connect and then refresh the app.`}
            </Alert>
          </Snackbar>

          <Snackbar
            style={{ marginBottom: "30px" }}
            open={isSingerReadyToMove}
            onClose={() => {
              setIsSingerReadyToMove(false);
            }}
            autoHideDuration={4000}
          >
            <Alert
              onClose={() => {
                setIsSingerReadyToMove(false);
              }}
              severity="success"
            >
              You have selected {selectedSinger?.displayName}. Use your up and
              down arrows to move them throughout the queue.
            </Alert>
          </Snackbar>

          <Snackbar
            style={{ marginBottom: "30px" }}
            open={nameCantBeEmpty}
            onClose={() => {
              setNameCantBeEmpty(false);
            }}
            autoHideDuration={4000}
          >
            <Alert
              onClose={() => {
                setNameCantBeEmpty(false);
              }}
              severity="error"
            >
              All fields are mandatory. Please try again.
            </Alert>
          </Snackbar>

          <Snackbar
            style={{ marginBottom: "30px" }}
            open={nameExistsAlreadyInQueue}
            onClose={() => {
              setNameExistsAlreadyInQueue(false);
            }}
            autoHideDuration={4000}
          >
            <Alert
              onClose={() => {
                setNameExistsAlreadyInQueue(false);
              }}
              severity="error"
            >
              Unfortunately that name already exists in queue, please slightly
              modify it.
            </Alert>
          </Snackbar>

          <Snackbar
            style={{ marginBottom: "30px" }}
            open={successfullyAddedSingerBackToCurrentQueue}
            onClose={() => {
              setSuccessfullyAddedSingerBackToCurrentQueue(false);
            }}
            autoHideDuration={4000}
          >
            <Alert
              onClose={() => {
                setSuccessfullyAddedSingerBackToCurrentQueue(false);
              }}
              severity="success"
            >
              The singer has been added back to the queue.
            </Alert>
          </Snackbar>

          <Snackbar
            style={{ marginBottom: "30px" }}
            open={successfullyDownloadedRequest}
            onClose={() => {
              setSuccessfullyDownloadedRequest(false);
            }}
            autoHideDuration={4000}
          >
            <Alert
              onClose={() => {
                setSuccessfullyDownloadedRequest(false);
              }}
              severity="success"
            >
              Successfully approved download request.
            </Alert>
          </Snackbar>

          <Snackbar
            style={{ marginBottom: "30px" }}
            open={singerIsAlreadyInCurrentQueueModal}
            onClose={() => {
              setSingerIsAlreadyInCurrentQueueModal(false);
            }}
            autoHideDuration={4000}
          >
            <Alert
              onClose={() => {
                setSingerIsAlreadyInCurrentQueueModal(false);
              }}
              severity="error"
            >
              Unfortunately that singer is already present in the current queue.
            </Alert>
          </Snackbar>
        </GridContainer>
      ) : (
        <div className={classes.easeInOut}>
          <div
            style={{
              height: "180%",
              zIndex: "99999",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              marginTop: "10%",
            }}
          >
            <img className={classes.loaderImg} src={Logo} alt="..." />
            <img
              style={{
                position: "relative",
                width: "15%",
              }}
              src={Loader}
              alt="..."
            />
          </div>
        </div>
      )}
    </div>
  );
};

QueueManager.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    queue: state.queue,
    userInRange: state.auth.isUserInRange,
    singerMoving: state.queue.singerMoving,
    authUser: state.auth.user,
    danceRequests: state.dance.danceRequestDetails.danceRequests,
    downloadRequests: state.downloadRequests,
    downloadDataLoaded:
      state.downloadRequests.downloadRequestDetails.dataLoaded,
    danceDataLoaded: state.dance.danceRequestDetails.dataLoaded,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteFromQueue: (singer) => dispatch(deleteFromQueue(singer)),
  editSingerInQueue: (singerId, singer) =>
    dispatch(editSingerInQueue(singerId, singer)),
  changingPositionsInQueue: (direction, singer, singerIndex, tableData) =>
    dispatch(
      changingPositionsInQueue(direction, singer, singerIndex, tableData)
    ),
  setUserHasSung: (singerId, currentSinger, tableData, index) =>
    dispatch(setUserHasSung(singerId, currentSinger, tableData, index)),
  getJustDatabaseSongList: () => dispatch(getJustDatabaseSongList()),
  addNewSingerAndSong: (newSinger) => dispatch(addNewSingerAndSong(newSinger)),
  //deleteAll: () => dispatch(deleteAll()),
  moveToTopInQueue: (toReturnData) => dispatch(moveToTopInQueue(toReturnData)),
  getArchivedQueue: () => dispatch(getArchivedQueue()),
  addSingerBackToQueue: (singer) => dispatch(addSingerBackToQueue(singer)),
  //deleteAllInArchive: () => dispatch(deleteAllInArchive()),
  setNewOrderForQueue: (newQueue) => dispatch(setNewOrderForQueue(newQueue)),
  getDanceRequests: () => dispatch(getDanceRequests()),
  getDownloadRequests: () => dispatch(getDownloadRequests()),
  succesfullyDownloadedSong: (singer, data, singerIndex, songList) =>
    dispatch(succesfullyDownloadedSong(singer, data, singerIndex, songList)),
  denyDownloadRequest: (singer, tableData) =>
    dispatch(denyDownloadRequest(singer, tableData)),
  updateRequestStatus: (status, requestInfo, data) =>
    dispatch(updateRequestStatus(status, requestInfo, data)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(queueManagerStyle)
)(QueueManager);
