/*eslint-disable*/
import React, { useRef, useEffect, useState } from "react";
import { Redirect } from 'react-router-dom'
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Link } from "react-router-dom";
import axios from 'axios'
import qs from 'qs';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import RequestForm from "components/Forms/QueueSubmitForm.jsx";
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { getDistance, isPointInPolygon } from 'geolib';



// actions
import { addToQueue, setUserCreatedFalse } from "store/actions/queueActions"

import requestsStyles from "assets/jss/material-dashboard-pro-react/views/requestsStyle.jsx";

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }


const Requests = (props) => {


  const { authError, classes } = props;
  const [nameRef, setNameRef] = useState('')
  const [songRef, setSongRef] = useState('')
  const [artistRef, setArtistRef] = useState('')
  const [fieldsNotFilled, setFieldsNotFilled] = useState(false)
  const [userNotInRangeAlert, setUserNotInRangeAlert] = useState(false)
  const [token, setToken] = useState('')

  const submitRequest = () => {
    if (!props.userInRange) {
      setUserNotInRangeAlert(true)
    } else if (!nameRef || !songRef ) {
      setFieldsNotFilled(true)
    } else {
      var toSubmit = { name: nameRef, song: songRef }
      props.addToQueue(toSubmit)
      setNameRef('')
      setSongRef('')
      setArtistRef('')

    }

  }





  return (
    <div className={classes.container}>
        <div className={classes.container}>
         <GridContainer>
         <GridItem xs={12} sm={12} md={12} className={classes.formContainer}>
           <Card className={classes.requestsForm}>
             <CardHeader color="primary">
               <h1 className={classes.requestsFormHeader} >Request A Song</h1>
             </CardHeader>
             <CardBody>
               {/* <TextField style={{ width: "33%" }}>

                 </TextField>
                 <TextField style={{ width: "33%" }}>

                 </TextField>
                 <TextField style={{ width: "33%" }}>

                 </TextField> */}
               <div className={classes.formDiv}>
                 <form className={classes.form} noValidate autoComplete="off">
                   <TextField value={nameRef} onChange={(e) => setNameRef(e.target.value)} className={classes.formTextField} id="Name" label="Your Name" />
                   <TextField value={songRef} onChange={(e) => setSongRef(e.target.value)} className={classes.formTextField} id="Song" label="Song" />
                   {/* <TextField value={artistRef} onChange={(e) => setArtistRef(e.target.value)} className={classes.formTextField} id="Artist" label="Artist" /> */}
                 </form>
                 <Button onClick={submitRequest} color="primary" className={classes.submitBtn} >Submit</Button>
               </div>
             </CardBody>
           </Card>
         </GridItem>
       </GridContainer>


       <Snackbar open={props.userCreated} onClose={() => props.setUserCreatedFalse()} autoHideDuration={6000} >
         <Alert onClose={() => props.setUserCreatedFalse()} severity="success">
           Request Created!
   </Alert>
       </Snackbar>

       <Snackbar open={fieldsNotFilled} onClose={() => setFieldsNotFilled(false)}>
           <Alert onClose={() => setFieldsNotFilled(false)} severity='error'>
             All Fields Must Be Filled
           </Alert>
         </Snackbar>


         <Snackbar open={userNotInRangeAlert} onClose={() => setUserNotInRangeAlert(false)}>
           <Alert onClose={() => setUserNotInRangeAlert(false)} severity='error'>
           Unfortunately you must be connected PCL's wifi to be added to the queue. Please connect and then refresh the app.
           </Alert>
         </Snackbar>
             </div>
    </div>
  );
}

Requests.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
    userCreated: state.queue.queueDetails.userCreated,
    userInRange: state.auth.isUserInRange
  }
}

const mapDispatchToProps = (dispatch) => ({
  addToQueue: (inputs) => dispatch(addToQueue(inputs)),
  setUserCreatedFalse: () => dispatch(setUserCreatedFalse())
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(requestsStyles)
)(Requests);
