import Fab from "@material-ui/core/Fab";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "../CustomButtons/Button";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import React, { useState } from "react";

import {
  deleteAllInArchive,
  deleteAllInQueue,
} from "store/actions/queueActions";

import { CircularProgress } from "@material-ui/core";

function DeleteAllInQueue(props) {

  const [deleteAllInQueueModal, setDeleteAllInQueueModal] = useState(false);
  const [deleteArchivedQueueModal, setDeleteArchivedQueueModal] = useState(
    false
  );

  const [deleteQueueLoading, setDeleteQueueLoading] = useState(false);
  const [deleteArchiveLoading, setDeleteArchiveLoading] = useState(false);

  const { classes } = props;

  return (
    <>
      <Button
        onClick={() => {
          setDeleteQueueLoading(false);
          setDeleteArchiveLoading(false);
          setDeleteAllInQueueModal(true);
        }}
        color="danger"
      >
        Delete
      </Button>

      <Dialog
        style={{ marginTop: "35px" }}
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalSmall,
        }}
        open={deleteAllInQueueModal}
        //TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setDeleteAllInQueueModal(false);
        }}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <DialogTitle
          id="shipment-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            onClick={() => {
              setDeleteAllInQueueModal(false);
            }}
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            {" "}
            <Close className={classes.modalClose} />
          </Button>
          <h3 style={{ textAlign: "center", marginTop: "50px" }}>
            Are you sure you want to delete all singers in the current
            queue?
          </h3>
        </DialogTitle>

        <DialogContent
          id="shipment-modal-slide-description"
          className={classes.modalBody}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Button
              onClick={ () => {
                setDeleteQueueLoading(true);
                deleteAllInQueue().then(() => {
                  setDeleteQueueLoading(false);
                  setDeleteAllInQueueModal(false);
                  setDeleteArchivedQueueModal(true);
                });
              }}
              color="danger"
            >
              {deleteQueueLoading ? <CircularProgress /> : "Delete"}
            </Button>
            <Button
              onClick={() => {
                setDeleteAllInQueueModal(false);
              }}
              color="primary"
            >
              Cancel
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        style={{ marginTop: "35px" }}
        classes={{
          root: classes.modalRoot,
          paper: classes.modal + " " + classes.modalSmall,
        }}
        open={deleteArchivedQueueModal}
        keepMounted
        onClose={() => {
          setDeleteArchivedQueueModal(false);
        }}
        aria-labelledby="shipment-modal-slide-title"
        aria-describedby="shipment-modal-slide-description"
      >
        <DialogTitle
          id="shipment-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            onClick={() => {
              setDeleteArchivedQueueModal(false);
            }}
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
          >
            {" "}
            <Close className={classes.modalClose} />
          </Button>
          <h3 style={{ textAlign: "center", marginTop: "50px" }}>
            Would you like to also delete all singers in the queue archive?
          </h3>
        </DialogTitle>

        <DialogContent
          id="shipment-modal-slide-description"
          className={classes.modalBody}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Button
              onClick={ () => {
                setDeleteArchiveLoading(true);
                deleteAllInArchive().then(() => {
                  setDeleteArchiveLoading(false);
                  setDeleteArchivedQueueModal(false);
                });
              }}
              color="danger"
            >
              {deleteArchiveLoading ? (
                <CircularProgress />
              ) : (
                "Yes, delete them as well."
              )}
            </Button>
            <Button
              onClick={() => {
                setDeleteArchivedQueueModal(false);
              }}
              color="primary"
            >
              No, leave them.
            </Button>
          </div>
        </DialogContent>
      </Dialog>

    </>
  );
}

export default DeleteAllInQueue;