/*eslint-disable*/
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import firebase, { db, storage } from "firebase/fbConfig.js"
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';

import axios from 'axios'

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Check from '@material-ui/icons/Check';
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import styles from "assets/jss/material-dashboard-pro-react/components/profileFormStyle.jsx";

import { updateUser, setUpdateProfileModal } from "store/actions/profileActions.jsx"

// Assets
import precincts from "variables/precincts.jsx"

const ProfileForm = (props) => {


  const { classes, uid, user, history, stateDisplayName, stateEmail } = props;



  const [firstName, setFirstName] = useState(props.user.firstName || '')
  const [lastName, setLastName] = useState(props.user.lastName || '')
  const [email, setEmail] = useState(stateEmail ? stateEmail : props.user.email ? props.user.email :  '')
  const [phoneNumber, setPhoneNumber] = useState(props.user.phoneNumber || '')
  // const [bio, setBio] = useState(props.user.bio || '')
  const [fieldsNotFilled, setFieldsNotFilled] = useState(false)
  const [displayName, setDisplayName] = useState(user.displayName ? user.displayName : stateDisplayName ? stateDisplayName : "")
  const [displayNameAlreadyExistsSnackBar, setDisplayNameAlreadyExistsSnackBar] = useState(false)



  const saveChanges = (e) => {
    e.preventDefault()
    var displayNameAlreadyExists = false
    if (!displayName) {
      setFieldsNotFilled(true)
    } else {
      return db.collection("TEST-users").get()
        .then((snapshot) => {
          snapshot = snapshot.docs
          snapshot.forEach((doc) => {
            var docId = doc.id
            doc = doc.data()
            if (doc.displayName && doc.displayName === displayName && docId !== uid) {
              displayNameAlreadyExists = true
            }
          })
          if (!displayNameAlreadyExists) {
            return db.collection('TEST-users').doc(uid).get()
              .then((results) => {
                results = results.data()
                console.log('results', results)
                results.firstName = firstName || ''
                results.lastName = lastName || ''
                results.email = email || ''
                results.phoneNumber = phoneNumber || ''
                results.displayName = displayName
                return db.collection('TEST-users').doc(uid).set(results)
                  .then(() => {
                    return db.collection('queue').get()
                      .then((snapshot) => {
                        snapshot = snapshot.docs
                        var userInQueue = false
                        var userObj
                        snapshot.forEach((doc) => {
                         doc = doc.data()
                          if (doc.uid && doc.uid === uid) {
                            userInQueue = true
                            // doc.displayName = displayName
                            userObj = doc
                          }
                        })
                        if (userInQueue) {
                          return db.collection('queue').doc(userObj.id).update({ displayName: displayName })
                            .then(() => {
                              return db.collection('queue-archive').doc(userObj.id).update({ displayName: displayName })
                                .then(() => {
                                  if (userObj.songChoice && userObj.songChoice.requestId) {
                                    return db.collection('requests').doc(userObj.songChoice.requestId).update({ requestedBy: displayName })
                                    .then(() => {
                                      props.setUpdateProfileModal()
                                      history.push('/how-it-works')
                                      window.location.reload()
                                    })
                                  } else {
                                    props.setUpdateProfileModal()
                                    history.push('/how-it-works')
                                      window.location.reload()
                                  }
                                })
                            })
                        } else {
                          props.setUpdateProfileModal()
                          history.push('/how-it-works')
                          window.location.reload()
                        }
                      })
                  })
              })
          } else {
            setDisplayNameAlreadyExistsSnackBar(true)
          }
        })
    }
  }



  return (
    <div style={{ marginBottom: "10px" }} className={classes.formContainer}>
      <form
      >
        <GridContainer justify="center">
          <GridItem xs={11}>
            <GridItem xs={12} sm={12}>
              <CustomInput
                labelText="Display Name"
                id="displayName"
                defaultValue={stateDisplayName ? stateDisplayName : displayName}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "text",
                  onChange: (event) => setDisplayName(event.target.value),
                  value: displayName
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12}>
              <CustomInput
                labelText="First Name"
                id="firstName"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "text",
                  onChange: (event) => setFirstName(event.target.value),
                  value: firstName
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12}>
              <CustomInput
                labelText="Last Name"
                id="lastName"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "text",
                  onChange: (event) => setLastName(event.target.value),
                  value: lastName
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12}>
              <CustomInput
                emailInput
                labelText="Email"
                id="email"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  required: true,
                  type: "text",
                  onChange: (event) => setEmail(event.target.value),
                  value: email
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12}>
              <CustomInput
                labelText="Phone Number"
                id="phone"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  maxLength: "16",
                  type: "text",
                  onChange: (event) => setPhoneNumber(event.target.value),
                  value: phoneNumber

                }}
              />
            </GridItem>

            {/* <GridItem xs={12} sm={12}>
              <CustomInput
                labelText="Bio"
                id="bio"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  required: false,
                  type: "text",
                  onChange: (event) => setBio(event.target.value),
                  value: bio
                }}
              />
            </GridItem> */}
            <Button
              onClick={(e) => saveChanges(e)}
              type="submit"
              color="primary"
              style={{
                marginLeft: "10px",
                marginBottom: "10px"
              }}
            >
              Save Changes
            </Button>
          </GridItem>
        </GridContainer>
      </form>

      <Snackbar open={fieldsNotFilled} onClose={() => setFieldsNotFilled(false)}>
        <Alert onClose={() => setFieldsNotFilled(false)} severity='error'>
          Your display name field is mandatory
        </Alert>
      </Snackbar>


      <Snackbar open={displayNameAlreadyExistsSnackBar} onClose={() => setDisplayNameAlreadyExistsSnackBar(false)}>
        <Alert onClose={() => setDisplayNameAlreadyExistsSnackBar(false)} severity='error'>
          Unfortunately that display name has been taken, please try a different one.
        </Alert>
      </Snackbar>



    </div>
  );
}

ProfileForm.propTypes = {
  classes: PropTypes.object.isRequired
};


const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    authUser: state.auth.user,
    user: state.profile.user,
    assessments: state.assessment,
    profile: state.profile,
    displayName: state.auth.displayName,
    stateEmail: state.auth.email
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUpdateProfileModal: () => dispatch(setUpdateProfileModal())
  }
}


export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ProfileForm));
