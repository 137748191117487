import {
  container,
  cardTitle,
  dangerColor,
  whiteColor,
  linkText
} from "assets/jss/material-dashboard-pro-react.jsx";

const requestsStyles = theme => ({
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px"
    }
  },
requestsForm: {
  height: "400px",
},
requestsFormHeader: {
  textAlign: "center",
  fontWeight: 500
},
formDiv: {
  display: "flex",
  flexDirection: "column",
  height: "100%"
},
form: {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
   height: "100%"
},
formTextField: {
  width: "25%"
},
submitBtn: {
  width: "25%",
  margin: "58px auto 0px auto"
},
tooFarCardBody: {
display: "flex",
alignItems: "center",
textAlign: "center"
},
tooFarCardBodyText: {
  fontSize: "30px"
}
 });

export default requestsStyles;
