/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Style from "assets/jss/material-dashboard-pro-react/views/actionsStyle";

import Logo from "assets/img/pclBigLogo.png";
import Loader from "assets/img/loader.gif";

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      updateActionModal: false,
      editActionID: "",
      editActionTitle: "",
    };
  }

  handleClickOpen = (modal, actionID, actionTitle) => {
    var x = [];
    x[modal] = true;
    this.setState({
      ...x,
      editActionID: actionID,
      editActionTitle: actionTitle,
    });
  };

  handleClose = (modal) => {
    var x = [];
    x[modal] = false;
    this.setState(x);
  };

  handleChange = (docID) => {
    console.log(docID);
  };

  render() {
    const { AuthBool, classes } = this.props;

    if (AuthBool) {
      if (
        this.props.actionsData.dataLoaded &&
        this.props.users.dataLoaded &&
        !this.state.dataLoaded
      ) {
        this.loadData();
      }
    }

    if (
      this.state.dataLoaded &&
      this.props.user.email === this.props.authUser.email
    ) {
      if (
        this.props.user.firstName &&
        this.props.user.lastName &&
        this.props.user.phoneNumber
      ) {
        return <Redirect to="/favorites" />;
      } else {
        return <Redirect to="/how-it-works" />;
      }
    }

    return (
      <div>
        <div className={classes.easeInOut}>
          <div
            style={{
              height: "40%",
              zIndex: "99999",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              marginTop: "10%",
            }}
          >
            <img className={classes.loaderImg} src={Logo} alt="..." />
            <img
              style={{
                // margin: "30% 0% 0% 33%",
                position: "relative",
                width: "15%",
              }}
              src={Loader}
              alt="..."
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    AuthBool: !!state.auth.user,
    actionsData: state.actions.actions,
    users: state.actions.users,
    authUser: state.auth.user,
    user: state.profile.user,
    displayName: state.auth.displayName,
  };
};

export default compose(connect(mapStateToProps), withStyles(Style))(Orders);
